import React, {ChangeEvent, useEffect, useState} from "react";
import {translate} from "../../selectors/translations";
type PropsFromState = {
    textToEdit?: string;
    onSubmitCb: (data: string) => void;
    onCancelCb: () => void;
};

const EditNoteForm: React.FC<PropsFromState> = (props: PropsFromState) => {
    const { textToEdit = '', onSubmitCb, onCancelCb } = props;
    const [ note, setNote ] = useState('');
    const [ isNoteValid, setNoteValid ] = useState(false);

    useEffect(() => {
        setNote(textToEdit);
    }, [textToEdit]);

    useEffect(() => {
        const isValid = !!(typeof note === "string" && note.length);
        setNoteValid(isValid);
    }, [note]);

    const onSubmitHandler = (): void => {
        if (onSubmitCb && onSubmitCb instanceof Function) {
            onSubmitCb(note);
        }
    };

    const onCancelHandler = (): void => {
        if (onCancelCb && onCancelCb instanceof Function) {
            onCancelCb();
        }
    };

    const onChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        setNote(event.target.value)
    };

    return (
        <div>
            <div>
                <textarea
                    className="note-textarea"
                    rows={6}
                    onChange={onChangeHandler}
                    value={note}>
                </textarea>
            </div>
            <div className="flex justify-between align-center pt-10">
                { isNoteValid &&
                    <button className="g_btn g_btn--primary"
                            onClick={onSubmitHandler}>
                        {translate('add_sign_note_button')}
                    </button>
                }
                { !isNoteValid &&
                <button className="g_btn confirmation_btn--cancel" disabled>
                    {translate('add_sign_note_button')}
                </button>
                }
                <button className="g_btn g_btn--outlined"
                        onClick={onCancelHandler}>
                    {translate('cancel')}
                </button>
            </div>
        </div>
    );
};

export default EditNoteForm;
