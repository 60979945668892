export interface Institution {
  name: string;
  id: string;
}

export interface InstitutionsState {
  all: Institution[];
  errorMsg: string;
}

export enum InstitutionEnum {
  GET_INSTITUTIONS_REQUEST = '@@institutions/GET_INSTITUTIONS_REQUEST',
  GET_INSTITUTIONS_SUCCESS = '@@institutions/GET_INSTITUTIONS_SUCCESS',
}

interface GetInstitutionRequest {
  type: typeof InstitutionEnum.GET_INSTITUTIONS_REQUEST;
}

interface GetInstitutionSuccess {
  type: typeof InstitutionEnum.GET_INSTITUTIONS_SUCCESS;
  payload: Array<Institution>;
}

export type InstitutionsActionType = GetInstitutionRequest | GetInstitutionSuccess;
