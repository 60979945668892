import React, {useCallback} from "react";
import './CustomRadioButton.css';

interface Props {
    isSelected: boolean;
    value?: any;
    onClick?: (value?: any) => void;
}

const CustomRadioButton: React.FC<Props> = (props: Props) => {
    const { isSelected, value, onClick } = props;

    const handleClick = useCallback(() => {
        onClick && onClick(value);
    }, [value, onClick]);

    const selectedClassName = isSelected ? 'selected' : '';

    return (
        <div
            className={`radio-icon ${selectedClassName}`}
            onClick={handleClick}></div>
    );
}

export default CustomRadioButton;
