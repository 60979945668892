import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { connect, useSelector } from 'react-redux'

import AccountForm from '../../components/AccountForm/AccountForm'

import { AccountsActionType } from '../../store/Accounts/types'
import { createRequest, unsetAccountInfo } from '../../store/Accounts/action'
import { ApplicationState } from '../../store'
import { AccountFormMode } from "./constants"
import { MedStaffDto } from "../../sdk"
import { getInstitutionsSelector, institutionName } from "../../selectors/institutions"

type PropsFromState = {
  authUser: MedStaffDto;
}

type PropsFromDispatch = {
  createRequest: (data: MedStaffDto) => void;
  unsetAccountInfo: () => void;
}

type RouteParams = {
  id: string | undefined;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps<RouteParams>;

type MedPersonalNumber = {
  medPersonalNumber: string;
}

type AccountFormData = MedStaffDto & MedPersonalNumber;

const AccountCreatePage: React.FC<AllProps> = (props: AllProps) => {
  const {authUser} = props
  // used to rerender after institutions are pulled
  useSelector(getInstitutionsSelector)

  useEffect(() => {
    props.unsetAccountInfo()
  }, [])

  const saveAccountInfo = (accountData: AccountFormData) => {
    const normalizedPNR = accountData.medPersonalNumber.toString().replace(/-/g, '').trim()
    props.createRequest({...accountData, personalNumber: normalizedPNR})
    props.history.push('/accounts')
  }

  return (
    <AccountForm
      data={{
        firstName: '',
        gender: 'male',
        language: 'sv',
        roles: 'doctor',
        lastName: '',
        institution: institutionName(authUser.institution),
        country: 'sv',
        email: '',
        medPersonalNumber: ''
      }}
      mode={AccountFormMode.CreateNewAccount}
      onCancel={(): void => props.history.push('/accounts')}
      onSubmit={saveAccountInfo}>
    </AccountForm>
  )
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  authUser: state.auth.user,
})

const mapDispatchToProps = (dispatch: (action: AccountsActionType) => void): PropsFromDispatch => ({
  createRequest: (data: MedStaffDto): void => dispatch(createRequest(data)),
  unsetAccountInfo: (): void => dispatch(unsetAccountInfo())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountCreatePage)

