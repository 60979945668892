import React from 'react';
import { ReactComponent as ClockIcon } from '../../../assets/img/icons/clock-icon.svg';
import { translate } from '../../../selectors/translations';
import { milliToIMin } from '../../../utils/datetime';
import { TestCategoryData } from '../../../store/PatientProfile/types';

interface IProps {
  testCategoryData: TestCategoryData;
  showTitle?: boolean;
}

const PreliminaryResult: React.SFC<IProps> = (props: IProps) => {
  const { testCategoryData, showTitle = true } = props;
  const { type, spentTime, preliminaryResult } = testCategoryData;
  return (
    <div className="">
      <div className="cognitive-chart_box">
        { showTitle && <h3>{translate(type)}</h3> }
        <div className="cognitive_progress-chart">
          <span className="progress-line" style={{width: `${preliminaryResult}%`}}></span>
        </div>
        <div className="cognitive_chart-data flex justify-between align-center">
          <div className="flex-item--shrink">
            <div className="flex align-center">
              <ClockIcon className="cognitive_clock-icon" />
              <span className="bold">{`${milliToIMin(spentTime)} min`}</span></div>
          </div>
          <div className="flex-item--shrink"><span className="bold">{translate("Total points")}: {`${preliminaryResult}%`}</span></div>
        </div>
      </div>
    </div>
  );
}

export default PreliminaryResult;
