import React from 'react';
import { translate } from '../../../selectors/translations';
import { formatDate } from '../../../utils/datetime';

type PropsFromState = {
  type: string;
  onClick: () => void;
  updatedAt: string;
}

const GeneralInfo: React.SFC<PropsFromState> = (props: PropsFromState) => {
  const { type, updatedAt, onClick } = props;
  return (
    <div className="diagnostic_test_summary">
      <div className="flex-container justify-between" onClick={onClick}>
        <div className="flex-item flex-item--shrink" style={{ flex: '0 0 300px' }}>
          <div className="test-title">{translate(type)}</div>
        </div>
      </div>
      <div className={`status_box flex-item--shrink`}>
          <div className="status-holder">
          <div>{`${translate('Last Update')}: ${formatDate(updatedAt)}`}</div>
          </div>
          </div>
    </div>
  )
}

export default GeneralInfo;
