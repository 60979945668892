import {PatientProfileActionType, PatientProfileActionTypes, PatientProfileState, Survey} from './types';
import {PatientProfile} from "./models";

const initialState: PatientProfileState = new PatientProfile();

export function PatientProfileReducer(
  state = initialState,
  action: PatientProfileActionType
): PatientProfileState {
  switch (action.type) {
    case PatientProfileActionTypes.UPDATE_PATIENTS_REQUEST:
      return { ...state }
    case PatientProfileActionTypes.UPDATE_PATIENTS_SUCCESS:
      return {
        ...state,
        ...action.data
      }
    case PatientProfileActionTypes.UPDATE_PATIENTS_FAILURE:
      return {
        ...state,
      }
    case PatientProfileActionTypes.CREATE_PATIENTS_REQUEST:
      return {...state}
    case PatientProfileActionTypes.CREATE_PATIENTS_SUCCESS:
      return {
        ...state,
        ...action.data,
        redirectAfterCreation: true
      }
    case PatientProfileActionTypes.CREATE_PATIENTS_RESET:
      return {
        ...state,
        redirectAfterCreation: false
      }
    case PatientProfileActionTypes.GET_PATIENT_REQUEST:
      return { ...state };
    case PatientProfileActionTypes.GET_PATIENT_SUCCESS:
      return {
        ...state,
        ...action.patient
      }
    case PatientProfileActionTypes.UNSET_PATIENT_PROFILE:
      return {
        ...state,
        ...initialState
      }
    case PatientProfileActionTypes.UPLOAD_PROFILE_IMAGES_SUCCESS:
      return {
        ...state,
        downloads: action.data.downloads,
        pdfFileLink: action.data.fileLink
      }
    case PatientProfileActionTypes.GET_VIEWING_ATTEMPTS_SUCCESS:
      return {
        ...state,
        viewingAttempts: action.response.attempts
      }
    case PatientProfileActionTypes.GET_VIEWING_ATTEMPTS_FAILURE:
      return {
        ...state,
        viewingAttempts: []
      }
    case PatientProfileActionTypes.GET_ANAMNESIS_CONFIG_SUCCESS:
      return {
        ...state,
        anamnesisQuestions: action.response.questions,
        anamnesisSections: action.response.sections,
        anamnesisQuestionsNew: action.response.questionsNew,
        anamnesisSectionsNew: action.response.sectionsNew,
      }
    case PatientProfileActionTypes.SET_TEST_AS_VIEWED_SUCCESS:
    return {
        ...state,
        measurements: state.measurements.map((m: Survey) => m.type === action.response.type && m._id === action.response.testId ? {...m, viewingAttempts: action.response.viewingAttempts}: m),
        cognitiveTests: state.cognitiveTests.map((m: Survey) => m.type === action.response.type && m._id === action.response.testId ? {...m, viewingAttempts: action.response.viewingAttempts}: m),
      }
    case PatientProfileActionTypes.SET_PDF_REPORT_TYPE:
      return {
        ...state,
        pdfReportType: action.payload
      }
    case PatientProfileActionTypes.REFRESH_LOGIN_CODE_SUCCESS:
      return {
        ...state,
        loginCode: action.payload
      }
    case PatientProfileActionTypes.GET_NEW_API_QUESTIONER_ANSWERS_REQUEST:
      return { ...state };
    case PatientProfileActionTypes.GET_NEW_API_QUESTIONER_ANSWERS_SUCCESS:
      return { ...state, questionerAnswers: action.payload };
    case PatientProfileActionTypes.GET_NEW_API_QUESTIONER_ANSWERS_FAILURE:
      return { ...state };
    default:
      return state;
  }
}
