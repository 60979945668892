import React, {useCallback} from "react";
import {translate} from "../../selectors/translations";
import {ReactComponent as PlayIcon} from "../../assets/img/icons/play-2-icon.svg";
import './PlayButton.scss';

interface IProps {
    onClick: () => void;
}

const PlayButton: React.FC<IProps> = (props: IProps): JSX.Element => {
    const { onClick } = props;

    const handleClick = useCallback(() => {
        onClick && onClick();
    }, [onClick]);

    return (
        <button
            className="g_btn g_btn--outlined play-button"
            onClick={handleClick}>
            <span className='play-button__icon'>
                <PlayIcon />
            </span>
            <span className='play-button__label'>{translate('Play drawing process')}</span>
        </button>
    );
};

export default PlayButton;
