import { createSelector } from 'reselect';
import { BrainConfigItem } from '../store/Patients/types';
import type0Image from '../assets/img/surveyChart/type0.png';
import type1Image from '../assets/img/surveyChart/type1.png';
import type2Image from '../assets/img/surveyChart/type2.png';
import type3Image from '../assets/img/surveyChart/type3.png';
import type4Image from '../assets/img/surveyChart/type4.png';
import type5Image from '../assets/img/surveyChart/type5.png';
import type6Image from '../assets/img/surveyChart/type6.png';
import type7Image from '../assets/img/surveyChart/type7.png';

import DiagnosticLayer11 from '../assets/img/brain-config/01_1.png';
import DiagnosticLayer12 from '../assets/img/brain-config/01_2.png';
import DiagnosticLayer21 from '../assets/img/brain-config/02_1.png';
import DiagnosticLayer22 from '../assets/img/brain-config/02_2.png';
import DiagnosticLayer31 from '../assets/img/brain-config/03_1.png';
import DiagnosticLayer32 from '../assets/img/brain-config/03_2.png';
import DiagnosticLayer41 from '../assets/img/brain-config/04_1.png';
import DiagnosticLayer42 from '../assets/img/brain-config/04_2.png';
import DiagnosticLayer51 from '../assets/img/brain-config/05_1.png';
import DiagnosticLayer52 from '../assets/img/brain-config/05_2.png';
import DiagnosticLayer61 from '../assets/img/brain-config/06_1.png';
import DiagnosticLayer62 from '../assets/img/brain-config/06_2.png';
import DiagnosticLayer71 from '../assets/img/brain-config/07_1.png';
import DiagnosticLayer72 from '../assets/img/brain-config/07_2.png';
import DiagnosticLayer81 from '../assets/img/brain-config/08_1.png';
import DiagnosticLayer82 from '../assets/img/brain-config/08_2.png';
import DiagnosticLayer91 from '../assets/img/brain-config/09_1.png';
import DiagnosticLayer92 from '../assets/img/brain-config/09_2.png';
import DiagnosticLayer101 from '../assets/img/brain-config/10_1.png';
import DiagnosticLayer102 from '../assets/img/brain-config/10_2.png';
import DiagnosticLayer111 from '../assets/img/brain-config/11_1.png';
import DiagnosticLayer112 from '../assets/img/brain-config/11_2.png';
import DiagnosticLayer121 from '../assets/img/brain-config/12_1.png';
import DiagnosticLayer122 from '../assets/img/brain-config/12_2.png';
import DiagnosticLayer131 from '../assets/img/brain-config/13_1.png';
import DiagnosticLayer132 from '../assets/img/brain-config/13_2.png';
import DiagnosticLayer141 from '../assets/img/brain-config/14_1.png';
import DiagnosticLayer142 from '../assets/img/brain-config/14_2.png';
import DiagnosticLayer151 from '../assets/img/brain-config/15_1.png';
import DiagnosticLayer152 from '../assets/img/brain-config/15_2.png';
import DiagnosticLayer161 from '../assets/img/brain-config/16_1.png';
import DiagnosticLayer162 from '../assets/img/brain-config/16_2.png';
import DiagnosticLayer171 from '../assets/img/brain-config/17_1.png';
import DiagnosticLayer172 from '../assets/img/brain-config/17_2.png';
import DiagnosticLayer181 from '../assets/img/brain-config/18_1.png';
import DiagnosticLayer182 from '../assets/img/brain-config/18_2.png';
import DiagnosticLayer191 from '../assets/img/brain-config/19_1.png';
import DiagnosticLayer192 from '../assets/img/brain-config/19_2.png';
import DiagnosticLayer201 from '../assets/img/brain-config/20_1.png';
import DiagnosticLayer202 from '../assets/img/brain-config/20_2.png';
import DiagnosticLayer211 from '../assets/img/brain-config/21_1.png';
import DiagnosticLayer212 from '../assets/img/brain-config/21_2.png';

export interface SymptomSurveyScoreConfigs {
  startIndex: number;
  endIndex: number;
  maxScoreAnswerValue: number;
}

const brainConfigList = [
  {
    title: "Alzheimer's disease, prodromal - Amnestic MCI",
    frontImage: DiagnosticLayer11,
    backImage: DiagnosticLayer12,
    memory: [2, 2, 1, 1, 0, 0, 0],
    frontal: [1,0,0,0,0,0,0,-1,0,0],
    subcortical: [1,-1,0,0,0,0,0,0,0,0],
    temporal: [0,-1,1,0,0,1,0,0,0,1],
    associatedSymptoms: [2,-1,-1,-1,0,0,0,0,0,0]
  },
  {
    title: "Alzheimer's disease, mild dementia - Early onset",
    frontImage: DiagnosticLayer21,
    backImage: DiagnosticLayer22,
    memory: [2, 2, 2, 2, 1, 1, 0],
    frontal: [1,1,0,0,0,0,0,-1,0,0],
    subcortical: [1,1,0,0,0,0,0,0,0,0],
    temporal: [1,1,1,1,0,1,1,-1,0,1],
    associatedSymptoms: [2,1,-1,1,0,0,0,0,0,0]
  },
  {
    title: "Alzheimer's disease, mild-moderate dementia - Late onset",
    frontImage: DiagnosticLayer31,
    backImage: DiagnosticLayer32,
    memory: [2, 2, 2, 2, 1, 1, 0],
    frontal: [2,1,1,1,-1,0,1,1,0,0],
    subcortical: [1,1,0,0,0,0,0,0,0,0],
    temporal: [1,1,2,1,0,1,1,1,0,0],
    associatedSymptoms: [2,1,1,0,0,0,1,0,1,0]
  },
  {
    title: "Posterior cortical atrophy - PCA",
    frontImage: DiagnosticLayer41,
    backImage: DiagnosticLayer42,
    memory: [0, 1, 0, 0, 0, 0, 0],
    frontal: [0,0,0,0,0,0,0,0,0,0],
    subcortical: [1,0,0,0,0,0,0,0,0,0],
    temporal: [1,1,2,2,1,1,-1,0,1,0],
    associatedSymptoms: [1,1,0,0,0,0,0,0,0,0]
  },
  {
    title: "Alzheimer's disease with cerebrovascular injury - AD + VaD",
    frontImage: DiagnosticLayer51,
    backImage: DiagnosticLayer52,
    memory: [2, 2, 1, 1, -1, 1, 0],
    frontal: [2,2,0,1,-1,0,0,2,0,0],
    subcortical: [2,2,0,-1,1,2,-1,0,0,0],
    temporal: [1,1,2,1,1,1,1,1,1,1],
    associatedSymptoms: [2,2,1,1,1,-1,-1,-1,1,-1]
  },
  {
    title: "Vascular dementia - VaD",
    frontImage: DiagnosticLayer61,
    backImage: DiagnosticLayer62,
    memory: [0, 1, -1, -1, -1, 1, 0],
    frontal: [2,1,0,1,1,0,0,1,0,0],
    subcortical: [2,2,1,1,1,2,1,1,0,0],
    temporal: [1,1,0,0,0,1,1,0,0,0],
    associatedSymptoms: [2,1,1,1,2,-1,0,0,1,1]
  },
  {
    title: "Frontal temporal dementia, behavioral variant - FTDbv",
    frontImage: DiagnosticLayer71,
    backImage: DiagnosticLayer72,
    memory: [0, 0, 1, 1, -1, 0, 0],
    frontal: [2,2,2,2,1,1,1,1,2,-1],
    subcortical: [1,-1,0,1,0,0,0,1,0,0],
    temporal: [1,1,0,0,0,0,0,0,0,1],
    associatedSymptoms: [0,0,1,-1,1,-1,1,0,0,0]
  },
  {
    title: "Semantic dementia - SD",
    frontImage: DiagnosticLayer81,
    backImage: DiagnosticLayer82,
    memory: [1, 1, 1, -1, 0, 0, 0],
    frontal: [0,-1,0,0,0,1,-1,1,0,0],
    subcortical: [-1,0,0,0,0,0,0,-1,0,0],
    temporal: [0,0,-1,0,0,0,0,2,-1,2],
    associatedSymptoms: [0,0,-1,0,1,0,0,0,0,0]
  },
  {
    title: "Primary progressive non-fluid aphasia - PNFA",
    frontImage: DiagnosticLayer91,
    backImage: DiagnosticLayer92,
    memory: [0, 1, 0, 0, 0, 0, 0],
    frontal: [0,0,0,0,-1,2,-1,-1,0,0],
    subcortical: [1,0,0,0,0,0,0,1,-1,0],
    temporal: [0,0,0,0,0,-1,1,-1,0,2],
    associatedSymptoms: [1,1,1,0,-1,0,-1,0,0,0]
  },
  {
    title: "Lewybodydemens - DLB",
    frontImage: DiagnosticLayer101,
    backImage: DiagnosticLayer102,
    memory: [0, -1, 1, 1, -1, 1, 1],
    frontal: [1,1,0,0,0,0,0,1,0,0],
    subcortical: [1,2,2,1,1,1,-1,1,0,0],
    temporal: [1,1,2,2,1,-1,1,0,1,0],
    associatedSymptoms: [1,1,1,2,0,0,-1,2,1,-1]
  },
  {
    title: "Parkinson's disease with dementia - PDD",
    frontImage: DiagnosticLayer111,
    backImage: DiagnosticLayer112,
    memory: [0, 1, -1, 0, 0, 0, 0],
    frontal: [2,1,0,0,1,0,0,0,0,0],
    subcortical: [2,2,2,2,2,1,1,2,1,0],
    temporal: [-1,-1,1,1,-1,0,0,0,0,0],
    associatedSymptoms: [2,1,1,2,0,0,0,-1,1,0]
  },
  {
    title: "Alzheimer's disease with cerebrovascular injury - AD + VaD",
    frontImage: DiagnosticLayer121,
    backImage: DiagnosticLayer122,
    memory: [2, 2, 2, 2, 0, 2, 1],
    frontal: [1,2,1,2,2,1,1,2,1,2],
    subcortical: [2,1,1,1,2,1,1,0,0,1],
    temporal: [2,2,1,2,2,2,2,1,0,1],
    associatedSymptoms: [2,1,1,1,2,1,1,2,1,2]
  },
  {
    title: "Alzheimer's disease, prodromal - Amnestic MCI",
    frontImage: DiagnosticLayer131,
    backImage: DiagnosticLayer132,
    memory: [2, 2, 2, 1, 0, 0, 0],
    frontal: [2,2,2,2,-1,1,0,0,0,0],
    subcortical: [0,1,2,2,1,1,0,0,0,2],
    temporal: [1,-1,0,-1,0,2,1,0,0,2],
    associatedSymptoms: [0,1,0,2,0,0,0,0,0,0]
  },
  {
    title: "Alzheimer's disease with cerebrovascular injury - AD + VaD",
    frontImage: DiagnosticLayer141,
    backImage: DiagnosticLayer142,
    memory: [1, 1, 0, 2, 1, 2, 1],
    frontal: [2,1,0,1,0,1,0,1,0,1],
    subcortical: [2,2,2,2,2,2,0,0,2,2],
    temporal: [2,2,2,2,2,2,2,2,1,2],
    associatedSymptoms: [2,2,1,0,0,0,0,0,2,1]
  },
  {
    title: "Semantic dementia - SD",
    frontImage: DiagnosticLayer151,
    backImage: DiagnosticLayer152,
    memory: [1, 1, 1, 1, 2, 0, 0],
    frontal: [1,0,0,-1,0,2,0,0,0,0],
    subcortical: [0,0,0,0,0,0,1,0,0,0],
    temporal: [0,0,2,0,0,0,0,0,1,1],
    associatedSymptoms: [0,0,0,0,0,0,0,0,0,0]
  },
  {
    title: "Vascular dementia - VaD",
    frontImage: DiagnosticLayer161,
    backImage: DiagnosticLayer162,
    memory: [1, 1, 1, 1, 0, 0, 0],
    frontal: [2,1,0,1,1,2,0,0,0,0],
    subcortical: [1,2,1,1,0,1,1,0,1,0],
    temporal: [1,1,1,-1,1,0,0,0,0,0],
    associatedSymptoms: [2,1,0,0,0,0,-1,0,1,1]
  },
  {
    title: "Alzheimer's disease with cerebrovascular injury - AD + VaD",
    frontImage: DiagnosticLayer171,
    backImage: DiagnosticLayer172,
    memory: [2, 2, 2, 2, 1, 1, 0],
    frontal: [2,1,0,0,0,1,0,2,0,0],
    subcortical: [1,1,1,1,2,2,2,0,0,0],
    temporal: [1,1,0,1,1,1,1,0,0,1],
    associatedSymptoms: [1,1,0,2,1,0,0,0,0,0]
  },
  {
    title: "Alzheimer's disease, mild-moderate dementia - Late onset",
    frontImage: DiagnosticLayer181,
    backImage: DiagnosticLayer182,
    memory: [1, 2, 2, 2, 1, 2, 0],
    frontal: [2,-1,1,1,2,1,0,1,1,0],
    subcortical: [-1,-1,2,2,2,1,2,1,-1,0],
    temporal: [2,-1,-1,-1,0,0,-1,2,2,0],
    associatedSymptoms: [2,2,2,2,1,0,2,-1,-1,0]
  },
  {
    title: "Alzheimer's disease, mild dementia - Early onset",
    frontImage: DiagnosticLayer191,
    backImage: DiagnosticLayer192,
    memory: [1, 2, 1, 1, 0, 0, 0],
    frontal: [0,0,0,0,0,0,0,0,0,0],
    subcortical: [2,1,1,2,1,2,0,0,1,0],
    temporal: [0,0,0,0,0,0,0,0,0,0],
    associatedSymptoms: [0,0,0,0,0,0,0,0,0,0]
  },
  {
    title: "Alzheimer's disease, mild-moderate dementia - Late onset",
    frontImage: DiagnosticLayer201,
    backImage: DiagnosticLayer202,
    memory: [2, 2, 2, 2, 2, 0, 0],
    frontal: [0,0,0,1,1,0,0,1,1,0],
    subcortical: [1,-1,0,0,0,0,0,0,0,-1],
    temporal: [1,2,1,-1,0,-1,-1,-1,2,0],
    associatedSymptoms: [1,0,0,2,0,0,0,0,1,0]
  },
  {
    title: "Alzheimer's disease with cerebrovascular injury - AD + VaD",
    frontImage: DiagnosticLayer211,
    backImage: DiagnosticLayer212,
    memory: [1, 0, 0, 0, -1, 0, 0],
    frontal: [2,2,2,2,0,1,2,0,0,2],
    subcortical: [1,1,0,0,1,2,0,2,0,0],
    temporal: [2,-1,0,-1,0,1,0,1,0,1],
    associatedSymptoms: [2,2,2,2,1,1,2,0,2,-1]
  },
];

export const getBrainConfig = createSelector(
  [], (): BrainConfigItem[] => brainConfigList
);

export const symptomSurveyScoreConfigs: SymptomSurveyScoreConfigs = {
  startIndex: 0,
  endIndex: 46,
  maxScoreAnswerValue: 2
};

export const fCategory = [
  { grade: 2, type: 'F1', title: 'Initiative' },
  { grade: 1, type: 'F2', title: 'Planning' },
  { grade: 0, type: 'F3', title: 'Opinion' },
  { grade: 0, type: 'F4', title: 'Insight' },
  { grade: 3, type: 'F5', title: 'Emotional variation' },
  { grade: 2, type: 'F6', title: 'Language variation' },
  { grade: 1, type: 'F7', title: 'Humor' },
  { grade: 1, type: 'F8', title: 'Logic' },
  { grade: 2, type: 'F9', title: 'Behaviour' },
  { grade: 1, type: 'F10', title: 'Appetite control' }
];

export const ptCategory = [
  { grade: 2, type: 'PT1', title: 'Practical ability' },
  { grade: 1, type: 'PT2', title: 'Construction' },
  { grade: 0, type: 'PT3', title: 'Room orientation' },
  { grade: 0, type: 'PT4', title: 'Shape sense' },
  { grade: 2, type: 'PT5', title: 'Body perception' },
  { grade: 2, type: 'PT6', title: 'Computability' },
  { grade: 1, type: 'PT7', title: 'Writing ability' },
  { grade: 1, type: 'PT8', title: 'Language understanding' },
  { grade: 2, type: 'PT9', title: 'Recognition ability' },
  { grade: 1, type: 'PT10', title: 'Naming ability' }
];

export const skCategory = [
  { grade: 2, type: 'SK1', title: 'Thought tempo' },
  { grade: 3, type: 'SK2', title: 'Responsiveness' },
  { grade: 0, type: 'SK3', title: 'Operating speed' },
  { grade: 0, type: 'SK4', title: 'Muscle tone' },
  { grade: 2, type: 'SK5', title: 'Walking pattern' },
  { grade: 2, type: 'SK6', title: 'Balance' },
  { grade: 1, type: 'SK7', title: 'Fine motor skills' },
  { grade: 1, type: 'SK8', title: 'Mimic' },
  { grade: 2, type: 'SK9', title: 'Speech clarity' },
  { grade: 1, type: 'SK10', title: 'Bladder control' }
];

export const mCategory = [
  { grade: 2, type: 'M1', title: 'Proximity memory 1' },
  { grade: 1, type: 'M2', title: 'Proximity memory 2' },
  { grade: 0, type: 'M3', title: 'Planning memory 1' },
  { grade: 0, type: 'M4', title: 'Planning memory 2' },
  { grade: 2, type: 'M5', title: 'Remote memory' },
  { grade: 3, type: 'M6', title: 'Time perception 1' },
  { grade: 1, type: 'M7', title: 'Time perception 2' },
];

export const asCategory = [
  { grade: 2, type: 'AS1', title: 'Stress sensitivity' },
  { grade: 1, type: 'AS2', title: 'Depression' },
  { grade: 0, type: 'AS3', title: 'Anxiety' },
  { grade: 0, type: 'AS4', title: 'Sleep disturbance' },
  { grade: 3, type: 'AS5', title: 'Emotional Instability' },
  { grade: 2, type: 'AS6', title: 'Aggressivness' },
  { grade: 1, type: 'AS7', title: 'Delusions' },
  { grade: 2, type: 'AS8', title: 'Hallucinations' },
  { grade: 0, type: 'AS9', title: 'Confusion episodes' },
  { grade: 1, type: 'AS10', title: 'Absence attack' },
];

export const surveyCharts: { [key: string]: any } = {
  type0: type0Image,
  type1: type1Image,
  type2: type2Image,
  type3: type3Image,
  type4: type4Image,
  type5: type5Image,
  type6: type6Image,
  type7: type7Image
};
export const surveyCharts2: { [key: string]: any } = {
  'gradually-transition-in-an-insidious': type0Image,
  'gradually-transition-in-a-faster-pace': type1Image,
  'sudden-change-thereafter-unchanged': type2Image,
  'sudden-change-thereafter-improved': type3Image,
  'sudden-change-thereafter-gradual-deterioration': type4Image,
  'sudden-change-thereafter-insidious-deterioration': type5Image,
  'periodically-returning-symptoms-with-normal-functioning-periods': type6Image,
  'do-not-know-not-relevant': type7Image
};
