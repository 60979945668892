import { AppointmentsState, AppointmentsdActionType, AppointmentsEnum } from './types';

const initialState: AppointmentsState = {
  todayMeetings: [],
  meetings: [
    {
      date: new Date().getTime(),
      appointments: [
        {
          type: 'past',
          _id: '1',
          meetingDateStart: 1579158000000,
          meetingDateEnd: 1579161600000,
          patientName: 'John Sv...',
          meetingId: '1',
        },
        {
          type: 'past',
          _id: '2',
          meetingDateStart: 1579168800000,
          meetingDateEnd: 1579172400000,
          patientName: 'John Sv...',
          meetingId: '2',
        },
        {
          type: 'empty',
          meetingDateStart: 1579172400000,
          meetingDateEnd: 1579183200000,
          _id: '',
          patientName: '',
          meetingId: ''
        },
        {
          type: 'future',
          _id: '3',
          meetingDateStart: 1579183200000,
          meetingDateEnd: 1579186800000,
          patientName: 'John Sv...',
          meetingId: '3',
        },
        {
          type: 'future',
          _id: '4',
          meetingDateStart: 1579190400000,
          meetingDateEnd: 1579194000000,
          patientName: 'John Sv...',
          meetingId: '4',
        },
      ]
    },
  
    {
      date: new Date(new Date().setDate(new Date().getDate() + 1)).getTime(),
      appointments: [
        {
          type: 'empty',
          meetingDateStart: 1579244400000,
          meetingDateEnd: 1579248000000,
          _id: '',
          patientName: '',
          meetingId: ''
        },
        {
          type: 'future',
          _id: '5',
          meetingDateStart: 1579248000000,
          meetingDateEnd: 1579251600000,
          patientName: 'John Sv...',
          meetingId: '5',
        },
        {
          type: 'future',
          _id: '6',
          meetingDateStart: 1579251600000,
          meetingDateEnd: 1579255200000,
          patientName: 'John Sv...',
          meetingId: '6',
        },
        {
          type: 'empty',
          meetingDateStart: 1579255200000,
          meetingDateEnd: 1579266000000,
          _id: '',
          patientName: '',
          meetingId: ''
        },
        {
          type: 'future',
          _id: '7',
          meetingDateStart: 1579266000000,
          meetingDateEnd: 1579269600000,
          patientName: 'John Sv...',
          meetingId: '7',
        },
        {
          type: 'future',
          _id: '8',
          meetingDateStart: 1579269600000,
          meetingDateEnd: 1579273200000,
          patientName: 'John Sv...',
          meetingId: '8',
        },
      ]
    },
  
    {
      date: new Date(new Date().setDate(new Date().getDate() + 2)).getTime(),
      appointments: [
        {
          type: 'future',
          _id: '9',
          meetingDateStart: 1579330800000,
          meetingDateEnd: 1579334400000,
          patientName: 'John Sv...',
          meetingId: '9',
        },      
        {
          type: 'future',
          _id: '2',
          meetingDateStart: 1579338000000,
          meetingDateEnd: 1579341600000,
          patientName: 'John Sv...',
          meetingId: '2',
        },
        {
          type: 'future',
          _id: '3',
          meetingDateStart: 1579341600000,
          meetingDateEnd: 1579345200000,
          patientName: 'John Sv...',
          meetingId: '3',
        },
        {
          type: 'empty',
          meetingDateStart: 1579345200000,
          meetingDateEnd: 1579348800000,
          _id: '',
          patientName: '',
          meetingId: ''
        },
  
        {
          type: 'future',
          _id: '4',
          meetingDateStart: 1579352400000,
          meetingDateEnd: 1579356000000,
          patientName: 'John Sv...',
          meetingId: '4',
        },
      ]
    },
  
  ],
  errorMsg: ''
}

export function AppointmentsReducer(
  state = initialState,
  action: AppointmentsdActionType
): AppointmentsState {
  switch (action.type) {
    case AppointmentsEnum.GET_MEETINGS_REQUEST:
      return { ...state }
    case AppointmentsEnum.GET_MEETINGS_SUCCESS:
      return {
        ...state,
        meetings: [...action.data]
      }
    case AppointmentsEnum.GET_MOCKY_MEETINGS_REQUEST:
      return {...state}
    case AppointmentsEnum.GET_TODAY_MEETINGS:
      const meetings = [...state.meetings.filter(meeting => new Date(meeting.date).getDate() === new Date(action.date).getDate())]
      const appointments = meetings.length && meetings[0].appointments || [];      
      return {
        ...state,
        todayMeetings: [...appointments]
      }
    default:
      return state
  }
}

