import React from 'react';
import useTranslate from "../../hooks/useTranslate"
import { AppVersionDTO } from "../../sdk"

type PropsFromState = {
    version?: AppVersionDTO;
    onClickHandler: () => void;
    extraClassName?: string;
}

const MobileAppVersion: React.FC<PropsFromState> = (props: PropsFromState): React.ReactElement => {
  const { version, onClickHandler, extraClassName = '' } = props;
  const translateLocalazy = useTranslate()

  return (
    <div className={`app-version ${extraClassName}`} onClick={onClickHandler}>
        <p>{`${translateLocalazy('versioning.hcp')}: v. ${version?.hcpPortal || '--'}`}</p>
        <p>{`${translateLocalazy('versioning.pp')}: v. ${version?.patientPortal || '--'}`}</p>
        <p>{`${translateLocalazy('versioning.ios')}: v. ${version?.ios || '--'}`}</p>
        <p>{`${translateLocalazy('versioning.android')}: v. ${version?.android || '--'}`}</p>
        <p>{`${translateLocalazy('versioning.ss')}: v. ${version?.symptomSurvey || '--'}`}</p>
    </div>
  )
}

export default MobileAppVersion;
