import React from "react";
import {ReactComponent as PointIcon} from "../../assets/img/icons/play-icon-player.svg";

interface ProgressLinePointerProps {
    progressValue: number;
    maxIntervalValue: number;
}

const ProgressLinePointer: React.FC<ProgressLinePointerProps> = (props: ProgressLinePointerProps) => {
    const { progressValue, maxIntervalValue } = props;
    const normalizedProgress = progressValue > maxIntervalValue ? maxIntervalValue : progressValue;
    return (
        <div className="risk_progress-pointer">
            <PointIcon
                style={{ left: `${((normalizedProgress / maxIntervalValue) * 100).toFixed(2)}%` }}
                className="pointer" />
        </div>
    );
};

export default ProgressLinePointer;
