import React, { useState, useEffect } from 'react';
import {languageInUse, translate} from '../selectors/translations';
import { ReactComponent as DocumentIcon } from '../assets/img/icons/document.svg';
import IFrame from '../components/IFrame';
import callApi from "../utils/apiCaller";
import {ApiMethods, ApiDocumentsPath} from "../types/Api";
import {useSelector} from "react-redux";

interface DocumentType {
    title: string;
    iframeCode: string;
}

const Documents = (): React.ReactElement => {
  const [iframeCode, setIframeCode] = useState('');
  const [documents, setDocuments] = useState<DocumentType[] | []>([]);
  const currentLanguage = useSelector(languageInUse);
  const researchUrl = currentLanguage && currentLanguage === 'sv' ?
      'https://www.minnesmottagningen.se/forskning-evidens' : 'https://en.minnesmottagningen.se/forskning-evidens'
  useEffect(() => {
    callApi(ApiMethods.GET, `${ApiDocumentsPath.BASE_URL}`)
        .then((response) => {
            setDocuments(response.data);
            setIframeCode(response.data[0].iframeCode)
        })
    }, []);

  return (
    <div className="card-box patient-info overflow-y">
      <div className="box-title">
        <h3>{translate('documents')}</h3>
      </div>
            <div className="flex flex-column">
              {
                  documents.length && (documents as DocumentType[]).map((doc: DocumentType, index: number) => (
                  <div className="c-pointer flex align-center" key={index}>
                    <DocumentIcon className={iframeCode === doc.iframeCode ? 'document-icon green' : 'document-icon'} />
                    <div
                      onClick={(): void => setIframeCode(iframeCode === doc.iframeCode ? '' : doc.iframeCode)}
                      className="document-title">{doc.title}</div>
                  </div>
                ))
              }
                <div className="c-pointer flex align-center">
                    <DocumentIcon className="document-icon" />
                    <a href={researchUrl}
                       className="document-title"
                       target="_blank"
                       rel="noreferrer noopener">{translate('documents.researchAndEvidence')}</a>
                </div>
              {
                <IFrame embeddedHtml={iframeCode} />
              }

            </div>
    </div>
  )
}
export default Documents;
