import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect, Route } from 'react-router-dom'

import { AuthState, AuthActionType } from '../../store/Auth/types';
import {TranlsationLanguage, TranslationsActionType} from '../../store/Translations/types';
import { ApplicationState } from '../../store';
import { sessionRequest } from '../../store/Auth/action';
import {getAllQuestioners} from "../../store/Questioners/actions";
import {QuestionersActionType} from "../../store/Questioners/types";
/* eslint-disable react/prop-types */
type PropsFromRouting = {
  Component: any;
  path: string;
  exact?: boolean;
}

type PropsFromState = {
  auth: AuthState;
  languages: TranlsationLanguage[];
}

type PropsFromDispatch = {
  getSession: () => void;
  getQuestioners: () => void;
}

type allProps = PropsFromRouting & PropsFromState & PropsFromDispatch;

const RouteGuard: React.FC<allProps> = (props: allProps) => {
  const { auth, Component, getSession, getQuestioners } = props;
  useEffect(() => {
    getSession();
    getQuestioners();
  }, [])
  const render = (props: RouteComponentProps): React.ReactElement => {
    return auth.isAuthenticated
      ? <Component {...props} />
      : <Redirect to={{pathname: '/login', state: {from: props.location.pathname }}}/>
  }

  return <Route {...props} render={render} />
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  auth: state.auth,
  languages: state.translations.languages
});

const mapDispatchToProps = (dispatch: (action: AuthActionType | QuestionersActionType | TranslationsActionType) => void): PropsFromDispatch => ({
  getSession: (): void => dispatch(sessionRequest()),
  getQuestioners: (): void => dispatch(getAllQuestioners()),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {pure: false})(RouteGuard);
