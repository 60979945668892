import React, {useState} from 'react';

type PropsType = {
  totalItemsCount: number;
  pageSize: number;
  currentPage: number;
  onChangePage: (pageNumber: number) => void;
  pagesPerView?: number;
}

const Paginator: React.FC<PropsType> = ({
                                          totalItemsCount,
                                          pageSize,
                                          currentPage,
                                          onChangePage,
                                          pagesPerView = 10
                                        }) => {
  const pagesCount = Math.ceil(totalItemsCount / pageSize);
  const pages: Array<number> = [];
  for (let i = 1; i <= pagesCount; i++) {
    pages.push(i);
  }

  const portionCount = Math.ceil(pagesCount / pagesPerView);
  const [portionNumber, setPortionNumber] = useState(1);
  const startWithPageNumber = (portionNumber - 1) * pagesPerView + 1;
  const endWithPageNumber = portionNumber * pagesPerView;

  return (
    <div className="paginator">
      {portionNumber > 1 && <button onClick={() => {
        setPortionNumber(portionNumber - 1)
      }}>{'<'}</button>}

      {
        pages
          .filter(p => p >= startWithPageNumber && p <= endWithPageNumber)
          .map(p => (
            <div key={p} className={`page-wrap ${currentPage === p ? 'selected-page-wrap' : ''}`}>
              <span
                  className={`page-number ${currentPage === p ? 'selected-page' : ''}`}
                  onClick={() => {
                    onChangePage(p);
                  }}>
                {p + ' '}
              </span>
            </div>
            )
          )
      }
      {portionCount > portionNumber && <button onClick={() => {
        setPortionNumber(portionNumber + 1)
      }}>{'>'}</button>}
    </div>
  );
};

export default Paginator;
