import { RecognizeInfo } from '../Family/types';
import {
  AuthActionType,
  AuthActionTypes,
  SessionInfoResponse,
  LoginPayloadEmail,
} from './types'
import {
  AppVersionDTO,
  BankIdAttemptDto,
  BankIdStatusDto,
  LoginWithPasswordResponseDto,
  QrCodeDto,
  SessionDto
} from "../../sdk"

export const startBankId = (): AuthActionType => ({type: AuthActionTypes.START_BANK_ID_REQUEST,})
export const startBankIdSuccess = (response: BankIdAttemptDto): AuthActionType => ({
  type: AuthActionTypes.START_BANK_ID_SUCCESS,
  response
})
export const cleanBankIdAttempt = (): AuthActionType => ({
  type: AuthActionTypes.START_BANK_ID_CLEAN,
})
export const startBankIdFail = (errorMsg: string): AuthActionType => ({
  type: AuthActionTypes.START_BANK_ID_FAILURE,
  errorMsg
})

export const getQrCodeDataRequest = (orderRef: string): AuthActionType => ({type: AuthActionTypes.GET_QR_CODE_DATA_REQUEST, orderRef})
export const getQrCodeDataSuccess = (response: QrCodeDto): AuthActionType => ({
  type: AuthActionTypes.GET_QR_CODE_DATA_SUCCESS,
  qrCodeData: response.qrCodeString!
})
export const getQrCodeDataFail = (errorMsg: string): AuthActionType => ({
  type: AuthActionTypes.GET_QR_CODE_DATA_FAILURE,
  errorMsg
})

export const checkBankIdStatusRequest = (orderRef: string): AuthActionType => ({type: AuthActionTypes.CHECK_BANK_ID_STATUS_REQUEST, orderRef})
export const checkBankIdStatusSuccess = (response: BankIdStatusDto): AuthActionType => ({
  type: AuthActionTypes.CHECK_BANK_ID_STATUS_SUCCESS,
  response
})
export const checkBankIdStatusFail = (errorMsg: string): AuthActionType => ({
  type: AuthActionTypes.CHECK_BANK_ID_STATUS_FAILURE,
  errorMsg
})

export const loginRequest = (data: LoginPayloadEmail): AuthActionType => ({type: AuthActionTypes.POST_LOGIN_REQUEST, data})
export const loginSuccess = (response: LoginWithPasswordResponseDto): AuthActionType => ({type: AuthActionTypes.POST_LOGIN_SUCCESS, response})
export const loginFail = (errorMsg: string): AuthActionType => ({type: AuthActionTypes.POST_LOGIN_FAILURE, errorMsg})

export const sessionRequest = (): AuthActionType => ({type: AuthActionTypes.GET_SESSION_REQUEST})
export const sessionSuccess = (response: SessionDto): AuthActionType => ({type: AuthActionTypes.GET_SESSION_SUCCESS, response})
export const sessionFailure = (errorMsg: string): AuthActionType => ({type: AuthActionTypes.GET_SESSION_FAILURE, errorMsg})

export const getReferrerRequest = (data: RecognizeInfo): AuthActionType => ({type: AuthActionTypes.POST_SESSION_INFO_REQUEST, payload: data})
export const getReferrerSuccess = (response: SessionInfoResponse): AuthActionType => ({type: AuthActionTypes.POST_SESSION_INFO_SUCCESS, response})
export const getReferrerFailure = (errorMsg: string): AuthActionType => ({type: AuthActionTypes.POST_SESSION_INFO_FAILURE, errorMsg});

export const setAppVersionRequest = (payload: AppVersionDTO): AuthActionType => ({type: AuthActionTypes.POST_APP_VERSION_REQUEST, payload});
export const setAppVersionSuccess = (response: AppVersionDTO): AuthActionType => ({type: AuthActionTypes.POST_APP_VERSION_SUCCESS, response});
export const setAppVersionFailure = (errorMsg: string): AuthActionType => ({type: AuthActionTypes.POST_APP_VERSION_FAILURE, errorMsg});

export const logout = (): AuthActionType => ({type: AuthActionTypes.LOGOUT});

export const setErrorMsg = (errMsg: string): AuthActionType => ({type: AuthActionTypes.SET_ERROR_MSG, errMsg});
export const setSuccessMsg = (successMsg: string): AuthActionType => ({type: AuthActionTypes.SET_SUCCESS_MSG, successMsg});
