import React, {useCallback, useEffect, useState} from "react";
import PhoneInput, {CountryData} from "react-phone-input-2";
import {translate} from "../../selectors/translations";
import 'react-phone-input-2/lib/plain.css';
import './PhoneInputWithMask.scss';

const defaultConfigs = {
    countryCode: '46',
    country: 'se',
    preferredCountries: ['se'],
    placeholder: 'Type here...',
    countryCodeEditable: false,
    masks: {
        'se': '(.).. .. .. ...'
    }
};

interface IProps {
    inputValue: string;
    onChange: (phone: string, data: any) => void;
}

const PhoneInputWithMask: React.FC<IProps> = (props: IProps): JSX.Element => {

    const { inputValue = '', onChange } = props;
    const { country, countryCodeEditable, masks, preferredCountries, placeholder } = defaultConfigs;
    const [ phoneToShow, setPhoneToShow ] = useState(inputValue);
    const [ initialValue, setInitialValue ] = useState(inputValue);

    const onChangeHandler = useCallback((value: string, data: CountryData) => {
        let phone = value;
        if (data.countryCode === defaultConfigs.country) {
            let rawNumber = value.slice(data.dialCode.length);
            if (rawNumber[0] !== '0') {
                setPhoneToShow(`${data.dialCode}0${rawNumber}`);
            } else {
                while (rawNumber[0] === '0') {
                    rawNumber = rawNumber.slice(1);
                }
                phone = `${data.dialCode}${rawNumber}`;
                setPhoneToShow(value);
            }
        }
        onChange && onChange(phone, data);
    }, [onChange]);

    const onMountHandler = (value: string, data: CountryData) => {
        if (data.countryCode === defaultConfigs.country) {
            const rawNumber = value.slice(data.dialCode.length);
            if (rawNumber[0] !== '0') {
                setPhoneToShow(`${data.dialCode}0${rawNumber}`);
            }
        }
    };

    useEffect(() => {
        if (!initialValue) {
            setInitialValue(inputValue);
            let normalizedValue = inputValue.replace('+', '');
            if (normalizedValue.slice(0, defaultConfigs.countryCode.length) === defaultConfigs.countryCode) {
                const rawNumber = normalizedValue.slice(defaultConfigs.countryCode.length);
                if (rawNumber[0] !== '0') {
                    normalizedValue = `${defaultConfigs.countryCode}0${rawNumber}`;
                }
            }
            setPhoneToShow(normalizedValue);
        }
    }, [inputValue]);

    return (
        <PhoneInput
            country={country}
            value={phoneToShow}
            preferredCountries={preferredCountries}
            placeholder={translate(placeholder)}
            countryCodeEditable={countryCodeEditable}
            onChange={onChangeHandler}
            onMount={onMountHandler}
            inputClass={'patient-input-field'}
            buttonClass={'patient-country-button'}
            dropdownClass={'patient-list-dropdown'}
            masks={masks}
        />
    );
}

export default PhoneInputWithMask;
