import React, { useCallback, useState } from 'react'
import { translate } from '../../../../selectors/translations'
import { formatDate } from '../../../../utils/datetime'
import PatientSideHistoryLayout from "../PatientSideHistoryLayout"
import { ViewHistoryDto } from "../../../../sdk"
import { generateInteractor } from "../../util"

type PropsFromState = {
  viewingAttempts: ViewHistoryDto[];
}

const PatientSideViewHistory: React.FC<PropsFromState> = (props: PropsFromState) => {
  const {viewingAttempts} = props
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const handleExpandClick = useCallback((): void => {
    setIsExpanded((prevState) => !prevState)
  }, [])
  const interactor = generateInteractor()

  return (
    <PatientSideHistoryLayout
      isExpanded={isExpanded}
      handleExpandClick={handleExpandClick}
      title={translate('specialistReviewing')}
    >
      {isExpanded &&
          <div className="card_body">
            {
              viewingAttempts.length
                ? viewingAttempts
                  .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
                  .map((a, index: number) => (
                    <article key={index} className="card_comment-box" style={{padding: '5px'}}>
                      <div className="comment_header">
                        <div className="comment_header_title flex collapse justify-between align-center">
                          <div className="flex-item flex-item--shrink">
                          <span className="download-pdf-title">
                              {interactor(a.interactor)}
                          </span>
                            <br/>
                            <span className="download-pdf-date">{formatDate(a.createdAt)}</span>
                          </div>
                        </div>
                      </div>
                    </article>
                  ))
                : (<div className="card_comment-box">
                  <div className="comment_header">
                    <div className="comment_header_title">{translate('no results')}</div>
                  </div>
                </div>)
            }
          </div>
      }
    </PatientSideHistoryLayout>
  )
}

export default PatientSideViewHistory
