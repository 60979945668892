import React, { Dispatch } from 'react'
import ModalWindow from '../../components/ModalWindow'
import {
  RemoveConfirmationPopup,
  DeleteConfirmationPopup,
  RestoreConfirmationPopup,
  DownloadPdfPopup,
  DownloadFilePopup,
  BankIdLoginPopup,
  PatientConsentConfirmation,
  IeWarningPopup,
  ConfirmationConsentInstitution,
  AppVersionPopup,
  ItemsForPDFReportPopup, RefreshLoginCodePopup,
} from '../../components/Popups/Popups'
import { NoteModal } from '../../components/Modals/Modals'
import { ApplicationState } from '../../store'
import {
  postNoteRequest,
  putNoteRequest,
  createPatientRequest,
  updatePatientRequest,
  setPdfReportType, refreshLoginCodeRequest
} from '../../store/PatientProfile/action'
import { postVoucherCode, updateVoucherCode } from '../../store/VoucherCodes/action'
import { postAskFamilySurvey } from '../../store/Family/action'
import { removeRequest, deletePatient, restorePatient } from '../../store/Patients/action'
import { connect, useDispatch } from 'react-redux'
import { ModalWindowAction } from '../../store/ModalWindowData/types'
import { closeModalWindow } from '../../store/ModalWindowData/action'
import { removeAccount, deleteAccount, restoreAccount } from '../../store/Accounts/action'
import { setAppVersionRequest } from '../../store/Auth/action'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {
  ManageSmsTemplateModal,
  AddVoucherCodeForm,
  EditVoucherCodeForm, DeleteSmsTemplateConfirmationModal
} from "../../components/VoucherCodeForm/VoucherCodeForm"
import { RequestFamilySurveyForm } from "../../components/SendFamilySurveyRequest/RequestFamilySurveyForm"
import { UserAnswersExportPopup } from "../../components/Popups/UserAnswersExportPopup"
import { deleteSmsTemplate, postSmsTemplate } from "../../store/SmsTemplate/action"

type ModalPropsDataType<T extends (props: { data: any, onConfirm: any }) => void> = Parameters<T>[0]['data']
type ModalPropsCallbackType<T extends (props: { data: any, onConfirm: any }) => void> = Parameters<T>[0]['onConfirm']

function ModalBuilder<T extends (props: { data: any, onConfirm: any }) => React.JSX.Element>(modal: T) {
  return function buildModal(data: ModalPropsDataType<T>, onConfirm: ModalPropsCallbackType<T>) {
    return modal({data, onConfirm})
  }
}

const ModalWindowTypes = {
  'remove': ModalBuilder(RemoveConfirmationPopup),
  'delete': ModalBuilder(DeleteConfirmationPopup),
  'restore': ModalBuilder(RestoreConfirmationPopup),
  'notePopup': ModalBuilder(NoteModal),
  'downloadPopup': ModalBuilder(DownloadPdfPopup),
  'downloadFile': ModalBuilder(DownloadFilePopup),
  'bankIdLogin': ModalBuilder(BankIdLoginPopup),
  'summaryPopup': ModalBuilder(NoteModal),
  'createPatientConfirmation': ModalBuilder(PatientConsentConfirmation),
  'institutionConsent': ModalBuilder(ConfirmationConsentInstitution),
  'ieWarning': ModalBuilder(IeWarningPopup),
  'postVoucherCode': ModalBuilder(AddVoucherCodeForm),
  'requestFamilySurvey': ModalBuilder(RequestFamilySurveyForm),
  'updateVoucherCode': ModalBuilder(EditVoucherCodeForm),
  'postSmsTemplate': ModalBuilder(ManageSmsTemplateModal),
  'updateSmsTemplate': ModalBuilder(ManageSmsTemplateModal),
  'deleteSmsTemplate': ModalBuilder(DeleteSmsTemplateConfirmationModal),
  'appVersion': ModalBuilder(AppVersionPopup),
  'selectItemsForPDFReport': ModalBuilder(ItemsForPDFReportPopup),
  'exportAnswersData': ModalBuilder(UserAnswersExportPopup),
  'refreshLoginCode': ModalBuilder(RefreshLoginCodePopup),
} as const

export type ModalWindowType = keyof typeof ModalWindowTypes
export type ModalWindowProps<T extends ModalWindowType> = Parameters<typeof ModalWindowTypes[T]>[0]

type ModalWindowPropsFromState<T extends ModalWindowType> = {
  data: ModalWindowProps<T>;
  questionNumber?: string;
  isOpened: boolean;
  type: T;
}


type AllProps<T extends ModalWindowType> =
  ModalWindowPropsFromState<T>
  & RouteComponentProps;

const BASE_URL: string = (process.env.REACT_APP_BASE_URL as string)

export type ModalWindowHandlerActionEnum = keyof ReturnType<typeof ModalWindowCallbackHandlers>
export type ModalWindowHandlerCallbackEnum<T extends ModalWindowHandlerActionEnum> = Parameters<ReturnType<typeof ModalWindowCallbackHandlers>[T]>[0]

const ModalWindowCallbackHandlers = (dispatch: Dispatch<any>) => ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  'cancel': (data: null) => dispatch(closeModalWindow()),
  'removePatient': (...data: Parameters<typeof removeRequest>) => dispatch(removeRequest(...data)),
  'deletePatient': (...data: Parameters<typeof deletePatient>) => dispatch(deletePatient(...data)),
  'restorePatient': (...data: Parameters<typeof deletePatient>) => dispatch(restorePatient(...data)),
  'removeAccount': (...data: Parameters<typeof removeAccount>) => dispatch(removeAccount(...data)),
  'deleteAccount': (...data: Parameters<typeof deleteAccount>) => dispatch(deleteAccount(...data)),
  'restoreAccount': (...data: Parameters<typeof restoreAccount>) => dispatch(restoreAccount(...data)),
  'createPatient': (...data: Parameters<typeof createPatientRequest>) => {
    dispatch(createPatientRequest(...data))
    dispatch(closeModalWindow())
  },
  'addNote': (...data: Parameters<typeof postNoteRequest>) => dispatch(postNoteRequest(...data)),
  'editNote': (...data: Parameters<typeof putNoteRequest>) => dispatch(putNoteRequest(...data)),
  'downloadFile': (data: { fileLink: string }) => {
    window.open(`${BASE_URL}/users/download/${data.fileLink}`, '_target')
    dispatch(closeModalWindow())
  },
  'updateVoucherCode': (...data: Parameters<typeof updateVoucherCode>) => dispatch(updateVoucherCode(...data)),
  'postVoucherCode': (...data: Parameters<typeof postVoucherCode>) => dispatch(postVoucherCode(...data)),
  'postSmsTemplate': (...data: Parameters<typeof postSmsTemplate>) => {
    dispatch(postSmsTemplate(...data))
    dispatch(closeModalWindow())
  },
  'deleteSmsTemplate': (...data: Parameters<typeof deleteSmsTemplate>) => {
    dispatch(deleteSmsTemplate(...data))
    dispatch(closeModalWindow())
  },
  'requestFamilySurvey': (...data: Parameters<typeof postAskFamilySurvey>) => dispatch(postAskFamilySurvey(...data)),
  'setAppVersion': (...data: Parameters<typeof setAppVersionRequest>) => dispatch(setAppVersionRequest(...data)),
  'setPdfReportType': (...data: Parameters<typeof setPdfReportType>) => {
    dispatch(setPdfReportType(...data))
    dispatch(closeModalWindow())
  },
  'refreshLoginCode': (...data: Parameters<typeof refreshLoginCodeRequest>) => dispatch(refreshLoginCodeRequest(...data)),
  'addInstitution': (...data: Parameters<typeof updatePatientRequest>) => dispatch(updatePatientRequest(...data)),
})

const ModalWindowGuard = <T extends ModalWindowType>(props: AllProps<T>) => {
  const {type, isOpened, data} = props
  const dispatch = useDispatch()

  const handleCallback = (props: ModalWindowAction<any>) => {
    return ModalWindowCallbackHandlers(dispatch)[props.action](props.data)
  }

  return (
    <div>
      {
        isOpened
          ? <ModalWindow component={ModalWindowTypes[type](data, handleCallback)}/>
          : null
      }
    </div>
  )
}

const mapStateToProps = (state: ApplicationState) => ({
  ...state.modalWindowData
})

export default withRouter(connect(mapStateToProps)(ModalWindowGuard))
