import React, { useEffect, useReducer } from "react"
import { usePrevious } from "../hooks/usePrevious"
import { datadogLogs } from "@datadog/browser-logs"

type PropsFromState = {
  route: string;
  routeParams?: { [key: string]: string | number | boolean };
};

const origin = () => process.env['NODE_ENV'] === 'development' ? 'http://localhost:13131' : document.location.origin

function onScroll(e: Event) {
  // @ts-ignore
  const height = e.target.scrollHeight - window.innerHeight
  // @ts-ignore
  const scroll = e.target.scrollTop
  const center = height / 2
  let progress
  if (scroll < center) {
    progress = -1 + (scroll / center)
  } else {
    progress = 1 - (center / scroll)
    progress += progress / 1.75
  }

  // @ts-ignore
  document.getElementById('flutter')!.contentWindow.postMessage(JSON.stringify({
    type: "scroll",
    progress: progress
  }), origin())
}

function onFlutterEvent(e: MessageEvent) {
  const flutterIframe = document.getElementById('flutter')
  if (!flutterIframe) {
    return
  }

  try {
    const event = JSON.parse(e.data)
    const element = document.querySelector('#testSection')!
    const currentScroll = element.scrollTop
    const contentIsVisible = currentScroll <= event.y && currentScroll + window.innerHeight >= event.y

    switch (event.type) {
      case 'flutter_size_changed':
        flutterIframe.style.height = `${event.height}px`
        break
      case 'scroll_flutter':
        if (!contentIsVisible) {
          element!.scrollTo(0, event.y)
        }
        break
    }
  } catch (e) {
    datadogLogs.logger.error('Receiving flutter event failed', {}, e as Error)
  }
}

const FlutterRoute: React.FC<PropsFromState> = (props: PropsFromState) => {
  const {route, routeParams} = props
  const [iframeKey, forceUpdate] = useReducer(x => x + 1, 0)
  const prevLang = usePrevious(routeParams && routeParams.lang)
  let params = '?'
  for (const key in routeParams) {
    params += `${key}=${routeParams[key]}&`
  }
  params += `token=${window.localStorage.getItem('token')}`

  useEffect(() => {
    const currentLang = routeParams && routeParams.lang
    if (prevLang !== currentLang) {
      forceUpdate()
    }
  }, [routeParams])

  const src = `${origin()}/flutter/#/${route}${params}`

  useEffect(() => {
    window.addEventListener('message', onFlutterEvent, false)
    document.querySelector('#testSection')!.addEventListener('scroll', onScroll, false)

    return () => {
      window.removeEventListener('message', onFlutterEvent)
      document.querySelector('#testSection')!.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <iframe key={iframeKey} id="flutter" src={src} width="100%" frameBorder="0" allow="clipboard-read; clipboard-write"
            scrolling="no"></iframe>
  )
}


export default FlutterRoute
