import React, { useState, useEffect } from 'react';
import { translate } from '../../selectors/translations';

import { ReactComponent as CloseIcon } from '../../assets/img/icons/close-icon.svg';
import { ModalWindowAction } from '../../store/ModalWindowData/types';

interface NoteProps {
  onConfirm: (data: ModalWindowAction<any>) => void;
  data: any;
}

export const NoteModal= (props: NoteProps) => {
  const { data, onConfirm } = props;
  const [note, setNote] = useState('');

  useEffect(() => {
    if (data.comment) {
      setNote(data.comment)
    }
  }, [data])

  const handleSubmit = (): void => {
    if (!note) {
      return;
    }
    switch (data.type) {
      case 'addNote':
        onConfirm({ action: 'addNote', data: { userId: data.userId, comment: note } });
        break;
      case 'editNote':
        onConfirm({ action: 'editNote', data: { userId: data.userId, comment: note, noteId: data.noteId } });
        break;
    }
  }

  return (
    <div>
      <div>
        <div className="note-popup_header flex collapse justify-between align-center">
          <div className="flex-item flex-item--stretch">{data.title}</div>
          <div className="flex-item flex-item--shrink">
            <button type="button" onClick={(): void => onConfirm({ action: 'cancel', data: null })}><CloseIcon /></button>
          </div>
        </div>
        <div className="note-popup_body">
          <p className="note-popup_info">{data.text}</p>
          <textarea
            rows={8}
            value={note}
            onChange={(e): void => setNote(e.target.value)}>
          </textarea>
        </div>
        <div className="note-popup_footer">
          <button className="g_btn g_btn--primary" onClick={handleSubmit}>{translate('save')}</button>
          <button className="g_btn g_btn--outlined" onClick={(): void => onConfirm({ action: 'cancel', data: null })}>{translate('cancel')}</button>
        </div>
      </div>
    </div>
  )
}
