import {
  ModalWindowHandlerActionEnum,
  ModalWindowType, ModalWindowHandlerCallbackEnum
} from "../../hoc/ModalWindowGuard/ModalWindowGuard"

export enum ModalWindowDataTypes {
  OPEN_MODAL_WINDOW = '@@modalWindowData/OPEN_MODAL_WINDOW',
  CLOSE_MODAL_WINDOW = '@@modalWindowData/CLOSE_MODAL_WINDOW',
}

export interface ModalWindowState {
  data: {[key: string]: string | number | [] | object};
  text: string[];
  type: ModalWindowType;
  isOpened: boolean;
}

export interface ModalWindowData {
  component: React.ReactElement;
  data: {[key: string]: string | number | [] | object};
  text: string[];
}

export interface ModalWindowAction<T extends ModalWindowHandlerActionEnum> {
  action: T;
  data: ModalWindowHandlerCallbackEnum<T>;
}
export type ModalWindowCallbackHandler<T extends ModalWindowHandlerActionEnum> = (data: ModalWindowAction<T>) => void


export interface OpenModalWindow {
  type: typeof ModalWindowDataTypes.OPEN_MODAL_WINDOW;
  data?: {[key: string]: string | number | [] | object};
  modalType: ModalWindowType;
  questionNumber?: string;
}

export interface CloseModalWindow {
  type: typeof ModalWindowDataTypes.CLOSE_MODAL_WINDOW;
}

export type ModalWindowDataType =
  OpenModalWindow |
  CloseModalWindow;
