import { ApplicationState } from '../store'
import { Institution } from "../store/Institutions/types"
import { UserInstitution } from "../types/Common"
import store from "../configureStore"

export const getInstitutionsSelector = (state: ApplicationState): Institution[] => state.institutions.all

export const institutionName = (institution: string): string => {
  const institutions = store.getState().institutions.all

  return institutions.find(i => i.id === institution)?.name || institution
}

export const isGerasInstitution = (institution: string): boolean => {
  const name = institutionName(institution)

  return name === UserInstitution.GSAPP
}
