/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum QuestionerType {
    ANAMNESIS = 'anamnesis',
    COGNITIVE = 'cognitive',
    SYMPTOM_SURVEY = 'symptom_survey',
}
