import React from "react";
import './TooltipIconComponent.scss';
import {ReactComponent as InfoIcon} from "../../assets/img/icons/information-outline.svg";
import {translate} from "../../selectors/translations";

interface Props {
    message?: string;
    htmlMessage?: string;
    translateX?: number;
    translateY?: number;
}

const TooltipIconComponent: React.FC<Props> = (props: Props) => {

    const { message, translateX = 0, translateY = 0, htmlMessage } = props;

    const position = {
        transform: `translate(${translateX}px, ${translateY}px)`
    };

    return (
        <div className="tooltip-icon__wrapper">
            <InfoIcon className='tooltip-icon__icon'/>
            {htmlMessage &&
                <div dangerouslySetInnerHTML={{ __html: htmlMessage }}
                     style={position}
                     className="tooltip-icon__message"></div>
            }
            {message &&
                <div className="tooltip-icon__message"
                     style={position}>{translate(message)}</div>
            }
        </div>
    )
};

export default TooltipIconComponent;
