import React, {SyntheticEvent, useCallback, useState} from "react";
import { ReactComponent as SearchIcon } from '../../../../assets/img/icons/search-icon.svg';
import {translate} from "../../../../selectors/translations";

interface IProps {
    value: string;
    showSearchIcon?: boolean;
    onChange: (e: any) => void;
    placeHolder?: string;
    onKeyUp?: (e: any) => void;
}
const SearchPatientInput: React.FC<IProps> = (props: IProps): JSX.Element => {

    const { value, placeHolder, showSearchIcon = true, onChange, onKeyUp } = props;

    const [ searchFocused, setSearchFocused ] = useState(false);

    const handleSearchInput = useCallback((e: SyntheticEvent): void => {
        onChange && onChange((e.currentTarget as any).value);
    }, []);

    const handleKeyUp = useCallback((e: any): void => {
        onKeyUp && onKeyUp(e);
    }, []);

    const handleSearchFocus = useCallback(() => {
        setSearchFocused(true);
    }, []);

    const handleSearchBlur = useCallback(() => {
        setSearchFocused(false);
    }, []);

    return (
        <div className='ph-10 p-relative'>
            { showSearchIcon && <SearchIcon
                className='p-absolute search-icon'/>
            }
            <input
                className="search-block_input landing-input"
                onChange={handleSearchInput}
                onFocus={handleSearchFocus}
                onBlur={handleSearchBlur}
                onKeyUp={handleKeyUp}
                type="text"
                value={value}
                placeholder={searchFocused ? '' : (placeHolder || translate('landingPage.tile.searchInputPlaceholder'))} />
        </div>
    );
};

export default SearchPatientInput;
