/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TranslationApp {
    GERAS_MOBILE_APP = 'geras-mobile-app',
    GERAS_PATIENT_PORTAL = 'geras-patient-portal',
    GERAS_HCP = 'geras-hcp',
    GERAS_COMMON = 'geras-common',
    GERAS_SYMPTOM_SURVEY = 'geras-symptom-survey',
}
