import {QuestionersActionTypes, QuestionersActionType, QuestionersState} from './types';

const initialState: QuestionersState = {
  all: [],
};

export function QuestionersReducer(
  state = initialState,
  action: QuestionersActionType
): QuestionersState {
  switch (action.type) {
    case QuestionersActionTypes.GET_ALL_QUESTIONERS:
      return {
        ...state
      }
    case QuestionersActionTypes.GET_ALL_QUESTIONERS_SUCCESS:
      return {
        ...state,
        all: action.payload
      }
    case QuestionersActionTypes.GET_ALL_QUESTIONERS_FAILURE:
      return {
        ...state
      }
    default:
      return state;
  }
}
