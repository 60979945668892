/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppLanguage } from '../models/AppLanguage';
import type { OldQuestionerAnswerDto } from '../models/OldQuestionerAnswerDto';
import type { PatientAnswersDTO } from '../models/PatientAnswersDTO';
import type { QuestionerAnswerPointsChange } from '../models/QuestionerAnswerPointsChange';
import type { QuestionerAnswersDTOInput } from '../models/QuestionerAnswersDTOInput';
import type { QuestionerAnswersDTOOutput } from '../models/QuestionerAnswersDTOOutput';
import type { QuestionerType } from '../models/QuestionerType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class QuestionerAnswerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Save new user answer on questioner. Returns id of created answer
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public create(
        requestBody: QuestionerAnswersDTOInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/questioner-answer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Save symptom survey about the relative
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public saveSymptomSurvey(
        requestBody: QuestionerAnswersDTOInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/questioner-answer/ss',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Update existent answer as a patient
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public update(
        id: string,
        requestBody: QuestionerAnswersDTOInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/questioner-answer/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get answer by id
     * @param id
     * @returns QuestionerAnswersDTOOutput
     * @throws ApiError
     */
    public get(
        id: string,
    ): CancelablePromise<QuestionerAnswersDTOOutput> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/questioner-answer/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Update existent answer by a doctor
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public updateByDoctor(
        id: string,
        requestBody: QuestionerAnswersDTOInput,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/questioner-answer/{id}/doctor',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Revalidate existent answer
     * @param id
     * @returns any
     * @throws ApiError
     */
    public validate(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/questioner-answer/{id}/validate',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Mark answer as viewed by doctor
     * @param id
     * @param what
     * @returns any
     * @throws ApiError
     */
    public markAsViewed(
        id: string,
        what: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/questioner-answer/{id}/viewed/{what}',
            path: {
                'id': id,
                'what': what,
            },
        });
    }

    /**
     * Update existent answer points through hcp
     * @param id
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public updatePoints(
        id: string,
        requestBody: QuestionerAnswerPointsChange,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/questioner-answer/{id}/points',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get existent answer for hcp
     * @param patientId
     * @returns QuestionerAnswersDTOOutput
     * @throws ApiError
     */
    public getForPatient(
        patientId: string,
    ): CancelablePromise<Array<QuestionerAnswersDTOOutput>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/questioner-answer/patient/{patientId}',
            path: {
                'patientId': patientId,
            },
        });
    }

    /**
     * Get existent answer for hcp
     * @param patientId
     * @returns PatientAnswersDTO
     * @throws ApiError
     */
    public getForPatientV2(
        patientId: string,
    ): CancelablePromise<PatientAnswersDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/questioner-answer/patient/{patientId}/v2',
            path: {
                'patientId': patientId,
            },
        });
    }

    /**
     * Debug Speech To Text
     * @returns string
     * @throws ApiError
     */
    public debugSpeechToText(): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/questioner-answer/speech',
        });
    }

    /**
     * Export questioner answer to CSV
     * @param questionerType
     * @param language
     * @param startDate
     * @param endDate
     * @param section
     * @param latest
     * @param completedOnly
     * @returns any
     * @returns binary
     * @throws ApiError
     */
    public export(
        questionerType: QuestionerType,
        language: AppLanguage,
        startDate: string,
        endDate: string,
        section: Array<string>,
        latest: boolean = false,
        completedOnly: boolean = false,
    ): CancelablePromise<any | Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/questioner-answer/export',
            query: {
                'questionerType': questionerType,
                'language': language,
                'startDate': startDate,
                'endDate': endDate,
                'latest': latest,
                'section': section,
                'completedOnly': completedOnly,
            },
        });
    }

    /**
     * Find old answers by userId
     * @param userId
     * @returns OldQuestionerAnswerDto
     * @throws ApiError
     */
    public findOldAnswersByUserId(
        userId: string,
    ): CancelablePromise<Array<OldQuestionerAnswerDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/questioner-answer/old/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

}
