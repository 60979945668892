import React, { ReactElement } from 'react'
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg'
import { ReactComponent as RemoveIcon } from '../../assets/img/icons/remove.svg'
import { ReactComponent as RestoreIcon } from '../../assets/img/icons/restore.svg'
import { ReactComponent as UserIcon } from '../../assets/img/icons/user-icon.svg'
import { ReactComponent as ArchiveIcon } from '../../assets/img/icons/archive.svg'
import { translate } from '../../selectors/translations'
import { FilterChangedData, PatientAction } from '../../store/Patients/types'
import { FilterChangedData as AccounFilterChangedData } from '../../store/Accounts/types'
import { dateToAge, formatDate } from '../../utils/datetime'
import AccountRoles from "../AccountRoles"
import { MedStaffDto, MedStaffRole, UserDto } from "../../sdk"
import { institutionName } from "../../selectors/institutions"

type changedData = FilterChangedData | AccounFilterChangedData;

type HeadersPropsFromState = {
  onSortClick: (data: changedData) => void;
  sortOpts: { sortableColumns: string[]; isAsc: boolean; filterField: string };
  columnTitles: string[];
};

type PatientsBodyPropsFromState = {
  data: UserDto[];
  actions: string[];
  propKeys: string[];
  onActionClick: (data: PatientAction) => void | ReactElement;
}

type AccountsBodyPropsFromState = {
  data: MedStaffDto[];
  actions: string[];
  propKeys: string[];
  onActionClick: (data: PatientAction) => void | ReactElement;
  authUser: MedStaffDto;
}

const checkAccountAction = (roles: string[], userId: string, authUserId: string): boolean => {
  return roles.includes('admin') || (userId === authUserId);
};

export const TableHeaders: React.FC<HeadersPropsFromState> = (props: HeadersPropsFromState): React.ReactElement => {
  const { columnTitles, onSortClick, sortOpts } = props;
  return (
    <thead>
      <tr>
        <th>&nbsp;</th>
        {
          columnTitles
            .map((title: string, index: number) => (
              <th className={`${sortOpts.sortableColumns.includes(title) && 'c-pointer'}`}
                onClick={(): void => {
                  if (!sortOpts.sortableColumns.includes(title)) return;
                  onSortClick({ sort: { field: title, direction: !sortOpts.isAsc } })
                }}
                key={index}>
                <span>{translate(title)}</span>
                {
                  sortOpts.sortableColumns.includes(title) &&
                  (
                    <div className={`table_sort-icon ${(sortOpts.filterField === title)
                      ? (sortOpts.isAsc ? 'table_sort-icon--ascending'
                        : 'table_sort-icon--descending') : ''} `}></div>
                  )
                }
              </th>
            ))
        }
        <th className="t_left">{translate('actions')}</th>
      </tr>
    </thead>
  )
}

export const PatientTableBody: React.FC<PatientsBodyPropsFromState> = (props: PatientsBodyPropsFromState): React.ReactElement => {
  const { data, onActionClick, actions, propKeys } = props;
  return (
    <tbody>
      {data && data.length
        ?
        data.map((p: UserDto, index: number) => (
          <tr key={index} className='search_patients-table-row'>
            <td>
              <div className="user-icon_block">
                <UserIcon />
              </div>
            </td>
            <td onClick={(): void | ReactElement => onActionClick({ action: 'card', _id: p._id })}>
            {p.lastName}
            </td>
            <td onClick={(): void | ReactElement => onActionClick({ action: 'card', _id: p._id })}>{p.firstName}</td>
            <td onClick={(): void | ReactElement => onActionClick({ action: 'card', _id: p._id })}>{p.personalNumber || '-'}</td>
            <td onClick={(): void | ReactElement => onActionClick({ action: 'card', _id: p._id })}>{ dateToAge(p.birthDate)}</td>
            <td onClick={(): void | ReactElement => onActionClick({ action: 'card', _id: p._id })}>{translate(p.gender)}</td>
            <td onClick={(): void | ReactElement => onActionClick({ action: 'card', _id: p._id })}>{formatDate(p.createdAt)}</td>
            <td>
              <div className="action-btn_holder flex align-center justify-end">
                {actions.includes('edit') && <EditIcon className="action-btn-svg" onClick={(): void | ReactElement => onActionClick({ action: 'edit', _id: p._id })} />}
                {actions.includes('restore') && <RestoreIcon className="action-btn-svg" onClick={(): void | ReactElement => onActionClick({ action: 'restore', _id: p._id })} />}
                {actions.includes('remove') && <ArchiveIcon className="action-btn-svg" onClick={(): void | ReactElement => onActionClick({ action: 'remove', _id: p._id })} />}
                {actions.includes('delete') && <RemoveIcon className="action-btn-svg" style={{ fill: '#BA1717' }} onClick={(): void | ReactElement => onActionClick({ action: 'delete', _id: p._id })} />}
              </div>
            </td>
          </tr>
        ))
        : (<tr><td className="single-row" colSpan={propKeys.length + 4}>{translate('no results')}</td></tr>)
      }
    </tbody>
  )
}

export const AccountsTableBody: React.FC<AccountsBodyPropsFromState> = (props: AccountsBodyPropsFromState): React.ReactElement => {
  const { data, onActionClick, actions, propKeys, authUser } = props;
  return (
    <tbody>
      {data.length
        ?
        data.map((a, index) => (
          <tr key={index}>
            <td>
              <div className="user-icon_block">
                <UserIcon />
              </div>
            </td>
            <td onClick={(): void | ReactElement => onActionClick({ action: 'card', _id: a._id })}>{a.lastName}</td>
            <td onClick={(): void | ReactElement => onActionClick({ action: 'card', _id: a._id })}>{a.firstName}</td>
            <td onClick={(): void | ReactElement => onActionClick({ action: 'card', _id: a._id })}>{translate(a.gender)}</td>
            <td onClick={(): void | ReactElement => onActionClick({ action: 'card', _id: a._id })}>{institutionName(a.institution)}</td>
            <td onClick={(): void | ReactElement => onActionClick({ action: 'card', _id: a._id })}>
              <AccountRoles
                roles={a.roles}/>
            </td>
            <td>
              <div className="action-btn_holder flex align-center justify-end">
                {actions.includes('edit') && checkAccountAction(authUser.roles, a._id, authUser._id)
                  && <EditIcon className="action-btn-svg" onClick={(): void | ReactElement => onActionClick({ action: 'edit', _id: a._id })} />}
                {actions.includes('restore') && authUser.roles.includes(MedStaffRole.ADMIN)
                  && <RestoreIcon className="action-btn-svg" onClick={(): void | ReactElement => onActionClick({ action: 'restore', _id: a._id })} />}
                {actions.includes('remove') && authUser.roles.includes(MedStaffRole.ADMIN)
                  && <ArchiveIcon className="action-btn-svg" onClick={(): void | ReactElement => onActionClick({ action: 'remove', _id: a._id })} />}
                {actions.includes('delete') && authUser.roles.includes(MedStaffRole.ADMIN)
                  && <RemoveIcon className="action-btn-svg" style={{ fill: '#BA1717' }} onClick={(): void | ReactElement => onActionClick({ action: 'delete', _id: a._id })} />}
            </div>
            </td>
          </tr>
        ))
        : (<tr><td className="single-row" colSpan={propKeys.length + 4}>{translate('no results')}</td></tr>)
      }
    </tbody>
  )
}
