import React, {useState, useEffect} from 'react';
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { setErrorMsg, setSuccessMsg } from '../../store/Auth/action';
import { translate } from '../../selectors/translations';
import {notificationTimeout} from "../../types/constants";

const NotificationComponent: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const error = useSelector((state: ApplicationState) => state.auth.errorMsg || state.accounts.errorMsg);
  const successMsg = useSelector((state: ApplicationState) => state.auth.successMsg);
  const dispath = useDispatch();
  const handleClose = () => {
    setIsActive(false);
    dispath(setErrorMsg(''));
    dispath(setSuccessMsg(''));
  };

  useEffect(() => {
    if(error || successMsg) {
      setIsActive(true)
    }
    const timer = setTimeout(() => {
      handleClose();
    }, notificationTimeout);
    return (): void => {clearTimeout(timer)}
  }, [error, successMsg]);

  return (
    <div className="notification_box">
      {
        isActive
        ? (<div className={`notification_wrapper ${error && 'notification_wrapper--error'} ${successMsg && 'notification_wrapper--success'}`}>
            <div className="notification_header pb-20">
              <button type="button" className="notification_close-btn" onClick={handleClose}><CloseIcon /></button>
            </div>
            <div className="notification_body">{translate(error || successMsg)}</div>
          </div>)
        : ''
      }

      </div>
  );
}

export default NotificationComponent;
