import { AuthActionType, AuthActionTypes, AuthState } from './types'
import { AppLanguage, Gender } from "../../sdk"

const initialState: AuthState = {
  isFetching: false,
  authFailed: false,
  isAuthenticated: !!localStorage.getItem('token'),
  isSsnFetching: false,
  isTokenChecked: true,
  userFetched: false,
  appVersion: {
    ios: '0.0.0',
    android: '0.0.0',
    hcpPortal: '0.0.0',
    symptomSurvey: '0.0.0',
    patientPortal: '0.0.0'
  },
  isPasswordWasReset: false,
  isPasswordWasChanged: false,
  user: {
    _id: '',
    email: '',
    institution: '',
    firstName: '',
    lastName: '',
    gender: Gender.MALE,
    personalNumber:  '',
    roles: [],
    language: AppLanguage.SV,
    country: AppLanguage.SV,
    // resetPasswordToken: '',
  },
  errorMsg: '',
  successMsg: ''
}

export function AuthReducer (
  state = initialState,
  action: AuthActionType
): AuthState {
  switch(action.type) {
    case AuthActionTypes.START_BANK_ID_CLEAN:
      return {
        ...state,
        bankIdAttempt: undefined,
        qrCodeData: undefined,
      };
    case AuthActionTypes.START_BANK_ID_SUCCESS:
      return {
        ...state,
        bankIdAttempt: action.response
      };
    case AuthActionTypes.GET_QR_CODE_DATA_SUCCESS:
      return {
        ...state,
        qrCodeData: action.qrCodeData
      };
    case AuthActionTypes.CHECK_BANK_ID_STATUS_FAILURE:
    case AuthActionTypes.GET_QR_CODE_DATA_FAILURE:
      return {
        ...state,
        authFailed: true
      };
    case AuthActionTypes.START_BANK_ID_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        isPasswordWasChanged: false,
        authFailed: false
      };
    case AuthActionTypes.POST_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false,
        isPasswordWasChanged: false,
        authFailed: false
      };
    case AuthActionTypes.CHECK_BANK_ID_STATUS_SUCCESS:
      if (action.response.user) {
        return {
          ...state,
          isFetching: false,
          isAuthenticated: true,
          errorMsg: ''
        }
      }
      return state
    case AuthActionTypes.POST_LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        errorMsg: ''
      };

    case AuthActionTypes.POST_LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        isAuthenticated: false
      };
      case AuthActionTypes.GET_SESSION_SUCCESS:
      return {
          ...state,
          user: action.response.user,
          isAuthenticated: true,
          appVersion: action.response.appVersion,
        }
      case AuthActionTypes.GET_SESSION_FAILURE:
        return {
          ...state,
          user: initialState.user,
          isAuthenticated: false
        }
      case AuthActionTypes.POST_APP_VERSION_SUCCESS: {
        return {
          ...state,
          appVersion: action.response
        }
      }
      case AuthActionTypes.SET_ERROR_MSG: {
        return {
          ...state,
          errorMsg: action.errMsg,
          successMsg: ''
        }
      }
      case AuthActionTypes.SET_SUCCESS_MSG: {
        return {
          ...state,
          successMsg: action.successMsg,
          errorMsg: '',
        }
      }
    default:
      return state
  }
}
