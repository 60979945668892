export interface Meetings {
  date: number;
  appointments: Appointment[];
}

export interface Appointment {
  type: string;
  _id: string;
  meetingDateStart: number;
  meetingDateEnd: number;
  patientName: string;
  meetingId: string;
}

export interface AppointmentsState {
  todayMeetings: Appointment[];
  meetings: Meetings[];
  errorMsg: string;
}

export enum AppointmentsEnum {
  GET_MEETINGS_REQUEST = '@@appointments/GET_MEETINGS_REQUEST',
  GET_MEETINGS_SUCCESS = '@@appointments/GET_MEETINGS_SUCCESS',
  GET_MEETINGS_FAILURE = '@@appointments/GET_MEETINGS_FAILURE',
  GET_REQUEST_FAILURE = '@@appointments/GET_REQUEST_FAILURE',
  GET_TODAY_MEETINGS = '@@appointments/GET_TODAY_MEETINGS',
  GET_MOCKY_MEETINGS_REQUEST = '@@appointments/GET_MOCKY_MEETINGS_REQUEST',
}

interface GetMeetingsRequest {
  type: typeof AppointmentsEnum.GET_MEETINGS_REQUEST;
  datesRange: number[];
}

interface GetMeetingsSuccess {
  type: typeof AppointmentsEnum.GET_MEETINGS_SUCCESS;
  data: Meetings[];
}
interface GetMockyMeetingsRequest {
  type: typeof AppointmentsEnum.GET_MOCKY_MEETINGS_REQUEST;
  dates: number[];
}
interface GetRequestFailure {
  type: typeof AppointmentsEnum.GET_REQUEST_FAILURE;
  errorMsg: string;
}

interface GetTodayMeetings {
  type: typeof AppointmentsEnum.GET_TODAY_MEETINGS;
  date: number;
}




export type AppointmentsdActionType = GetMeetingsRequest | GetMeetingsSuccess | GetRequestFailure | GetMockyMeetingsRequest | GetTodayMeetings;
