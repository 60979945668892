import React from 'react';
import { translate } from '../../selectors/translations';
interface DropdownItem {
  title: string;
  isSelected: boolean;
  propToPropagate: string;
}

interface DropdownMenuProps {
  show: boolean;
  items: DropdownItem[];
  onClick: (val: string) => void;
  style?: { [key: string]: string};
}

const DropdownMenu: React.FC<DropdownMenuProps> = (props: DropdownMenuProps) => {
  const { style = {} } = props;
  return (
    <ul
        className={`selector_list-block left ${!props.show && 'hide'}`}
        style={style}
    >
      {
        props.items
          .filter((item: DropdownItem) => !item.isSelected)
          .map((item: DropdownItem, i: number) =>
            (<li
              key={i}
              onClick={(): void => props.onClick(item.propToPropagate)}
              className="ellipsis">
              {translate(item.title)}
            </li>)
          )
      }
    </ul>
  );
}

export default DropdownMenu;
