import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'

import RouteGuard from './hoc/RouteGuard';

import Documents from './pages/documents';
import Login from './pages/login';
import NotFound from './components/NotFound';
import PatientCard from './pages/patients/patientCard';
import PatientList from './pages/patients/patientList';
import PatientDeletedList from './pages/patients/patientsDeletedList';
import AccountsList from './pages/accounts/accountsList';
import AccountUpdatePage from './pages/accounts/accountUpdate';
import AccountCreatePage from './pages/accounts/accountCreate';
import AccountsDeletedList from './pages/accounts/accountsDeletedList';
import AdminPanel from './pages/adminPanel/adminPanel';
import GeneratePdf from "./pages/generatePdf/generatePdf";
import ContactsPage from "./pages/contacts";
import {useSelector} from "react-redux";
import {isSideMenuExpanded} from "./selectors/app";
import PatientCreatePage from "./pages/patients/PatientCreate/patientCreate";
import PatientAdministratePage from "./pages/patients/PatientAdministrate";
import {RouteNames} from "./types/constants";
import StartPage from "./pages/StartPage";
import { useLocation } from "react-router"

const Routes: React.FC = () => {
  const isMenuExpanded = useSelector(isSideMenuExpanded);
  const location = useLocation();
  const isMenuPossible = !['/login', '/not-found'].includes(location.pathname)
  const mainClassName = isMenuPossible && isMenuExpanded ? 'main-content-wrapper shrinked-for-sidebar' : 'main-content-wrapper';
  return (
      <main className={mainClassName}>
        <div className="page-wrapper">
          <Switch>
            <Route exact path={RouteNames.Blank} >
              {<Redirect to={RouteNames.Start}/>}
            </Route>
            <Route exact path="/login" component={Login} />
            <Route exact path="/not-found" component={NotFound} />

            <RouteGuard exact path="/admin-panel" Component={AdminPanel} />
            <RouteGuard exact path={RouteNames.Start} Component={StartPage} />
            <RouteGuard exact path={RouteNames.Patient} Component={PatientList} />
            <RouteGuard exact path={`${RouteNames.PatientCard}/:id`} Component={PatientCard} />
            <RouteGuard exact path="/patient/deleted" Component={PatientDeletedList} />
            <RouteGuard exact path={RouteNames.PatientNew} Component={PatientCreatePage} />
            <RouteGuard exact path="/patient/edit/:id" Component={PatientAdministratePage} />
            <RouteGuard exact path="/generate-pdf/:patientId" Component={GeneratePdf} />
            <RouteGuard exact path={`/accounts`} Component={AccountsList} />
            <RouteGuard exact path={`/accounts/create`} Component={AccountCreatePage} />
            <RouteGuard exact path={`/accounts/deleted`} Component={AccountsDeletedList} />
            <RouteGuard path={`/accounts/:id`} Component={AccountUpdatePage} />
            <RouteGuard path="/documents" exact Component={Documents} />
            <RouteGuard path={RouteNames.Contacts} exact Component={ContactsPage} />

            {/*<Route exact path="/family/invite/:token/:lang" component={FamilyInvitation}/>*/}
            {/*<RouteGuard path="/appointments" exact Component={AppointmentsdPage} />*/}
            {/*<RouteGuard path="/video-call" exact Component={VideoCalls} />*/}
            <Route path='*' exact={true} >
              {
                <Redirect to={RouteNames.NotFound}/>
              }
            </Route>
          </Switch>
        </div>
      </main>
    )
} ;

export default Routes;
