import { TranlsationsState, TranslationsActionType, TranslationsActionTypes, TranlsationLanguage } from './types';

const initialState: TranlsationsState = {
  languages: [],
  translationsFetched: false
};

export function TranslationReducer(
  state = initialState,
  action: TranslationsActionType
): TranlsationsState {
  switch (action.type) {
    case TranslationsActionTypes.FETCH_TRANSLATIONS:
      return {
        ...state,
        translationsFetched: false,
      };
    case TranslationsActionTypes.FETCH_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        translationsFetched: true,
        languages: state.languages.map(
            (item) => item.key === action.payload.lang ? {...item, translations: Object.assign({}, item.translations, action.payload.translations)}
                : item
        )
      };
    case TranslationsActionTypes.FETCH_TRANSLATIONS_DONE:
      return {
        ...state,
        translationsFetched: true,
      };
    case TranslationsActionTypes.SWITCH_TRANSLATION:
      return {
        ...state,
        languages: state.languages.map((l: TranlsationLanguage) => ({...l, inUse: l.key === action.language}))
      }
    default:
      return state;
  }
}
