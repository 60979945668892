import React, { useEffect, useState } from "react"
import { fetchRequest, filterChanged, getViewedPatients, resetPatientsState } from "../../store/Patients/action"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../store"
import { getPatients, selectViewedPatients } from "../../selectors/patients"
import { translate } from "../../selectors/translations"
import GreetingComponent from "./components/GreetingComponent"
import SearchPatientInput from "./components/SearchPatientInput"
import CreatePatientButton from "./components/CreatePatientButton"
import SearchResultTable from "./components/SearchResultTable"
import greenHills from '../../assets/img/greenHills.svg'
import '../../assets/scss/layouts/greenHills.scss'
import './StartPage.scss'
import { UserDto } from "../../sdk"

const StartPage: React.FC = () => {
  const [timeoutId, setTimeoutId] = useState<any>()
  const dispatch = useDispatch()
  const [patientsResults, setPatientsResults] = useState<UserDto[]>([])
  const filters = useSelector((state: ApplicationState) => state.patients.filters)
  const viewedPatientsResults: UserDto[] = useSelector(selectViewedPatients) as UserDto[]
  const patientsSearchResults: UserDto[] = useSelector(getPatients) as UserDto[]

  useEffect(() => {
    dispatch(getViewedPatients())
    return () => {
      dispatch(resetPatientsState())
    }
  }, [])

  useEffect(() => {
    if (filters.searchTerm) {
      setPatientsResults(Array.isArray(patientsSearchResults) ? patientsSearchResults : [])
    } else {
      setPatientsResults(viewedPatientsResults)
    }
  }, [filters.searchTerm, patientsSearchResults, viewedPatientsResults])

  useEffect(() => {
    clearTimeout(timeoutId)
    if (!filters || !filters.searchTerm) {
      return
    }
    const id = setTimeout(() => {
      dispatch(fetchRequest(1, filters))
    }, 500)
    setTimeoutId(id)
    return (): void => {
      clearTimeout(timeoutId)
    }
  }, [filters.searchTerm])

  const handleSearchInput = (value: string): void => {
    dispatch(filterChanged({searchTerm: value}))
  }

  return (
    <div className="flex-container pt-20">
      <div className="item--12 item-md-11 item-xl-8 m-auto">
        <div className="pb-20">
          <GreetingComponent/>
        </div>
        <div className="start-page__content-wrapper">
          <div className="start-page__create-button-wrapper">
            <CreatePatientButton/>
          </div>
          <div className="start-page__search-input-wrapper">
            <h2 className="t_left pt-20--padding-bottom margin-me--left">{translate('startPage.searchHint')}</h2>
            <SearchPatientInput
              value={filters.searchTerm}
              onChange={handleSearchInput}
              showSearchIcon={false}
              placeHolder={translate('landingPage.tile.searchInputPlaceholder')}
            />
          </div>
          <div className="start-page__search-results-wrapper">
            <SearchResultTable
              patients={patientsResults}
            />
          </div>
        </div>
      </div>
      <img className="greenHills" src={greenHills}/>
    </div>
  )
}

export default StartPage


