import { RecognizeInfo } from '../Family/types'
import {
  AppVersionDTO,
  BankIdAttemptDto,
  BankIdStatusDto,
  LoginWithPasswordResponseDto,
  MedStaffDto,
  SessionDto
} from "../../sdk"

export interface AuthState {
  isFetching: boolean;
  isAuthenticated: boolean;
  authFailed: boolean;
  isSsnFetching: boolean;
  isTokenChecked: boolean;
  userFetched: boolean;
  appVersion: AppVersionDTO;
  user: MedStaffDto;
  errorMsg: string;
  successMsg: string;
  isPasswordWasReset: boolean;
  isPasswordWasChanged: boolean;
  bankIdAttempt?: BankIdAttemptDto;
  qrCodeData?: string;
}

export interface ResetPasswordPayload {
  password: string;
  token: string;
}

export enum LoginOption {
  email = 'email',
  qrCode = 'qrCode'
}

export interface LoginPayloadEmail {
  type: LoginOption.email
  email: string;
  password: string;
}

export interface LoginPayloadQrCode {
  type: LoginOption.qrCode
}

export type LoginPayload = LoginPayloadEmail  | LoginPayloadQrCode;

export interface SessionInfoResponse {
  referrer: string;
}

export enum AuthActionTypes {
  START_BANK_ID_REQUEST = '@@auth/START_BANK_ID',
  START_BANK_ID_SUCCESS = '@@auth/START_BANK_ID_SUCCESS',
  START_BANK_ID_CLEAN = '@@auth/START_BANK_ID_CLEAN',
  START_BANK_ID_FAILURE = '@@auth/START_BANK_ID_FAILURE',

  GET_QR_CODE_DATA_REQUEST = '@@auth/GET_QR_CODE_DATA',
  GET_QR_CODE_DATA_SUCCESS = '@@auth/GET_QR_CODE_DATA_SUCCESS',
  GET_QR_CODE_DATA_FAILURE = '@@auth/GET_QR_CODE_DATA_FAILURE',

  CHECK_BANK_ID_STATUS_REQUEST = '@@auth/CHECK_BANK_ID_STATUS',
  CHECK_BANK_ID_STATUS_SUCCESS = '@@auth/CHECK_BANK_ID_STATUS_SUCCESS',
  CHECK_BANK_ID_STATUS_FAILURE = '@@auth/CHECK_BANK_ID_STATUS_FAILURE',

  POST_LOGIN_REQUEST = '@@auth/POST_LOGIN_REQUEST',
  POST_LOGIN_SUCCESS = '@@auth/POST_LOGIN_SUCCESS',
  POST_LOGIN_FAILURE = '@@auth/POST_LOGIN_FAILURE',
  GET_SESSION_REQUEST = '@@auth/GET_SESSION_REQUEST',
  GET_SESSION_SUCCESS = '@@auth/GET_SESSION_SUCCESS',
  GET_SESSION_FAILURE = '@@auth/GET_SESSION_FAILURE',
  POST_SESSION_INFO_REQUEST = '@@auth/POST_SESSION_INFO_REQUEST',
  POST_SESSION_INFO_SUCCESS = '@@auth/POST_SESSION_INFO_SUCCESS',
  POST_SESSION_INFO_FAILURE = '@@auth/POST_SESSION_INFO_FAILURE',
  LOGOUT = '@@auth/LOGOUT',
  SET_ERROR_MSG = '@@auth/SET_ERROR_MSG',
  SET_SUCCESS_MSG = '@@auth/SET_SUCCESS_MSG',

  POST_APP_VERSION_REQUEST = '@@auth/POST_APP_VERSION_REQUEST',
  POST_APP_VERSION_SUCCESS = '@@auth/POST_APP_VERSION_SUCCESS',
  POST_APP_VERSION_FAILURE = '@@auth/POST_APP_VERSION_FAILURE',
}

export interface LoginRequest {
  type: AuthActionTypes.POST_LOGIN_REQUEST;
  data: LoginPayloadEmail;
}

export interface LoginSuccess {
  type: AuthActionTypes.POST_LOGIN_SUCCESS;
  response: LoginWithPasswordResponseDto;
}

export interface LoginFail {
  type: AuthActionTypes.POST_LOGIN_FAILURE;
  errorMsg: string;
}

export interface StartBankIdRequest {
  type: AuthActionTypes.START_BANK_ID_REQUEST;
}

export interface StartBankIdSuccess {
  type: AuthActionTypes.START_BANK_ID_SUCCESS;
  response: BankIdAttemptDto;
}

export interface StartBankIdClean {
  type: AuthActionTypes.START_BANK_ID_CLEAN;
}

export interface StartBankIdFailure {
  type: AuthActionTypes.START_BANK_ID_FAILURE;
  errorMsg: string;
}

export interface GetQrCodeDataRequest {
  type: AuthActionTypes.GET_QR_CODE_DATA_REQUEST;
  orderRef: string
}

export interface GetQrCodeDataSuccess {
  type: AuthActionTypes.GET_QR_CODE_DATA_SUCCESS;
  qrCodeData: string;
}

export interface GetQrCodeDataFailure {
  type: AuthActionTypes.GET_QR_CODE_DATA_FAILURE;
  errorMsg: string;
}

export interface CheckBankIdStatusRequest {
  type: AuthActionTypes.CHECK_BANK_ID_STATUS_REQUEST;
  orderRef: string
}

export interface CheckBankIdStatusSuccess {
  type: AuthActionTypes.CHECK_BANK_ID_STATUS_SUCCESS;
  response: BankIdStatusDto;
}

export interface CheckBankIdStatusFailure {
  type: AuthActionTypes.CHECK_BANK_ID_STATUS_FAILURE;
  errorMsg: string;
}

export interface Logout {
  type: AuthActionTypes.LOGOUT;
}

export interface GetSessionRequest {
  type: AuthActionTypes.GET_SESSION_REQUEST;
}

export interface GetSessionSuccess {
  type: AuthActionTypes.GET_SESSION_SUCCESS;
  response: SessionDto;
}

export interface GetSessionFailure {
  type: AuthActionTypes.GET_SESSION_FAILURE;
  errorMsg: string;
}

export interface PostSessionInfoRequest {
  type: typeof AuthActionTypes.POST_SESSION_INFO_REQUEST;
  payload: RecognizeInfo;
}

export interface PostSessionInfoSuccess {
  type: typeof AuthActionTypes.POST_SESSION_INFO_SUCCESS;
  response: SessionInfoResponse;
}

export interface PostSessionInfoFailure {
  type: typeof AuthActionTypes.POST_SESSION_INFO_FAILURE;
  errorMsg: string;
}

export interface PostAppVersionRequest {
  type: typeof AuthActionTypes.POST_APP_VERSION_REQUEST;
  payload: AppVersionDTO;
}

export interface PostAppVersionSuccess {
  type: typeof AuthActionTypes.POST_APP_VERSION_SUCCESS;
  response: AppVersionDTO;
}

export interface PostAppVersionFailure {
  type: typeof AuthActionTypes.POST_APP_VERSION_FAILURE;
  errorMsg: string;
}

export interface SetErrorMsg {
  type: typeof AuthActionTypes.SET_ERROR_MSG;
  errMsg: string;
}

export interface SetSuccessMsg {
  type: typeof AuthActionTypes.SET_SUCCESS_MSG;
  successMsg: string;
}

export type AuthActionType = LoginRequest |
  LoginSuccess |
  LoginFail |
  GetSessionRequest |
  GetSessionSuccess |
  GetSessionFailure |
  PostSessionInfoSuccess |
  PostSessionInfoRequest |
  PostSessionInfoFailure |
  PostAppVersionRequest |
  PostAppVersionSuccess |
  PostAppVersionFailure |
  StartBankIdRequest |
  StartBankIdClean |
  StartBankIdSuccess |
  StartBankIdFailure |
  GetQrCodeDataRequest |
  GetQrCodeDataSuccess |
  GetQrCodeDataFailure |
  CheckBankIdStatusRequest |
  CheckBankIdStatusSuccess |
  CheckBankIdStatusFailure |
  Logout |
  SetErrorMsg |
  SetSuccessMsg;

