import React from 'react';

const ProgressBar: React.FC = () => {
  return (
    <div className="slider">
    <div className="line"></div>
    <div className="subline inc"></div>
    <div className="subline dec"></div>
   </div>
  );
}

export default ProgressBar;