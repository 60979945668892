import React from 'react';
import { translate } from '../../../selectors/translations';
import { FormattedAnswer } from '../../../store/PatientProfile/types';
import { ModalWindowType } from "../../../hoc/ModalWindowGuard/ModalWindowGuard"

type TestAnswerDataProps = {
  TimeUsed: React.ReactElement | null;
  Expect: React.ReactElement | null;
  Answer: React.ReactElement | null;
  Content: React.ReactElement | null;
  attempt: number;
  points: number | undefined;
  maxAttempt: number;
  openModal: (type: ModalWindowType, data: any) => void;
  answer: FormattedAnswer;
  // erase: React.ReactElement | null;
}

const TestAnswerData: React.SFC<TestAnswerDataProps> = (props: TestAnswerDataProps) => {
  const { TimeUsed, Expect, Answer, Content, attempt, points, maxAttempt} = props;
  return (
    <div className="spoiler_body">
      <div className="diagnostic_question_body p-10">
        <div className="block-title flex justify-between align-center ">
          <div className="flex-item--shrink">
            <h4>{translate('attempt')} {attempt} {translate('of')} {maxAttempt}</h4>
          </div>
          <div className="flex-item--shrink">
            {points === void 0 ? null : <h4>{translate('Points')} <strong>{points}</strong></h4>}
          </div>
        </div>
        <div>
          <div className="inner-wrapper inner-wrapper--answers flex-item--stretch">
            <article className="diagnostic_question_details flex flex--wrap">
              {TimeUsed}
              {Expect}
              {Answer}
              {Content}
            </article>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TestAnswerData;
