import { useSelector } from "react-redux"
import { getInstitutionsSelector } from "../../selectors/institutions"
import useTranslate from "../../hooks/useTranslate"
import {
  InteractionType,
  InteractorDto, MedStaffRole, PatientProfileDTO,
  PropertyChangeInteractionHistoryDto,
  QuestionerAnswerChangeHistoryDto, ReportExportHistoryDto, TextMessageHistoryDto,
  UserRole,
} from "../../sdk"

export function generateInteractor() {
  const institutions = useSelector(getInstitutionsSelector)
  const translateLocalazy = useTranslate()
  const institution = (id: string) => institutions.find(i => i.id == id)?.name

  const role = (roles: (UserRole | MedStaffRole)[]) => {
    return roles.map(r => translateLocalazy(r)).join(', ')
  }

  return (i?: InteractorDto) => {
    if (!i) {
      return ''
    }
    return `${role(i.roles)} ${i.firstName} ${i.lastName} (${institution(i.institution)})`
  }
}

type InteractionHistoryItem = PatientProfileDTO['interactionHistory'][0]

export function isPropertyChange(item: InteractionHistoryItem): item is PropertyChangeInteractionHistoryDto {
  return item.kind == InteractionType.PROPERTY_CHANGE
}

export function isReportExport(item: InteractionHistoryItem): item is ReportExportHistoryDto {
  return item.kind == InteractionType.REPORT_EXPORT
}

export function isMessage(item: InteractionHistoryItem): item is TextMessageHistoryDto {
  return item.kind == InteractionType.TEXT_MESSAGE
}

export function isAnswerChange(item: InteractionHistoryItem): item is QuestionerAnswerChangeHistoryDto {
  return item.kind == InteractionType.QUESTIONER_ANSWER_CHANGE
}
