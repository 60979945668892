import React from "react";

type PropsFromState = {
    title: string;
};

const HttpPendingStub: React.FC<PropsFromState> = (props: PropsFromState) => {
    const { title = '' } = props;
    return (
        <div
            className="flex w-100 h-100 justify-center align-center"
            style={{ zIndex: 100, backgroundColor: '#ffffff' }}>
            <p className="m-0">{ title }</p>
        </div>
    );
};

export default HttpPendingStub;
