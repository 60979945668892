import {
  ModalWindowState, ModalWindowDataType, ModalWindowDataTypes
} from './types';

const initialState: ModalWindowState = {
  data: {},
  text: [],
  type: 'remove',
  isOpened: false
}

export function ModalWindowDataReducer(
  state = initialState,
  action: ModalWindowDataType
): ModalWindowState {
  switch (action.type) {
    case ModalWindowDataTypes.OPEN_MODAL_WINDOW:
      return {
        ...state,
        isOpened: true,
        data: action.data || {},
        type: action.modalType
      }
    case ModalWindowDataTypes.CLOSE_MODAL_WINDOW:
      return {
        ...state,
        isOpened: false,
        data: {},
        text: []
      }
    default:
      return {...state}
  }
}
