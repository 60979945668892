import React from 'react';
import { ProgerssLineSetup } from '../../../types/Common';
import { ReactComponent as InfoIcon } from '../../../assets/img/icons/information-outline.svg';
import { translate } from '../../../selectors/translations';
import ProgressLinePointer from "../../ProgressLinePointer";

type ProgressLineProps = {
  isRevert: boolean;
  noPrint?: boolean;
  lineSetup: ProgerssLineSetup;
  progressValue: number;
  legend: string;
  infoLegendKey: string;
}

const ProgressLine: React.FC<ProgressLineProps> = (props: ProgressLineProps) => {
  const { isRevert, lineSetup, progressValue, legend, infoLegendKey, noPrint } = props;
  const maxIntervalValue = lineSetup.intervals[lineSetup.intervals.length - 1];
  return (
    <div>
      <div className="risk_progress-legend flex align-center">
        <span dangerouslySetInnerHTML={{ __html: legend }}></span>
        <InfoIcon className={`info-icon ${noPrint ? 'no-print': null}`}/>
        <div dangerouslySetInnerHTML={{ __html: lineSetup.generalLegend + translate(infoLegendKey) }} className="info_list"></div>
      </div>
        <ProgressLinePointer
            progressValue={progressValue}
            maxIntervalValue={maxIntervalValue}
        />
      <div className="risk_progress-chart">
        <span className={`${isRevert ? 'progress-line progress-line-cognitive--revert' : 'progress-line progress-line-cognitive'}`}></span>
        {
          lineSetup.intervals
            .filter((intervalValue: number, i: number) => (i > 0 && i < (lineSetup.intervals.length - 1)))
            .map((intervalValue: number, i: number) =>
              <span
                  style={{ left: `${((intervalValue / maxIntervalValue) * 100).toFixed(2)}%` }}
                  key={i}
                  className="progress-line-border"></span>
            )
        }
        {
          lineSetup.intervalHole
            && lineSetup.intervalHole.map((intHole: [number, number], i: number) =>
              <span
                key={i}
                style={{
                  left: `${((intHole[0] / maxIntervalValue) * 100).toFixed(2)}%`,
                  width: `${(((intHole[1] - intHole[0]) / maxIntervalValue) * 100).toFixed(2)}%`
                }}
                className="progress-line-hole"></span>)
        }
      </div>
      <div className="risk_progress-score-legend flex">
        {
          lineSetup.intervals
            .map((intervalValue: number, i: number) =>
              lineSetup.intervals[i + 1]
                ? <span
                      style={{ width: `${(((lineSetup.intervals[i + 1] - intervalValue) / maxIntervalValue) * 100).toFixed(2)}%` }}
                      key={i}>{translate(lineSetup.intervalsLegend[i])}</span>
                : null
            )
        }
      </div>
    </div>
  )
}

export default ProgressLine;
