import { ApplicationState } from "../store"
import { AuthState } from "../store/Auth/types"
import { createSelector } from "reselect"

export const authState = (state: ApplicationState): AuthState => state.auth

export const authUser = createSelector(
  authState,
  (state: AuthState) => state.user
)

export const authRoles = createSelector(
  authUser,
  (user): Array<string> => user.roles
)

export const userInstitutionSelector = createSelector(
  authUser,
  (user): string => user.institution
)

export const userNameSelector = createSelector(
  authUser,
  (user): { [key: string]: string } => {
    const result = {
      firstName: '',
      lastName: '',
      fullName: ''
    }
    if (!user) {
      return result
    }
    const {firstName, lastName} = user
    result.firstName = firstName || ''
    result.lastName = lastName || ''
    result.fullName = `${firstName ? `${firstName} ` : ''}${lastName}`
    return result
  }
)

const selectQrCodeData = (state: AuthState) => {
  return state.qrCodeData
}
const selectBankIdAttempt = (state: AuthState) => {
  return state.bankIdAttempt
}

export const getQrCodeData = createSelector(
  authState,
  selectQrCodeData
)

export const getBankIdAttempt = createSelector(
  authState,
  selectBankIdAttempt
)
