import {AppActionType, AppActionTypes, AppState} from "./types";
import {PatientProfileActionType, PatientProfileActionTypes} from "../PatientProfile/types";

const initialState: AppState = {
    isSideMenuExpanded: true,
    httpPendingAction: null
};

export function AppReducer(
    state = initialState,
    action: AppActionType | PatientProfileActionType
): AppState {
    switch(action.type) {
        case AppActionTypes.EXPAND_SIDE_MENU:
            return {
                ...state,
                isSideMenuExpanded: true
            };
        case AppActionTypes.COLLAPSE_SIDE_MENU:
            return {
                ...state,
                isSideMenuExpanded: false
            };
        case PatientProfileActionTypes.GET_PATIENT_REQUEST:
            return {
                ...state,
                httpPendingAction: action.type
            };
        case PatientProfileActionTypes.GET_PATIENT_SUCCESS:
        case PatientProfileActionTypes.GET_PATIENT_FAILURE:
            return {
                ...state,
                httpPendingAction: null
            };
        default:
            return state;
    }
}
