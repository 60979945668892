/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InstitutionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get all institutions
     * @returns any
     * @throws ApiError
     */
    public getAllInstitutions(): CancelablePromise<Array<Record<string, any>>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/institution',
        });
    }

}
