import {closeModalWindow} from '../ModalWindowData/action';
import {all, call, fork, put, takeEvery} from 'redux-saga/effects'
import callApi from '../../utils/apiCaller';
import {
    PatientProfileActionTypes,
    PatientPostNoteRequest,
    PatientPutNoteRequest,
    RefreshLoginCodeRequest, SendLoginCodeTextMessageRequest, SendTextMessageRequest,
    GetNewApiQuestionerAnswersRequest
} from './types'
import {ApiUserPaths, ApiMethods} from '../../types/Api';
import {
    getPatientSuccess,
    getPatientError,
    postNoteFailure,
    getViewingAttemptsSuccess,
    getViewingAttemptsFailure,
    getAnamnesisConfigSuccess,
    getAnamnesisConfigFailure,
    updatePatientSuccess,
    updatePatientFailure,
    createPatientSuccess,
    createPatientFailure,
    sendEmailSuccess,
    setTestAsViewedSuccess,
    setTestAsViewedFailure,
    getPatient,
    refreshLoginCodeSuccess,
    sendTextMessageSuccess,
    getNewApiQuestionerAnswersFailure,
    getNewApiQuestionerAnswersSuccess,
} from './action'
import {setSuccessMsg, setErrorMsg} from '../Auth/action';
import {translate} from "../../selectors/translations";
import {mapPatientProfile} from "../../utils/mapPatientProfile";
import {PatientRepository} from "../../repositories/patient.repository";
import { bindHandler, generator } from "../bind-handler";
import { CreateUserByAdminDto, QuestionerType, UpdateUserByAdminDto, UserDto } from "../../sdk"
import { apiV2, SdkResponse, wrapResponse } from "../../repositories/repository"
import { fetchInstitutionsRequest } from "../Institutions/action";

const commonErrorMessage = 'An unknown error occurred.';

function* handleGetPatient(action: any): Generator {
    yield put({
        type: PatientProfileActionTypes.GET_NEW_API_QUESTIONER_ANSWERS_REQUEST,
        patientId: action.patientId,
    })
    const patientApiCall = apiV2.userAdmin.getPatientProfile.bind(apiV2.userAdmin)
    const patientData = (yield wrapResponse(patientApiCall(action.patientId))) as SdkResponse<typeof patientApiCall>

    const oldAnswersApiCall = apiV2.questionerAnswer.findOldAnswersByUserId.bind(apiV2.questionerAnswer)
    const oldAnswers = (yield wrapResponse(oldAnswersApiCall(action.patientId))) as SdkResponse<typeof oldAnswersApiCall>

    if (patientData.success && oldAnswers.success) {
        yield put(getPatientSuccess(mapPatientProfile({
            data: {
                ...patientData.data,
                // @ts-ignore
                // TODO delete this old data
                measurements: oldAnswers.data.filter(e => e.type === QuestionerType.ANAMNESIS),
                cognitiveTests: oldAnswers.data.filter(e => e.type === QuestionerType.COGNITIVE),
            }
        })))
    } else {
        if (!patientData.success) {
            yield put(getPatientError(patientData.error))
            yield put(setErrorMsg(patientData.error || commonErrorMessage))
        } else if (!oldAnswers.success) {
            yield put(getPatientError(oldAnswers.error))
            yield put(setErrorMsg(oldAnswers.error || commonErrorMessage))
        }
    }
}

function* handleGetNewApiQuestionerAnswersRequest(action: GetNewApiQuestionerAnswersRequest): Generator {
    const res: any = yield wrapResponse(apiV2.questionerAnswer.getForPatient(action.patientId))
    if (res.success) {
        yield put(getNewApiQuestionerAnswersSuccess(res.data));
    } else {
        yield put(getNewApiQuestionerAnswersFailure(res.data && res.data.message || commonErrorMessage));
        yield put(setErrorMsg(res.data && res.data.message || commonErrorMessage));
    }
}

function* addNote(action: PatientPostNoteRequest): Generator {
    const {noteType, cb, userId, comment} = action.data;
    if (
        typeof userId !== 'string' ||
        !userId.length ||
        typeof comment !== 'string' ||
        !comment.length
    ) {
        cb(false);
        return;
    }

    const res: any = yield wrapResponse(apiV2.doctorNote.create({text: comment, patient: userId, kind: noteType}))
    if (res.success) {
        // yield put(postNoteSuccess(res.data));
        yield put(getPatient(userId));
        cb(true);
    } else {
        yield put(postNoteFailure(res.error));
        yield put(setErrorMsg(commonErrorMessage));
        cb(false);
    }
}

function* editNote(action: PatientPutNoteRequest): Generator {
    const { cb, userId} = action.data;
    const res: any = yield wrapResponse(apiV2.doctorNote.update(action.noteId, {
        text: action.data.comment
    }))

    if (res.success) {
        // yield put(putNoteSuccess(res.data));
        yield put(getPatient(userId));
        cb(true);
    } else {
        yield put(postNoteFailure(res.error));
        yield put(setErrorMsg(commonErrorMessage));
        cb(false);
    }
}

function* getAnamnesisConfig(action: { type: string; data: { userId: string; language?: string } }): Generator {
    try {
        const res: any = yield call(callApi, ApiMethods.GET, `${ApiUserPaths.GET_ANAMNESIS_CONFIG}?patientId=${action.data.userId}&language=${action.data.language || 'en'}`)
        if (res.error || res.status === 401) {
            yield put(getAnamnesisConfigFailure(res.error))
        } else {
            const rawQuestions = res.datal?.questions || {}
            const questions = Object.keys(rawQuestions).reduce((prev: any, item: string) => {
                const question = rawQuestions[item];
                if (['73', '74', '75', '76', '77', '78', '79', '80', '81'].includes(item)) {
                    const [l1, l2, ...rest] = question.labels;
                    question.labels = [l2, l1, ...rest];
                }
                prev[item] = question;
                return prev;
            }, {});
            const processedResponse = {
                questions,
                sections: res.data?.sections || {},
                questionsNew: res.data?.questionsNew || {},
                sectionsNew: res.data?.sectionsNew || {},
            };
            yield put(getAnamnesisConfigSuccess(processedResponse));
        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(getAnamnesisConfigFailure(err.stack))
        } else {
            yield put(getAnamnesisConfigFailure(commonErrorMessage))
        }
    }
}

// UPDATE PATIENT
function* handleUpdate(opts: { type: PatientProfileActionTypes; data: UserDto & UpdateUserByAdminDto }): Generator {
    const userId = opts.data._id;
    const res: any = yield PatientRepository.update(userId, opts.data)

    if (res.error) {
        yield put(updatePatientFailure(res.error))
        yield put(setErrorMsg(res.error || commonErrorMessage));
    } else {
        yield put(updatePatientSuccess(res.data))
        yield put(closeModalWindow())
        const successMessage = translate('editAccountSuccessMessage')
        yield put(setSuccessMsg(successMessage))
        yield put(fetchInstitutionsRequest())
        yield put(getPatient(userId))
    }
}

// CREATE PATIENT
function* handleCreate(opts: { type: PatientProfileActionTypes; data: CreateUserByAdminDto }): Generator {
    const res: any = yield call(PatientRepository.create, opts.data);
    if (res.error) {
        yield put(createPatientFailure(res.error || res.data.error || commonErrorMessage))
        yield put(setErrorMsg(res.error || res.data.error || commonErrorMessage));
    } else {
        yield put(createPatientSuccess(res.data))
        const successMessage = `${translate('createNewPatientSuccessMessage')} - ${res.data.firstName || ''} ${res.data.lastName || ''}`;
        yield put(setSuccessMsg(successMessage));
    }
}

function* sendEmail(opts: { type: PatientProfileActionTypes; data: { withInstructions: boolean; patientId: string } }): Generator {
    try {
        const res: any = yield call(callApi, ApiMethods.POST, ApiUserPaths.SEND_EMAIL, opts.data)
        if (res.error) {
            yield put(createPatientFailure(res.error))
        } else {
            yield put(setSuccessMsg('The email to the patient has now been sent'))
            yield put(sendEmailSuccess())
        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(createPatientFailure(err.stack))
        } else {
            yield put(createPatientFailure(commonErrorMessage))
        }
    }
}

function* handleLoginCodeSendTextMessage(opts: SendLoginCodeTextMessageRequest): Generator {
    try {
        const res: any = yield call(callApi, ApiMethods.POST, ApiUserPaths.SEND_WELCOME_TEXT_MESSAGE, opts.data);
        if (res.error) {
            yield put(createPatientFailure(res.error));
            yield put(setErrorMsg(res.error))
        } else {
            yield put(setSuccessMsg(translate('patientPhoneNumber.successMessage')));
            yield put(sendTextMessageSuccess());
        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(createPatientFailure(err.stack));
            yield put(setErrorMsg(err.stack))
        } else {
            yield put(createPatientFailure(commonErrorMessage));
            yield put(setErrorMsg(commonErrorMessage))
        }
    }
}

function* handleSendTextMessage(opts: SendTextMessageRequest): Generator {
    const apiCall = apiV2.sms.sendSms.bind(apiV2.sms)
    const res = (yield wrapResponse(apiCall(opts.data.patientId, {template: opts.data.textMessageType}))) as SdkResponse<typeof apiCall>

    if (res.success) {
        yield put(setSuccessMsg(translate('patientPhoneNumber.successMessage')))
        yield put(sendTextMessageSuccess())
    } else {
        const errorMessage = res.error
        yield put(createPatientFailure(errorMessage))
        yield put(setErrorMsg(errorMessage || commonErrorMessage))
    }
}

function* setTestAsViewed(opts: {
    type: PatientProfileActionTypes;
    data: { testId: string; sectionId: number };
}): Generator {
    try {
        const res: any = yield call(callApi, ApiMethods.PATCH, `${ApiUserPaths.SET_TEST_AS_VIEWED.replace(':testId', opts.data.testId)}`, opts.data);
        if (res.error) {
            yield put(setTestAsViewedFailure(res.error))
        } else {
            yield put(setTestAsViewedSuccess(res.data))
        }
    } catch (err) {
        if (err instanceof Error && err.stack) {
            yield put(setTestAsViewedFailure(err.stack))
        } else {
            yield put(setTestAsViewedFailure(commonErrorMessage))
        }
    }
}

function* refreshLoginCode(opt: RefreshLoginCodeRequest) {
    const {payload} = opt
    const apiCall = apiV2.auth.refreshLoginCode.bind(apiV2.auth)
    const res = (yield wrapResponse(apiCall(payload.patientId))) as SdkResponse<typeof apiCall>

    if (res.success) {
        yield put(closeModalWindow())
        yield put(refreshLoginCodeSuccess(res.data))
        const successMessage = translate('loginCode.refreshSuccessMessage')
        yield put(setSuccessMsg(successMessage))
    } else {
        yield put(setErrorMsg(res.error))
    }
}

function* watchSetTestAsViewed(): Generator {
    yield takeEvery(PatientProfileActionTypes.SET_TEST_AS_VIEWED_REQUEST, setTestAsViewed)
}

function* watchSendEmail(): Generator {
    yield takeEvery(PatientProfileActionTypes.SEND_WELCOMING_EMAIL_REQUEST, sendEmail)
}

function* watchLoginCodeSendTextMessage(): Generator {
    yield takeEvery(PatientProfileActionTypes.SEND_WELCOMING_TEXT_MESSAGE_REQUEST, handleLoginCodeSendTextMessage);
}

function* watchSendTextMessage(): Generator {
    yield takeEvery(PatientProfileActionTypes.SEND_TEXT_MESSAGE_REQUEST, handleSendTextMessage);
}

function* watchCreateRequest(): Generator {
    yield takeEvery(PatientProfileActionTypes.CREATE_PATIENTS_REQUEST, handleCreate)
}

function* watchGetAnamnesisSettings(): Generator {
    yield takeEvery(PatientProfileActionTypes.GET_ANAMNESIS_CONFIG_REQUEST, getAnamnesisConfig);
}

function* watchGetNewApiQuestionerAnswersRequest(): Generator {
    yield takeEvery(PatientProfileActionTypes.GET_NEW_API_QUESTIONER_ANSWERS_REQUEST, handleGetNewApiQuestionerAnswersRequest);
}

function* watchAddNote(): Generator {
    yield takeEvery(PatientProfileActionTypes.POST_NOTE_REQUEST, addNote);
}

function* watchEditNote(): Generator {
    yield takeEvery(PatientProfileActionTypes.PUT_NOTE_REQUEST, editNote);
}

function* watchRefreshLoginCode(): Generator {
    yield takeEvery(PatientProfileActionTypes.REFRESH_LOGIN_CODE_REQUEST, refreshLoginCode);
}

function* patientProfileSaga(): Generator {
    yield all([
        bindHandler(PatientProfileActionTypes.GET_PATIENT_REQUEST, handleGetPatient),
        bindHandler(PatientProfileActionTypes.UPDATE_PATIENTS_REQUEST, handleUpdate),
        fork(watchAddNote),
        fork(watchEditNote),
        fork(watchGetAnamnesisSettings),
        fork(watchCreateRequest),
        fork(watchSendEmail),
        fork(watchSetTestAsViewed),
        fork(watchRefreshLoginCode),
        fork(watchSendTextMessage),
        fork(watchLoginCodeSendTextMessage),
        fork(watchGetNewApiQuestionerAnswersRequest),
        generator(({userId}) => wrapResponse(apiV2.medStaff.rememberView(userId)))
          .onSuccess(getViewingAttemptsSuccess)
          .onError(getViewingAttemptsFailure)
          .bind(PatientProfileActionTypes.GET_VIEWING_ATTEMPTS_REQUEST),
    ])
}

export default patientProfileSaga;
