import React from 'react';
import { FormattedAnswer, Recordings } from '../../../store/PatientProfile/types';
import { toSeconds, chunkArr } from '../../../utils/datetime';
import { ReactComponent as ReactionIcon } from '../../../assets/img/icons/reaction.svg';
import { ReactComponent as HandIcon } from '../../../assets/img/icons/hand.svg';
import { ReactComponent as ClockIcon } from '../../../assets/img/icons/clock-icon.svg';
import ArrowCoding from '../../../assets/img/icons/arrow_coding.svg';
import CirclePatternSvg from '../../CirclePatternSvg';
import SvgComponent from '../../SvgComponent';
import { translate } from '../../../selectors/translations';
import AudioPlayer from '../../AudioPlayer';
type CommonSectionProps = {
  answer: FormattedAnswer;
  recordings?: Recordings[];
}

type Checking = {
  [key: number]: (expect: any, answer: any) => string;
}

type AnswerSection = {
  [key: number]: (answer: FormattedAnswer) => React.ReactElement | React.ReactElement[] | null;
}

type ContentSection = {
  [key: string]: (answer: FormattedAnswer) => React.ReactElement | null;
}

const expectSection = (expectData: any): any => ({
  1: null,
  2: null,
  3: expectData.map((item: string, index: number) => (<dd key={index}>{item}</dd>)),
  4: expectData.map((item: string, index: number) => (<dd key={index}>{item}</dd>)),
  5: expectData.map((item: string, index: number) => (<dd key={index}>{item}</dd>)),
  6: expectData.map((item: string, index: number) => (<dd key={index}>{item}</dd>)),
  7: expectData.map((item: string, index: number) => (<dd key={index}>{item}</dd>)),
  8: expectData.map((item: string, index: number) => (<dd key={index}>{item}</dd>)),
  9: expectData.map((item: string, index: number) => (<dd key={index}>{item}</dd>)),
  10: expectData.map((item: string, index: number) => (<dd key={index}>{item}</dd>)),
  11: expectData.map((item: string, index: number) => (<dd key={index}>{item}</dd>)),
  12: expectData.map((item: string, index: number) => (<dd key={index}>{item}</dd>)),
  13: (<dt>{translate('coding test hint')}</dt>),
  14: (<dt><CirclePatternSvg color={'#b2b2b2'} data={expectData} /></dt>),
  15: (<dt><CirclePatternSvg color={'#b2b2b2'} data={expectData} /></dt>),
  16: expectData.map((item: string, index: number) => (<dd key={index}>{item}</dd>)),
});

const checking: Checking = {
  1: (): string => '',
  2: (): string => '',
  3: (): string => '',
  4: (expect, answer): string => expect.includes(answer.trim().toLowerCase()) ? '#73b937' : '#db314b',
  5: (expect, answer): string => expect.includes(answer.trim().toLowerCase()) ? '#73b937' : '#db314b',
  6: (expect, answer): string => expect === answer ? '#73b937' : '#db314b',
  7: (expect, answer): string => expect === answer ? '#73b937' : '#db314b',
  8: (expect, answer): string => expect === answer ? '#73b937' : '#db314b',
  9: (expect, answer): string => expect.includes(answer.toString().trim().toLowerCase()) ? '#73b937' : '#db314b',
  10: (expect, answer): string => answer === expect ? '#73b937' : '#db314b',
  11: (expect, answer): string => answer.toString() === expect.toString() ? '#73b937' : '#db314b',
  12: (): string => '',
  13: (): string => '',
  14: (expect, answer): string => answer === expect ? '#73b937' : '#db314b',
  15: (expect, answer): string => answer === expect ? '#73b937' : '#db314b',
  16: (expect, answer): string => expect.includes(answer) ? '#73b937' : '#db314b',
}

const answerChecking = (answer: any, expect: any) => (index: number): string => checking[index](expect, answer);

const answerSections: AnswerSection = {
  1: () => (<dd></dd>) as React.ReactElement,
  2: () => (<dd></dd>) as React.ReactElement,
  3: () => (<dd></dd>) as React.ReactElement,
  4: (answer) => answer.resultData.map((item: string, index: number) => (<dd style={{ 'color': answerChecking(answer.result, answer.expect)(4) }} key={index}>{item}</dd>)),
  5: (answer) => answer.resultData.map((item: string, index: number) => (<dd style={{ 'color': answerChecking(item, answer.expect)(5) }} key={index}>{item}</dd>)),
  6: (answer) => answer.resultData.map((item: string, index: number) => (<dd style={{ 'color': answerChecking(answer.result, answer.expect)(6) }} key={index}>{item}</dd>)),
  7: (answer) => answer.resultData.map((item: string, index: number) => (<dd style={{ 'color': answerChecking(answer.result, answer.expect)(7) }} key={index}>{item}</dd>)),
  8: (answer) => answer.resultData.map((item: string, index: number) => (<dd style={{ 'color': answerChecking(answer.result, answer.expect)(8) }} key={index}>{item}</dd>)),
  9: (answer) => answer.resultData.map((item: string, index: number) => (<dd style={{ 'color': answerChecking(item, answer.expect)(9) }} key={index}>{item}</dd>)),
  10: (answer) => answer.resultData.map((item: string, index: number) => (<dd style={{ 'color': answerChecking(answer.result, answer.expect)(10) }} key={index}>{item}</dd>)),
  11: (answer) => answer.resultData.map((item: string, index: number) => (<dd style={{ 'color': answerChecking(answer.result, answer.expect)(11) }} key={index}>{item}</dd>)),
  12: (answer) => [...chunkArr(answer.resultData, 5), [`Ord: ${answer.resultData.filter(i => Boolean(i)).length}/11`]].map((item: string[], index: number) => (<dd key={index}>{item.filter(i => Boolean(i)).join(' ')}</dd>)),
  13: (answer) => (<dd>{(answer.expect as number[]).reduce((acc, val, index) => val === ((answer.result as {sequence: number[]}).sequence as number[])[index] ? acc += 1 : acc, 0)} / {`${answer.expectData.length}`}</dd>) as React.ReactElement,
  14: (answer) => (<dd><CirclePatternSvg color={answerChecking(answer.result, answer.expect)(14)} data={answer.resultData} /></dd>) as React.ReactElement,
  15: (answer) => (<dd><CirclePatternSvg color={answerChecking(answer.result, answer.expect)(15)} data={answer.resultData} /></dd>) as React.ReactElement,
  16: (answer) => answer.resultData.map((item: string, index: number) => (<dd style={{ 'color': answerChecking(answer.result, answer.expect)(16) }} key={index}>{item}</dd>)),
  17: () => (<dd>{translate('Answer was not provided!')}</dd>) as React.ReactElement,
  18: () => (<dd>{translate('idk')}</dd>) as React.ReactElement
}

const answerSection = (answer: FormattedAnswer) => (index: number): React.ReactElement | React.ReactElement[] | null => answerSections[index](answer)

const contentSections: ContentSection = {
  '1': (answer: FormattedAnswer) => (<div className="svg-holder"><SvgComponent {...answer} /></div>) as React.ReactElement,
  '2': (answer: FormattedAnswer) => (<div className="svg-holder"><SvgComponent {...answer} /></div>) as React.ReactElement,
  '3': (answer: FormattedAnswer) => (<div className="svg-holder"><SvgComponent {...answer} /></div>) as React.ReactElement,
  '13': (answer: FormattedAnswer) => (<CodingAnswerSection answer={answer} />) as React.ReactElement,
  '9': (answer: FormattedAnswer) => (answer.record ? <AudioPlayer url={answer.record.realUrl} /> : null) as React.ReactElement,
  '12': (answer: FormattedAnswer) => (answer.record ? <AudioPlayer url={answer.record.realUrl} /> : null) as React.ReactElement,
  '5': (answer: FormattedAnswer) => (answer.record ? <AudioPlayer url={answer.record.realUrl} /> : null) as React.ReactElement,
  'none': () => null,
}

const contentSection = (answer: FormattedAnswer) => (index: string): React.ReactElement | null => contentSections[index] ? contentSections[index](answer) : contentSections['none'](answer);

export const TimeUsedSection: React.SFC<CommonSectionProps> = (props: CommonSectionProps) => {
  const { answer } = props;
  const { timeFromTouch, timeUsed } = answer;
  return (
    <dl className="answer-item">
      <dt>{translate('time-used')}</dt>
      <dd className="flex align-center" title={translate('Total time')} ><ClockIcon className="cognitive_clock-icon" />{`${toSeconds(timeUsed)} sec`}</dd>
      {
        timeFromTouch !== void 0
          ? (<dd className="flex align-center" title={translate('Hand on screen')}><HandIcon className="cognitive_clock-icon" />{`${toSeconds(timeFromTouch)} sec`}</dd>)
          : null
      }
      {
        timeFromTouch !== void 0
          ? (<dd className="flex align-center" title={translate('Reaction time')}><ReactionIcon className="cognitive_clock-icon" />{`${toSeconds(timeUsed - timeFromTouch)} sec`}</dd>)
          : null
      }
    </dl>
  )
}

export const ExpectSection: React.SFC<CommonSectionProps> = (props: CommonSectionProps) => {
  const { answer } = props;
  const { expectData } = answer;
  return answer.expect === void 0 || answer.expect === null || answer.key === '13-0'
    ? null
    : <dl className="answer-item">
      <dt>{translate('Expect')}</dt>
      {expectSection(expectData)[answer.type]}
    </dl>
}

export const AnswerSection: React.SFC<CommonSectionProps> = (props: CommonSectionProps) => {
  const { answer, recordings } = props;
  let record: string[] = [];
  if (recordings && answer.externalId && answer.result !== -1) {
    const _record = recordings.find((r) => r._id === answer.externalId)
    if (_record && _record.transcription !== void 0) {
      record = _record.transcription.replace(/\n/ig, '').replace(',', '').split(' ')
      answer.resultData = record;
      answer.result = record;
    }
  } else if (recordings && [5, 9, 12].includes(answer.type) && !answer.externalId && answer.result === 1) {
    answer.result = [translate('Recording not found')];
    answer.resultData = [translate('Recording not found')];
  }
  //Add here two custom types for Idk and undefined answer results
  const type = answer.result === void 0 ? 17 : answer.result === -1 ? 18 : answer.type;

  return answer.key === '13-0'
    ? <dl className="answer-item no-borders">
      <dt>{contentSection({ ...answer, record: null })(String(13))}</dt>
    </dl>
    : <dl className="answer-item no-borders">
      <dt>{translate('Answer')}</dt>
      {answerSection(answer)(type)}
    </dl>
}

export const ContentSection: React.SFC<CommonSectionProps> = (props: CommonSectionProps) => {
  const { answer, recordings } = props;
  let record;
  if (recordings && answer.externalId) {
    record = recordings.find((r) => r._id === answer.externalId)
  }
  return answer.key === '13-0' ? null : contentSection({ ...answer, record })(String(answer.type));
}

type CodingAnswer =  {
  sequence: number[];
  resetCount: number;
}

export const CodingAnswerSection: React.SFC<CommonSectionProps> = (props: CommonSectionProps) => {
  const { answer } = props;
  const { expect } = answer;
  const { sequence, resetCount } = (answer.result as CodingAnswer);
  const chunkConst = 10;
  const chunkedCodingResult = chunkArr((expect as number[]), chunkConst);
  return (
    <div className="coding-holder">
      {answer.key === '13-1' ? <div style={{fontSize: '14px', textAlign: 'center'}}>{translate('Reset counts')}: {resetCount || 0}</div> : null}
      {
        chunkedCodingResult.map((row, i) => {
          return (
            <div key={i} className="Arrow__row">
              <div>
                <div className="Arrow__legend">
                  {translate('image')}
                </div>
                <div className="Arrow__legend">
                  {translate('Expect')}
                </div>
                <div className="Arrow__legend">
                  {translate('Answer')}
                </div>
              </div>
              {
                row.map((item, j) => {
                  return (
                    <div key={`${i}-${j}`}>
                      <div className="Arrow__item">
                        <img
                          className={`Arrow__item-img coding-arrow-${item}`}

                          src={ArrowCoding}
                          alt="ww"
                        />
                      </div>
                      <div className="Arrow__item">
                        {item}
                      </div>

                      <div
                        className={sequence[chunkConst * i + j] !== Number(item)
                          ? 'Arrow__item Arrow__item_wrong'
                          : 'Arrow__item'}>
                        {sequence[chunkConst * i + j]}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </div>
  )
}
