import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"

import deleteIcon from '../../assets/img/voucherCode/delete.png'
import { openModalWindow } from "../../store/ModalWindowData/action"
import { ReactComponent as EditIcon } from "../../assets/img/icons/edit.svg"
import useTranslate from "../../hooks/useTranslate"
import { getSmsTemplates } from "../../store/SmsTemplate/action"
import { getInstitutionsSelector, institutionName } from "../../selectors/institutions"
import { AppLanguage } from "../../sdk"
import { smsTemplatesSelector } from "../../selectors/patients"
import { forceRefreshTranslations } from "../../store/Translations/actions"

const SmsTemplates: React.FC<any> = () => {
  const dispatch = useDispatch()
  const smsTemplates = useSelector(smsTemplatesSelector)
  const translateLocalazy = useTranslate()
  useSelector(getInstitutionsSelector)

  useEffect(() => {
    dispatch(getSmsTemplates())
  }, [])
  const placeholders = 'Possible placeholders to use: {userFirstName}, {userLastName}, {userLoginCode}, {downloadAppUrl}, {kindRegardsEndingTemplate}, {habitsInfoLink}, {patientPortalUrl}, {stripePayLink}'

  return (
    <div className="card-box patient-info">
      <div className="box-title">
        <h3>{translateLocalazy('admin.sms-templates.form-title')}</h3>
        <button style={{marginLeft: '24px'}}
                onClick={() => dispatch(openModalWindow('postSmsTemplate', null))}
                className="g_btn g_btn--primary"
                type="button">{translateLocalazy('admin.sms-templates.add-new-btn')}
        </button>
        <button style={{marginLeft: '24px'}}
                onClick={() => dispatch(forceRefreshTranslations())}
                className="g_btn g_btn--primary"
                type="button">{translateLocalazy('admin.force-refresh-translations')}
        </button>
      </div>
      <h4>{placeholders}</h4>
      <br/>
      <div className="voucher-code">
        <span className="statistic-item">{translateLocalazy('admin.sms-templates.title')}</span>
        <span className="statistic-item">{translateLocalazy('admin.sms-templates.visible-for-institutions')}</span>
        <span className="statistic-item">{translateLocalazy('admin.sms-templates.translations')}</span>
        <span className="statistic-item"></span>
      </div>
      {
        smsTemplates.map((template, index) => (
          <div key={index} className="voucher-code ">
            <span className="statistic-item" style={{marginRight: '24px'}}>{translateLocalazy(template.titleKey)}</span>
            <span className="statistic-item"
                  style={{marginRight: '24px'}}>{template.visibleForInstitutions.map(e => institutionName(e)).join(', ')}</span>
            <span className="statistic-item"
                  style={{marginRight: '24px'}}>{Object.keys(template.translations)
              .map(e => <span key={e} className="risk_progress-legend">
                <a className="translation-link info-icon"
                   href={getLocalazyLink(e, template.translationKeyId)}
                   target="_blank" rel="noreferrer">{e.toUpperCase()} </a>
                <div className="info_list">{template.translations[e] || '...'}</div>
              </span>)}</span>

            <EditIcon className="action-btn-svg" style={{marginLeft: '24px', cursor: 'pointer'}}
                      onClick={() => dispatch(openModalWindow('updateSmsTemplate', template))}/>
            <img style={{marginLeft: '24px', cursor: 'pointer'}} width={20} src={deleteIcon} alt=""
                 onClick={() => dispatch(openModalWindow('deleteSmsTemplate', template))}
            />
          </div>
        ))
      }
    </div>
  )
}

const getLocalazyLink = (language: string, translationKeyId: string) => {
  switch (language) {
    case AppLanguage.SV:
      return `https://localazy.com/p/geras-hcp/phrases/1244/edit/${translationKeyId}`
    default:
      return `https://localazy.com/p/geras-hcp/source-language/${translationKeyId}`

  }
}

export default SmsTemplates
