import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import callApi from '../../utils/apiCaller';
import {
  VoucherCodesActionTypes,
  PostVoucherCodeRequest,
  UpdateVoucherCodeRequest,
  RefreshVoucherCodeRequest,
  DeleteVoucherCodeRequest
} from './types'
import { ApiMethods, ApiVoucherCodePath } from '../../types/Api';
import {
  getVoucherCodesSuccess,
  getVoucherCodesError,
  postVoucherCodeSuccess,
  postVoucherCodeError,
  updateVoucherCodeSuccess,
  updateVoucherCodeError,
  deleteVoucherCodeSuccess,
  refreshVoucherCodeError,
  getVoucherCodes,
  refreshVoucherCodeSuccess,
  deleteVoucherCodeError,
  getVoucherUsageStatsSuccess,
  getVoucherUsageStatsError,
} from './action'
import {closeModalWindow} from "../ModalWindowData/action";
import { apiV2, SdkResponse, wrapResponse } from "../../repositories/repository"

// GET VOUCHER CODES LIST
function* handleGetVoucherCodes(): Generator {
  try {
    const res: any = yield call(callApi, ApiMethods.GET, `${ApiVoucherCodePath.GET_CODES}`)
    if (res.error) {
      yield put(getVoucherCodesError(res.error))
    } else {
      yield put(getVoucherCodesSuccess(res.data))
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getVoucherCodesError(err.stack))
    } else {
      yield put(getVoucherCodesError('An unknown error occured.'))
    }
  }
}

// GET VOUCHER CODES LIST
function* handleGetVoucherStatsUsage(): Generator {
  try {
    const apiCall = apiV2.payment.getAllFreeCardPayments.bind(apiV2.payment)
    const newPayments = (yield wrapResponse(apiCall())) as SdkResponse<typeof apiCall>
    if (!newPayments.success) {
      yield put(getVoucherUsageStatsError(newPayments.error))
      return
    }

    const oldVoucherCodeStats: any = yield call(callApi, ApiMethods.GET, `${ApiVoucherCodePath.GET_STATS}`)
    if (oldVoucherCodeStats.error) {
      yield put(getVoucherUsageStatsError(oldVoucherCodeStats.error))
    } else {
      const mergedData = [...oldVoucherCodeStats.data, ...newPayments.data.map(e => ({
        paymentId: e.paymentIntentId,
        userName: e.user ? e.user.firstName + ' ' + e.user.lastName : 'Deleted',
        voucherCodeName: "free_card",
        voucherDiscount: "Bought for free",
        datePaid: e.createdAt
      }))]
      yield put(getVoucherUsageStatsSuccess(mergedData))
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(getVoucherUsageStatsError(err.stack))
    } else {
      yield put(getVoucherUsageStatsError('An unknown error occured.'))
    }
  }
}

function* createCode(action: PostVoucherCodeRequest): Generator {
  try {
    const res: any = yield call(callApi, ApiMethods.POST, ApiVoucherCodePath.CREATE_CODE, action.data);
    if (res.status === 500) {
      res.error = res.data;
    }
    if (res.error) {
      yield put(postVoucherCodeError(res.error))
    } else {
      yield put(closeModalWindow());
      yield put(postVoucherCodeSuccess(res.data));
      yield put(getVoucherCodes())
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(postVoucherCodeError(err.stack))
    } else {
      yield put(postVoucherCodeError('An unknown error occured.'))
    }
  }
}


function* editCode(action: UpdateVoucherCodeRequest): Generator {
  try {
    const res: any = yield call(callApi, ApiMethods.PUT, `${ApiVoucherCodePath.EDIT_CODE}/${action.data.id}`, action.data);
    if (res.status === 500) {
      res.error = res.data;
    }
    if (res.error) {
      yield put(updateVoucherCodeError(res.error))
    } else {
      yield put(closeModalWindow());
      yield put(updateVoucherCodeSuccess(res.data));
      yield put(getVoucherCodes())
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(updateVoucherCodeError(err.stack))
    } else {
      yield put(updateVoucherCodeError('An unknown error occured.'))
    }
  }
}

function* refreshCode(action: RefreshVoucherCodeRequest): Generator {
  try {
    const res: any = yield call(callApi, ApiMethods.PUT, `${ApiVoucherCodePath.REFRESH_CODE}/${action._id}`);
    if (res.status === 500) {
      res.error = res.data;
    }
    if (res.error) {
      yield put(refreshVoucherCodeError(res.error))
    } else {
      yield put(refreshVoucherCodeSuccess(res.data));
      yield put(getVoucherCodes())
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(refreshVoucherCodeError(err.stack))
    } else {
      yield put(refreshVoucherCodeError('An unknown error occured.'))
    }
  }
}

function* deleteCode(action: DeleteVoucherCodeRequest): Generator {
  try {
    const res: any = yield call(callApi, ApiMethods.DELETE, `${ApiVoucherCodePath.DELETE_CODE}/${action._id}`);
    if(res.error) {
      yield put(deleteVoucherCodeError(res.error))
    } else {
      yield put(deleteVoucherCodeSuccess(res.data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(deleteVoucherCodeError(err.stack))
    } else {
      yield put(deleteVoucherCodeError('An unknown error occured.'))
    }
  }
}

function* watchGetVoucherCodes(): Generator {
  yield takeEvery(VoucherCodesActionTypes.GET_VOUCHER_CODE_REQUEST, handleGetVoucherCodes)
}

function* watchGetVoucherStatsUsage(): Generator {
  yield takeEvery(VoucherCodesActionTypes.GET_VOUCHER_USAGE_STATS_REQUEST, handleGetVoucherStatsUsage)
}

function* watchCreateVoucherCode(): Generator {
  yield takeEvery(VoucherCodesActionTypes.POST_VOUCHER_CODE_REQUEST, createCode)
}

function* watchUpdateVoucherCode(): Generator {
  yield takeEvery(VoucherCodesActionTypes.UPDATE_VOUCHER_CODE_REQUEST, editCode)
}

function* watchRefreshVoucherCode(): Generator {
  yield takeEvery(VoucherCodesActionTypes.REFRESH_VOUCHER_CODE_REQUEST, refreshCode)
}

function* watchDeleteVoucherCode(): Generator {
  yield takeEvery(VoucherCodesActionTypes.DELETE_VOUCHER_CODE_REQUEST, deleteCode)
}


function* voucherCodeSaga(): Generator {
  yield all([
    fork(watchGetVoucherCodes),
    fork(watchGetVoucherStatsUsage),
    fork(watchCreateVoucherCode),
    fork(watchUpdateVoucherCode),
    fork(watchRefreshVoucherCode),
    fork(watchDeleteVoucherCode),
  ])
}

export default voucherCodeSaga;
