import React from 'react';
import { ModalWindowData } from '../../store/ModalWindowData/types';

const ModalWindow: React.FC<Partial<ModalWindowData>> = (props: Partial<ModalWindowData>): React.ReactElement => {
  const { component } = props;
  return (
    <div className="confirmation_wapper">
      <div className="confirmation_box">
      {component}
      </div>
    </div>
  )
}

export default ModalWindow;