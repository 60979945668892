import React from 'react';
import Toggleable from '../../rc-components/Toggleable';
import { formatDate, milliToIMin } from '../../utils/datetime';
import { DropdownCb, ProgerssLineSetup } from '../../types/Common';
import { Survey, Recordings } from '../../store/PatientProfile/types';
import TestCategorySection from './TestCategorySection';
import { getCognitive, getTestStatus } from '../../selectors/patientProfile';
import { CognitiveTestCategory } from '../../types/Common';
import { translate } from '../../selectors/translations';
import ProgressLine from './ProgressLine';
import './CognitiveTestCard.css';
import { ReactComponent as CheckIcon } from '../../assets/img/icons/check.svg';
import { useDispatch } from 'react-redux';
import { setTestAsViewed } from '../../store/PatientProfile/action';
import {STATUS_COLOR} from '../../types/constants';
import { ModalWindowType } from "../../hoc/ModalWindowGuard/ModalWindowGuard"

export const SCORE_RISK_SETUP: ProgerssLineSetup = {
  intervals: [0, 35.5, 45, 59],
  intervalsLegend: ['Cognitive Disease', 'MCI', 'SCI'],
  generalLegend: `
    Cognitive Disease: < 35.5 <br />
    MCI: 35.5 - 45.0 <br />
    SCI: > 45.0 <br />
  `
};

// Total test time came in seconds from back-end so here we will use seconds also
export const TIME_RISK_SETUP: ProgerssLineSetup = {
  intervals: [0, 19 * 60, 24 * 60, 28 * 60, 48 * 60],
  intervalsLegend: ['SCI', 'MCI', '', 'Cognitive Disease'],
  intervalHole: [[24 * 60, 28 * 60]],
  generalLegend: `
    SCI: ~17:48 min (17:00 - 19:00 min) <br />
    MCI: ~21:30 min (19:00 - 24:00 min) <br />
    Dementia: ~38:00 min (28:00 - 48:00 min) <br />
  `
};

type CognitiveTestCardProps = {
  testResult: Survey;
  recordings: Recordings[];
  openModal: (type: ModalWindowType, data: any) => void;
  currentLanguage: string;
}

const CognitiveTestCard: React.FC<CognitiveTestCardProps> = (props: CognitiveTestCardProps) => {
  const { testResult, recordings, openModal, currentLanguage } = props;
  const cognitiveQuestions = getCognitive();
  const dispatch = useDispatch();
  const status = getTestStatus(testResult, testResult.answers.length, 0);

  if (currentLanguage === 'sv') {
    SCORE_RISK_SETUP.intervalsLegend = ['Kognitiv sjukdom', 'MCI', 'SCI']
    TIME_RISK_SETUP.intervalsLegend = ['SCI', 'MCI', '', 'Kognitiv sjukdom']
  }

  return (
    <div className="flex-item item-sm-12">
      <div className="card-box diagnostic_test-box cog-test-box" style={{ overflow: 'visible' }}>
        <Toggleable>
          {
            (dropdownId: string, setDropdownId: DropdownCb): React.ReactElement => (
              <div>
                <div className="diagnostic_test_summary">
                  <div
                    className="flex-container justify-between align-start cog-test-wrapper"
                    onClick={(): void => {
                      setDropdownId(dropdownId === 'survey' ? '' : 'survey');
                      dispatch(setTestAsViewed({testId: testResult._id, sectionId: 0}))
                    }}>
                    <div className="flex-item flex-item--shrink" style={{ flex: '0 0 190px' }}>
                      <div className="test-title">{translate('cognitive')}</div>
                    </div>
                    <div className="flex-item flex-item--shrink results-block" style={{ flex: '0 0 220px' }}>
                      <span className="extra-label">{translate('Suspected')}:</span>
                      <ProgressLine
                        isRevert={false}
                        infoLegendKey={'riskLegend'}
                        legend={`${translate('Points')}: <strong>${testResult.totalPoints || 0}</strong> / 59`}
                        progressValue={testResult.totalPoints}
                        lineSetup={SCORE_RISK_SETUP} />
                    </div>
                    <div className="flex-item flex-item--shrink results-block" style={{ flex: '0 0 220px' }}>
                      <ProgressLine
                        infoLegendKey={'riskLegend'}
                        progressValue={(Math.round(testResult.totalTestTime / 1000))}
                        legend={`${translate('Time to take test')}: <strong>${milliToIMin(testResult.totalTestTime)} min</strong>`}
                        lineSetup={TIME_RISK_SETUP}
                        isRevert={true}/>
                    </div>
                    <div className={`status_box flex-item flex-item--shrink`} >
                      <div className="status-holder">
                        {
                          status === 'Complete' &&
                          <CheckIcon className="circle-checked"/>
                        }
                        <span className="status-text">{status === 'Complete' ? formatDate(testResult.createdAt) : translate(status)}</span>
                        <span className={`circle circle--${STATUS_COLOR[status]}`}></span>
                      </div>
                      {
                        testResult.earlyExit
                         && <div className="test-date exit-text">{translate('Patient left test ahead of time')}</div>
                      }
                    </div>
                  </div>
                </div>
                {
                  dropdownId
                  && <div className="diagnostic_test_body">
                    <section className="cognitive-test_section">
                      <div className="test_description_title">{translate('report-tasks')}</div>
                      {
                        cognitiveQuestions.map((testCategory: CognitiveTestCategory, i: number) =>
                          <TestCategorySection
                            openModal={openModal}
                            recordings={recordings}
                            key={i}
                            testAnswersData={testResult.testData[i]}
                            testQuestionsData={testCategory} />
                        )
                      }
                    </section>
                        <p style={{fontSize: '9px'}}>
                          1. Bloniecki, V., Hagman, G., Ryden, M. et al. Digital Screening for Cognitive Impairment — A Proof of Concept Study. J Prev Alzheimers Dis 8, 127–134 (2021).
                          <br/>
                          2. Bloniecki, V., Ulfvarson, J., Javanshiri, K. et al. The Geras Solutions Cognitive Test for Assessing Cognitive Impairment: Normative Data from a Population-Based Cohort. J Prev Alzheimers Dis (2023).
                          <br/>
                          <a rel="noopener noreferrer" target="_blank" href="https://www.minnesmottagningen.se/forskning-evidens">www.gerassolutions.com</a>
                        </p>
                  </div>
                }
              </div>
            )
          }
        </Toggleable>
      </div>
    </div>
  );
}

export default CognitiveTestCard;
