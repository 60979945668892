import { UserDto } from "../../sdk";

type displayedPatients = 'all' | 'cognitive' | 'recommendations' | 'appointments';

export interface PatientFieldsToSort {
    createdAt: string;
    firstName: string;
    gender: string;
    age: number;
    lastName: string;
    country: string;
}

export interface PatientFilters {
    searchTerm: string;
    displayedType: displayedPatients;
    gender?: "male" | "female" | "all";
    language?: "en" | "sv" | "all";
    sort: SortChangedData;
    ageMin: string;
    ageMax: string;
    scoreMin: string;
    scoreMax: string;
    institution: string;
}

export interface PatientsState {
    all: UserDto[];
    filters: PatientFilters;
    errorMsg: string;
    successMsg: string;
    totalUsersCount: number;
    userToDelete: string;
    selectViewedPatients: UserDto[];
}

export interface BrainConfigItem {
    title: string;
    frontImage: string;
    backImage: string;
    memory: number[];
    frontal: number[];
    subcortical: number[];
    temporal: number[];
    associatedSymptoms: number[];
}

export interface ResponsePayload {
    patients: UserDto[];
    totalUsersCount: number;
}

export interface PatientAction {
    action: string;
    _id: string;
}

export interface SortChangedData {
    field: string;
    direction: boolean;
}

export interface FilterChangedData {
    [key: string]: string | displayedPatients | SortChangedData;
}

export enum PatientsActionTypes {
    GET_PATIENTS_REQUEST = '@@patients/GET_PATIENTS_REQUEST',
    GET_PATIENTS_SUCCESS = '@@patients/GET_PATIENTS_SUCCESS',
    GET_PATIENTS_FAILURE = '@@patients/GET_PATIENTS_FAILURE',
    FILTER_CHANGED = '@@patients/FILTER_CHANGED',

    REMOVE_PATIENT_REQUEST = '@@patients/REMOVE_PATIENTS_REQUEST',
    REMOVE_PATIENT_FAILURE = '@@patients/REMOVE_PATIENT_FAILURE',
    REMOVE_PATIENT_SUCCESS = '@@patients/REMOVE_PATIENT_SUCCESS',

    GET_DELETED_PATIENTS_REQUEST = '@@patients/GET_DELETED_PATIENTS_REQUEST',
    GET_DELETED_PATIENTS_SUCCESS = '@@patients/GET_DELETED_PATIENTS_SUCCESS',
    GET_DELETED_PATIENTS_FAILURE = '@@patients/GET_DELETED_PATIENTS_FAILURE',

    RESTORE_PATIENT_REQUEST = '@@patients/RESTORE_PATIENT_REQUEST',
    RESTORE_PATIENT_SUCCESS = '@@patients/RESTORE_PATIENT_SUCCESS',
    RESTORE_PATIENT_FAILURE = '@@patients/RESTORE_PATIENT_FAILURE',

    DELETE_PATIENT_REQUEST = '@@patients/DELETE_PATIENT_REQUEST',
    DELETE_PATIENT_SUCCESS = '@@patients/DELETE_PATIENT_SUCCESS',
    DELETE_PATIENT_FAILURE = '@@patients/DELETE_PATIENT_FAILURE',

    RESET_PATIENTS_STATE = '@@patients/RESET_PATIENTS_STATE',

    GET_PATIENTS_BBP_REPORT_REQUEST = '@@patients/GET_PATIENTS_BBP_REPORT_REQUEST',
    GET_PATIENTS_BBP_REPORT_SUCCESS = '@@patients/GET_PATIENTS_BBP_REPORT_SUCCESS',
    GET_PATIENTS_BBP_REPORT_FAILURE = '@@patients/GET_PATIENTS_BBP_REPORT_FAILURE',
    GET_VIEWED_PATIENTS = '@@patients/GET_VIEWED_PATIENTS',
    GET_VIEWED_PATIENTS_SUCCESS = '@@patients/GET_VIEWED_PATIENTS_SUCCESS'
}

interface PatientRequest<T extends PatientsActionTypes> {
    type: T;
}

interface PatientFailedRequest<T extends PatientsActionTypes> extends PatientRequest<T> {
    type: T;
    errorMsg: string;
}

interface GetPatientsRequest extends PatientRequest<PatientsActionTypes.GET_PATIENTS_REQUEST>{
    page: number;
    filter?: PatientFilters;
}

interface GetPatientsFailure extends PatientRequest<PatientsActionTypes.GET_PATIENTS_FAILURE> {
    errorMsg: string;
}

interface GetPatientsSuccess extends PatientRequest<PatientsActionTypes.GET_PATIENTS_SUCCESS> {
    patients: ResponsePayload;
}

interface FilterChanged extends PatientRequest<PatientsActionTypes.FILTER_CHANGED> {
    filterData: FilterChangedData;
}

export interface RemovePatientRequest extends PatientRequest<PatientsActionTypes.REMOVE_PATIENT_REQUEST> {
    patientId: string;
}

interface RemovePatientSuccess extends PatientRequest<PatientsActionTypes.REMOVE_PATIENT_SUCCESS> {
    data: {
        _id: string;
    };
}

interface RemovePatientFailure extends PatientFailedRequest<PatientsActionTypes.REMOVE_PATIENT_FAILURE> {
}

interface GetDeletedPatientsRequest extends PatientRequest<PatientsActionTypes.GET_DELETED_PATIENTS_REQUEST> {
}

interface GetDeletedPatientsSuccess extends PatientRequest<PatientsActionTypes.GET_DELETED_PATIENTS_SUCCESS> {
    data: {
        patients: UserDto[];
    };
}

interface GetDeletedPatientsFailure extends PatientFailedRequest<PatientsActionTypes.GET_DELETED_PATIENTS_FAILURE> {
}

export interface RestorePatientRequest extends PatientRequest<PatientsActionTypes.RESTORE_PATIENT_REQUEST> {
    id: string;
}

interface RestorePatientSuccess extends PatientRequest<PatientsActionTypes.RESTORE_PATIENT_SUCCESS> {
    data: {
        _id: string;
    };
}

interface RestorePatientFailure extends PatientFailedRequest<PatientsActionTypes.RESTORE_PATIENT_FAILURE> {
}

export interface DeletePatientRequest extends PatientRequest<PatientsActionTypes.DELETE_PATIENT_REQUEST> {
    id: string;
}

interface DeletePatientSuccess extends PatientRequest<PatientsActionTypes.DELETE_PATIENT_SUCCESS> {
    data: {
        _id: string;
        status: boolean;
    };
}

interface DeletePatientFailure extends PatientFailedRequest<PatientsActionTypes.DELETE_PATIENT_FAILURE> {
}

interface ResetPatientsState extends PatientRequest<PatientsActionTypes.RESET_PATIENTS_STATE> {
}

export interface GetBPPReport extends PatientRequest<PatientsActionTypes.GET_PATIENTS_BBP_REPORT_REQUEST> {
    payload: { type: string };
}

export interface GetBPPReportSuccess extends PatientRequest<PatientsActionTypes.GET_PATIENTS_BBP_REPORT_SUCCESS> {
    response: any;
}

export interface GetBPPReportFailure {
    type: typeof PatientsActionTypes.GET_PATIENTS_BBP_REPORT_FAILURE;
    errMsg: string;
}

export interface GetViewedPatients extends PatientRequest<PatientsActionTypes.GET_VIEWED_PATIENTS> {
}

export interface GetViewedPatientsSuccess extends PatientRequest<PatientsActionTypes.GET_VIEWED_PATIENTS_SUCCESS> {
    payload: any;
}

export type PatientsActionType =
    GetPatientsRequest |
    GetPatientsFailure |
    GetPatientsSuccess |
    FilterChanged |
    RemovePatientRequest |
    RemovePatientSuccess |
    RemovePatientFailure |
    GetDeletedPatientsRequest |
    GetDeletedPatientsSuccess |
    GetDeletedPatientsFailure |
    RestorePatientRequest |
    RestorePatientSuccess |
    RestorePatientFailure |
    DeletePatientRequest |
    DeletePatientSuccess |
    DeletePatientFailure |
    ResetPatientsState |
    GetBPPReport |
    GetBPPReportSuccess |
    GetBPPReportFailure |
    GetViewedPatients |
    GetViewedPatientsSuccess;
