import React, { useCallback, useState } from "react"
import PatientSideHistoryLayout from "../PatientSideHistoryLayout"
import { translate } from "../../../../selectors/translations"
import { TextMessageHistoryDto } from "../../../../sdk"
import { generateInteractor } from "../../util"
import { formatDate } from "../../../../utils/datetime"
import { useSelector } from "react-redux"
import { smsTemplatesSelector } from "../../../../selectors/patients"
import useTranslate from "../../../../hooks/useTranslate"

interface IProps {
  messageHistory: TextMessageHistoryDto[];
}

const PatientSideMessagesHistory: React.FC<IProps> = (props: IProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const translateLocalazy = useTranslate()
  const {messageHistory} = props
  const smsTemplates = useSelector(smsTemplatesSelector)
  const interactor = generateInteractor()

  const handleExpandClick = useCallback((): void => {
    setIsExpanded((prevState) => !prevState)
  }, [])

  return (
    <PatientSideHistoryLayout
      isExpanded={isExpanded}
      handleExpandClick={handleExpandClick}
      title={translate('Text-message history')}
    >
      {isExpanded &&
          <div className="card_body">
            {
              messageHistory.length
                ? messageHistory
                  .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
                  .map((h, index) => (
                    <article key={index}
                             className="card_comment-box"
                             style={{padding: '5px'}}>
                      <div className="comment_header">
                        <div className="comment_header_title">
                          {translateLocalazy(smsTemplates.find(e => e._id === h.metadata.messageTemplate)?.titleKey || h.metadata.messageTemplate) }
                        </div>
                        <div className="comment_header_author">
                          {interactor(h.interactor)} - {formatDate(h.createdAt)}
                        </div>
                      </div>
                    </article>
                  ))
                : <article className="card_comment-box">
                  <div className="comment_header">
                    <div className="comment_header_title">{translate('no results')}</div>
                  </div>
                </article>
            }
          </div>
      }
    </PatientSideHistoryLayout>
  )
}

export default PatientSideMessagesHistory
