import {PdfItems} from "../types/constants";
import {PdfReportSubsectionData} from "../models/pdfReportData";

const itemsWithSubsections = [PdfItems.AnamnesisNew, PdfItems.SymptomSurveys];

export const mapPdfSelectionToDto = (value: any) => {
    return Object.keys(value).reduce((prev: any, key) => {
        const item = value[key];
        if (Array.isArray(item)) {
            prev[key] = item.map(item => {
                const { id, isSelected: visible } = item;
                const result: any = { id, visible };
                if (itemsWithSubsections.includes(item.type)) {
                    result.sectionsVisible = visible ? item.subsections
                        .filter((subsection: PdfReportSubsectionData) => subsection.isSelected)
                        .map((subsection: PdfReportSubsectionData) => subsection.id) : [];
                }
                return result;
            });
        } else {
            prev[key] = item.isSelected;
        }
        return prev;
    }, {});
};

export const defineIsSelectedAll = (value: any): boolean => {
    const processResult = (prev: boolean, item: any): boolean => {
        if (!prev) {
            return prev;
        }
        if (Array.isArray(item)) {
            prev = item.reduce(processResult, true);
        } else {
            prev = item.isSelected;
        }
        return prev;
    };
    return Object.values(value).reduce(processResult, true);
};

