/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppointmentDto } from '../models/AppointmentDto';
import type { AvailableDatesDto } from '../models/AvailableDatesDto';
import type { AvailableTimesDto } from '../models/AvailableTimesDto';
import type { CreateAppointmentBodyDto } from '../models/CreateAppointmentBodyDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AppointmentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create appointment
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public createAppointment(
        requestBody: CreateAppointmentBodyDto,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/appointment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Mark appointment as paid
     * @param id
     * @returns AppointmentDto
     * @throws ApiError
     */
    public handlePaidAppointment(
        id: string,
    ): CancelablePromise<AppointmentDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/appointment/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get appointment
     * @param id
     * @returns AppointmentDto
     * @throws ApiError
     */
    public getAppointment(
        id: string,
    ): CancelablePromise<AppointmentDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/appointment/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Remove reservation
     * @param id
     * @returns boolean
     * @throws ApiError
     */
    public cancelReservation(
        id: string,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/appointment/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get available booking dates
     * @param month
     * @param timezone
     * @returns AvailableDatesDto
     * @throws ApiError
     */
    public getAvailableDates(
        month: string,
        timezone: string,
    ): CancelablePromise<Array<AvailableDatesDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/appointment/dates',
            query: {
                'month': month,
                'timezone': timezone,
            },
        });
    }

    /**
     * Get available booking timeslots in exact date
     * @param date
     * @param timezone
     * @returns AvailableTimesDto
     * @throws ApiError
     */
    public getAvailableTimes(
        date: string,
        timezone: string,
    ): CancelablePromise<Array<AvailableTimesDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/appointment/times',
            query: {
                'date': date,
                'timezone': timezone,
            },
        });
    }

    /**
     * Get all appointments
     * @returns any
     * @throws ApiError
     */
    public getAllAppointments(): CancelablePromise<Array<Record<string, any>>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/appointment/all',
        });
    }

}
