import {useState, SyntheticEvent, useMemo, useEffect} from 'react';
import { isEqual } from "../utils/isEqual";

export interface FormValidators {
    [key: string]: Array<(value: any) => string | null>;
}

export type DefaultFormState = {
    [key: string]: string | number | [] | string[] | number[] | any;
}

export interface FormStateHandler {
    handleSubmit: (event: SyntheticEvent, extraData?: {[key: string]: any}) => void;
    handleInputChange: (event: SyntheticEvent) => void;
    handleForceUpdate: (data: DefaultFormState, validate?: boolean) => void;
    handleInputFocus: (event: SyntheticEvent) => void;
    handleInputBlur: (event: SyntheticEvent) => void;
    setValueDirectly: (key: string, value: any) => void;
    inputs: DefaultFormState;
    errors: string[];
}

type UnionHtmlCustomElement = HTMLInputElement | HTMLSelectElement;

const useFormStateHandlerG2 = <T extends DefaultFormState>(
    cb: ((data: any) => void) | null,
    initialState: T,
    validators: FormValidators,
    onFieldChange?: (data: any) => void
): FormStateHandler => {
    const [inputs, setInputs] = useState(initialState || {});
    const [errors, setErrors] = useState([] as string[]);
    const [touchedFields, setTouchedFields] = useState(new Set());
    const [, setIsFormValid] = useState(false);
    const [errorsList, setErrorsList] = useState({});

    const formValidators = useMemo(() => {
        if (validators && typeof validators === 'object') {
            return validators;
        }
        return {};
    }, [validators]);

    const _validate = (props: DefaultFormState): string[] => {
        let errors = [] as string[];
        const errorsList = {} as { [key: string]: string[] };
        Object.keys(props)
            .forEach((propKey) => {
                // @ts-ignore
                const result = _validateField(propKey, props);
                errors = [...errors, ...result];
                errorsList[propKey] = result;
            });
        setErrorsList(errorsList);
        return errors;
    }

    const _validateField = (fieldName: string, formValue: any): string[] => {
        const fieldValidator = formValidators[fieldName];
        if (!Array.isArray(fieldValidator)) {
            return [];
        }
        return fieldValidator.reduce((errors, validator) => {
            const error = validator(formValue[fieldName]);
            if (error) {
                // @ts-ignore
                errors.push(error);
            }
            return errors;
        }, []);
    };

    const _validateForm = (): boolean => {
        const fields = Object.keys(inputs);
        for (let i = 0; i < fields.length; i++) {
            const result = _validateField(fields[i], inputs);
            if (result.length) {
                return false;
            }
        }
        return true;
    };

    const _transformErrorsList = (list: { [key: string]: string[] }): string[] => {
        return Object.values(list).reduce((prev, item) => {
            return [...prev, ...item];
        }, []);
    };

    const handleSubmit = (event?: SyntheticEvent, extraData?: {[key: string]: any}): void => {
        if (event) {
            event.preventDefault();
        }
        const _errors = _validate(inputs);
        setErrors(() => _errors);

        if (!_errors.length && cb) {
            cb(extraData ? {...inputs, ...extraData} : inputs);
        }
    }

    const handleForceUpdate = (data: DefaultFormState, validate = true): void => {
        Object.keys(data).forEach((prop: string) => setInputs(inputs => ({ ...inputs, [prop]: data[prop] })));
        if (validate) {
            const _errors = _validate(data);
            setErrors((errors) => Object.keys(inputs)
                .filter((propKey: string) => (Object.keys(data).includes(propKey) && _errors.includes(propKey)) || (!Object.keys(data).includes(propKey) && errors.includes(propKey))))
        }
    }

    const handleInputChange = (event: SyntheticEvent): void => {
        event.persist();
        const target = event.target as UnionHtmlCustomElement;
        const _newInputs = { ...inputs, [target.name]: target.value };
        setInputs(_newInputs);
        const list: any = {
            ...errorsList,
        };
        if (touchedFields.has(target.name)) {
            list[target.name] = _validateField(target.name, _newInputs);
        }
        setErrorsList(list);
        setErrors(_transformErrorsList(list));
    }

    const setValueDirectly = (key: string, value: any): void => {
        if (!key) {
            return;
        }
        const _newInputs = { ...inputs, [key]: value };
        setInputs(_newInputs);
        const list: any = {
            ...errorsList,
        };
        if (touchedFields.has(key)) {
            list[key] = _validateField(key, _newInputs);
        }
        setErrorsList(list);
        setErrors(_transformErrorsList(list));
    }

    const handleInputFocus = (event: SyntheticEvent): void => {
        event.persist();
        const target = event.target as UnionHtmlCustomElement;
        setTouchedFields(prevState => prevState.add(target.name));
    };

    const handleInputBlur = (event: SyntheticEvent): void => {
        event.persist();
        const target = event.target as UnionHtmlCustomElement;
        setTouchedFields(prevState => prevState.add(target.name));
        const list = {
            ...errorsList,
        };
        list[target.name] = _validateField(target.name, inputs);
        setErrorsList(list);
        setErrors(_transformErrorsList(list));

    };

    useEffect(() => {
        const isFormValid = _validateForm();
        setIsFormValid(isFormValid);
        onFieldChange && onFieldChange({
            inputs,
            isFormValid,
            isFormChanged: !isEqual(initialState, inputs)
        });
    }, [inputs])

    useEffect(() => {
        setIsFormValid(_validateForm());
    }, [])

    return {
        handleSubmit,
        handleInputChange,
        handleForceUpdate,
        handleInputFocus,
        handleInputBlur,
        setValueDirectly,
        inputs: (inputs as DefaultFormState),
        errors: errors as string[]
    }
}

export default useFormStateHandlerG2;
