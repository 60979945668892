/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ManageScreenDTO } from '../models/ManageScreenDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ScreenService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new screen
     * @param requestBody
     * @returns ManageScreenDTO
     * @throws ApiError
     */
    public createMany(
        requestBody: Array<string>,
    ): CancelablePromise<Array<ManageScreenDTO>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/screen/batch',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Create a new screen
     * @param requestBody
     * @returns ManageScreenDTO
     * @throws ApiError
     */
    public create(
        requestBody: ManageScreenDTO,
    ): CancelablePromise<ManageScreenDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/screen',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all existent screens
     * @returns ManageScreenDTO
     * @throws ApiError
     */
    public getAll(): CancelablePromise<Array<ManageScreenDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/screen',
        });
    }

    /**
     * Update an existent screen
     * @param id
     * @param requestBody
     * @returns ManageScreenDTO
     * @throws ApiError
     */
    public update(
        id: string,
        requestBody: ManageScreenDTO,
    ): CancelablePromise<ManageScreenDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/screen/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete an existent screen
     * @param id
     * @returns any
     * @throws ApiError
     */
    public delete(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/screen/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get screen by id
     * @param id
     * @returns ManageScreenDTO
     * @throws ApiError
     */
    public getOne(
        id: string,
    ): CancelablePromise<ManageScreenDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/screen/{id}',
            path: {
                'id': id,
            },
        });
    }

}
