import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {JsDataTypes} from "../../types/Common";
import {translate} from "../../selectors/translations";
import {ReactComponent as EditIcon} from "../../assets/img/icons/edit.svg";
import EditNoteForm from "../EditNoteForm";
import HttpPendingStub from "../HttpPendingStub";
import {patientPutNoteRequest} from "../../store/PatientProfile/action";
import './NoteItem.scss';
import { DoctorNoteType } from "../../sdk";
import { NormalizedNote } from "../../store/PatientProfile/types";

type PropsFromState = {
    note: NormalizedNote;
    patientId: string;
    noteType: DoctorNoteType;
};

const NoteItem: React.FC<PropsFromState> = (props: PropsFromState) => {
    const {note, patientId, noteType} = props;
    const [isEditView, setIsEditView] = useState(false);
    const [isHTTPPending, setIsHTTPPending] = useState(false);
    const dispatch = useDispatch();
    const setEditModeHandler = (): void => {
        setIsEditView(true);
    };
    const setViewModeHandler = (): void => {
        setIsEditView(false);
    };
    const handleSaveEditResponse = (result: boolean): void => {
        setIsHTTPPending(false);
        if (result) {
            setViewModeHandler();
        }
    };
    const submitEditFormHandler = (comment: string): void => {
        if (typeof comment !== JsDataTypes.String || !comment.length) {
            return;
        }
        const {_id: noteId} = note;
        const payload = {
            userId: patientId,
            comment,
            noteType,
            cb: handleSaveEditResponse
        };
        setIsHTTPPending(true);
        dispatch(patientPutNoteRequest(payload, noteId));
    };
    return (
        <div>
            {!isEditView &&
                <div className="pt-10">
                    <div className="side-note">
                        <div className="side-note__body">
                            <div className="side-note__text">
                                <p className="m-0"
                                   dangerouslySetInnerHTML={{__html: (note.normalizedComment)}}></p>
                            </div>
                            <div className="side-note__edit-button"
                                 onClick={setEditModeHandler}>
                                <EditIcon />
                            </div>
                            <div className="side-note__header">
                                <p className="m-0">- {note.doctorRole}, {note.doctorName} - {note.updatedAt}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isEditView &&
                <div className="pv-10 p-relative">
                    {isHTTPPending &&
                        <div className="p-absolute w-100 h-100">
                            <HttpPendingStub title={translate('httpPending.label')}></HttpPendingStub>
                        </div>
                    }
                    <EditNoteForm
                        textToEdit={note.text}
                        onCancelCb={setViewModeHandler}
                        onSubmitCb={submitEditFormHandler}></EditNoteForm>
                </div>
            }
        </div>
    );
};

export default NoteItem;
