import React, { useState, ReactElement, useEffect, useContext } from 'react'
import { DropdownCb } from '../../types/Common'
import MassExpandContext from '../../hooks/massExpandContext'

type DefaultProps = {
  defaultValue?: string;
  children: (dropdownId: string, setDropdownId: DropdownCb) => ReactElement;
}

const Toogleable: React.FC<DefaultProps> = (props: DefaultProps) => {
  const {defaultValue} = props
  const [dropdownId, setDropdownId] = useState(defaultValue || '')
  const expand = useContext(MassExpandContext)
  useEffect(() => {
    if (expand) {
      setDropdownId('external')
    } else if (defaultValue) {
      setDropdownId(defaultValue)
    } else {
      setDropdownId('')
    }
  }, [expand])

  return props.children(dropdownId, setDropdownId)
}

export default Toogleable
