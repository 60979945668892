import {PdfItems, ReportStatuses} from "../types/constants";

export class PdfReportItemData {

    id: string;
    title: string;
    date: string;
    status: string;
    isForbiddenToSelect: boolean;
    isSelected: boolean;
    type: string;

    constructor(data: any = {}) {
        const {
            id = '',
            title = '',
            date = '',
            status,
            type = '',
            isSelected = true
        } = data;
        this.id = id;
        this.title = title;
        this.date = date;
        this.status = status;
        this.isForbiddenToSelect = false;
        if (typeof status === 'string') {
            this.isSelected = !!(status && status !== ReportStatuses.NotRequested);
        } else {
            this.isSelected = isSelected;
        }
        this.type = type;
    }

    select(): void {
        this.isSelected = true;
    }

    deselect(): void {
        this.isSelected = false;
    }

    toggleSelection(): void {
        this.isSelected = !this.isSelected;
    }
}

export class PdfReportSubsectionData extends PdfReportItemData {

    parentId: string;
    parentType: string;

    constructor(data: any = {}) {
        const { parentId = '', parentType = '', ...rest } = data;
        super({
            ...rest,
            type: PdfItems.SubSections
        });
        this.parentId = parentId;
        this.parentType = parentType;
    }
}

export class PdfReportSectionData extends PdfReportItemData {

    subsections: Array<PdfReportSubsectionData>;
    areSubsectionsClosed: boolean;

    get anySubsectionSelected(): boolean {
        return !!(this.subsections.filter(item => item.isSelected).length);
    }

    constructor(data: any = {}) {
        const { subsections = [], ...rest } = data;
        super(rest);
        this.subsections = subsections;
        this.areSubsectionsClosed = false;
    }

    toggleSubsection(id: string): void {
        this.subsections = this.subsections.map(item => {
            if (item.id === id) {
                item.toggleSelection();
            }
            return item;
        });
    }

    refreshSelfSelection(): void {
        this.isSelected = this.anySubsectionSelected;
    }

    setSubsectionsClosed(): void {
        this.areSubsectionsClosed = true;
    }

    selectAllSubsections(): void {
        this.subsections = this.subsections.map(item => {
            item.select();
            return item;
        });
    }

    deselectAllSubsections(): void {
        this.subsections = this.subsections.map(item => {
            item.deselect();
            return item;
        });
    }

    toggleSelection(): void {
        super.toggleSelection();
        this.areSubsectionsClosed = !this.isSelected;
        if (this.isSelected && !this.anySubsectionSelected) {
            this.selectAllSubsections();
        }
    }

    selectSomeSubsections(ids: Array<number | string>): void {
        // eslint-disable-next-line no-undef
        const idsSet = new Set(ids);
        this.subsections = this.subsections.map(item => {
            if (idsSet.has(item.id) && !!(item.status && item.status !== ReportStatuses.NotRequested)) {
                item.select();
            } else {
                item.deselect();
            }
            return item;
        });
    }
}
