import React, { RefObject } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import PatientSideInfo from '../../components/PatientSideInfo'
import {
  AnamnesisSectionData,
  NormalizedNote,
  PatientProfileActionType,
  PatientProfileState,
  Survey
} from '../../store/PatientProfile/types'
import {
  getAnamnesisConfig,
  getPatient,
  getViewingAttempts,
  unsetPatientProfile
} from '../../store/PatientProfile/action'
import { ApplicationState } from '../../store'
import SurveyCard from '../../components/SurveyCard'
import CognitiveTestCard from '../../components/CognitiveTestCard'
import FamilySurveyCard from '../../components/FamilySurveyCard'
import { TranlsationLanguage, TranlsationsState, } from '../../store/Translations/types'
import { openModalWindow } from '../../store/ModalWindowData/action'
import { ModalWindowDataType } from '../../store/ModalWindowData/types'
import { getInitialQuestionnaire, getQuestions } from '../../selectors/patientProfile'
import { AnamnesisConfig } from '../../types/Common'
import { translate } from "../../selectors/translations"
import PatientAsideNotes from "../../components/PatientAsideNotes"
import FlutterRoute from "../../flutter-tools/FlutterRoute"

import "./PatientCard.scss"
import {
  DoctorNoteType,
  DoctorNoteWithDoctorDto,
  MedStaffDto,
  MedStaffRole,
  QuestionerAnswersDTOOutput,
  QuestionerType
} from "../../sdk"
import { isGerasInstitution } from "../../selectors/institutions"
import { ModalWindowType } from "../../hoc/ModalWindowGuard/ModalWindowGuard"

type PropsFromState = {
  patientProfile: PatientProfileState;
  translations: TranlsationsState;
  isMenuExpanded: boolean;
  user: MedStaffDto;
  anamnesisConfig: {
    sections: AnamnesisSectionData[];
    questions: { [key: number]: AnamnesisConfig };
  };
  anamnesisConfigNew: {
    sections: AnamnesisSectionData[];
    questions: { [key: number]: AnamnesisConfig };
  };
}
type DefaultState = {
  currentLanguage: string;
  width: number;
  height: number;
  showArchivedTests: boolean;
}
type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>

interface RouteParams {
  id: string;
}
type allProps = PropsFromState & PropsFromDispatch & DefaultState & RouteComponentProps<RouteParams>;

export const normalizeCommentInNote = (data: Array<DoctorNoteWithDoctorDto>): Array<NormalizedNote> => {
  return data.map((item: DoctorNoteWithDoctorDto): NormalizedNote =>
    ({
      ...item,
      normalizedComment: item.text.replace(/\n/g, '<br>\n'),
      doctorName: (item.doctor?.firstName || '') + ' ' + (item.doctor?.lastName || ''),
      doctorRole: item.doctor?.roles.map((role) => translate(role)).join(', '),
      isNurse: item.doctor?.roles.includes(MedStaffRole.NURSE)
    }))
}

class PatientCard extends React.Component<allProps> {
  state: DefaultState;
  testSectionRef: RefObject<HTMLDivElement> = React.createRef();
  patientInfoRef: RefObject<HTMLDivElement> = React.createRef();
  uploadFormData = new FormData();
  constructor(props: allProps) {
    super(props);

    this.state = {
      currentLanguage: (props.translations.languages.find((language) => language.inUse) as TranlsationLanguage).key,
      width: window.innerWidth,
      height: window.innerHeight,
      showArchivedTests: false
    };
    this.createPDFReport = this.createPDFReport.bind(this);
  }

  componentDidMount(): void {
    this.props.getPatient(this.props.match.params.id);
    this.props.getViewingAttempts(this.props.match.params.id);
    this.props.getAnamnesisConfig(this.props.match.params.id, this.state.currentLanguage);
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }


  componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateDimensions);
    this.props.unsetPatientProfile();
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  UNSAFE_componentWillReceiveProps(nextProps: allProps): void {
    const _languageKey = (nextProps.translations.languages.find((language: TranlsationLanguage) => language.inUse) as TranlsationLanguage).key;
    if (_languageKey !== this.state.currentLanguage) {
      this.setState(() => ({currentLanguage: _languageKey}));
      this.props.getAnamnesisConfig(this.props.match.params.id, _languageKey);
    }
  }

  createPDFReport() {
    this.props.openModal(
        'selectItemsForPDFReport',
        {
          userId: this.props.match.params.id,
        });
  }
  renderSurveys(surveys: Survey[]): any {
    return surveys
      .filter((survey: Survey) => !['whyGeras', 'questionnaire'].includes(survey.type))
      .map((survey: Survey, index: number): React.ReactElement => {
        let config;
        if (survey.type === 'initialQuestionnaire') {
          config = {
            sections: [],
            questions: getInitialQuestionnaire()
          }
        } else if (survey.type === 'anamnesisFlex') {
          config = this.props.anamnesisConfig
        }
        else if (survey.type === 'anamnesisFlexNew') {
          config = this.props.anamnesisConfigNew
        } else {
          config = {
            sections: [],
            questions: getQuestions()
          }
        }
        survey.gender = this.props.patientProfile.gender;
        survey.sectionSet = this.props.patientProfile.anamnesisSectionSet;
        return <SurveyCard surveyConfig={config} survey={survey} key={index} isNewAnamnesis={survey.type === 'anamnesisFlexNew'} />
      });
  }

  renderCognitiveTests(tests: Survey[]): React.ReactElement<typeof CognitiveTestCard>[] {
    return tests
    .map((test) => {
      return {...test, sectionSet: [1]}
    }).map((test: Survey, index: number) =>
      <CognitiveTestCard
        openModal={this.props.openModal}
        recordings={this.props.patientProfile.recordings}
        testResult={test}
        currentLanguage={this.state.currentLanguage}
        key={index} />)
  }

  renderFamilySurveyCard(surveys: QuestionerAnswersDTOOutput[]): React.ReactElement<typeof FamilySurveyCard>[] {
    return surveys.map((survey) =>
      <FamilySurveyCard patient={this.props.patientProfile} key={survey.id} answers={survey}/>)
  }

  toggleArchivedTests = () => {
    this.setState((prevState: DefaultState) => {
      return {
        ...prevState,
        showArchivedTests: !prevState.showArchivedTests,
      }
    })
  }

  render(): React.ReactElement {
    const {
      measurements,
      cognitiveTests,
      questionerAnswers,
      doctorNotes
    } = this.props.patientProfile;
    const { isMenuExpanded } = this.props;
    const patientInfo = { ...this.props.patientProfile };
    const isWideScreen = this.state.width >= 1500;
    const patientClassName = `flex flex-column flex-item item-xl-3 patient-section-wrapper ${isMenuExpanded && !isWideScreen ? '' : 'shrinked-for-sidebar'}`;
    const infoClassName = `flex-item item-xl-9 pb-20 patient-section-wrapper ${isMenuExpanded && !isWideScreen ? '' : 'shrinked-for-sidebar'}`;
    const normalizedNotes = normalizeCommentInNote(doctorNotes)
    const journalNotes = normalizedNotes.filter(n => n.kind == DoctorNoteType.JOURNAL_NOTE)
    const summaries = normalizedNotes.filter(n => n.kind == DoctorNoteType.SUMMARY_RESULTS_AND_ASSESSMENT)
    const diagnoses = normalizedNotes.filter(n => n.kind == DoctorNoteType.DIAGNOSIS)
    const recommendations = normalizedNotes.filter(n => n.kind == DoctorNoteType.RECOMMENDATION)

    return (
      <>
        <div className="diagnostic_content-wrapper flex-container">
          <div className={patientClassName}>
            <div style={{ paddingBottom: '10px' }}
                 ref={this.patientInfoRef}>
              <PatientSideInfo pathname={this.props.location.pathname}
                               onDownloadPDF={(): void => this.createPDFReport()}
                               patientInfo={patientInfo} />
            </div>
            <div className="flex-container">
              {isGerasInstitution(this.props.user.institution) &&
                  <div className={'flex-item item--12'}>
                    <PatientAsideNotes
                        title={translate('journalNotes')}
                        notes={journalNotes}
                        noteType={DoctorNoteType.JOURNAL_NOTE}
                        patientId={this.props.match.params.id}/>
                  </div>
              }
              <div className={'flex-item item--12'}>
                <PatientAsideNotes
                    title={translate('diagnosis')}
                    notes={diagnoses}
                    noteType={DoctorNoteType.DIAGNOSIS}
                    patientId={this.props.match.params.id}/>
              </div>
              <div className={'flex-item item--12'}>
                <PatientAsideNotes
                    title={translate('summary_results_and_assessment')}
                    notes={summaries}
                    noteType={DoctorNoteType.SUMMARY_RESULTS_AND_ASSESSMENT}
                    patientId={this.props.match.params.id}/>
              </div>
              <div className={'flex-item item--12'}>
                <PatientAsideNotes
                    title={translate('recommendation')}
                    notes={recommendations}
                    noteType={DoctorNoteType.RECOMMENDATION}
                    patientId={this.props.match.params.id}/>
              </div>
            </div>

          </div>

          <div id="testSection"
               ref={this.testSectionRef}
               className={infoClassName}>
            {
              (cognitiveTests.length || measurements.length) ? <div className={"show-archived-tests"}>
                <span
                    onClick={this.toggleArchivedTests}>{this.state.showArchivedTests ? translate('showNewTests') : translate('showArchivedTests')}
                </span>
              </div> : null
            }
            <div className="flex-container flex-item--fullwidth expand-trigger">
              {this.renderFamilySurveyCard(questionerAnswers.filter(e => e.questioner.kind === QuestionerType.SYMPTOM_SURVEY))}
              {
                this.state.showArchivedTests ?
                    <>
                      {this.renderCognitiveTests(cognitiveTests)}
                      {this.renderSurveys(measurements)}
                    </> :
                    <>
                      {questionerAnswers.length ? <FlutterRoute
                          route="questioner-answer-list"
                          routeParams={{patientId: this.props.match.params.id, lang: this.state.currentLanguage}}
                      /> : null}
                    </>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  patientProfile: state.patientProfile,
  translations: state.translations,
  isMenuExpanded: state.appState.isSideMenuExpanded,
  user: state.auth.user,
  anamnesisConfig: {
    sections: state.patientProfile.anamnesisSections,
    questions: state.patientProfile.anamnesisQuestions
  },
  anamnesisConfigNew: {
    sections: state.patientProfile.anamnesisSectionsNew,
    questions: state.patientProfile.anamnesisQuestionsNew,
  }
});

const mapDispatchToProps = (dispatch: (action: PatientProfileActionType | ModalWindowDataType) => void) => ({
  getPatient: (patientId: string): void => dispatch(getPatient(patientId)),
  unsetPatientProfile: (): void => dispatch(unsetPatientProfile()),
  openModal: (type: ModalWindowType, data: any): void => dispatch(openModalWindow(type, data)),
  getViewingAttempts: (userId: string): void => dispatch(getViewingAttempts(userId)),
  getAnamnesisConfig: (userId: string, language?: string): void => dispatch(getAnamnesisConfig(userId, language)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientCard);
