/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppointmentDto } from '../models/AppointmentDto';
import type { FreeCardBodyDto } from '../models/FreeCardBodyDto';
import type { PaymentDto } from '../models/PaymentDto';
import type { PaymentIntentDto } from '../models/PaymentIntentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create payment intent
     * @returns PaymentIntentDto
     * @throws ApiError
     */
    public createPaymentIntent(): CancelablePromise<PaymentIntentDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/intent',
        });
    }

    /**
     * Use free card to pay for the meeting
     * @param requestBody
     * @returns AppointmentDto
     * @throws ApiError
     */
    public useFreeCard(
        requestBody: FreeCardBodyDto,
    ): CancelablePromise<AppointmentDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/free-card',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get all free card payments
     * @returns PaymentDto
     * @throws ApiError
     */
    public getAllFreeCardPayments(): CancelablePromise<Array<PaymentDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/free-card',
        });
    }

    /**
     * Stripe webhook
     * @returns any
     * @throws ApiError
     */
    public listenWebhook(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/webhook',
        });
    }

    /**
     * Get all payments
     * @returns PaymentDto
     * @throws ApiError
     */
    public getAllPayments(): CancelablePromise<Array<PaymentDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment',
        });
    }

}
