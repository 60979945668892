export enum VoucherCodesActionTypes {
  POST_VOUCHER_CODE_REQUEST = '@@voucherCode/POST_VOUCHER_CODE_REQUEST',
  POST_VOUCHER_CODE_SUCCESS = '@@voucherCode/POST_VOUCHER_CODE_SUCCESS',
  POST_VOUCHER_CODE_FAILURE = '@@voucherCode/POST_VOUCHER_CODE_FAILURE',

  GET_VOUCHER_CODE_REQUEST = '@@voucherCode/GET_VOUCHER_CODE_REQUEST',
  GET_VOUCHER_CODE_SUCCESS = '@@voucherCode/GET_VOUCHER_CODE_SUCCESS',
  GET_VOUCHER_CODE_FAILURE = '@@voucherCode/GET_VOUCHER_CODE_FAILURE',

  UPDATE_VOUCHER_CODE_REQUEST = '@@voucherCode/UPDATE_VOUCHER_CODE_REQUEST',
  UPDATE_VOUCHER_CODE_SUCCESS = '@@voucherCode/UPDATE_VOUCHER_CODE_SUCCESS',
  UPDATE_VOUCHER_CODE_FAILURE = '@@voucherCode/UPDATE_VOUCHER_CODE_FAILURE',

  REFRESH_VOUCHER_CODE_REQUEST = '@@voucherCode/REFRESH_VOUCHER_CODE_REQUEST',
  REFRESH_VOUCHER_CODE_SUCCESS = '@@voucherCode/REFRESH_VOUCHER_CODE_SUCCESS',
  REFRESH_VOUCHER_CODE_FAILURE = '@@voucherCode/REFRESH_VOUCHER_CODE_FAILURE',

  DELETE_VOUCHER_CODE_REQUEST = '@@voucherCode/DELETE_VOUCHER_CODE_REQUEST',
  DELETE_VOUCHER_CODE_SUCCESS = '@@voucherCode/DELETE_VOUCHER_CODE_SUCCESS',
  DELETE_VOUCHER_CODE_FAILURE = '@@voucherCode/DELETE_VOUCHER_CODE_FAILURE',

  GET_VOUCHER_USAGE_STATS_REQUEST = '@@voucherCode/GET_VOUCHER_USAGE_STATS_REQUEST',
  GET_VOUCHER_USAGE_STATS_SUCCESS = '@@voucherCode/GET_VOUCHER_USAGE_STATS_SUCCESS',
  GET_VOUCHER_USAGE_STATS_FAILURE = '@@voucherCode/GET_VOUCHER_USAGE_STATS_FAILURE',
}

// COGNITIVE_TEST_REPORT = 1
export type ProductType = 1;

export interface VoucherCodeRequestPayload {
  discount: number;
  code: string;
}

export interface UpdateVoucherCodeRequestPayload {
  id: string;
  discount: number;
  code: string;
}

export interface VoucherCodeResponse {
  _id: string;
  productType: ProductType;
  generatedCode: string;
  expirationDate: string;
  discountPercentage: number;
  createdAt: string;
  expiresIn: number;
  isExpired: boolean;
}

export interface VoucherStatsUsageResponse {
  userId: string;
  paymentId: string;
  userName: ProductType;
  datePaid: string;
  productId: string;
  voucherCodeName: string;
  voucherDiscount: string;
}

export interface VoucherCodesState {
  all: VoucherCodeResponse[];
  stats: VoucherStatsUsageResponse[];
  errorMsg: string;
}

export interface PostVoucherCodeRequest {
  type: typeof VoucherCodesActionTypes.POST_VOUCHER_CODE_REQUEST;
  data: VoucherCodeRequestPayload;
}
interface PostVoucherCodeSuccess {
  type: typeof VoucherCodesActionTypes.POST_VOUCHER_CODE_SUCCESS;
  response: VoucherCodeResponse;
}
interface PostVoucherCodeFailure {
  type: typeof VoucherCodesActionTypes.POST_VOUCHER_CODE_FAILURE;
  errorMsg: string;
}


export interface GetVoucherCodeRequest {
  type: typeof VoucherCodesActionTypes.GET_VOUCHER_CODE_REQUEST;
}
interface GetVoucherCodeSuccess {
  type: typeof VoucherCodesActionTypes.GET_VOUCHER_CODE_SUCCESS;
  response: VoucherCodeResponse[];
}
interface GetVoucherCodeFailure {
  type: typeof VoucherCodesActionTypes.GET_VOUCHER_CODE_FAILURE;
  errorMsg: string;
}


export interface UpdateVoucherCodeRequest {
  type: typeof VoucherCodesActionTypes.UPDATE_VOUCHER_CODE_REQUEST;
  data: UpdateVoucherCodeRequestPayload;
}
interface UpdateVoucherCodeSuccess {
  type: typeof VoucherCodesActionTypes.UPDATE_VOUCHER_CODE_SUCCESS;
  response: VoucherCodeResponse;
}
interface UpdateVoucherCodeFailure {
  type: typeof VoucherCodesActionTypes.UPDATE_VOUCHER_CODE_FAILURE;
  errorMsg: string;
}

export interface RefreshVoucherCodeRequest {
  type: typeof VoucherCodesActionTypes.REFRESH_VOUCHER_CODE_REQUEST;
  _id: string;
}
interface RefreshVoucherCodeSuccess {
  type: typeof VoucherCodesActionTypes.REFRESH_VOUCHER_CODE_SUCCESS;
  response: VoucherCodeResponse;
}
interface RefreshVoucherCodeFailure {
  type: typeof VoucherCodesActionTypes.REFRESH_VOUCHER_CODE_FAILURE;
  errorMsg: string;
}


export interface DeleteVoucherCodeRequest {
  type: typeof VoucherCodesActionTypes.DELETE_VOUCHER_CODE_REQUEST;
  _id: string;
}
interface DeleteVoucherCodeSuccess {
  type: typeof VoucherCodesActionTypes.DELETE_VOUCHER_CODE_SUCCESS;
  response: {
    _id: string;
    status: boolean;
  };
}
interface DeleteVoucherCodeFailure {
  type: typeof VoucherCodesActionTypes.DELETE_VOUCHER_CODE_FAILURE;
  errorMsg: string;
}

export interface GetVoucherUsageStatsRequest {
  type: typeof VoucherCodesActionTypes.GET_VOUCHER_USAGE_STATS_REQUEST;
}
interface GetVoucherUsageStatsSuccess {
  type: typeof VoucherCodesActionTypes.GET_VOUCHER_USAGE_STATS_SUCCESS;
  response: VoucherStatsUsageResponse[];
}
interface GetVoucherUsageStatsFailure {
  type: typeof VoucherCodesActionTypes.GET_VOUCHER_USAGE_STATS_FAILURE;
  errorMsg: string;
}

export type VoucherCodesActionType =
    PostVoucherCodeRequest |
    PostVoucherCodeSuccess |
    PostVoucherCodeFailure |
    GetVoucherCodeRequest |
    GetVoucherCodeSuccess |
    GetVoucherCodeFailure |
    UpdateVoucherCodeRequest |
    UpdateVoucherCodeSuccess |
    UpdateVoucherCodeFailure |
    RefreshVoucherCodeRequest |
    RefreshVoucherCodeSuccess |
    RefreshVoucherCodeFailure |
    DeleteVoucherCodeRequest |
    DeleteVoucherCodeSuccess |
    DeleteVoucherCodeFailure |
    GetVoucherUsageStatsRequest |
    GetVoucherUsageStatsSuccess |
    GetVoucherUsageStatsFailure;
