/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppointmentDto } from './AppointmentDto';

export type PaymentIntentDto = {
    id: string;
    status: PaymentIntentDto.status;
    amount: number;
    currency: string;
    client_secret: string;
    appointment: AppointmentDto;
};

export namespace PaymentIntentDto {

    export enum status {
        CREATED = 'created',
        COMPLETED = 'completed',
        FAILED = 'failed',
    }


}

