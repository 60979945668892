import React, { useEffect, useState } from "react"
import { translate } from "../../../../selectors/translations"
import { useSelector } from "react-redux"
import MedicalTestItem from "../MedicalTestItem/MedicalTestItem"
import { ReactComponent as TestIcon } from '../../../../assets/img/icons/clock-icon.svg'
import './PatientAnamnesisForm.scss'
import useTranslate from "../../../../hooks/useTranslate"
import { getQuestioners } from "../../../../selectors/patientProfile"
import { AvailableQuestionersDto, QuestionerType } from "../../../../sdk"
import { isDefined } from "../../../../sdk/core/request"

const homeScreenMapConfigs = [
  {
    title: 'patientAccount.newPatient.patientTasks.cognitiveTest',
    slug: 'cognitive'
  },
  {
    title: 'patientAccount.newPatient.patientTasks.symptomSurvey',
    slug: 'symptom_survey'
  },
  {
    title: 'patientAccount.newPatient.patientTasks.medicalHistory',
    slug: 'anamnesis'
  },
]

const anamnesisSectionsGroups = [
  {
    title: 'anamnesisFlexNew.categories.social',
    sectionSlugs: ['social-background-section', 'cognitive-functions-section', 'dietary-habits-section', 'exercise-section'],
  },
  {
    title: 'anamnesisFlexNew.categories.medical',
    sectionSlugs: ['healthstatus-section', 'prescriptivedrugs-section', 'allgergy-section', 'hereditary-section']
  },
  {
    title: 'anamnesisFlexNew.categories.screening',
    sectionSlugs: ['concentration-section', 'depression-section', 'stress-questions-section', 'alcohol-drugs-section', 'ptsd-questions-section']
  }
]

interface IProps {
  onFormChange: (data: any) => void;
  homeScreenMap: AvailableQuestionersDto;
  children?: React.ReactNode;
}

const PatientAnamnesisForm: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {homeScreenMap, children, onFormChange} = props
  const [availableQuestioners, changeAvailableQuestioners] = useState(homeScreenMap)
  const translateLocalazy = useTranslate()
  const anamnesis = useSelector(getQuestioners).find(q => q.kind == QuestionerType.ANAMNESIS)
  // sets initial when loaded
  useEffect(() => {
    changeAvailableQuestioners(homeScreenMap)
  }, [homeScreenMap])

  const handleAnamnesisSectionClick = (slug: string) => {
    const currentSelection = availableQuestioners.anamnesis || []
    const newAnamnesisConfig = currentSelection?.includes(slug)
      ? currentSelection.filter(s => s != slug)
      : [...currentSelection!, slug]
    const update = {...availableQuestioners, anamnesis: newAnamnesisConfig.length ? newAnamnesisConfig : null}
    changeAvailableQuestioners(update)
    onFormChange(update)
  }

  const handleQuestionerClick = (slug: string) => {
    let update
    if (availableQuestioners[slug]) {
      update = {...availableQuestioners, [slug]: null}
    } else {
      const allSections = slug === 'anamnesis' ? anamnesis?.sections.map(e => e.slug) || [] : []
      update = {...availableQuestioners, [slug]: allSections}
    }
    changeAvailableQuestioners(update)
    onFormChange(update)
  }

  const isQuestionerEnabled = (slug: string): boolean => availableQuestioners && Boolean(availableQuestioners[slug])
  const isSectionEnabled = (slug: string): boolean => availableQuestioners && Boolean(availableQuestioners['anamnesis']?.includes(slug))

  return (
    <div className="card-box patient-info">
      <div>
        <div className="medical-items__header">
          <div className="box-title">
            <i className="icon-holder patients-title_icon-holder"><TestIcon color="#ffffff"/></i>
            <h3>{translate('headerCreatePatientSteps.step2')}</h3>
          </div>
        </div>
        <div className="medical-items">
          {homeScreenMapConfigs.map(item => {
            return (
              <div className="item--12 item-sm-6 item-md-4" key={item.slug}>
                <div className="medical-items__items-group-item">
                  <MedicalTestItem
                    title={item.title}
                    onClick={handleQuestionerClick}
                    id={item.slug}
                    isSelected={isQuestionerEnabled(item.slug)}></MedicalTestItem>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div>
        <div className="medical-items__holder">
          {anamnesisSectionsGroups.map(column => (
            <div className="item--12 item-sm-6 item-lg-3 medical-items__items-group" key={column.title}>
              <h3 className="medical-items__items-group-heading">{translate(column.title)}</h3>
              {column.sectionSlugs.map(slug => anamnesis?.sections
                .find(s => s.slug === slug))
                .filter(isDefined)
                .map(section =>
                  (
                    <div className="medical-items__items-group-item sub-items" key={section.slug}>
                      <MedicalTestItem
                        title={translateLocalazy('questioner.anamnesis.section.' + section.slug)}
                        onClick={handleAnamnesisSectionClick}
                        id={section.slug}
                        isSelected={isSectionEnabled(section.slug)}></MedicalTestItem>
                    </div>

                  ))}
            </div>
          ))}
        </div>
        {children ? <div>{children}</div> : null}
      </div>
    </div>
  )
}

export default PatientAnamnesisForm
