/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDoctorNoteDto } from '../models/CreateDoctorNoteDto';
import type { DoctorNoteDto } from '../models/DoctorNoteDto';
import type { UpdateDoctorNoteDto } from '../models/UpdateDoctorNoteDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DoctorNoteService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns DoctorNoteDto
     * @throws ApiError
     */
    public create(
        requestBody: CreateDoctorNoteDto,
    ): CancelablePromise<DoctorNoteDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/doctor-note',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns DoctorNoteDto
     * @throws ApiError
     */
    public update(
        id: string,
        requestBody: UpdateDoctorNoteDto,
    ): CancelablePromise<DoctorNoteDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/doctor-note/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @returns DoctorNoteDto
     * @throws ApiError
     */
    public getAllForPatient(
        id: string,
    ): CancelablePromise<Array<DoctorNoteDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/doctor-note/{id}',
            path: {
                'id': id,
            },
        });
    }

}
