import React from 'react';

type CirclePatternSvgProps = {
  data: number[][];
  color: string;
}

const defaultCirclesData = {
  pattern: [
    [30, 30],
    [30, 90],
    [30, 150],
    [90, 30],
    [90, 90],
    [90, 150],
    [150, 30],
    [150, 90],
    [150, 150]
  ],
  rectSize: 180,
  cellSize: 30,
  defaultR: 12,
  matchR: 18 
};

const drawMatchCircle = (item: number[], index: number): React.ReactElement => (
  <circle
    key={index}
    cx={item[0] * 2 * defaultCirclesData.cellSize + defaultCirclesData.cellSize}
    cy={item[1] * 2 * defaultCirclesData.cellSize + defaultCirclesData.cellSize}
    r={defaultCirclesData.matchR}
    strokeWidth="3"
    stroke="#73b937"
    fill="white"
  />
);

const drawLine = (item: number[], index: number, data: number[][]): React.ReactElement | null => {
  return !index
    ? null
    : (
      <line
        stroke="#73b937"
        strokeWidth="5"
        key={index}
        x1={item[0] * 2 * defaultCirclesData.cellSize + defaultCirclesData.cellSize}
        y1={item[1] * 2 * defaultCirclesData.cellSize + defaultCirclesData.cellSize}
        x2={data[index - 1][0] * 2 * defaultCirclesData.cellSize + defaultCirclesData.cellSize}
        y2={data[index - 1][1] * 2 * defaultCirclesData.cellSize + defaultCirclesData.cellSize}
      />
    )
};

const drawCircle = (item: number[], index: number): React.ReactElement => (
  <circle
    key={index}
    cx={item[0]}
    cy={item[1]}
    r={defaultCirclesData.defaultR}
    fill="#b2b2b2"
  />
);

const CirclePatternSvg: React.SFC<CirclePatternSvgProps> = (props: CirclePatternSvgProps) => {
  const { data, color } = props;
  return (
    <svg width={defaultCirclesData.rectSize} height={defaultCirclesData.rectSize}>
      <rect
        fill="white"
        x={0}
        y={0}
        width={defaultCirclesData.rectSize}
        height={defaultCirclesData.rectSize}
        strokeWidth={'5'}
        stroke={color}
      />
      { data.map(drawLine) }
      { data.map(drawMatchCircle) }
      { defaultCirclesData.pattern.map(drawCircle) }
    </svg>)
};

export default CirclePatternSvg;