/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Referrer {
    MOBILE_APP = 'mobileApp',
    HCP = 'hcp',
    PATIENT_PORTAL = 'patientPortal',
}
