/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ManageQuestionerDTO } from '../models/ManageQuestionerDTO';
import type { QuestionerDTO } from '../models/QuestionerDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class QuestionerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns QuestionerDTO
     * @throws ApiError
     */
    public getSymptomSurveyQuestioner(): CancelablePromise<QuestionerDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/questioner/symptom-survey',
        });
    }

    /**
     * Create a new questioner
     * @param requestBody
     * @returns QuestionerDTO
     * @throws ApiError
     */
    public create(
        requestBody: ManageQuestionerDTO,
    ): CancelablePromise<QuestionerDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/questioner',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get questioners for user
     * @returns QuestionerDTO
     * @throws ApiError
     */
    public getQuestioners(): CancelablePromise<Array<QuestionerDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/questioner',
        });
    }

    /**
     * Update an existent questioner
     * @param id
     * @param requestBody
     * @returns QuestionerDTO
     * @throws ApiError
     */
    public update(
        id: string,
        requestBody: ManageQuestionerDTO,
    ): CancelablePromise<QuestionerDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/questioner/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Delete an existent questioner
     * @param id
     * @returns any
     * @throws ApiError
     */
    public delete(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/questioner/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get a questioner by id
     * @param id
     * @returns QuestionerDTO
     * @throws ApiError
     */
    public getById(
        id: string,
    ): CancelablePromise<QuestionerDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/questioner/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get all questioners
     * @returns QuestionerDTO
     * @throws ApiError
     */
    public getAllQuestioners(): CancelablePromise<Array<QuestionerDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/questioner/all',
        });
    }

}
