import { JsDataTypes } from "../types/Common";
import { PatientProfile } from "../store/PatientProfile/models";
import { calculateAge, formatDateDDMMYYYY } from "./datetime";
import { PatientProfileState } from "../store/PatientProfile/types";

import { DoctorNoteType } from "../sdk";

export const mapPatientProfile = (res: {data: PatientProfileState}): PatientProfileState => {
  if (
    !res
    || typeof res !== JsDataTypes.Object
    || !res.data
    || typeof res.data !== JsDataTypes.Object
  ) {
    return new PatientProfile();
  }
  const { birthDate} = res.data as PatientProfile;
  const normalizedBirthDate = new Date(birthDate).toString();
  let age = "User does not specify a birthday";
  if (birthDate) {
    age = `${calculateAge(new Date(birthDate))} (${formatDateDDMMYYYY(new Date(birthDate))})`
  }

  return {
    ...res.data,
    birthDate: normalizedBirthDate,
    age,
  };
};

export const patientHasNotesOfType =
  (profile: PatientProfileState, type: DoctorNoteType) => getPatientNotesOfType(profile, type).length > 0

export const getPatientNotesOfType =
  (profile: PatientProfileState, type: DoctorNoteType) => profile.doctorNotes.filter(n => n.kind == type)
