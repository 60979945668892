import { InstitutionsState, InstitutionsActionType, InstitutionEnum } from './types';

const initialState: InstitutionsState = {
  all: [],
  errorMsg: ''
}

export function InstitutionsReducer(
  state = initialState,
  action: InstitutionsActionType
): InstitutionsState {
  switch (action.type) {
    case InstitutionEnum.GET_INSTITUTIONS_REQUEST:
      return { ...state }
    case InstitutionEnum.GET_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        all: action.payload
      }
    default:
      return state
  }
}

