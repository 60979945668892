export enum ApiMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH'
}

export enum ApiUserPaths {
  GET_ANAMNESIS_CONFIG = '/users/anamnesis',
  SEND_EMAIL = '/users/send-email/welcome',
  SET_TEST_AS_VIEWED = '/tests/:testId/sectionReview',
  SEND_WELCOME_TEXT_MESSAGE = '/messages/send-sms/welcome',
}

export enum ApiDashboardPath {
  GET = '/dashboard',
}

export enum ApiAuthPath {
  SESSION_INFO = '/session/referrer',
}

export enum ApiFamilyPath {
  CHECK = '/family/check-token',
  CREATE = '/family',
  REQUEST_FAMILY_SURVEY = '/nurse-request',
}

export enum ApiVoucherCodePath {
  CREATE_CODE = '/voucher-code',
  GET_CODES = '/voucher-code',
  REFRESH_CODE = '/voucher-code',
  EDIT_CODE = '/voucher-code/edit',
  DELETE_CODE = '/voucher-code',
  GET_STATS = '/voucher-code/stats',
}

export enum ApiDocumentsPath {
  BASE_URL = '/documents',
}
