import React, { ReactElement } from 'react';
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router';
import { getPatients } from '../../selectors/patients';
import { ReactComponent as SearchIcon } from '../../assets/img/icons/search-icon.svg';
import { ReactComponent as FilterIcon } from '../../assets/img/icons/filter-icon.svg';
import {
  PatientFilters,
  PatientsActionType,
  PatientAction,
} from '../../store/Patients/types';
import { fetchRequest, resetPatientsState } from '../../store/Patients/action';
import { ApplicationState } from '../../store';
import Toggleable from '../../rc-components/Toggleable/Toggleable';
import SelectorButton from '../../components/SelectorButton/SelectorButton';
import FiltersForm from '../../components/FiltersForm/FiltersForm';
import { PatientTableBody, TableHeaders } from '../../components/DataTable/Table';
import { getLanguages, translate } from '../../selectors/translations';
import { LanguageType } from '../../store/Translations/types';
import { openModalWindow } from '../../store/ModalWindowData/action';
import { ModalWindowDataType } from '../../store/ModalWindowData/types';
import Paginator from "../../components/Paginator/Paginator";
import { unsetPatientProfile } from "../../store/PatientProfile/action";
import { PatientProfileActionType } from "../../store/PatientProfile/types";
import greenHills from '../../assets/img/greenHills.svg';
import '../../assets/scss/layouts/greenHills.scss'
import { UserDto } from "../../sdk";
import { ModalWindowType } from "../../hoc/ModalWindowGuard/ModalWindowGuard"

const tableTitles = [
  'lastName',
  'firstName',
  'personalNumber',
  'age',
  'gender',
  'createdAt'
];

type PropsFromState = {
  successMsg?: string;
  errorMsg?: string;
  languages: { key: LanguageType; inUse: boolean }[];
  accountInstitution: string;
  patients: UserDto[]
  totalUsersCount: number
}

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>

type AllProps = PropsFromDispatch & PropsFromState & RouteComponentProps;

class PatientListPage extends React.Component<AllProps> {
  actionTypes: { [key: string]: (data: string) => void | ReactElement } = {
    'edit': (): void => {
    },
    'card': (): void => {
    },
    'remove': (): void => {
    },
  }

  state: { currentPage: number, filters: PatientFilters } = {
    filters: {
      sort: {field: 'createdAt', direction: false},
      searchTerm: '',
      displayedType: 'all',
      gender: 'all',
      language: 'all',
      ageMin: '0',
      ageMax: '150',
      scoreMin: '0',
      scoreMax: '59',
      institution: ''
    },
    currentPage: 1,
  }

  constructor(props: AllProps) {
    super(props);
    this.actionTypes.edit = ((id: string): void => this.props.history.push(`/patient/edit/${id}`)).bind(this)
    this.actionTypes.card = ((id: string): void => this.props.history.push(`/patient/card/${id}`)).bind(this)

    this.actionTypes.remove = ((id: string): void => {
      const patient = this.props.patients.find((p: UserDto) => p._id === id)!;
      this.props.openModal('remove', {
        _id: patient._id,
        firstName: patient.firstName,
        lastName: patient.lastName,
        type: 'removePatient'
      })
    }).bind(this)
  }


  public componentDidMount(): void {
    const {filters} = this.state
    const {resetPatientProfile} = this.props
    resetPatientProfile()
    this.props.fetchRequest(1, filters)
  }

  componentWillUnmount(): void {
    this.props.resetState();
  }

  onPageChange(page: number) {
    this.setState({currentPage: page})
    this.props.fetchRequest(page, this.state.filters);
  }

  onFilterChanged(filters: any) {
    const newFilters = {...this.state.filters, ...filters}
    this.setState({filters: newFilters})
    this.props.fetchRequest(1, newFilters);
  }

  debounce(func: any, wait: number) {
    let timeout: any;

    return function executedFunction(...args: any) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  render(): React.ReactElement {
    const {filters} = this.state;
    const {patients, totalUsersCount} = this.props;
    const resultsText = (totalUsersCount && totalUsersCount === 1) ? translate('totalPatients.counter.singular') : translate('totalPatients.counter.plural');

    return (
      <div>
        <div className="search-box">
          <div className="flex-container justify-between align-start">
            <div className="flex-item item-sm-12 item-lg-7">
              <div className="search-block_wrapper flex">
                <div className="input-holder flex-item--stretch">
                  <div className="icon-holder">
                    <SearchIcon className="search-block_search-icon"/>
                  </div>
                  <input
                    className="search-block_input"
                    onChange={(e): void => this.onFilterChanged({searchTerm: e.target.value})}
                    type="text"
                    value={filters.searchTerm}
                    placeholder={translate('Write patient’s name, personal number, or email')}/>
                </div>
              </div>
              <div className="search-block_results-counter hidden-lg-down">
                <span>{translate('search.results')}:&nbsp;</span>
                <span className="t_bold">{totalUsersCount || 0}&nbsp;</span>
                <span>{resultsText}</span>
              </div>
            </div>
            <div className="search_filters-row flex-item item-sm-12 item-lg-5">
              <Toggleable>
                {
                  (dropdownId: string, setDropdownId: (id: string) => void): React.ReactElement => (
                    <div className="flex-container">
                      <div className="flex-item flex-item--shrink search_status-selector patients_filter-box">
                      </div>
                      <div className="flex-item flex-item--shrink search_filters-box">
                        <SelectorButton
                          Elem={<FiltersForm
                            filters={filters}
                            filtersChanged={this.onFilterChanged.bind(this)}
                            closeFilters={setDropdownId}
                          />}
                          icon={<FilterIcon className="search_filter-icon"/>}
                          classPrefix={'--filters'}
                          type={'filterForm'}
                          title={'filters'}
                          dropdownId={dropdownId}
                          onToggleMenu={setDropdownId}>
                        </SelectorButton>
                      </div>
                    </div>
                  )
                }
              </Toggleable>
            </div>
          </div>
        </div>
        <div className="search_table-box">
          <table className="search_patients-table">
            <TableHeaders
              onSortClick={this.onFilterChanged.bind(this)}
              sortOpts={{
                sortableColumns: ['lastName', 'firstName', 'createdAt', 'gender', 'age'],
                isAsc: filters.sort.direction,
                filterField: filters.sort.field
              }}
              columnTitles={tableTitles}
            />
            <PatientTableBody
              data={patients}
              actions={['edit', 'remove']}
              propKeys={tableTitles}
              onActionClick={(data: PatientAction): void | ReactElement => this.actionTypes[data.action](data._id)}
            />
          </table>
        </div>
        <div className="flex justify-center paginator-wrapper">
          <Paginator
            currentPage={this.state.currentPage}
            onChangePage={p => this.onPageChange(p)}
            totalItemsCount={totalUsersCount}
            pageSize={50}
          />
        </div>
        <img className="greenHills" src={greenHills}/>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  successMsg: '',
  errorMsg: '',
  languages: getLanguages(state),
  accountInstitution: state.auth.user.institution,
  patients: getPatients(state),
  totalUsersCount: state.patients.totalUsersCount,
});

const mapDispatchToProps = (dispatch: (action: PatientsActionType | ModalWindowDataType | PatientProfileActionType) => void) => ({
  fetchRequest: (page: number, filter?: PatientFilters): void => dispatch(fetchRequest(page, filter)),
  openModal: (type: ModalWindowType, data: any): void => dispatch(openModalWindow(type, data)),
  resetState: (): void => dispatch(resetPatientsState()),
  resetPatientProfile: (): void => dispatch(unsetPatientProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientListPage);
