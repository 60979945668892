import React, { useCallback } from "react";
import { RouteNames } from "../../../../types/constants";
import { useHistory } from "react-router-dom";
import { ReactComponent as UserIcon } from "../../../../assets/img/icons/user-icon.svg";
import "./SearchResultTable.scss";
import { translate } from "../../../../selectors/translations";
import { formatDate } from "../../../../utils/datetime";
import { UserDto } from "../../../../sdk";

interface IProps {
  patients: UserDto[];
}

const SearchResultTable: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {patients} = props;
  const history = useHistory();

  const handleRowClick = useCallback((patient: UserDto) => {
    history.push(`${RouteNames.PatientCard}/${patient._id}`);
  }, []);

  return (
    <div className="search-result-table__wrapper">
      <table className="search-result-table">
        <thead>
        <tr>
          <th></th>
          <th>{translate('name')}</th>
          <th>{translate('personalNumber')}</th>
          <th style={{height: '50px'}}>{translate('createdAt')}</th>
          {/*<th className="titled-status-header">*/}
          {/*  <div className="status-hearer-wrapper">*/}
          {/*    <span>Anamnes</span>*/}
          {/*    <span>Kognitivt test</span>*/}
          {/*    <span>Symtomenkät</span>*/}
          {/*  </div>*/}
          {/*</th>*/}
        </tr>
        </thead>
        <tbody className="search-result-table__body">
        {patients.map((patient, index) => {
          return (

            <tr key={index}
                onClick={() => handleRowClick(patient)}
                className="search-result-table__row">
              <td className="search-result-table__cell">
                <div className="user-icon">
                  <UserIcon/>
                </div>
              </td>
              <td className="search-result-table__cell">{patient.firstName} {patient.lastName}</td>
              <td className="search-result-table__cell">{patient.personalNumber}</td>
              <td className="search-result-table__cell">
                {formatDate(patient.createdAt)}
                {/*{patient.testStatuses ?*/}
                {/*  <div className="flex justify-end">*/}
                {/*    {patient.testStatuses.map((item, index) => {*/}
                {/*      return <div style={{backgroundColor: item.color}}*/}
                {/*                  key={index}*/}
                {/*                  className="t_bold statusCircleDiv">*/}
                {/*          <p className="statusText" style={{color: item.color}}>*/}
                {/*            {translate(`testStatus.${item.status}` || 'testStatus.error')}*/}
                {/*          </p>*/}
                {/*      </div>*/}
                {/*    })*/}
                {/*    }*/}
                {/*  </div>*/}
                {/*  : <div className="flex justify-end ph-5">*/}
                {/*    <p className="m-0">No status results</p>*/}
                {/*  </div>*/}
                {/*}*/}
              </td>
            </tr>
          );
        })
        }
        </tbody>
      </table>
    </div>
  );
};

export default SearchResultTable;
