import React, { useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import {
  loginRequest,
  startBankId,
  getQrCodeDataRequest,
  cleanBankIdAttempt, checkBankIdStatusRequest
} from '../store/Auth/action'
import { switchTranslation } from '../store/Translations/actions'

import { ApplicationState } from '../store'

import {
  AuthState,
  AuthActionType,
  LoginPayload,
  LoginOption,
  LoginPayloadEmail
} from '../store/Auth/types'
import { TranslationsActionType } from '../store/Translations/types'
import LoginForm from '../components/LoginForm/LoginForm'
import { TranlsationLanguage } from '../store/Translations/types'
import { getBankIdAttempt } from "../selectors/auth"

type PropsFromState = {
  auth: AuthState;
  languages: TranlsationLanguage[];
}

type PropsFromDispatch = {
  loginRequest: (data: LoginPayloadEmail) => void;
  switchLanguage: (language: string) => void;
  getQrCodeData: (orderRef: string) => void;
  startBankId: () => void;
  cleanBankIdAttempt: () => void;
  checkBankIdStatus: (orderRef: string) => void;
}

type AllProps = PropsFromState & PropsFromDispatch & RouteComponentProps;

let bankIdStatusIntervalId: NodeJS.Timeout | undefined
let qrCodeIntervalId: NodeJS.Timeout | undefined

const LoginPage: React.FC<AllProps> = (props: AllProps) => {
  const bankIdAttempt = useSelector(getBankIdAttempt)
  const {
    loginRequest,
    auth,
    location,
    switchLanguage,
    languages,
    startBankId,
    getQrCodeData,
    cleanBankIdAttempt,
    checkBankIdStatus
  } = props
  // @ts-ignore
  const {from} = location.state || {from: {pathname: '/'}}

  const [loginType, setLoginType] = useState(LoginOption.qrCode)

  const startCheckingBankIdStatus = (orderRef: string) => {
    bankIdStatusIntervalId = setInterval(() => checkBankIdStatus(orderRef), 1000)
  }
  const stopCheckingBankIdStatus = () => {
    if (bankIdStatusIntervalId) {
      clearInterval(bankIdStatusIntervalId)
      bankIdStatusIntervalId = undefined
    }
  }

  const startGeneratingQrCode = (orderRef: string) => {
    qrCodeIntervalId = setInterval(() => getQrCodeData(orderRef), 1000)
  }
  const stopGeneratingQrCode = () => {
    if (qrCodeIntervalId) {
      clearInterval(qrCodeIntervalId)
      cleanBankIdAttempt()
      qrCodeIntervalId = undefined
    }
  }

  const onSubmit = (userData: LoginPayload): void => {
    setLoginType(userData.type)
    stopCheckingBankIdStatus()
    stopGeneratingQrCode()

    switch (userData.type) {
      case LoginOption.qrCode:
        startBankId()
        break
      case LoginOption.email:
        loginRequest(userData)
        break
    }
  }

  if (auth.isAuthenticated) {
    stopCheckingBankIdStatus()
    stopGeneratingQrCode()

    return <Redirect to={from}/>
  } else if (auth.authFailed) {
    stopCheckingBankIdStatus()
    stopGeneratingQrCode()
  }

  if (bankIdAttempt) {
    switch (loginType) {
      case LoginOption.email:
        break
      case LoginOption.qrCode:
        if (!qrCodeIntervalId) {
          startGeneratingQrCode(bankIdAttempt.orderRef)
          startCheckingBankIdStatus(bankIdAttempt.orderRef)
        }
        break
    }
  }

  return (
    <div>
      <LoginForm
        onSubmit={onSubmit}
        isFetching={auth.isFetching}
        switchLanguage={switchLanguage}
        languages={languages}
        errorMsg={auth.errorMsg}/>
    </div>
  )
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  auth: state.auth,
  languages: state.translations.languages,
})

type UnionActionTypes = AuthActionType | TranslationsActionType;

const mapDispatchToProps = (dispatch: (action: UnionActionTypes) => void): PropsFromDispatch => ({
  loginRequest: (userData) => dispatch(loginRequest(userData)),
  startBankId: () => dispatch(startBankId()),
  cleanBankIdAttempt: () => dispatch(cleanBankIdAttempt()),
  checkBankIdStatus: (orderRef: string) => dispatch(checkBankIdStatusRequest(orderRef)),
  getQrCodeData: (orderRef: string) => dispatch(getQrCodeDataRequest(orderRef)),
  switchLanguage: (language: string) => dispatch(switchTranslation(language)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
