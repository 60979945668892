import React, { useState } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close-icon.svg'
import { translate } from '../../selectors/translations'
import { ModalWindowAction, ModalWindowCallbackHandler } from '../../store/ModalWindowData/types'
import useTranslate from "../../hooks/useTranslate"
import { useSelector } from "react-redux"
import { getInstitutionsSelector } from "../../selectors/institutions"
import Select from 'react-select'
import { ManageSmsTemplateDto } from "../../sdk"

type PropsFromState = {
  onConfirm: (data: ModalWindowAction<any>) => void;
  data: any;
}

type FormActionState = {
  onConfirm: (val: any) => void;
  data?: any;
  onSubmitAction?: string;
}

const FormHeader: React.SFC<FormActionState> = (props: FormActionState): React.ReactElement => {
  const {onConfirm} = props
  return (
    <div className="note-popup_header flex collapse justify-betweenalign-center">
      <div className="flex-item flex-item--stretch">{translate('Add-voucher')}</div>
      <div className="flex-item flex-item--shrink">
        <button type="button" onClick={(): void => onConfirm({action: 'cancel', data: null})}><CloseIcon/>
        </button>
      </div>
    </div>
  )
}

const FormFooter: React.SFC<FormActionState> = (props: FormActionState): React.ReactElement => {
  const {onConfirm, data, onSubmitAction} = props
  return (
    <div className="note-popup_footer">
      <button className="g_btn g_btn--primary"
              onClick={(): void => onConfirm({action: onSubmitAction, data})}>{translate('save')}</button>
      <button className="g_btn g_btn--outlined"
              onClick={(): void => onConfirm({action: 'cancel', data: null})}>{translate('cancel')}</button>
    </div>
  )
}

export const EditVoucherCodeForm = (props: PropsFromState) => {
  const {data, onConfirm} = props

  const [discount, setDiscount] = useState(data.voucher.percentage || 50)
  const [code, setCode] = useState(data.voucher.generatedCode || '')

  return (
    <div>
      <FormHeader onConfirm={(val): void => onConfirm({action: val.action, data: {}})}/>

      <div className="input-holder flex-item item-sm-3"><label>{translate('Select-discount')}</label>
        <select
          onChange={(event) => {
            const target: number = +event.target.value
            setDiscount(target)
          }}
          value={discount} name="code">
          {data.discounts.map((el: any, i: number) => <option key={i}
                                                              value={el.percentage}>{el.percentage + '%'}</option>)}
        </select>
      </div>
      <div className="input-holder flex-item item-sm-6" style={{marginTop: '14px'}}>
        <label>{translate('Enter-code')}</label>
        <input type="text"
               onChange={(event) => {
                 const target = event.target.value
                 setCode(target.trim())
               }}
               value={code} name="voucherCode" placeholder={translate('Type here...')}/>
        {!code || code.length < 4 && <span
            className="login_form-error">{translate('Field must not be empty and must have a minimum of 4 characters long')}</span>}
      </div>
      <FormFooter onSubmitAction={'updateVoucherCode'} onConfirm={(val): void => onConfirm({
        action: val.action,
        data: {discount, code, id: data.voucher._id}
      })}/>
    </div>
  )
}

export const AddVoucherCodeForm = (props: PropsFromState): React.ReactElement => {
  const {data, onConfirm} = props

  const [discount, setDiscount] = useState(50)
  const [code, setCode] = useState('')

  return (
    <div>
      <FormHeader onConfirm={(val): void => onConfirm({action: val.action, data: {}})}/>
      <div className="input-holder flex-item item-sm-3"><label>{translate('Select-discount')}</label>
        <select
          onChange={(event) => {
            const target: number = +event.target.value
            setDiscount(target)
          }}
          value={discount} name="code">
          {data.discounts.map((el: any, i: number) => <option key={i}
                                                              value={el.percentage}>{el.percentage + '%'}</option>)}
        </select>
      </div>
      <div className="input-holder flex-item item-sm-6" style={{marginTop: '14px'}}>
        <label>{translate('Enter-code')}</label>
        <input type="text"
               onChange={(event) => {
                 const target = event.target.value
                 setCode(target.trim())
               }}
               value={code} name="voucherCode" placeholder={translate('Type here...')}/>
        {!code || code.length < 4 && <span
            className="login_form-error">{translate('Field must not be empty and must have a minimum of 4 characters long')}</span>}
      </div>
      <FormFooter onSubmitAction={'postVoucherCode'}
                  onConfirm={(val): void => onConfirm({action: val.action, data: {discount, code}})}/>
    </div>
  )
}

export const DeleteSmsTemplateConfirmationModal = (props: {
  onConfirm: ModalWindowCallbackHandler<'deleteSmsTemplate' | 'cancel'>
  data: { _id: string, titleKey: string };
}) => {
  const {data, onConfirm} = props
  const translateLocalazy = useTranslate()

  return (
    <div>
      <div className="note-popup_header flex collapse justify-betweenalign-center">
        <div
          className="flex-item flex-item--stretch"
          dangerouslySetInnerHTML={{__html: translateLocalazy('admin.sms-template.delete-template').replace('{templateName}', `<strong>"${translateLocalazy(data.titleKey)}"</strong>`)}}>
        </div>
        <div className="flex-item flex-item--shrink">
          <button type="button" onClick={(): void => onConfirm({action: 'cancel', data: null})}><CloseIcon/>
          </button>
        </div>
      </div>
      <div className="note-popup_footer">
        <button className="g_btn g_btn--primary"
                onClick={(): void => onConfirm({
                  action: 'deleteSmsTemplate',
                  data: data._id
                })}>{translateLocalazy('delete')}</button>
        <button className="g_btn g_btn--outlined"
                onClick={(): void => onConfirm({action: 'cancel', data: null})}>{translate('cancel')}</button>
      </div>
    </div>
  )
}

export const ManageSmsTemplateModal = (props: {
  onConfirm: ModalWindowCallbackHandler<'postSmsTemplate' | 'cancel'>;
  data: ManageSmsTemplateDto | null;
}) => {
  const {data, onConfirm} = props
  const translateLocalazy = useTranslate()
  const institutions = useSelector(getInstitutionsSelector)

  const [title, setTitle] = useState(data?.title || '')
  const [selectedInstitutions, setSelectedInstitutions] = useState(data?.visibleForInstitutions || [])

  return (
    <div>
      <div className="note-popup_header flex collapse justify-betweenalign-center">
        <div className="flex-item flex-item--stretch">{translateLocalazy('admin.sms-template.new-template')}</div>
        <div className="flex-item flex-item--shrink">
          <button type="button" onClick={(): void => onConfirm({action: 'cancel', data: null})}><CloseIcon/>
          </button>
        </div>
      </div>
      <div className="input-holder flex-item item-sm-6" style={{marginTop: '14px'}}>
        {!data?._id &&
            <div className="input-holder flex-item">
                <label>{translateLocalazy('admin.sms-template.enter-title')}</label>
                <input type="text"
                       onChange={(event) => setTitle(event.target.value)}
                       value={title} name="title" placeholder={translate('Type here...')}/>
              {!title && <span
                  className="login_form-error">{translate('Field must not be empty and must have a minimum of 4 characters long')}</span>}
            </div>
        }
        <br/>
        <div className="input-holder flex-item"><label>{translateLocalazy('admin.sms-template.select-institution')}</label>
          <Select
            name="institutions"
            isMulti
            defaultValue={selectedInstitutions.map(e => ({value: e, label: institutions.find(i => i.id === e)?.name}))}
            options={institutions.map(e => ({value: e.id, label: e.name}))}
            onChange={e => setSelectedInstitutions(e.map(a => a.value))}
          />
        </div>
      </div>
      <FormFooter onSubmitAction={'postSmsTemplate'} onConfirm={() => {
        const manageDto: ManageSmsTemplateDto = {
          _id: data?._id,
          title: title.trim(),
          visibleForInstitutions: selectedInstitutions
        }
        onConfirm({action: 'postSmsTemplate', data: manageDto})
      }}/>
    </div>
  )
}
