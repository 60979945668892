import React from 'react';
import playIcon from '../../assets/img/icons/play-icon-player.svg';
import pauseIcon from '../../assets/img/icons/pause-icon.svg';
import './AudioPlayer.css';
type PropsFromState = {
  url: string;
}

type ComponentState = {
  inProgress: boolean;
  buckets: number[];
  audioParseDone: boolean;
  trackDuration: number;
  NUMBER_OF_BUCKETS: number;
  currentTime: number;
  svgWidth: number;
  svgHeight: number;
  url: string;
  SPACE_BETWEEN_BARS: number;
  bucketSVGWidth: number;
  tickRate: number;
  progressInc: number;
  progress: number;
  progressChange: number;
  playing: boolean;
  isScrolling: boolean;
  editingAnswer: boolean;
  timer: number;
}

class AudioPlayer extends React.Component<PropsFromState, ComponentState> {
  timer: any;
  reffs: { [key: string]: any } = {};
  constructor(props: PropsFromState) {
    super(props);

    this.tick = this.tick.bind(this);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.handleMouseDownEvent = this.handleMouseDownEvent.bind(this);
    this.handleMouseUpEvent = this.handleMouseUpEvent.bind(this);
    this.handleMouseMoveEvent = this.handleMouseMoveEvent.bind(this);
    this.setRef = this.setRef.bind(this);
    const svgWidth = 300;
    const NUMBER_OF_BUCKETS = 660;
    const spaceBetweenBuckets = 75; // %

    this.state = {
      inProgress: false,
      playing: false,
      url: '',
      buckets: [],
      audioParseDone: false,
      trackDuration: 60, //in seconds
      NUMBER_OF_BUCKETS,
      currentTime: 0,
      svgWidth,
      svgHeight: 260,
      SPACE_BETWEEN_BARS: svgWidth / NUMBER_OF_BUCKETS * spaceBetweenBuckets / 100,
      bucketSVGWidth: svgWidth / NUMBER_OF_BUCKETS * (100 - spaceBetweenBuckets) / 100,
      tickRate: 30, //ms
      progressInc: 0,
      progress: 0,
      progressChange: 0,
      isScrolling: false,
      editingAnswer: false,
      timer: 0,
    };
  }

  componentDidMount(): void {
    const { NUMBER_OF_BUCKETS, audioParseDone } = this.state as ComponentState;
    const { url } = this.props;
    try {
      const audioCtx = new (window.AudioContext)();
      fetch(url)
        .then(res => res.arrayBuffer())
        .then(((arrayBuffer: ArrayBuffer): void => {
          if (arrayBuffer && !audioParseDone) {
            audioCtx.decodeAudioData(arrayBuffer,
              buffer => {
                const decodedAudioData = buffer.getChannelData(0);
                const decodedAudioData2 = buffer.numberOfChannels > 1 ? buffer.getChannelData(1) : buffer.getChannelData(0);

                const bucketDataSize = Math.floor(decodedAudioData.length / NUMBER_OF_BUCKETS);
                const buckets: number[] = [];

                for (let i = 0; i < NUMBER_OF_BUCKETS; i++) {
                  const startingPoint = i * bucketDataSize;
                  const endingPoint = i * bucketDataSize + bucketDataSize;
                  const peak = decodedAudioData
                    .slice(startingPoint + 1, endingPoint)
                    .reduce(function (previousValue, currentValue) {
                      if (previousValue < currentValue) {
                        return currentValue * 1.5;
                      }
                      else {
                        return previousValue;
                      }
                    }, decodedAudioData[startingPoint]);
                  const peak2 = decodedAudioData2
                    .slice(startingPoint + 1, endingPoint)
                    .reduce(function (previousValue, currentValue) {
                      return (previousValue + Math.abs(currentValue)) / 2
                    }, Math.abs(decodedAudioData[startingPoint]));
                  buckets.push(Math.abs(peak + peak2) / 4);
                }

                const trackDuration = buffer.duration;
                this.setState(prevState => ({
                  ...prevState,
                  buckets,
                  trackDuration: Math.ceil(trackDuration),
                  audioParseDone: true,
                  url: this.props.url,
                  progressInc: (this.state as ComponentState).svgWidth * (this.state as ComponentState).tickRate / trackDuration / 1000
                }));
              },
              e => {
                console.log(e)
              });
          }

        }).bind(this))
        .catch(e => console.log('Record fetch error - ', e));
    } catch (err) {
      console.log(err)
    }
  }

  handleMouseDownEvent(event: any): void {
    const { trackDuration, svgWidth } = (this.state as ComponentState);

    const clickedX = event.clientX;
    const svgDomRect = (this.reffs.audioSvg).getBoundingClientRect();

    const progressChange = clickedX - Math.round(svgDomRect.x);

    this.setState(prevState => {
      return {
        ...prevState,
        isScrolling: true,
        currentTime: trackDuration * progressChange / svgWidth,
        progress: clickedX - Math.round(svgDomRect.x) - 2
      }
    });
  }

  handleMouseMoveEvent(event: any): void {
    const { trackDuration, svgWidth, isScrolling } = (this.state as ComponentState);

    const clickedX = event.clientX;
    const svgDomRect = this.reffs.audioSvg.getBoundingClientRect();

    const progressChange = clickedX - Math.round(svgDomRect.x);

    if (isScrolling) {
      this.setState(prevState => {
        return {
          ...prevState,
          currentTime: trackDuration * progressChange / svgWidth,
          progress: clickedX - Math.round(svgDomRect.x) - 2
        }
      });
    }
  }

  handleMouseUpEvent(): void {
    this.setState(prevState => {
      return {
        ...prevState,
        isScrolling: false
      }
    });
    this.reffs.audioTrack.currentTime = this.state.currentTime;
  }

  tick(): void {
    const { progress, svgWidth, isScrolling } = this.state as ComponentState;

    if (progress >= svgWidth) {
      clearInterval(this.timer);
      this.setState(prevState => {
        return {
          ...prevState,
          inProgress: false,
          currentTime: 0,
          progress: 0
        }
      });
    }
    else if (!isScrolling) {
      return this.setState((prevState: ComponentState) => {
        return {
          ...prevState,
          currentTime: prevState.currentTime + 0.05,
          progress: prevState.progress + prevState.progressInc
        }
      })
    }
  }

  play(): void {
    if (!((this.state as ComponentState)).inProgress) {
      this.setState((prevState: ComponentState) => {
        return {
          ...prevState,
          inProgress: true,
          playing: true
        }
      });

      this.reffs.audioTrack.play();
      this.timer = setInterval(this.tick, this.state.tickRate);
    }
  }

  pause(): void {
    if ((this.state as ComponentState).inProgress) {
      this.setState(prevState => {
        return {
          ...prevState,
          inProgress: false
        }
      });
      this.reffs.audioTrack.pause();
      clearInterval(this.timer);
    }
  }

  setRef(key: string, node: any): void {
    this.reffs[key] = node;
  }

  render(): React.ReactElement {
    const {
      SPACE_BETWEEN_BARS,
      buckets,
      bucketSVGWidth,
      svgWidth,
      NUMBER_OF_BUCKETS,
      svgHeight,
      trackDuration,
      currentTime,
      inProgress,
      url
    } = this.state as ComponentState;

    const trackSecondsNumber = Math.floor(trackDuration % 60);
    const trackMinutesNumber = Math.floor(trackDuration / 60);
    const trackSeconds = trackSecondsNumber < 10 ? `0${trackSecondsNumber}` : trackSecondsNumber;
    const trackMinutes = trackMinutesNumber < 10 ? `0${trackMinutesNumber}` : trackMinutesNumber;
    const duration = `${trackMinutes}:${trackSeconds}`;

    const currentSecondsNumber = Math.floor(currentTime % 60);
    const currentMinutesNumber = Math.floor(currentTime / 60);
    const currentSeconds = currentSecondsNumber < 10 ? `0${currentSecondsNumber}` : currentSecondsNumber;
    const currentMinutes = currentMinutesNumber < 10 ? `0${currentMinutesNumber}` : currentMinutesNumber;
    const current = `${currentMinutes}:${currentSeconds}`;

    const timeLinesSections = trackDuration / 5;

    const timeLines: JSX.Element[] = [];
    for (let i = 0; i < timeLinesSections * 10; i++) {
      timeLines.push(
        <rect
          fill="white"
          key={`rect-time-lines-${i}`}
          x={svgWidth / (timeLinesSections * 10) * i}
          y={i % 10 ? 20 : 15}
          width={1.82}
          height={i % 10 ? 10 : 15}
        />
      );
    }
    for (let i = 1; i < timeLinesSections; i++) {
      const currentSecondsNumber = Math.floor((trackDuration / timeLinesSections) * i);
      const currentMinutesNumber = Math.floor(currentSecondsNumber / 60);
      const currentSeconds = currentSecondsNumber < 10 ? `0${currentSecondsNumber}` : currentSecondsNumber;
      const currentMinutes = currentMinutesNumber < 10 ? `0${currentMinutesNumber}` : currentMinutesNumber;
      const current = `${currentMinutes}:${currentSeconds}`;

      timeLines.push(
        <text
          fill="white"
          key={`rect-time-lines-text-${i}`}
          x={(svgWidth / timeLinesSections) * i - 16}
          y={12}
        >
          {current}
        </text>
      );

      if (currentSecondsNumber % 15 === 0 && trackDuration >= 50) {
        timeLines.push(
          <line
            stroke="black"
            key={`rect-time-lines-dash-section-${i}`}
            x1={(svgWidth / timeLinesSections) * i + 1}
            y1={35}
            x2={(svgWidth / timeLinesSections) * i + 1}
            y2={260}
            strokeDasharray="5"
          />
        )
      }
    }

    return (
      <div className="Record">
        <svg
          ref={(node: React.ReactNode): void => this.setRef('audioSvg', node)}
          width={`${svgWidth}px`}
          height={`${svgHeight}px`}
          className="Record__svg"
          onMouseDown={this.handleMouseDownEvent}
          onMouseMove={this.handleMouseMoveEvent}
          onMouseUp={this.handleMouseUpEvent}
        >
          <rect
            fill="#FFF"
            key={`rect-time-lines-back`}
            x={0}
            y={0}
            width={svgWidth}
            height={35}
          />
          <rect
            fill="white"
            key={`rect-time-lines-under`}
            x={0}
            y={30}
            width={svgWidth}
            height={1.82}
          />
          {
            buckets.length
              ? <rect
                fill="#61d73b"
                ref={(node): void => this.setRef('progressLine', node)}
                key={`rect-progress`}
                x={(this.state as ComponentState).progress}
                y={120}
                width={4 * 0.7}
                height={50} />
              : ''
          }
          {
            buckets.map((bucket, i) => {
              const bucketSVGHeight = bucket * 170;
              return (
                <rect
                  key={`rect-suffer-${i}`}
                  x={(svgWidth / NUMBER_OF_BUCKETS) * i + SPACE_BETWEEN_BARS / 2}
                  y={150 - bucketSVGHeight}
                  width={bucketSVGWidth}
                  height={bucketSVGHeight * 2}
                />
              )
            })
          }
        </svg>
        <div className="Record__controls">
          <span className="Record__controls_time"
            ref={(node): void => this.setRef('currentTime', node)}>
            {current}
          </span>
          {
            inProgress
              ? (
                <button onClick={this.pause} className="Record__controls_button">
                  <img src={pauseIcon} alt="" />
                </button>
              )
              : (
                <button onClick={this.play} className="Record__controls_button">
                  <img src={playIcon} alt="" />
                </button>
              )
          }
          {/* <ReactHowler loop={false} src={this.props.url} playing={this.state.playing}/> */}
          <audio ref={(node): void => this.setRef('audioTrack', node)} src={url}></audio>
          <span className="Record__controls_time">
            {duration}
          </span>
        </div>
      </div>
    );
  }
}

export default AudioPlayer;
