import React from 'react';
import TestAnswerCard from '../TestAnswerCard';
import Toggleable from '../../../rc-components/Toggleable';
import { CognitiveTestCategory, DropdownCb, TestCategoryLabelItem } from '../../../types/Common';
import { TestCategoryData, Answer, Recordings } from '../../../store/PatientProfile/types';
import { TimeUsedSection, ExpectSection, AnswerSection, ContentSection } from '../AnswerSections/AnswerSections';
import { getCognitiveAnswer, gradeAnswers } from '../../../selectors/patientProfile';
import { asArray } from '../../../utils/datetime';
import { translate } from '../../../selectors/translations';
import { ReactComponent as ArrowIcon } from '../../../assets/img/icons/en-flag.svg';
import PreliminaryResult from "../PreliminaryResult";
import { ModalWindowType } from "../../../hoc/ModalWindowGuard/ModalWindowGuard"

type TestCategorySectionProps = {
  testQuestionsData: CognitiveTestCategory;
  testAnswersData: TestCategoryData;
  recordings: Recordings[];
  openModal: (type: ModalWindowType, data: any) => void;
}

const checkPointsDisplaying: { [key: number]: (answer: Answer) => number | undefined } = {
  1: (answer: Answer): number | undefined => answer.grade,
  2: (answer: Answer): number | undefined => answer.grade,
  3: (answer: Answer): number | undefined => answer.grade,
  4: (): number | undefined => void 0,
  5: (answer: Answer): number | undefined => answer.key === '5-0' ? answer.grade : void 0,
  6: (answer: Answer): number | undefined => answer.grade,
  7: (answer: Answer): number | undefined => answer.grade,
  8: (answer: Answer): number | undefined => answer.grade,
  9: (answer: Answer): number | undefined => answer.key === '9-0' ? answer.grade : void 0,
  10: (answer: Answer): number | undefined => answer.grade,
  11: (answer: Answer): number | undefined => answer.grade,
  12: (answer: Answer): number | undefined => answer.grade,
  13: (answer: Answer): number | undefined => answer.key === '13-1' ? answer.grade : void 0,
  14: (answer: Answer): number | undefined => answer.grade,
  15: (answer: Answer): number | undefined => answer.grade,
  16: (answer: Answer): number | undefined => answer.grade,
}
//TODO Refactor this function
const withAnswersGuard = (Component: typeof TestAnswerCard) => {
  return function GuardedTestAnswer(
    answers: Answer[],
    variants: any[],
    recordings: Recordings[],
    openModal: (type: ModalWindowType, data: any) => void
  ): React.ReactElement {
    if (answers && answers.length) {
      return (
        <div>
          {
            answers.map((answer: Answer, i: number) => {
              const _answer = getCognitiveAnswer(answer, asArray(variants[i]));
              //TODO Remove this after app implementation
              _answer.timeUsed = (_answer.type === 12 && !_answer.timeUsed) ? 60000 : _answer.timeUsed;
              return (
                <Component
                  attempt={i + 1}
                  answer={_answer}
                  openModal={openModal}
                  maxAttempt={answers.length}
                  points={checkPointsDisplaying[answer.type](answer)}
                  Content={<ContentSection recordings={recordings} answer={_answer}/>}
                  Answer={<AnswerSection recordings={recordings} answer={_answer}/>}
                  Expect={<ExpectSection answer={_answer} key={i}/>}
                  TimeUsed={<TimeUsedSection answer={_answer}/>}
                  key={i}/>
              )
            })
          }
        </div>
      )
    }
    return (<div style={{padding: '10px'}}>{translate('Answer was not provided!')}</div>)
  }
}

const TestCategoryCard: React.FC<TestCategorySectionProps> = (props: TestCategorySectionProps) => {
  const {testAnswersData, testQuestionsData, recordings, openModal} = props;
  return (
    <div className="exercise_category">
      <div className='flex flex--wrap pt-10'>
        <div className='item--12 item-md-6 item-lg-8'>
          <h3 className='diagnostic_question_heading'>{testQuestionsData.titleKey}</h3>
        </div>
        <div className='item--12 item-md-6 item-lg-4'>
          <PreliminaryResult
            testCategoryData={testAnswersData}
            showTitle={false}/>
        </div>
      </div>
      <section className="diagnostic_test_body">
        {
          testQuestionsData.testLabels.map((item: TestCategoryLabelItem, i: number) => {
            return (
              <Toggleable key={i}>
                {
                  (dropdownId: string, setDropDownId: DropdownCb): React.ReactElement => (
                    <div className="spoiler_box">
                      <div
                        className={dropdownId ? 'spoiler_header diagnostic_question_header isOpen' : 'spoiler_header diagnostic_question_header'}>
                        <div className="flex justify-between"
                             onClick={(): void => setDropDownId(dropdownId === 'answer' ? '' : 'answer')}>
                          <div className="flex-item--shrink">
                            <h3>{item.label}</h3>
                          </div>
                          <div className="flex-item--shrink">
                            <span>{translate('Points')}&nbsp;
                              <strong>{gradeAnswers(testAnswersData.answers[i])}</strong> / {item.maxPoints}</span>
                          </div>
                        </div>
                        {
                          dropdownId
                            ? <div className="flex-item--shrink"
                                   style={{fontSize: '12px'}}>{translate(`note_type_${item.type}`)}</div>
                            : null
                        }
                      </div>
                      {dropdownId && withAnswersGuard(TestAnswerCard)(testAnswersData.answers[i], item.answerVariants, recordings, openModal)}
                      {
                        dropdownId &&
                          <div className="flex align-center justify-end margin-me--top"
                               onClick={(): void => setDropDownId('')}>
                              <div className="search_btn search_btn--collapse">
                                  <div className="icon-holder"><ArrowIcon
                                      className="search_filter-icon"/></div>
                                  <span>{translate('Collapse')}</span>
                              </div>
                          </div>
                      }
                    </div>
                  )
                }
              </Toggleable>
            )
          })
        }
      </section>
    </div>
  );
}

export default TestCategoryCard;
