export interface AppState {
    isSideMenuExpanded: boolean;
    httpPendingAction: any;
}

export enum AppActionTypes {
    EXPAND_SIDE_MENU = '@@app/EXPAND_SIDE_MENU',
    COLLAPSE_SIDE_MENU = '@@app/COLLAPSE_SIDE_MENU'
}

export interface ExpandSideMenu {
    type: AppActionTypes.EXPAND_SIDE_MENU;
}

export interface CollapseSideMenu {
    type: AppActionTypes.COLLAPSE_SIDE_MENU;
}

export type AppActionType = ExpandSideMenu | CollapseSideMenu;
