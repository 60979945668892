import { useSelector } from 'react-redux';
import {selectTranslations} from "../selectors/translations";

const useTranslate = () => {
    const translations = useSelector(selectTranslations);

    // in the translations returned from apy we can have "common/no", but in hcp we store all translations in 1 object
    function extractValue(inputString: string) {
        const parts = inputString.split('/');
        if (parts.length > 1) {
            return parts[1];
        } else {
            return inputString; // Return the whole string if there's no '/'
        }
    }

    return (key: string): string => {
        if (!key) {
            return key
        }
        const defaultLang = 'en'
        const langKey = localStorage.getItem('lang');
        let language = translations.find(lang => lang.key === langKey);
        const defaultLanguage = translations.find(lang => lang.key === defaultLang);
        if (!language) {
            language = translations.find(lang => lang.key === 'sv');
        }
        const formattedKey = extractValue(key)
        if(language && language.translations[formattedKey]){
            return language.translations[formattedKey]
        }
        if(defaultLanguage && defaultLanguage.translations[formattedKey]){
            return defaultLanguage.translations[formattedKey]
        }

        return key;
    };
};

export default useTranslate;
