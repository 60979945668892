import {
  VoucherCodesActionTypes,
  VoucherCodesActionType,
  VoucherCodeRequestPayload,
  UpdateVoucherCodeRequestPayload,
  VoucherCodeResponse, VoucherStatsUsageResponse,
} from './types';

export const getVoucherCodes = (): VoucherCodesActionType => ({type: VoucherCodesActionTypes.GET_VOUCHER_CODE_REQUEST});
export const getVoucherCodesSuccess = (data: Array<VoucherCodeResponse>): VoucherCodesActionType => ({type: VoucherCodesActionTypes.GET_VOUCHER_CODE_SUCCESS, response: data});
export const getVoucherCodesError = (error: string): VoucherCodesActionType => ({type: VoucherCodesActionTypes.GET_VOUCHER_CODE_FAILURE, errorMsg: error});

export const postVoucherCode = (data: VoucherCodeRequestPayload): VoucherCodesActionType => ({type: VoucherCodesActionTypes.POST_VOUCHER_CODE_REQUEST, data});
export const postVoucherCodeSuccess = (data: VoucherCodeResponse): VoucherCodesActionType => ({type: VoucherCodesActionTypes.POST_VOUCHER_CODE_SUCCESS, response: data});
export const postVoucherCodeError = (error: string): VoucherCodesActionType => ({type: VoucherCodesActionTypes.POST_VOUCHER_CODE_FAILURE, errorMsg: error});

export const updateVoucherCode = (data: UpdateVoucherCodeRequestPayload): VoucherCodesActionType => ({type: VoucherCodesActionTypes.UPDATE_VOUCHER_CODE_REQUEST, data});
export const updateVoucherCodeSuccess = (data: VoucherCodeResponse): VoucherCodesActionType => ({type: VoucherCodesActionTypes.UPDATE_VOUCHER_CODE_SUCCESS, response: data});
export const updateVoucherCodeError = (error: string): VoucherCodesActionType => ({type: VoucherCodesActionTypes.UPDATE_VOUCHER_CODE_FAILURE, errorMsg: error});

export const deleteVoucherCode = (_id: string): VoucherCodesActionType => ({type: VoucherCodesActionTypes.DELETE_VOUCHER_CODE_REQUEST, _id});
export const deleteVoucherCodeSuccess = (data: {_id: string; status: boolean}): VoucherCodesActionType => ({type: VoucherCodesActionTypes.DELETE_VOUCHER_CODE_SUCCESS, response: data});
export const deleteVoucherCodeError = (error: string): VoucherCodesActionType => ({type: VoucherCodesActionTypes.DELETE_VOUCHER_CODE_FAILURE, errorMsg: error});

export const getVoucherUsageStats = (): VoucherCodesActionType => ({type: VoucherCodesActionTypes.GET_VOUCHER_USAGE_STATS_REQUEST});
export const getVoucherUsageStatsSuccess = (data: Array<VoucherStatsUsageResponse>): VoucherCodesActionType => ({type: VoucherCodesActionTypes.GET_VOUCHER_USAGE_STATS_SUCCESS, response: data});
export const getVoucherUsageStatsError = (error: string): VoucherCodesActionType => ({type: VoucherCodesActionTypes.GET_VOUCHER_USAGE_STATS_FAILURE, errorMsg: error});

export const refreshVoucherCode = (_id: string): VoucherCodesActionType => ({type: VoucherCodesActionTypes.REFRESH_VOUCHER_CODE_REQUEST, _id});
export const refreshVoucherCodeSuccess = (data: VoucherCodeResponse): VoucherCodesActionType => ({type: VoucherCodesActionTypes.REFRESH_VOUCHER_CODE_SUCCESS, response: data});
export const refreshVoucherCodeError = (error: string): VoucherCodesActionType => ({type: VoucherCodesActionTypes.REFRESH_VOUCHER_CODE_FAILURE, errorMsg: error});
