import { asArray, formatDate, toArray } from '../utils/datetime'
import { AnamnesisConfig, CognitiveConfig } from '../types/Common'
import {
  AdjustedLoginCode,
  AnamnesisSectionData,
  Answer,
  FormattedAnswer,
  PatientProfileState,
  Survey
} from '../store/PatientProfile/types'
import { translate } from './translations'
import { cognitiveEn, cognitiveSv } from '../i18n/cognitive'
import { questionaryEn, questionarySv } from '../i18n/questionary'
import { initialQuestionnaireEn, initialQuestionnaireSv } from '../i18n/initil-questionnaire'
import { ApplicationState } from "../store"
import { PdfReportItemData, PdfReportSectionData, PdfReportSubsectionData } from "../models/pdfReportData"
import { PdfItems, symptomSurveyPdfParams } from "../types/constants"
import { sortItemByDate } from "../utils/findMostRecentItem"
import { createSelector } from "reselect"
import { patientHasNotesOfType } from "../utils/mapPatientProfile"
import {
  AnswerDTO,
  DoctorNoteType,
  InputType,
  QuestionerDTO,
  QuestionerType,
  QuestionScreenMetadata,
  SelectorInputDTO
} from "../sdk"
import { getRelativeDegree } from "../components/FamilySurveyCard/FamilySurveyCard"

type TestConfig = AnamnesisConfig | CognitiveConfig;

const onlyNumbersReg = new RegExp("^[0-9]+$")

const testsConfig: { [key: string]: { [key: string]: { [key: number]: TestConfig } } } = {
  questionary: {
    'en': questionaryEn,
    'sv': questionarySv,
    'zh': questionaryEn,
  },
  cognitive: {
    'en': cognitiveEn,
    'sv': cognitiveSv,
    'zh': cognitiveEn,
  },
  initialQuestionnaire: {
    'en': initialQuestionnaireEn,
    'sv': initialQuestionnaireSv,
    'zh': initialQuestionnaireEn,
  }
}

const _calculateOrientationAnswers = (): (key: string, isCorrect: number) => number => {
  const orientationSet = {
    '11-0': 0,
    '11-1': 0,
    '11-2': 0,
    '11-3': 0,
    '11-4': 0,
  }
  return function (key: string, isCorrect: number) {
    // eslint-disable-next-line no-prototype-builtins
    if (orientationSet.hasOwnProperty(key) && orientationSet[key] === 0) {
      // -eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      orientationSet[key] = isCorrect
    }
    return Object.values(orientationSet).reduce((prev, cur) => prev + cur, 0)
  }
}

//Key ---> Answer Type
const setAnswerGrade: { [key: number]: (answers: Answer[]) => number } = {
  1: (answers: Answer[]): number => answers.reduce((acc, val) => acc + val.grade, 0),
  2: (answers: Answer[]): number => answers.reduce((acc, val) => val.grade === 1 ? 1 : acc, 0),
  3: (answers: Answer[]): number => answers.reduce((acc, val) => acc + val.grade, 0),
  4: (answers: Answer[]): number => answers.every((a) => a.grade === 1) ? 1 : 0,
  5: (answers: Answer[]): number => answers.reduce((acc, val) => val.key === '5-0' ? val.grade : acc, 0),
  6: (answers: Answer[]): number => answers.reduce((acc, val) => acc + val.grade, 0),
  7: (answers: Answer[]): number => answers.reduce((acc, val) => acc + val.grade, 0),
  8: (answers: Answer[]): number => answers.reduce((acc, val) => acc + val.grade, 0),
  9: (answers: Answer[]): number => answers.reduce((acc, val) => val.key === '9-0' ? val.grade : acc, 0),
  10: (answers: Answer[]): number => answers.reduce((acc, val) => acc + val.grade, 0),
  // 11: (answers: Answer[]): number => answers.reduce((acc, val) => acc + val.grade, 0),
  // 11: (answers: Answer[]): number => answers.filter(el => el.grade === 1).length > 5 ? 5 : answers.filter(el => el.grade === 1).length,
  11: (answers: Answer[]): number => {
    const orientationAnswer = _calculateOrientationAnswers()
    let result = 0
    for (let i = 0; i < answers.length; i++) {
      result = orientationAnswer(answers[i].key, answers[i].grade)
    }
    return result
  },
  12: (answers: Answer[]): number => answers.reduce((acc, val) => acc + val.grade, 0),
  13: (answers: Answer[]): number => answers.reduce((acc, val) => val.key === '13-1' ? val.grade : acc, 0),
  14: (answers: Answer[]): number => answers.reduce((acc, val) => acc + val.grade, 0),
  15: (answers: Answer[]): number => answers.reduce((acc, val) => acc + val.grade, 0),
  16: (answers: Answer[]): number => answers.reduce((acc, val) => acc + val.grade, 0),
}

//Key ---> Answer Type
const formatAnswerResult: { [key: number]: <T extends object>(result: T, variants: any[]) => T[] } = {
  1: <T>(result: T): T[] => asArray(result),
  2: <T>(result: T): T[] => asArray(result),
  3: <T>(result: T): T[] => asArray(result),
  4: <T>(result: T): T[] => asArray(result),
  5: <T>(result: T): T[] => asArray(result),
  6: <T>(result: T): T[] => asArray(result),
  7: <T>(result: T): T[] => asArray(result),
  8: <T>(result: T): T[] => asArray(result),
  9: <T>(result: T): T[] => asArray(result),
  10: <T extends object>(result: T, variants: any[]): T[] => toArray(variants, parseInt(result.toString())),
  11: <T extends object>(result: T, variants: any[]): T[] => onlyNumbersReg.test(result.toString()) ? toArray(variants, parseInt(result.toString().trim(), 10)) : asArray(result),
  12: <T>(result: T): T[] => asArray(result),
  13: <T>(result: T): T[] => asArray(result),
  14: <T extends object>(result: T, variants: any[]): T[] => toArray(variants, parseInt(result.toString())),
  15: <T extends object>(result: T, variants: any[]): T[] => toArray(variants, parseInt(result.toString())),
  16: <T>(result: T): T[] => asArray(result),
}

const getQuestionarySurveyConfig: () => {
  [key: number]: AnamnesisConfig
} = () => testsConfig.questionary[localStorage.getItem('lang') || 'en'] as { [key: number]: AnamnesisConfig }

const getInitialQuestionnaireConfig: () => {
  [key: number]: AnamnesisConfig
} = () => testsConfig.initialQuestionnaire[localStorage.getItem('lang') || 'en'] as { [key: number]: AnamnesisConfig }

const getCognitiveSurveyConfig: () => CognitiveConfig[] = () => [...testsConfig.cognitive[localStorage.getItem('lang') || 'en'] as CognitiveConfig[]]

const getAnamnesisAnswersData = (question: any, answer: Answer) => {
  const answer2part = (`${question.labels[1] || ''}`).trim()

  if (!answer || (answer && answer.data === void 0)) {
    return [{
      label: answer2part || '',
      options: [
        translate('Answer was not provided!')
      ]
    }]
  }

  if (answer && answer.data === null && question.skipIfNo) {
    return [{
      label: answer2part || '',
      options: [
        `${translate('Question was skipped due to previous question answer')}`
      ]
    }]
  }

  if (answer.data === -1) {
    return [{
      label: answer2part || '',
      options: [
        `${translate('idk')}`
      ]
    }]
  }

  return asArray(answer.data)
    .filter((a: string | number | (string | number | [])[] | null) => a !== null)
    .map((a: string | number | (string | number | [])[] | null, i: number) => ({
      label: question.labels[i + 1] || '',
      answerText: question.extraLabel ? '' : null,
      options: Array.isArray(a)
        ? question.optionLabels[i]
          .filter((o: string, ind: number) => (a as number[]).includes(ind))
          .map((o: string) => o)
        : (question.optionLabels[i] ? [question.optionLabels[i][a as number]] : [a])
    }))
}

const getCognitiveAnswerData = (answer: Answer, answerVariants: string[][]): FormattedAnswer => {
  const {expect, type} = answer
  const {result} = answer
  const _expect = typeof expect === 'number' ? toArray(answerVariants, expect) : asArray(expect)
  const _result = formatAnswerResult[type](result as object, answerVariants)

  return {...answer, result, expectData: _expect, resultData: _result}
}

const getAnswersGrade = (answers: Answer[]): number => answers[0] ? setAnswerGrade[answers[0].type](answers) : 0

const getFormattedFamilySurvey = (name: string, questioner: QuestionerDTO): Record<string, string> => {
  const mapping = {}
  questioner.sections.forEach(e => e.screens.forEach(screen => mapping[screen.screen.slug] = screen.screen.metadata.title))
  return mapping
}

export const getFamilySurveyAnswerData = (answers: AnswerDTO[], questioner: QuestionerDTO) => {
  const result: Record<string, string> = {}

  for (const section of questioner.sections) {
    for (const screen of section.screens) {
      const value = answers.find(e => e.screen === screen.screen.id)?.value
      const input = (screen.screen.metadata as QuestionScreenMetadata).input
      if (value) {
        switch (input.kind) {
          case InputType.FREE_TEXT:
            result[screen.screen.slug] = value
            break
          case InputType.RADIO_GROUP:
          case InputType.SELECTOR:
            const option = (input as SelectorInputDTO).metadata.values.find(v => v.value === value)
            result[screen.screen.slug] = option?.label || translate('Answer was not provided!')
            break
        }
      } else {
        result[screen.screen.slug] = translate('Answer was not provided!')
      }
    }
  }

  return result
}

const types: { [key: number]: any } = {
  0: null,
  1: null,
  2: null,
  3: null,
  4: null,
  5: null,
  6: null,
  7: {
    answerNumberGrades: {
      73: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      74: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      75: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      76: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      77: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      78: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      79: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      80: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      81: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      }
    },
    answerTextGrades: {
      male: {
        minimalRisk: [0, 4],
        mildRisk: [5, 9],
        moderateRisk: [10, 14],
        moderateSeverRisk: [15, 19],
        severRisk: [20, 27]
      },
      female: {
        minimalRisk: [0, 4],
        mildRisk: [5, 9],
        moderateRisk: [10, 14],
        moderateSeverRisk: [15, 19],
        severRisk: [20, 27]
      }
    }
  },
  8: {
    answerNumberGrades: {
      85: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      86: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      87: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      88: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      89: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      90: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      91: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      92: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      93: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
    },
    answerTextGrades: {
      male: {
        low: [0, 17],
        high: [18, 54]
      },
      female: {
        low: [0, 17],
        high: [18, 54]
      }
    }
  },
  9: {
    answerNumberGrades: {
      96: {
        0: 0,
        1: 0,
        2: 1,
        3: 1,
        4: 1
      },
      97: {
        0: 0,
        1: 0,
        2: 1,
        3: 1,
        4: 1
      },
      98: {
        0: 0,
        1: 0,
        2: 1,
        3: 1,
        4: 1
      },
      99: {
        0: 0,
        1: 0,
        2: 0,
        3: 1,
        4: 1
      },
      100: {
        0: 0,
        1: 0,
        2: 0,
        3: 1,
        4: 1,
      },
      101: {
        0: 0,
        1: 0,
        2: 0,
        3: 1,
        4: 1
      }
    },
    answerTextGrades: {
      male: {
        low: [0, 3],
        high: [4, 6]
      },
      female: {
        low: [0, 3],
        high: [4, 6]
      }
    }
  },
  10: null,
  11: null,
  12: null,
  13: {
    answerNumberGrades: {
      126: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4
      },
      127: {
        0: 0,
        1: 0,
        2: 0,
        3: 1,
        4: 1,
        5: 2,
        6: 2,
        7: 3,
        8: 3,
        9: 3,
        10: 4,
        11: 4,
        12: 4,
        13: 4,
        14: 4,
        15: 4,
        16: 4,
        17: 4,
        18: 4,
        19: 4,
        20: 4
      },
      128: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4
      }
    },
    answerTextGrades: {
      male: {
        low: [0, 3],
        moderateRisk: [4, 5],
        high: [6, 7],
        severRisk: [8, 12]
      },
      female: {
        low: [0, 2],
        moderateRisk: [3, 5],
        high: [6, 7],
        severRisk: [8, 12]
      }
    }
  },
}

const newAnamnesisTypes: { [key: number]: any } = {
  0: null,
  1: null,
  2: null,
  3: null,
  4: null,
  5: null,
  6: {
    answerNumberGrades: {
      66: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      67: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      68: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      69: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      70: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      71: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      72: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      73: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      },
      74: {
        0: 0,
        1: 1,
        2: 2,
        3: 3
      }
    },
    answerTextGrades: {
      male: {
        minimalRisk: [0, 4],
        mildRisk: [5, 9],
        moderateRisk: [10, 14],
        moderateSeverRisk: [15, 19],
        severRisk: [20, 27]
      },
      female: {
        minimalRisk: [0, 4],
        mildRisk: [5, 9],
        moderateRisk: [10, 14],
        moderateSeverRisk: [15, 19],
        severRisk: [20, 27]
      }
    }
  },
  7: {
    answerNumberGrades: {
      77: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      78: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      79: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      80: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      81: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      82: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      83: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      84: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
      85: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
      },
    },
    answerTextGrades: {
      male: {
        low: [0, 17],
        high: [18, 54]
      },
      female: {
        low: [0, 17],
        high: [18, 54]
      }
    }
  },
  8: {
    answerNumberGrades: {
      88: {
        0: 0,
        1: 0,
        2: 1,
        3: 1,
        4: 1
      },
      89: {
        0: 0,
        1: 0,
        2: 1,
        3: 1,
        4: 1
      },
      90: {
        0: 0,
        1: 0,
        2: 1,
        3: 1,
        4: 1
      },
      91: {
        0: 0,
        1: 0,
        2: 0,
        3: 1,
        4: 1
      },
      92: {
        0: 0,
        1: 0,
        2: 0,
        3: 1,
        4: 1,
      },
      93: {
        0: 0,
        1: 0,
        2: 0,
        3: 1,
        4: 1
      }
    },
    answerTextGrades: {
      male: {
        low: [0, 3],
        high: [4, 6]
      },
      female: {
        low: [0, 3],
        high: [4, 6]
      }
    }
  },
  9: null,
  10: null,
  11: {
    answerNumberGrades: {
      107: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4
      },
      108: {
        0: 0,
        1: 0,
        2: 0,
        3: 1,
        4: 1,
        5: 2,
        6: 2,
        7: 3,
        8: 3,
        9: 3,
        10: 4,
        11: 4,
        12: 4,
        13: 4,
        14: 4,
        15: 4,
        16: 4,
        17: 4,
        18: 4,
        19: 4,
        20: 4
      },
      109: {
        0: 0,
        1: 1,
        2: 2,
        3: 3,
        4: 4
      }
    },
    answerTextGrades: {
      male: {
        low: [0, 3],
        moderateRisk: [4, 5],
        high: [6, 7],
        severRisk: [8, 12]
      },
      female: {
        low: [0, 2],
        moderateRisk: [3, 5],
        high: [6, 7],
        severRisk: [8, 12]
      }
    }
  },
}

const getAllRiskScoresGrades = (): {
  [key: number]: any
} => Object.values(types).reduce((acc, set) => ({...acc, ...(set ? set.answerNumberGrades : {})}), {})
const getNewAnamnesisRiskScoresGrades = (): {
  [key: number]: any
} => Object.values(newAnamnesisTypes).reduce((acc, set) => ({...acc, ...(set ? set.answerNumberGrades : {})}), {})

const getAnamnesisRiskScore = (answers: Answer[], type: number, gender: string, isNewAnamnesis: boolean): {
  numberGrade: number;
  textGrade: string
} => {
  const anamnesisRiskTypes = isNewAnamnesis ? newAnamnesisTypes : types
  if (anamnesisRiskTypes[type] === null) {
    return {numberGrade: 0, textGrade: 'lowRisk'}
  }

  const numberGrade = answers.reduce((acc, answer: Answer) => {
    const grades = anamnesisRiskTypes[type].answerNumberGrades[answer.key]
    if (!grades) {
      return acc
    }
    return Array.isArray(answer.data)
      ? acc + (answer.data.reduce((acc, val) => acc += (grades[val as number] || 0), 0) as number)
      : acc + grades[answer.data as number]
  }, 0)
  const textGrade = Object
    .keys(anamnesisRiskTypes[type].answerTextGrades[gender])
    .find((riskGrade) => {
      return numberGrade >= anamnesisRiskTypes[type].answerTextGrades[gender][riskGrade][0]
        && numberGrade <= anamnesisRiskTypes[type].answerTextGrades[gender][riskGrade][1]
    }) as string

  return {numberGrade, textGrade}
}

const getAnamnesisAnswerRiskScore = (answer: Answer, isNewAnamnesis = false): any => {
  const riskScoreGrades = isNewAnamnesis ? getNewAnamnesisRiskScoresGrades() : getAllRiskScoresGrades()
  const answerGrades = riskScoreGrades[parseInt(answer.key, 10)]
  if (!answerGrades) {
    return null
  }
  const maxScore = answerGrades[Object.keys(answerGrades)[Object.keys(answerGrades).length - 1]]
  return Array.isArray(answer.data)
    ? {
      maxScore: maxScore * answer.data.length,
      actualScore: answer.data.reduce((acc, val) => acc += (answerGrades[(val as number)] || 0), 0)
    }
    : {maxScore: maxScore, actualScore: answerGrades[answer.data]}
}


const getStatus = (
  survey: Survey,
  answersLength: number,
  sectionId: number
): string => {
  let status = 'Not Requested'
  if (!(survey.sectionSet as number[]).includes(sectionId) && !answersLength) {
    status = 'Not Requested'
  } else if ((survey.sectionSet as number[]).includes(sectionId) && !answersLength) {
    status = 'Requested'
  } else if (answersLength && survey.viewingAttempts && !survey.viewingAttempts[sectionId]) {
    status = 'Results for Review'
  } else if (survey.viewingAttempts && survey.viewingAttempts[sectionId] && answersLength) {
    status = 'Complete'
  }
  return status
}

const getAnamnesisSectionData = (
  section: AnamnesisSectionData,
  questions: { [key: number]: AnamnesisConfig },
  survey: Survey,
  isNewAnamnesis = false
): any => {
  const _questions = Object.keys(questions).filter((key: string, i: number) => questions[i].type < 200 && section.questionsSet.includes(parseInt(key, 10)))
  const _answers = survey.answers.filter((a: Answer) => _questions.includes(a.key))
  return {
    questions: _questions,
    answers: _answers,
    riskScore: getAnamnesisRiskScore(_answers, section.id, survey.gender || 'male', isNewAnamnesis),
    status: getStatus(survey, _answers.length, section.id),
    createdAt: _answers.length ? _answers[0].createdAt : null
  }
}

export const getDataForPDFReport = (state: ApplicationState): any => {
  const {patientProfile} = state
  const result = {} as any
  const {questionerAnswers} = patientProfile
  const newApiAnamnesises = questionerAnswers.filter((el) => el.questioner.kind === QuestionerType.ANAMNESIS)
  const newApiCognitives = questionerAnswers.filter((el) => el.questioner.kind === QuestionerType.COGNITIVE)

  result[PdfItems.Status] = new PdfReportItemData({type: PdfItems.Status, title: translate('status')})

  if (patientHasNotesOfType(patientProfile, DoctorNoteType.DIAGNOSIS)) {
    result[PdfItems.Diagnosis] = new PdfReportItemData({type: PdfItems.Diagnosis, title: translate('diagnosis')})
  }
  if (patientHasNotesOfType (patientProfile, DoctorNoteType.SUMMARY_RESULTS_AND_ASSESSMENT)) {
    result[PdfItems.Summary] = new PdfReportItemData({
      type: PdfItems.Summary,
      title: translate('summary_results_and_assessment')
    })
  }
  if (patientHasNotesOfType(patientProfile, DoctorNoteType.RECOMMENDATION)) {
    result[PdfItems.Recommendation] = new PdfReportItemData({
      type: PdfItems.Recommendation,
      title: translate('recommendation')
    })
  }
  result[PdfItems.Cognitive] = newApiCognitives
    .map(item => {
      return new PdfReportItemData({
        id: item.id,
        date: formatDate((item.updatedAt || 0)),
        type: PdfItems.Cognitive,
        title: translate('cognitive')
      })
    })
    .sort(sortItemByDate)

  result[PdfItems.SymptomSurveys] = patientProfile.questionerAnswers
    .filter(e => e.questioner.kind === QuestionerType.SYMPTOM_SURVEY)
    .map(survey => {
      const {id: surveyId, updatedAt: date} = survey
      const relativeDegree = getRelativeDegree(survey.questioner, survey)
      const title = `${translate('symptom survey')} - 
                    ${survey.oneTimeRespondent?.firstName ?? ''} 
                    ${survey.oneTimeRespondent?.lastName ?? ''} 
                    ${relativeDegree ? ` (${translate(relativeDegree)})` : ''}`
      const subsections = Object.values(symptomSurveyPdfParams).map((option) => {
        const {id, title} = option
        return new PdfReportSubsectionData({
          id,
          title: translate(title),
          parentType: PdfItems.SymptomSurveys,
          parentId: surveyId
        })
      })
      return new PdfReportSectionData({
        id: surveyId,
        date: formatDate(date || 0),
        type: PdfItems.SymptomSurveys,
        title,
        subsections
      })
    })
    .sort(sortItemByDate)

  result[PdfItems.AnamnesisNew] = newApiAnamnesises
    .map(item => {
      const answeredScreenIds = item.answers.map(answer => answer.screen)
      const _sections = [...item.questioner.sections].filter(section => {
        const sectionScreenIds = section.screens.map(el => el.screen.id)
        return sectionScreenIds.some(screenId => answeredScreenIds.includes(screenId!))
      })
      const parentId = item.id
      const subsections = _sections.map(section => {
        const {slug} = section
        return new PdfReportSubsectionData({
          id: slug,
          date: formatDate(item.updatedAt || 0),
          title: "questioner.anamnesis.section." + slug,
          status: null,
          parentType: PdfItems.AnamnesisNew,
          parentId: parentId
        })
      })
      return new PdfReportSectionData({
        id: parentId,
        date: formatDate(item.updatedAt || 0),
        subsections,
        type: PdfItems.AnamnesisNew,
        title: translate('anamnesisFlexNew')
      })
    })
    .sort(sortItemByDate)

  return result
}

export const getPatientState = (state: ApplicationState): PatientProfileState => {
  return state.patientProfile
}
export const getQuestioners = (state: ApplicationState): QuestionerDTO[] => {
  return state.questioners.all
}

export const getLoginCodeSelector = createSelector(
  [getPatientState],
  (patientState: PatientProfileState): AdjustedLoginCode => {
    const {loginCode} = patientState
    const result: AdjustedLoginCode = {
      expirationDate: '',
      code: null
    }
    if (!loginCode || typeof loginCode !== 'object') {
      return result
    }
    const {expirationDate, code} = loginCode
    result.code = typeof code === 'string' && code ? code : null
    result.expirationDate = typeof expirationDate === 'string' && expirationDate ? expirationDate : ''
    return result
  }
)

export const anamnesisConfigSelector = createSelector(
  [getPatientState],
  (patientState: PatientProfileState): AnamnesisSectionData[] => {
    return patientState.anamnesisSectionsNew.sort((item1, item2) => item1.id - item2.id)
  }
)

export const getQuestions = getQuestionarySurveyConfig
export const getInitialQuestionnaire = getInitialQuestionnaireConfig
export const getCognitive = getCognitiveSurveyConfig
export const getAnamnesisAnswer = getAnamnesisAnswersData
export const getFamily = getFormattedFamilySurvey
export const getCognitiveAnswer = getCognitiveAnswerData
export const gradeAnswers = getAnswersGrade
export const getAnswerRiskScore = getAnamnesisAnswerRiskScore
export const getSectionData = getAnamnesisSectionData
export const getTestStatus = getStatus
