import { datadogLogs } from "@datadog/browser-logs"

interface CustomHeaders {
  [key: string]: string | null;
}

const BASE_URL = process.env.REACT_APP_BASE_URL;
export default async function callApi(
  method: string,
  path: string,
  data?: any,
): Promise<any> {
  datadogLogs.logger.warn('Deprecated usage of v1 api', {method, path})
  if(!path.includes('users/anamnesis')) {
    // alert(`Deprecated usage of v1 api: ${method} ${path}`)
    // return
  }

  let dataToPass: any = '';

  const headers: CustomHeaders = {
    "Accept": "application/json",
  };

  if (window.localStorage.getItem('token')) {
    headers['Authorization'] = window.localStorage.getItem('token');
  }

  if (!(data instanceof FormData)) {
    headers["Content-Type"] = "application/json"
    dataToPass = JSON.stringify(data);
  } else {
    dataToPass = data;
  }

  const opt: any = {
    method,
    headers,
    body: dataToPass
  };
  const response = await fetch(`${BASE_URL}${path}`, opt);

  const responseData = await response.json();

  return Promise.resolve({status: response.status, data: responseData, error: responseData.message || null});
}
