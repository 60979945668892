import React from "react";
import classNames from "classnames";
import { ReactComponent as ExpandIconImg } from '../../assets/img/icons/arrow-left-icon.svg';
import {ArrowDirections} from "../../types/constants";

interface ExpandIconProps {
    direction: ArrowDirections;
}


const ExpandIcon: React.FC<ExpandIconProps> = (props: ExpandIconProps) => {
    const { direction } = props;
    const iconClasses = classNames('expand-icon', { [`expand-icon--${direction}`]: true });
    return (
        <div className={iconClasses}>
            <ExpandIconImg />
        </div>
    );
};

export default ExpandIcon;
