import { all, put, takeLatest } from 'redux-saga/effects'
import { QuestionersActionTypes } from './types'
import { getAllQuestionersFailure, getAllQuestionersSuccess } from "./actions"
import { apiV2, SdkResponse, wrapResponse } from "../../repositories/repository"

function* handleGetAllQuestioners(): Generator {
  const apiCall = apiV2.questioner.getAllQuestioners.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall())) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(getAllQuestionersSuccess(res.data))
    return
  }

  yield put(getAllQuestionersFailure(res.error))
}

export default function* questionersSaga(): Generator {
  yield all([
    takeLatest(QuestionersActionTypes.GET_ALL_QUESTIONERS, handleGetAllQuestioners),
  ])
}
