import { AccountActionTypes, AccountsActionType, AccountsState } from './types'
import { AppLanguage, Gender, MedStaffRole } from "../../sdk"

const initialState: AccountsState = {
  all: [],
  accountInfo: {
    _id: '',
    firstName: '',
    gender: Gender.MALE,
    language: AppLanguage.SV,
    roles: [MedStaffRole.DOCTOR],
    lastName: '',
    institution: '',
    country: AppLanguage.SV,
    email: '',
    personalNumber: '',
  },
  errorMsg: '',
  successMsg: '',
  filters: {
    searchTerm: '',
    sort: {
      field: 'role',
      direction: false
    },
  },
};

export function AccountReducer(
  state = initialState,
  action: AccountsActionType
): AccountsState {
  switch (action.type) {
    case AccountActionTypes.GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    case AccountActionTypes.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        all: [...action.accounts],
        errorMsg: ''
      }
    case AccountActionTypes.FILTER_CHANGED:
      return {
        ...state,
        ...{ filters: { ...state.filters, ...action.filterData } }
      }
    case AccountActionTypes.UPD_ACCOUNT_SUCCESS:
      return {
        ...state,
        all: [...state.all.map(acc => acc._id !== action.account._id ? acc : { ...acc, ...action.account })]
      }
    case AccountActionTypes.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        all: [...state.all, action.data]
      }
    case AccountActionTypes.GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountInfo: action.response
      }
    case AccountActionTypes.UNSET_ACCOUNT_PROFILE:
      return {
        ...state,
        accountInfo: { ...initialState.accountInfo }
      }
    case AccountActionTypes.GET_DELETED_ACCOUNTS_SUCCESS:
      return {
        ...state,
        all: action.data
      }
    case AccountActionTypes.REMOVE_ACCOUNT_SUCCESS:
      return {
        ...state,
        all: state.all.filter((a) => a._id !== action.data._id)
      }
    case AccountActionTypes.RESTORE_ACCOUNT_SUCCESS:
      return {
        ...state,
        all: state.all.filter((a) => a._id !== action.data._id)
      }
    case AccountActionTypes.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        all: state.all.filter((a) => a._id !== action.data._id)
      }
    default:
      return state
  }
}
