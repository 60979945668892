/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UniqueScreenType {
    CONNECT_CIRCLES = 'connect-circles',
    DRAW_CUBE = 'draw-cube',
    DRAW_CLOCK = 'draw-clock',
    NAME_ANIMAL = 'name-animal',
    REMEMBER_WORDS = 'remember-words',
    NUMBERS = 'numbers',
    SUBTRACTION = 'subtraction',
    SELECT_SIMILARITY = 'select-similarity',
    NAME_REMEMBERED_WORDS = 'name-remembered-words',
    REMEMBER_PATTERNS = 'remember-patterns',
    LETTER = 'letter',
    CODING = 'coding',
    CLOCK_TIME = 'clock-time',
    SELECT_REMEMBERED_PATTERN = 'select-remembered-pattern',
}
