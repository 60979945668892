import React, {useState, ReactElement, useCallback} from 'react';
import { useDispatch } from 'react-redux';
import { openModalWindow } from '../../../store/ModalWindowData/action';
import PlayButton from "../../PlayButton";
type CubeSvgProps = {
  paths: string[];
  times: number[];
  isIE: boolean;
  setup: {
    width: number;
    height: number;
  };
}

const redrawPath = (
  paths: string[],
  times: number[],
  cb: any
): void => {
  const delays = [0];

  for (let i = 0; i < times.length; i++) {
    delays.push(times[i] + delays[i])
  }
  for (let i = 0; i < paths.length; i++) {
    const xmlns = "http://www.w3.org/2000/svg";

    const tempPath = document.createElementNS(xmlns, 'path');
    tempPath.setAttributeNS(null, 'd', paths[i]);

    const pathLength = tempPath.getTotalLength();

    setTimeout(() => {
      cb(
        (prevState: ReactElement[]): ReactElement[] => {
          const prev = prevState.length >= paths.length ? [] : prevState;
          return [...prev,
          <path
            key={`cube-path${new Date().getTime()}`}
            d={paths[i]}
            stroke="black"
            fill="none"
            style={{
              'strokeDasharray': pathLength,
              'strokeDashoffset': pathLength,
              'animation': `draw forwards linear ${times[i] * 1.1}ms`,
            }} />
          ]
        }
      );
    }, delays[i] * 1.5)
  }
}

const drawPath = (paths: string[]): React.ReactElement[] =>
  paths.map((p: string, index: number) => (<path key={index} d={p} stroke="black" fill="none" />) as ReactElement)

const CubeSvg: React.FC<CubeSvgProps> = (props: CubeSvgProps) => {
  const { paths, times, setup, isIE } = props;
  const dispatch = useDispatch();
  const [fullPaths, setFullPaths] = useState(drawPath(paths));

    const handleClick = useCallback((): any => {
        isIE
        ? dispatch(openModalWindow('ieWarning', null))
        : redrawPath(paths, times, setFullPaths);
    }, [isIE, paths, times, redrawPath, setFullPaths]);

  return (
    <div className="svg-container" style={{marginBottom: '50px'}}>
      <div className="flex justify-center" style={{marginTop: '10px'}}>
      <hr className="drawing-line"/> <PlayButton onClick={handleClick}/><hr className="drawing-line"/>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="svg-block"
        viewBox={`0 0 ${setup.width} ${setup.height}`}
        width={setup.width}
        height={setup.height}>
        {fullPaths.map((p: React.ReactElement) => p)}
      </svg>
    </div>
  )
}

export default CubeSvg;
