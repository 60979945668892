import React from "react";
import {ReactComponent as InfoIcon} from "../../../assets/img/icons/information-outline.svg";
import {translate} from "../../../selectors/translations";
import ProgressLinePointer from "../../ProgressLinePointer";

type FamilyProgressLineProps = {
    noPrint?: boolean;
    maxIntervalValue: number;
    progressValue: number;
    legend: string;
    infoLegendKey: string;
}

const FamilyProgressLine: React.FC<FamilyProgressLineProps> = (props: FamilyProgressLineProps) => {
    const { maxIntervalValue, progressValue, legend, infoLegendKey, noPrint } = props;
    return (
        <>
            <div className="card-header-results-header">
                <div className="risk_progress-legend flex align-center">
                    <div dangerouslySetInnerHTML={{ __html: legend }}></div>
                    <div className='flex-item--shrink'>
                        <InfoIcon className={`info-icon ${noPrint ? 'no-print': null}`}/>
                    </div>
                    <div className="info_list">{translate(infoLegendKey)}</div>
                </div>
                <div className='card-header-duration_box'>
                    <ProgressLinePointer
                        progressValue={progressValue}
                        maxIntervalValue={maxIntervalValue}
                    />
                </div>
            </div>
            <div className='card-header-duration_box'>
                <div className="risk_progress-chart">
                    <span className='progress-line progress-line-family'></span>
                </div>
            </div>
        </>
    );
};

export default FamilyProgressLine;
