import { ManageSmsTemplateDto, SmsTemplateDto, SmsTemplateWithTranslationsDto } from "../../sdk"

export enum SmsTemplatesActionTypes {
  POST_SMS_TEMPLATE_REQUEST = '@@smsTemplate/POST_SMS_TEMPLATE_REQUEST',
  POST_SMS_TEMPLATE_SUCCESS = '@@smsTemplate/POST_SMS_TEMPLATE_SUCCESS',
  POST_SMS_TEMPLATE_FAILURE = '@@smsTemplate/POST_SMS_TEMPLATE_FAILURE',

  GET_SMS_TEMPLATE_REQUEST = '@@smsTemplate/GET_SMS_TEMPLATE_REQUEST',
  GET_SMS_TEMPLATE_SUCCESS = '@@smsTemplate/GET_SMS_TEMPLATE_SUCCESS',
  GET_SMS_TEMPLATE_FAILURE = '@@smsTemplate/GET_SMS_TEMPLATE_FAILURE',

  UPDATE_SMS_TEMPLATE_REQUEST = '@@smsTemplate/UPDATE_SMS_TEMPLATE_REQUEST',
  UPDATE_SMS_TEMPLATE_SUCCESS = '@@smsTemplate/UPDATE_SMS_TEMPLATE_SUCCESS',
  UPDATE_SMS_TEMPLATE_FAILURE = '@@smsTemplate/UPDATE_SMS_TEMPLATE_FAILURE',

  REFRESH_SMS_TEMPLATE_REQUEST = '@@smsTemplate/REFRESH_SMS_TEMPLATE_REQUEST',
  REFRESH_SMS_TEMPLATE_SUCCESS = '@@smsTemplate/REFRESH_SMS_TEMPLATE_SUCCESS',
  REFRESH_SMS_TEMPLATE_FAILURE = '@@smsTemplate/REFRESH_SMS_TEMPLATE_FAILURE',

  DELETE_SMS_TEMPLATE_REQUEST = '@@smsTemplate/DELETE_SMS_TEMPLATE_REQUEST',
  DELETE_SMS_TEMPLATE_SUCCESS = '@@smsTemplate/DELETE_SMS_TEMPLATE_SUCCESS',
  DELETE_SMS_TEMPLATE_FAILURE = '@@smsTemplate/DELETE_SMS_TEMPLATE_FAILURE',
}


export interface PostSmsTemplateRequest {
  type: typeof SmsTemplatesActionTypes.POST_SMS_TEMPLATE_REQUEST;
  data: ManageSmsTemplateDto;
}

interface PostSmsTemplateSuccess {
  type: typeof SmsTemplatesActionTypes.POST_SMS_TEMPLATE_SUCCESS;
  response: SmsTemplateDto;
}

interface PostSmsTemplateFailure {
  type: typeof SmsTemplatesActionTypes.POST_SMS_TEMPLATE_FAILURE;
  errorMsg: string;
}


export interface GetSmsTemplateRequest {
  type: typeof SmsTemplatesActionTypes.GET_SMS_TEMPLATE_REQUEST;
}

interface GetSmsTemplateSuccess {
  type: typeof SmsTemplatesActionTypes.GET_SMS_TEMPLATE_SUCCESS;
  response: SmsTemplateWithTranslationsDto[];
}

interface GetSmsTemplateFailure {
  type: typeof SmsTemplatesActionTypes.GET_SMS_TEMPLATE_FAILURE;
  errorMsg: string;
}


export interface UpdateSmsTemplateRequest {
  type: typeof SmsTemplatesActionTypes.UPDATE_SMS_TEMPLATE_REQUEST;
  data: ManageSmsTemplateDto;
}

interface UpdateSmsTemplateSuccess {
  type: typeof SmsTemplatesActionTypes.UPDATE_SMS_TEMPLATE_SUCCESS;
  response: SmsTemplateDto;
}

interface UpdateSmsTemplateFailure {
  type: typeof SmsTemplatesActionTypes.UPDATE_SMS_TEMPLATE_FAILURE;
  errorMsg: string;
}

export interface RefreshSmsTemplateRequest {
  type: typeof SmsTemplatesActionTypes.REFRESH_SMS_TEMPLATE_REQUEST;
  _id: string;
}

interface RefreshSmsTemplateSuccess {
  type: typeof SmsTemplatesActionTypes.REFRESH_SMS_TEMPLATE_SUCCESS;
  response: SmsTemplateDto;
}

interface RefreshSmsTemplateFailure {
  type: typeof SmsTemplatesActionTypes.REFRESH_SMS_TEMPLATE_FAILURE;
  errorMsg: string;
}


export interface DeleteSmsTemplateRequest {
  type: typeof SmsTemplatesActionTypes.DELETE_SMS_TEMPLATE_REQUEST;
  _id: string;
}

interface DeleteSmsTemplateSuccess {
  type: typeof SmsTemplatesActionTypes.DELETE_SMS_TEMPLATE_SUCCESS;
}

interface DeleteSmsTemplateFailure {
  type: typeof SmsTemplatesActionTypes.DELETE_SMS_TEMPLATE_FAILURE;
  errorMsg: string;
}

export interface SmsTemplatesState {
  all: SmsTemplateWithTranslationsDto[];
  errorMsg: string;
}


export type SmsTemplatesActionType =
  PostSmsTemplateRequest |
  PostSmsTemplateSuccess |
  PostSmsTemplateFailure |
  GetSmsTemplateRequest |
  GetSmsTemplateSuccess |
  GetSmsTemplateFailure |
  UpdateSmsTemplateRequest |
  UpdateSmsTemplateSuccess |
  UpdateSmsTemplateFailure |
  RefreshSmsTemplateRequest |
  RefreshSmsTemplateSuccess |
  RefreshSmsTemplateFailure |
  DeleteSmsTemplateRequest |
  DeleteSmsTemplateSuccess |
  DeleteSmsTemplateFailure
