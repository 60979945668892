import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {userNameSelector} from "../../../../selectors/auth";
import {languageInUse, translate} from "../../../../selectors/translations";

const GreetingComponent: React.FC = (): React.ReactElement => {
    const { firstName } = useSelector(userNameSelector);
    const currentLanguage = useSelector(languageInUse);
    const title = useMemo(() => {
        const greeting = translate('greetingComponent.title');
        if (!firstName) {
            return `${greeting}!`;
        }
        return `${greeting} ${firstName}!`;
    }, [firstName, currentLanguage]);
    return (
        <div className='t_left'>
            <h1 className='pb-10 m-0 h1 greetingTitle'>{title}</h1>
            <p className='m-0 greetingText'>{translate('greetingComponent.text')}</p>
        </div>
    );
};

export default GreetingComponent;
