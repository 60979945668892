import { closeModalWindow } from './../ModalWindowData/action'
import { all, put, takeLatest } from 'redux-saga/effects'
import { AccountActionTypes } from './types'
import {
  fetchError,
  fetchSuccess,
  deleteSuccess,
  createSuccess,
  updateSuccess,
  getAccountSuccess,
  getAccountFailure,
  deletedAccountSuccess,
  deletedAccountFailure,
  restoreAccountFailure,
  restoreAccountSuccess,
  removeAccountFailure,
  removeAccountSuccess,
  deleteAccountFailure,
} from './action'
import { translate } from "../../selectors/translations"
import { setSuccessMsg } from "../Auth/action"
import { apiV2, SdkResponse, wrapResponse } from "../../repositories/repository"
import { MedStaffDto } from "../../sdk"

// GET ACCOUNTS LIST
function* handleFetch(): Generator<any> {
  const apiCall = apiV2.medStaff.getAllMedStaff.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall())) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(fetchSuccess(res.data))
  } else {
    yield put(fetchError(res.error))
  }
}

// CREATE ACCOUNT
function* handleCreate(opts: { type: AccountActionTypes.CREATE_ACCOUNT_REQUEST; data: MedStaffDto }): Generator<any> {
  const apiCall = apiV2.medStaff.createMedStaffProfile.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall(opts.data))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(createSuccess(res.data))
    const successMessage = translate('createNewAccountSuccessMessage')
    yield put(setSuccessMsg(successMessage))
    return
  } else {
    yield put(fetchError(res.error))
  }
}

// UPDATE ACCOUNT
function* handleUpdate(opts: {
  type: AccountActionTypes.UPD_ACCOUNT_REQUEST;
  data: MedStaffDto
}): Generator<any> {
  const payload = {...opts.data}
  // @ts-ignore
  delete payload._id
  const userId = opts.data._id

  // const res: any = yield call(callApi, ApiMethods.PUT, `${ApiAccountPath.UPDATE_ACCOUNT}/${userId}`, payload)
  const apiCall = apiV2.medStaff.updateMedStaffProfile.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall(userId, payload))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(updateSuccess(res.data))
    const successMessage = translate('editAccountSuccessMessage')
    yield put(setSuccessMsg(successMessage))
  } else {
    yield put(fetchError(res.error))
  }
}

function* handleAccountInfo(data: { type: string; id: string }): Generator<any> {
  const apiCall = apiV2.medStaff.getMedStaffProfile.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall(data.id))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(getAccountSuccess(res.data))
  } else {
    yield put(getAccountFailure(res.error))
  }
}

// DELETE ACCOUNT
function* handleDelete(data: { type: string; id: string }): Generator<any> {
  const apiCall = apiV2.medStaff.deleteArchivedMedStaff.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall(data.id))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(deleteSuccess(res.data))
    yield put(closeModalWindow())
  } else {
    yield put(deleteAccountFailure(res.error))
  }
}

function* handleArchive(data: { type: string; id: string }): Generator<any> {
  const apiCall = apiV2.medStaff.archiveMedStaff.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall(data.id))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(removeAccountSuccess(res.data))
    yield put(closeModalWindow())
  } else {
    yield put(removeAccountFailure(res.error))
  }

}

function* handleRestore(data: { type: string; id: string }): Generator<any> {
  const apiCall = apiV2.medStaff.restoreAchievedMedStaff.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall(data.id))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(restoreAccountSuccess(res.data))
    yield put(closeModalWindow())
  } else {
    yield put(restoreAccountFailure(res.error))
  }

}

function* handleDeletedRequest(): Generator<any> {
  const apiCall = apiV2.medStaff.getArchivedMedStaff.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall())) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(deletedAccountSuccess(res.data))
  } else {
    yield put(deletedAccountFailure(res.error))
  }
}

function* accountsSaga(): Generator {
  yield all([
    takeLatest(AccountActionTypes.GET_ACCOUNTS_REQUEST, handleFetch),
    takeLatest(AccountActionTypes.DELETE_ACCOUNT_REQUEST, handleDelete),
    takeLatest(AccountActionTypes.GET_DELETED_ACCOUNTS_REQUEST, handleDeletedRequest),
    takeLatest(AccountActionTypes.REMOVE_ACCOUNT_REQUEST, handleArchive),
    takeLatest(AccountActionTypes.RESTORE_ACCOUNT_REQUEST, handleRestore),
    takeLatest(AccountActionTypes.UPD_ACCOUNT_REQUEST, handleUpdate),
    takeLatest(AccountActionTypes.GET_ACCOUNT_REQUEST, handleAccountInfo),
    takeLatest(AccountActionTypes.CREATE_ACCOUNT_REQUEST, handleCreate),
  ])
}

export default accountsSaga
