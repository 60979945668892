import React from "react";
import {ReactComponent as LockIcon} from '../../../../assets/img/icons/lock.svg';
import {translate} from "../../../../selectors/translations";
import {useDispatch, useSelector} from "react-redux";
import { ReactComponent as RefreshIcon } from '../../../../assets/img/icons/refresh.svg';
import {getLoginCodeSelector} from "../../../../selectors/patientProfile";
import {openModalWindow} from "../../../../store/ModalWindowData/action";
import {formatDate} from "../../../../utils/datetime";

interface IProps {
    patientId: string;
}

const LoginCodeForm: React.FC<IProps> = (props: IProps): JSX.Element => {
    const {patientId} = props;
    const { code, expirationDate } = useSelector(getLoginCodeSelector);
    const dispatch = useDispatch();

    const refreshLoginCode = (): void => {
        dispatch(openModalWindow('refreshLoginCode', {
          patientId: patientId
        }));
      };

    return (
        <div className="card-box">
            <div className="box-title">
                <i className="icon-holder patients-detail_icon-holder"><LockIcon/></i>
                <h3>{translate('loginCode.title')}: <span className='t_bold t_uppercase'>{code} </span></h3>

            </div>
            <div className="item--12 item-md-7 float-right-LoginCodeForm">
                <div className="flex flex--wrap justify-between">
                        <button
                              type="button"
                              className="t_uppercase g_btn g_btn--primary w-100 loginCodeUpdateBtn"
                              onClick={refreshLoginCode}>
                            {translate('loginCode.prolong')}
                            <RefreshIcon className='refresh-icon'/>
                          </button>
                </div>
                <p className='light'>{translate('loginCode.expires')}: {formatDate(expirationDate)}</p>
            </div>
        </div>

    );
};

export default LoginCodeForm;
