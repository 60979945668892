import React from "react";
import getNewApiAnamnesisApiRiskInfo, {riskInfoSectionSlugs} from "../../utils/newApiAnamnesisRiskInfo";
import {SectionRiskIndicator} from "../../components/SurveyCard/SectionInfo/SectionInfo";
import NewApiSectionAnswers from "./newApiSectionAnswers";

import useTranslate from "../../hooks/useTranslate";
import {formatDate} from "../../utils/datetime";
import { QuestionerAnswersDTOOutput } from "../../sdk";

type AnamnesisNewPdfContainerProps = {
    anamnesis: QuestionerAnswersDTOOutput;
    visibleSections: string[];
    gender: string;
}
const AnamnesisNewPdfContainer: React.FC<AnamnesisNewPdfContainerProps> = (props: AnamnesisNewPdfContainerProps) => {
    const {anamnesis, visibleSections, gender} = props;
    const translate = useTranslate();

    const isRiskInfoQuestionAnswer = (sectionSlug: string) => {
        return riskInfoSectionSlugs.includes(sectionSlug)
    }


    const renderSectionRiskIndicator = (sectionSlug: string) => {
        const riskInfo = getNewApiAnamnesisApiRiskInfo({anamnesis, sectionSlug, gender})
        if (!riskInfo) return null
        return <SectionRiskIndicator totalPoints={riskInfo.totalPoints} actualPoints={riskInfo.actualPoints}
                                     riskLevel={riskInfo.riskLevel}
                                     showRiskWarning={riskInfo.suicideRisk} forPdf/>
    }

    return (<>
            <h1 className="header2 margin-top">{translate('pdf.anamnesis')} {formatDate(anamnesis.updatedAt || 0)}</h1>
            {
                visibleSections.map((sectionSlug) => {
                    return <div key={sectionSlug} className={isRiskInfoQuestionAnswer(sectionSlug) ? 'break_inside--avoid_page' : ''}>
                        <div className="anamnesis-title-wrapper">
                            <h1 className="header3 vertical-margin">{translate(`questioner.anamnesis.section.${sectionSlug}`)}</h1>
                            {
                                renderSectionRiskIndicator(sectionSlug)
                            }
                        </div>
                        <NewApiSectionAnswers sectionSlug={sectionSlug} anamnesis={anamnesis}
                                              isQuestionAnswerVisible={!isRiskInfoQuestionAnswer(sectionSlug)}/>
                    </div>
                })
            }
        </>
    );
}
export default AnamnesisNewPdfContainer;
