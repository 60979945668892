import React, { useEffect } from 'react'
import loginImage from '../../assets/img/login-image.png';
import { LoginOption, LoginPayload } from '../../store/Auth/types'
import { translate } from '../../selectors/translations';
import useFormStateHandler from '../../hooks/formStateHandler';
import Toogleable from '../../rc-components/Toggleable';
import { DropdownCb } from '../../types/Common';
import SelectorBox from '../SelectorBox/SelectorBox';
import DropdownMenu from '../../components/DropdownMenu';
import { TranlsationLanguage } from '../../store/Translations/types';
import greenHills from '../../assets/img/greenHills.svg';
import '../../assets/scss/layouts/greenHills.scss'

import './LoginForm.css';
import QRCode from 'react-qr-code';
import { useSelector } from "react-redux"
import { getQrCodeData } from "../../selectors/auth"
import useTranslate from "../../hooks/useTranslate"

type PropsFromState = {
  isFetching: boolean;
  onSubmit: (userData: LoginPayload) => void;
  switchLanguage: (language: string) => void;
  languages: TranlsationLanguage[];
  errorMsg: string;
}

type FormProps = {
  onSubmit: (userData: LoginPayload) => void;
  isFetching: boolean;
  errorMsg: string;
}


const EmailForm: React.FC<FormProps> = (props: FormProps) => {
  const { isFetching, onSubmit, errorMsg } = props;
  const {
    inputs,
    errors,
    handleSubmit,
    handleInputChange
  } = useFormStateHandler(onSubmit, { email: '', password: '', type: LoginOption.email });
  return (
    <div>
      <div className="min-height-wrapper">
        <div className={`login_input-holder ${(errors.includes('email') || errorMsg === 'Incorrect username') && "field_error"}`}>
          <label>{translate('email')}</label>
          <input
            name="email"
            value={inputs.email}
            onChange={handleInputChange} />
          {errors.includes('email') && <span>{translate('Incorrect email')}</span>}
        </div>
        <div className={`login_input-holder ${(errors.includes('password') || errorMsg === 'Incorrect password') && "field_error"}`}>
          <label>{translate('Password')}</label>
          <input
            name="password"
            type="password"
            value={inputs.password}
            onKeyPress={(e): void | boolean => e.charCode === 13 ? handleSubmit(e) : false}
            onChange={handleInputChange} />
          {errors.includes('password') && <span>{translate('loginForm.errors.pwdIsInvalid')}</span>}
        </div>
      </div>
      <div className="login_btns-holder flex justify-between align-center">
        <button
          onClick={handleSubmit}
          className="login-btn g_btn g_btn--primary" type="submit">
          {isFetching ? translate('Logging in...') : translate('Login')}
        </button>
      </div>
    </div>
  )
};

let qrCodeWasShown = false
const QrCodeForm: React.FC<FormProps> = (props: FormProps) => {
  const {onSubmit} = props
  const t = useTranslate()
  const qrCodeData = useSelector(getQrCodeData)

  useEffect(() => {
    onSubmit({type: LoginOption.qrCode})
  }, [])

  const onRetry = () => {
    onSubmit({type: LoginOption.qrCode})
  }
  if (qrCodeData) {
    qrCodeWasShown = true
  } else if(!qrCodeWasShown) {
    return <></>
  }

  return <div>
    {!qrCodeData && <>
        <div className="min-height-wrapper">
            <h3 className="t_center">{t('bank-id.qr-code.scan-failed')}</h3>
            <div className="login_btns-holder flex justify-center align-center">
                <button className="login-btn g_btn g_btn--primary" type="submit"
                        onClick={onRetry}>{t('bank-id.try-again')}</button>
            </div>
        </div>
    </>}
    {qrCodeData && <>
      <h3 className="pt-10 t_center">{t('bank-id.qr-code.tip')}</h3>
      <div style={{background: 'white', padding: '16px'}}>
        <QRCode
          style={{height: "220px", maxWidth: "100%", width: "100%"}}
          value={qrCodeData || ''}
        />
      </div>
    </>}
  </div>
}

const _LoginForm: React.FC<PropsFromState> = (props: PropsFromState) => {
  const {onSubmit, isFetching, errorMsg} = props;
  const t = useTranslate();
  return (
    <div className="login_container justify-center">
      <div className="login_wrapper">
        <h1 className="login_title">{translate('welcome_geras_1')}<br />{translate('welcome_geras_2')}</h1>
        <div className="login_card-box">
          <div className="login_form-holder flex">
            <div className="login_from-wrapper flex-item--stretch">
              <Toogleable defaultValue={LoginOption.qrCode}>
                {
                  (dropdownId: string, setDropdownId: DropdownCb): React.ReactElement => (
                    <div>
                      <div className="login_switcher-holder">
                        <span className="login-title">{translate('Login with')}:</span>
                        <div className="login_switcher-btns">
                          <span onClick={(): void => setDropdownId(LoginOption.email)}
                            className={dropdownId === LoginOption.email ? 'active' : ''}>{translate('email')}</span>
                          <span onClick={(): void => setDropdownId(LoginOption.qrCode)}
                                className={dropdownId === LoginOption.qrCode ? 'active' : ''}>{t('bankId')}</span>
                        </div>

                      </div>
                        {dropdownId === LoginOption.email && <EmailForm isFetching={isFetching} onSubmit={onSubmit} errorMsg={errorMsg}/>}
                        {dropdownId === LoginOption.qrCode && <QrCodeForm isFetching={isFetching} onSubmit={onSubmit} errorMsg={errorMsg}/>}
                    </div>
                  )
                }
              </Toogleable>
            </div>
            <div className="login_from-wrapper flex-item--shrink flex align-center">
              <div className="login_lang-switcher">
                <Toogleable>
                  {
                    (dropdownId: string, setDropdownId: (id: string) => void): React.ReactElement =>
                    <SelectorBox
                      items={props.languages.map((l: TranlsationLanguage) => ({ title: l.key, isSelected: l.inUse, propToPropagate: l.key }))}
                      type="language"
                      Elem={
                        <DropdownMenu
                          show={dropdownId === 'language'}
                          onClick={(val: string): void => (setDropdownId(''), props.switchLanguage(val))}
                          items={props.languages.map((l: TranlsationLanguage) => ({ title: l.key, isSelected: l.inUse, propToPropagate: l.key }))} />
                      }
                      dropdownId={dropdownId}
                      onToggleMenu={setDropdownId}>
                    </SelectorBox>
                  }
                </Toogleable>
              </div>
              <img src={loginImage} className="AuthForm__image" alt="family picture" />
            </div>
          </div>
        </div>
      </div>
      <img className="greenHills" src={greenHills}/>
    </div>
  );
}

export default _LoginForm;
