export type DropdownCb = (id: string) => void;

export interface TestCategoryLabelItem {
  label: string;
  maxPoints: number;
  type: number;
  answerVariants: any[];
}

export interface CognitiveTestCategory {
  titleKey: string;
  type: string;
  testLabels: TestCategoryLabelItem[];
}

export interface Anamnesis {
  titleKey: string;
  answerLabels: string[];
  answers: string[][];
}

export interface AnamnesisConfig {
  type: number;
  labels: string[];
  optionLabels: (string[] | null)[] | null[];
  disabled: boolean;
  sort: number;
  showIdk: boolean;
  skipIfNo: number | null;
  expandOn: number | null;
}

export interface CognitiveTestLabel {
  label: string;
  maxPoints: number;
  type: number;
  answerVariants: any;
}

export interface CognitiveConfig {
  titleKey: string;
  type: string;
  testLabels: CognitiveTestLabel[];
}

export interface ProgerssLineSetup {
  intervals: number[];
  intervalsLegend: string[];
  generalLegend: string;
  intervalHole?: [[number, number]];
}

export enum JsDataTypes {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Undefined = 'undefined',
  Object = 'object'
}

export enum UserInstitution {
  All = 'All',
  GSAPP = 'GSAPP'
}
