import {PdfReportItemData} from "../models/pdfReportData";

export const findMostRecentItem = (items: Array<any>): Array<any> => {
    let mostRecent = items[0];
    for (let i = 0; i < items.length - 1; i++) {
        if (new Date(items[i + 1].date) > new Date(items[i].date)) {
            mostRecent = items[i + 1];
        }
    }
    return mostRecent;
};

export const sortItemByDate = (itemFirst: PdfReportItemData, itemSecond: PdfReportItemData): number => {
    const firstDate = new Date(itemFirst.date);
    const secondDate = new Date(itemSecond.date);
    if (firstDate > secondDate) {
        return -1;
    }
    if (firstDate < secondDate) {
        return 1;
    }
    return 0;
}
