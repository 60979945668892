import { all, put, takeLatest } from 'redux-saga/effects'
import { DeleteSmsTemplateRequest, PostSmsTemplateRequest, SmsTemplatesActionTypes } from './types'
import {
  getSmsTemplatesSuccess,
  getSmsTemplatesError,
  postSmsTemplateSuccess,
  postSmsTemplateError,
  deleteSmsTemplateSuccess, deleteSmsTemplateError,
} from './action'
import { apiV2, SdkResponse, wrapResponse } from "../../repositories/repository"

function* handleGetSmsTemplates() {
  const apiCall = apiV2.sms.getTemplates.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall())) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(getSmsTemplatesSuccess(res.data))
  } else {
    yield put(getSmsTemplatesError(res.error))
  }
}

function* handleCreateSmsTemplate({data}: PostSmsTemplateRequest) {
  const apiCall = apiV2.sms.upsertTemplate.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall(data))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(postSmsTemplateSuccess(res.data))
    yield handleGetSmsTemplates()
  } else {
    yield put(postSmsTemplateError(res.error))
  }
}

function* handleDeleteSmsTemplate({_id}: DeleteSmsTemplateRequest) {
  const apiCall = apiV2.sms.removeTemplate.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall(_id))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(deleteSmsTemplateSuccess())
    yield handleGetSmsTemplates()
  } else {
    yield put(deleteSmsTemplateError(res.error))
  }
}


function* smsTemplatesSaga() {
  yield all([
    takeLatest(SmsTemplatesActionTypes.GET_SMS_TEMPLATE_REQUEST, handleGetSmsTemplates),
    takeLatest(SmsTemplatesActionTypes.POST_SMS_TEMPLATE_REQUEST, handleCreateSmsTemplate),
    takeLatest(SmsTemplatesActionTypes.DELETE_SMS_TEMPLATE_REQUEST, handleDeleteSmsTemplate),
  ])
}

export default smsTemplatesSaga
