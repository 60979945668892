import React, {useCallback} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import LogoIcon from '../../assets/img/icons/logo.svg';
import UDIlogo from '../../assets/img/branding/UDI.png';
import MDlogo from '../../assets/img/branding/MD.png';
import Corplogo from '../../assets/img/branding/Factory.png';
import Reflogo from '../../assets/img/branding/REF.png';
import CElogo from '../../assets/img/branding/CE.png';
import Toggleable from '../../rc-components/Toggleable';
import Section from './Section';
import { ApplicationState } from '../../store';
import { TranlsationLanguage, TranslationsActionType } from '../../store/Translations/types';
import SelectorBox from '../SelectorBox/SelectorBox';
import DropdownMenu from '../../components/DropdownMenu';
import { useLocation } from 'react-router';
import { logout } from '../../store/Auth/action';
import { switchTranslation } from '../../store/Translations/actions';
import { AuthActionType } from '../../store/Auth/types';
import { translate } from '../../selectors/translations';
import MobileAppVersion from '../AppVersion';
import {openModalWindow} from "../../store/ModalWindowData/action";
import BurgerIcon from '../../assets/img/icons/menu_icon.svg';
import HideIcon from '../../assets/img/icons/arrow_left_icon.svg';
import {collapseSideMenu, expandSideMenu} from "../../store/App/action";
import {AppActionType} from "../../store/App/types";
import {RouteNames} from "../../types/constants";
import { AppVersionDTO, MedStaffDto, MedStaffRole } from "../../sdk"
import { getInstitutionsSelector, institutionName } from "../../selectors/institutions"
import useTranslate from "../../hooks/useTranslate"

export type SidebarSection = {
  titleKey: string;
  icon: string;
  link: null | string;
  availableLinks: string[];
  subSections: SubSection[];
  availableRoles: string[];
}

export type SubSection = {
  titleKey: string;
  link: string;
  availableRoles: string[];
}

const SIDEBAR_SECTIONS: SidebarSection[] = [
  {
    titleKey: 'start',
    icon: 'home',
    link: '/start',
    subSections: [],
    availableLinks: ['start'],
    availableRoles: ['all']
  },
  {
    titleKey: 'home',
    icon: 'home',
    link: '/',
    subSections: [],
    availableLinks: [''],
    availableRoles: ['all1']
  },
  {
    titleKey: 'patients',
    icon: 'user',
    link: '/patient',
    availableLinks: ['patient'],
    subSections: [
      { titleKey: 'allPatients', link: '/patient', availableRoles: ['all'] },
      { titleKey: 'deletedPatients', link: '/patient/deleted', availableRoles: ['all'] },
      { titleKey: 'newPatient', link: '/patient/new', availableRoles: ['all'] }
    ],
    availableRoles: ['all']
  },
  {
    titleKey: 'appointments',
    icon: 'calendar',
    link: '/appointments',
    subSections: [],
    availableLinks: ['appointments'],
    availableRoles: ['all1']
  },
  {
    titleKey: 'video-call',
    icon: 'phone',
    link: '/video-call',
    subSections: [],
    availableLinks: ['video-call'],
    availableRoles: ['all1']
  },
  {
    titleKey: 'careGiver',
    icon: 'account',
    link: '/accounts',
    availableLinks: ['accounts'],
    subSections: [
      { titleKey: 'allAccounts', link: '/accounts', availableRoles: ['all'] },
      { titleKey: 'deletedAccounts', link: '/accounts/deleted', availableRoles: ['admin'] },
      { titleKey: 'newAccount', link: '/accounts/create', availableRoles: ['all'] }
    ],
    availableRoles: ['all']
  },
  {
    titleKey: 'documents',
    icon: 'document',
    link: '/documents',
    subSections: [],
    availableLinks: ['documents'],
    availableRoles: ['all']
  },
  {
    titleKey: 'sidePanel.navigation.contact',
    icon: 'contacts',
    link: RouteNames.Contacts,
    subSections: [],
    availableLinks: [RouteNames.Contacts],
    availableRoles: ['all']
  },
  // {
  //   titleKey: 'translationTool',
  //   icon: 'document',
  //   link: '/translation-tool',
  //   subSections: [],
  //   availableLinks: ['/translation-tool'],
  //   availableRoles: ['admin']
  // },
  {
    titleKey: 'Admin',
    icon: 'admin',
    link: '/admin-panel',
    subSections: [],
    availableLinks: ['/admin-panel'],
    availableRoles: ['admin']
  },
  {
    titleKey: 'Logout',
    icon: 'logout',
    link: '/login',
    subSections: [],
    availableLinks: ['/login'],
    availableRoles: ['all']
  },
];

const createSidebarForRole = (role: string): SidebarSection[] => {
  return SIDEBAR_SECTIONS
    .map((section: SidebarSection) => {
      return {
        ...section,
        subSections: section.subSections.filter((subsection: SubSection) => subsection.availableRoles[0] === 'all' ? true : subsection.availableRoles.includes(role))
      }
    })
    .filter((section: SidebarSection) => section.availableRoles[0] === 'all' ? true : section.availableRoles.includes(role))
}

type PropsFromState = {
  authUser: MedStaffDto;
  languages: TranlsationLanguage[];
  appVersion: AppVersionDTO;
  isMenuExpanded: boolean;
}

type PropsFromDispatch = {
  logout: () => void;
  switchLanguage: (language: string) => void;
}

type allProps = PropsFromState & PropsFromDispatch

const excludingUrls = ['login', 'family', 'not-found', 'forgot-password', 'restore', 'reset-password', 'change-password', 'translation-tool', 'generate-pdf'];

const Sidebar: React.FC<allProps> = (props: allProps) => {
  const { authUser, languages, appVersion, isMenuExpanded } = props;
  const location = useLocation();
  const translateLocalazy = useTranslate()
  const dispatch = useDispatch();
  useSelector(getInstitutionsSelector)
  const { switchLanguage } = props;
  const sidebar = createSidebarForRole(authUser.roles[0]);
  const handleOnVersionClick = (): void => {
    if (authUser.roles.includes(MedStaffRole.ADMIN)) {
      dispatch(openModalWindow('appVersion', { version: appVersion }));
    }
  }
  const collapseMenu = (): void => {
    dispatch(collapseSideMenu());
  };

  const toggleSideBar = (): void => {
    if (isMenuExpanded) {
      collapseMenu();
      return;
    }
    dispatch(expandSideMenu());
  };

  const checkIsActive = useCallback((links: string[], location: string) => {
    return Boolean(links.find((item) => item.includes(location.split('/')[1])));
  }, []);

  return excludingUrls.includes(location.pathname.split('/')[1] || '')
    ? null
    : (
        <div className={`sidebar-wrapper ${isMenuExpanded ? 'active' : ''}`}>
          <div className="sidebar_toggler-box"
               onClick={toggleSideBar}><img src={isMenuExpanded ? HideIcon : BurgerIcon} /></div>
      <div className="sidebar_logo-box flex-item--shrink">
        <a className="logo_link" href="/"><img src={LogoIcon} alt="logo" /></a>
      </div>
      {
        authUser
        && authUser.email
        && <div className="flex flex-column">
          <div className="flex-item sidebar_info-box">
            <span>{translate(authUser.roles[0])}</span><br />
            <span> {authUser.lastName} {authUser.firstName} ({institutionName(authUser.institution)})</span><br />
            <span>{authUser.email}</span>
          </div>
        </div>
      }
      <div className="sidebar_nav-box flex-item--stretch">
        <ul className="nav-list" onClick={collapseMenu}>
          {
            sidebar.map((section: SidebarSection, index: number) => (
              <Section
                key={index}
                isActive={checkIsActive(section.availableLinks, location.pathname)}
                type={section.titleKey}
                section={section}
                logout={props.logout}>
              </Section>
            ))
          }
        </ul>
      </div>
      <div className="sidebar_actions-box flex-item--shrink">
        <div className="sidiber_action-item">
          {
            <Toggleable>
              {
                (dropdownId: string, setDropdownId: (id: string) => void): React.ReactElement =>
                  <SelectorBox
                    items={languages.map((l: TranlsationLanguage) => ({ title: l.key, isSelected: l.inUse, propToPropagate: l.key }))}
                    type="language"
                    Elem={
                      <DropdownMenu
                        show={dropdownId === 'language'}
                        onClick={(val: string): void => (setDropdownId(''), switchLanguage(val))}
                        items={languages.map((l: TranlsationLanguage) => ({ title: l.key, isSelected: l.inUse, propToPropagate: l.key }))} />
                    }
                    dropdownId={dropdownId}
                    onToggleMenu={setDropdownId}>
                  </SelectorBox>
              }
            </Toggleable>
          }
        </div>
      </div>
      <div className="ph-20 w-100 pv-20">
        <div className="pl-20 pb-10">
          <div className="footer-legal-mark pb-10">
            <img src={CElogo} height={20}/>
          </div>
          <div
            className="footer-legal-mark flex w-100 align-top">
            <img src={MDlogo} height={20}/>
            <span className="pl-10">{translateLocalazy('branding.md')}</span>
          </div>
          <div
            className="footer-legal-mark flex w-100 align-top">
            <img src={UDIlogo} height={21}/>
            <span className="pl-10">{translateLocalazy('branding.udi')}</span>
          </div>
          <div
            className="footer-legal-mark flex w-100 align-top">
            <img src={Reflogo} height={20}/>
            <div className="pl-10">
              <MobileAppVersion
                extraClassName={authUser.roles.includes(MedStaffRole.ADMIN) ? 'clickable' : ''}
                onClickHandler={handleOnVersionClick}
                version={appVersion}/>
            </div>
          </div>
          <div
            className="footer-legal-mark flex w-100 align-top pt-10">
            <img src={Corplogo} height={29}/>
            <span className="pl-10">{translateLocalazy('branding.corp')}</span>
          </div>
        </div>
      </div>
    </div>
    );
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  authUser: state.auth.user,
  languages: state.translations.languages,
  appVersion: state.auth.appVersion,
  isMenuExpanded: state.appState.isSideMenuExpanded
});

const mapDispatchToProps = (dispatch: (action: AuthActionType | TranslationsActionType | AppActionType) => void): PropsFromDispatch => ({
  logout: (): void => dispatch(logout()),
  switchLanguage: (language: string): void => dispatch(switchTranslation(language))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
