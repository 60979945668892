import React from "react";

import getNewApiAnamnesisApiRiskInfo, {extraInfoNotes} from "../../utils/newApiAnamnesisRiskInfo";
import {SectionNote} from "../../components/SurveyCard/SectionInfo/SectionInfo";
import useTranslate from "../../hooks/useTranslate";
import {
  CheckboxGroupInputMetadata,
  InputType,
  QuestionerAnswersDTOOutput,
  QuestionScreenDTO, RadioGroupInputMetadata,
  ScreenType,
  TwoChairsMetadata
} from "../../sdk"

type SectionAnswersProps = {
    anamnesis: QuestionerAnswersDTOOutput;
    sectionSlug: string;
    isQuestionAnswerVisible: boolean;
}
const NewApiSectionAnswers: React.FC<SectionAnswersProps> = (props: SectionAnswersProps) => {
    const {sectionSlug, anamnesis, isQuestionAnswerVisible} = props
    const sectionConfig = anamnesis.questioner.sections.find(el => el.slug === sectionSlug);
    const translate = useTranslate();

    const renderAnswerByScreenId = (screenId: string) => {
        const answer = anamnesis.answers.find(answer => answer.screen === screenId);
        // @ts-ignore
        const config = anamnesis.questioner.sections.find(section => section.slug === sectionSlug).screens.find(el => el.screen.id === screenId);

        if (!config || config.screen.kind !== ScreenType.QUESTION) {
            return null
        }

        const questionKind = (config.screen as QuestionScreenDTO).metadata.input.kind;
        const questionMetadata = (config.screen as QuestionScreenDTO).metadata.input.metadata;

        let textValue = ""

        if (answer && answer.value) {
            switch (questionKind) {
                case InputType.FREE_TEXT:
                    textValue = answer.value as any
                    break;
                case InputType.TWO_CHOICE:
                    textValue = translate(((answer.value as any) == '1' ? (questionMetadata as TwoChairsMetadata).firstLabel : (questionMetadata as TwoChairsMetadata).secondLabel) as string)
                    break;
                case InputType.CHECKBOX_GROUP:
                    const checkboxValue: string[] = Array.isArray(answer.value) ? answer.value : [answer.value]
                    // @ts-ignore
                    textValue = checkboxValue.map((el) => {
                        const correspondingConf = (questionMetadata as CheckboxGroupInputMetadata).values.find(item => item.value === el);
                        if (correspondingConf) return <p className="m-0">{translate(correspondingConf.label)}</p>
                        return <></>
                    });
                    break;
              case InputType.SELECTOR:
              case InputType.RADIO_GROUP:
                    const val = (questionMetadata as RadioGroupInputMetadata).values.find(el => el.value === answer.value as any);
                    if (val && val.label) {
                        textValue = translate(val.label)
                    }
            }
        } else {
            textValue = translate('No answer')
        }

        return <div className="answer-item">
            {textValue}
        </div>
    }

    const renderSectionNote = () => {
        const riskInfo = getNewApiAnamnesisApiRiskInfo({anamnesis, sectionSlug, gender: ""})
        if (!riskInfo) return null

        return <SectionNote {...riskInfo} showSuicideRiskWarning={riskInfo.suicideRisk}/>
    }

    const renderExtraInfoSectionNote = () => {
        const riskInfo = extraInfoNotes.find(el => el.slug === sectionSlug)
        if (!riskInfo) return null
        // @ts-ignore
        return <SectionNote {...riskInfo} showSuicideRiskWarning={false}/>
    }

    return <div className="anamnesis-section-wrapper">
        {
            sectionConfig && isQuestionAnswerVisible ? sectionConfig.screens.filter(el => el.screen.kind === "question").map((item, index) => {
                return <div key={item.screen.id! + index} className="question-answer-wrapper break_inside--avoid_page">
                    <h3>{`${index + 1}. ${translate(item.screen.metadata.title)}`}</h3>
                    <div className="answers-wrapper">
                        {
                            renderAnswerByScreenId(item.screen.id!)
                        }
                    </div>
                </div>
            }) : null
        }

        {
            renderSectionNote()
        }

        {
            renderExtraInfoSectionNote()
        }
    </div>
}

export default NewApiSectionAnswers;
