import { AccountsActionType, FilterChangedData, AccountActionTypes } from './types';
import { MedStaffDto } from "../../sdk";

export const fetchRequest = (): AccountsActionType => ({type: AccountActionTypes.GET_ACCOUNTS_REQUEST});
export const fetchError = (error: string): AccountsActionType => ({type: AccountActionTypes.GET_ACCOUNTS_FAILURE, errorMsg: error});
export const fetchSuccess = (data: MedStaffDto[]): AccountsActionType => ({type: AccountActionTypes.GET_ACCOUNTS_SUCCESS, accounts: data});

export const filterChanged = (data: FilterChangedData): AccountsActionType => ({ type: AccountActionTypes.FILTER_CHANGED, filterData: data});

export const deleteAccount = (id: string): AccountsActionType => ({type: AccountActionTypes.DELETE_ACCOUNT_REQUEST, id: id});
export const deleteSuccess = (data: {_id: string}): AccountsActionType => ({type: AccountActionTypes.DELETE_ACCOUNT_SUCCESS, data: data});
export const deleteAccountFailure = (err: string): AccountsActionType => ({type: AccountActionTypes.DELETE_ACCOUNT_FAILURE, errorMsg: err});

export const updateRequest = (data: MedStaffDto): AccountsActionType => ({type: AccountActionTypes.UPD_ACCOUNT_REQUEST, data});
export const updateSuccess = (data: MedStaffDto): AccountsActionType => ({type: AccountActionTypes.UPD_ACCOUNT_SUCCESS, account: data});
export const updateFailure = (err: string): AccountsActionType => ({type: AccountActionTypes.UPD_ACCOUNT_FAILURE, errorMsg: err});

export const createRequest = (data: MedStaffDto): AccountsActionType => ({type: AccountActionTypes.CREATE_ACCOUNT_REQUEST, data});
export const createSuccess = (data: MedStaffDto): AccountsActionType => ({type: AccountActionTypes.CREATE_ACCOUNT_SUCCESS, data});
export const createFailure = (err: string): AccountsActionType => ({type: AccountActionTypes.CREATE_ACCOUNT_FAILURE, errorMsg: err});

export const getAccount = (id: string): AccountsActionType => ({type: AccountActionTypes.GET_ACCOUNT_REQUEST, id});
export const getAccountSuccess = (response: MedStaffDto): AccountsActionType => ({type: AccountActionTypes.GET_ACCOUNT_SUCCESS, response});
export const getAccountFailure = (err: string): AccountsActionType => ({type: AccountActionTypes.GET_ACCOUNT_FAILURE, errMsg: err});

export const removeAccount = (id: string): AccountsActionType => ({type: AccountActionTypes.REMOVE_ACCOUNT_REQUEST, id: id});
export const removeAccountSuccess = (data: {_id: string}): AccountsActionType => ({type: AccountActionTypes.REMOVE_ACCOUNT_SUCCESS, data: data});
export const removeAccountFailure = (err: string): AccountsActionType => ({type: AccountActionTypes.REMOVE_ACCOUNT_FAILURE, errorMsg: err});

export const restoreAccount = (id: string): AccountsActionType => ({type: AccountActionTypes.RESTORE_ACCOUNT_REQUEST, id: id});
export const restoreAccountSuccess = (data: {_id: string}): AccountsActionType => ({type: AccountActionTypes.RESTORE_ACCOUNT_SUCCESS, data: data});
export const restoreAccountFailure = (err: string): AccountsActionType => ({type: AccountActionTypes.RESTORE_ACCOUNT_FAILURE, errorMsg: err});

export const deletedAccountRequest = (): AccountsActionType => ({type: AccountActionTypes.GET_DELETED_ACCOUNTS_REQUEST});
export const deletedAccountSuccess = (data: MedStaffDto[]): AccountsActionType => ({type: AccountActionTypes.GET_DELETED_ACCOUNTS_SUCCESS, data: data});
export const deletedAccountFailure = (err: string): AccountsActionType => ({type: AccountActionTypes.GET_DELETED_ACCOUNTS_FAILURE, errorMsg: err});

export const resetAccountsState = (): AccountsActionType => ({type: AccountActionTypes.RESET_ACCOUNTS_STATE});

export const unsetAccountInfo = (): AccountsActionType => ({type: AccountActionTypes.UNSET_ACCOUNT_PROFILE}) ;
