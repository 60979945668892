import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'
import callApi from '../../utils/apiCaller';
import { FamilyActionTypes, UpdateFamilyMemberRequest } from './types';
import {ApiMethods, ApiFamilyPath} from '../../types/Api';
import {
  postFamilyMemberSuccess,
  postFamilyMemberFailure,
  postFamilyTokenError,
  postFamilyTokenSuccess,
  updateFamilyMemberSuccess,
  updateFamilyMemberFailure,
  postAskFamilySurveyError,
  postAskFamilySurveySuccess
} from './action';
import {closeModalWindow} from "../ModalWindowData/action";
import {translate} from "../../selectors/translations";
import {setErrorMsg, setSuccessMsg} from "../Auth/action";
import { apiV2, SdkResponse, wrapResponse } from "../../repositories/repository"
import { SendSsToRelativeDto } from "../../sdk"

function* handlePostTokenCheck(action: any): Generator {
  try {
    const res: any = yield call(callApi, ApiMethods.POST, ApiFamilyPath.CHECK,action.data);

    if (res.error) {
      yield put(postFamilyTokenError(res.error))
    } else {
      yield put(postFamilyTokenSuccess(res.data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(postFamilyTokenError(err.stack))
    } else {
      yield put(postFamilyTokenError('An unknown error occured.'))
    }
  }
}

function* handlePostFamilyMember(action: any): Generator {
  try {
    const res: any = yield call(callApi, ApiMethods.POST, ApiFamilyPath.CREATE, action.data);

    if (res.error) {
      yield put(postFamilyMemberFailure(res.error))
    } else {
      yield put(postFamilyMemberSuccess(res.data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(postFamilyMemberFailure(err.stack))
    } else {
      yield put(postFamilyMemberFailure('An unknown error occured.'))
    }
  }
}

function* handleUpdateFamilyMember(action: UpdateFamilyMemberRequest): Generator {
  try {
    const res: any = yield call(callApi, ApiMethods.PUT, ApiFamilyPath.CREATE, action.data);
    if (res.error) {
      yield put(updateFamilyMemberFailure(res.error))
    } else {
      yield put(updateFamilyMemberSuccess(res.data));
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(updateFamilyMemberFailure(err.stack))
    } else {
      yield put(updateFamilyMemberFailure('An unknown error occured.'))
    }
  }
}

function* handlePostAskFamilySurvey(action: { action: any, data: SendSsToRelativeDto }) {
  const apiCall = apiV2.sms.sendSsToRelative.bind(apiV2.medStaff)
  const res = (yield wrapResponse(apiCall(action.data))) as SdkResponse<typeof apiCall>

  if (res.success) {
    yield put(closeModalWindow())
    yield put(postAskFamilySurveySuccess(res.data))
    const successMessage = translate('sendSymptomSurveySuccessMessage')
    yield put(setSuccessMsg(successMessage))
  } else {
    yield put(setErrorMsg(res.error || translate('An unknown error occured')))
    yield put(postAskFamilySurveyError(res.error))
  }
}

function* watchPostToken(): Generator {
  yield takeEvery(FamilyActionTypes.POST_FAMILY_TOKEN_REQUEST, handlePostTokenCheck);
}

function* watchPostFamilyMember(): Generator {
  yield takeEvery(FamilyActionTypes.POST_FAMILY_MEMBER_REQUEST, handlePostFamilyMember);
}

function* watchUpdateFM(): Generator {
  yield takeEvery(FamilyActionTypes.UPDATE_FAMILY_MEMBER_REQUEST, handleUpdateFamilyMember)
}

function* familySaga() {
  yield all([
    fork(watchPostToken),
    fork(watchPostFamilyMember),
    fork(watchUpdateFM),
  // @ts-ignore
    takeLatest(FamilyActionTypes.POST_ASK_FAMILY_SURVEY_REQUEST, handlePostAskFamilySurvey),
  ]);
}

export default familySaga;
