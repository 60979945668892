import { AnswerDTO } from "../sdk"

export enum SsAnswerVariant {
  yes = 'yes-pronounced-often',
  maybe = 'yes-somewhat-sometimes',
  no = 'no',
  idk = 'dont-know-cant-be-assessed'
}

export const calculateSymptomSurveyScore = (answers: AnswerDTO[] = []): number => {
  return answers.reduce((accumValue, answer): number => {
    let score = 0
    if (answer.value === SsAnswerVariant.yes) {
      score = 2
    }
    if (answer.value === SsAnswerVariant.maybe) {
      score = 1
    }
    accumValue += score
    return accumValue
  }, 0)
}

export const calculateActualMaxSymptomSurveyScore = (
  answers: AnswerDTO[] = [],
): number => {
  return answers.reduce((acc, item) => item.value !== SsAnswerVariant.idk ? acc + 2 : acc, 0)
}
