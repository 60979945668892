import { createSelector } from 'reselect';
import { AccountsState, AccountFilters } from './../store/Accounts/types';
import { ApplicationState } from './../store/index';
import { MedStaffDto } from "../sdk"

const selectAccounts = (state: ApplicationState): AccountsState => state.accounts;
const selectFilters = (state: ApplicationState): AccountFilters => state.accounts.filters;

export const getAccounts = createSelector(
  [selectAccounts, selectFilters],
  (accountsState: AccountsState, filters: AccountFilters): MedStaffDto[] => {
    const accounts = accountsState.all;
    const sortingField = filters.sort.field as keyof MedStaffDto;
    const direction = filters.sort.direction;

    return accounts
      .filter(a => filters.searchTerm ? `${a.firstName}_${a.lastName}`.toLowerCase().includes(filters.searchTerm.toLocaleLowerCase()) : true)
      // .filter(a => filters.displayedType === 'all' ? true : a.role === translate(`filter.accounts.${filters.displayedType}`))
      .sort((a, b) =>  (a[sortingField] > b[sortingField] ? (direction ? 1 : -1) : a[sortingField] < b[sortingField] ? (direction ? -1 : 1) : 0))
  }
);
