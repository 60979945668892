import React from 'react';
import Toggleable from '../../../rc-components/Toggleable';
import { DropdownCb } from '../../../types/Common';
import { translate } from '../../../selectors/translations';
import { getAnswerClass } from "../../../utils/getAnswerClass"
import {ReactComponent as InfoIcon} from "../../../assets/img/icons/information-outline.svg";
import { AnswerDTO } from "../../../sdk"

type PropsFromState = {
  patientProfile: AnswerDTO[];
  illnessProfile: { grade: number; type: string; title: string }[];
  title: string;
  profileTitle: string;
  score: number;
  totalScore: number;
}

const MemoryCateogry: React.FC<PropsFromState> = (props: PropsFromState) => {
  const { patientProfile, illnessProfile, title, totalScore, score } = props;
  return (
    <section className="spoiler_box">
      <Toggleable>
        {
          (dropdownId: string, setDropDownId: DropdownCb): React.ReactElement => (
            <div onClick={(): void => setDropDownId(dropdownId === 'answer' ? '' : 'answer')}>
              <div className={dropdownId === 'answer' ? 'spoiler_header diagnostic_question_header isOpen' : 'spoiler_header diagnostic_question_header'}>
                <div className="flex justify-between flex--wrap">
                  <div className="flex-item--shrink">
                    <h3>{title}</h3>
                  </div>
                  <div className="flex-item--shrink">
                    <p className='m-0'>{translate('Points')} <strong>{score}</strong> / {totalScore}</p>
                    <div className='flex align-center pt-5'>
                      <div className="risk_progress-legend flex align-center">
                        <div className='flex-item--shrink'>
                          <InfoIcon className={`info-icon m-0 flex`}/>
                        </div>
                        <div className="info_list">{translate('symptom_survey.riskLegend.hint')}</div>
                      </div>
                      <p className='common-title m-0 pl-5'>({`${translate('max_if_all_answered')} ${patientProfile.length * 2}`})</p>
                    </div>
                  </div>
                </div>
              </div>
              {dropdownId &&
                <div className="spoiler_body brain_graph_analize">
                  <div className="flex-container diagnostic_question_body p-10">
                    <div className="flex-item item-sm-6">
              <h3>{translate('patientsProfile')}</h3>
                      <ul>
                        {illnessProfile.map((item, i) => (
                          <li key={i}>
                            <div
                              // @ts-ignore
                              className={`brain-zone_color ${getAnswerClass(patientProfile[i].value)}`}>
                              {item.type}
                            </div>
                            {translate(item.title)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              }
            </div>
          )
        }
      </Toggleable>
    </section>
  )
}

export default MemoryCateogry;
