// TODO use dayjs
const toZeroFirstFormat = (strToFormat: string | number): string => {
  if(strToFormat.toString().length > 1) {
    return strToFormat.toString()
  }
  return `0${strToFormat}`.substring(-2)
};

export function dateToAge(date?: Date | string): string {
  if (!date) {
    return '-'
  }

  const ageDifMs = Date.now() - +new Date(date);
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970).toString()
}

export const formatDate = (date: string | Date | number): string => {
  if (!date) {
    return '';
  }
  const newDate = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date;
  return [
    `${toZeroFirstFormat(newDate.getDate())}/${toZeroFirstFormat(newDate.getMonth() + 1)}/${newDate.getFullYear()}`,
    `${toZeroFirstFormat(newDate.getHours())}:${toZeroFirstFormat(newDate.getMinutes())}`
  ].join(' ');
}

export const formatToDate = (date: string | Date): string => {
  if (!date) {
    return '';
  }
  const newDate = typeof date === 'string' ? new Date(date) : date;
  return [
    `${toZeroFirstFormat(newDate.getDate())}/${toZeroFirstFormat(newDate.getMonth() + 1)}/${newDate.getFullYear()}`,
  ].join(' ');
}

export const formatToMeetingDate = (date:  number | Date): string => {
  if (!date) {
    return '';
  }
  const newDate = typeof date === 'number' ? new Date(date) : date;
  return [
    `${toZeroFirstFormat(newDate.getHours())} : ${toZeroFirstFormat(newDate.getMinutes())}`,
  ].join(' ');
}

export const milliToIMin = (value: number): string => {
  return `${Math.floor((value / 1000) / 60)}:${toZeroFirstFormat(Math.floor(value / 1000)%60)}`;
}

export const toMinutes = (value: number): string => {
  return `${Math.floor(value / 60)}:${toZeroFirstFormat(value%60)}`;
}

export const toSeconds = (value: number): string => {
  return `${(value / 1000).toFixed(2)}`;
}

export const asArray = <T>(data: T): T[] => Array.isArray(data) ? data : [data];

export const toArray = <T>(data: any[], index: number): T[] => data[index] ? asArray(data[index]) : asArray(index);

export const chunkArr = <T>(arr: T[], chunkCounter: number): T[][]  =>  {
  const result: T[][] = [];
  arr = Array.isArray(arr) ? arr : [];
  for (let j = 0; j < arr.length; j += chunkCounter) {
    result.push(arr.slice(j, j+ chunkCounter));
  }
  return result;
}


export const  calculateAge = (birthday: Date) => { // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export const formatDateDDMMYYYY = (date: Date) => {
  const d = new Date(date),
    year = d.getFullYear();

  let day = '' + d.getDate(),
    month = '' + (d.getMonth() + 1);

    if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month, year].join('/');
}
