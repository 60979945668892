import React, { useState } from 'react';
import { translate } from '../../selectors/translations';
import { PatientProfileState } from '../../store/PatientProfile/types';
import { Redirect } from 'react-router-dom';
import {openModalWindow} from "../../store/ModalWindowData/action";
import {useDispatch, useSelector} from "react-redux";
import {getLoginCodeSelector} from "../../selectors/patientProfile";
import '../../assets/scss/utils/_grid.scss'
import { dateToAge } from "../../utils/datetime";

interface PatientStatusOption {
  title: string;
  propToPropagate: string;
  isSelected: boolean;
}

interface PatientStatusOptions {
  [key: string]: PatientStatusOption;
}

type PropsFromState = {
  patientInfo: PatientProfileState;
  pathname: string;
  onDownloadPDF: () => void;
}

export const informationOptions: PatientStatusOptions = {
  // -eslint-disable-next-line @typescript-eslint/camelcase
  information_gathering: {
    title: 'patientInfo.patientStatus.informationGathering',
    propToPropagate: 'information_gathering',
    isSelected: false
  },
  // -eslint-disable-next-line @typescript-eslint/camelcase
  complementary_info: {
    title: 'patientInfo.patientStatus.complementaryInfo',
    propToPropagate: 'complementary_info',
    isSelected: false
  },
  // -eslint-disable-next-line @typescript-eslint/camelcase
  results_not_reviewed: {
    title: 'patientInfo.patientStatus.resultsNotReviewed',
    propToPropagate: 'results_not_reviewed',
    isSelected: false
  },
  // -eslint-disable-next-line @typescript-eslint/camelcase
  results_reviewed: {
    title: 'patientInfo.patientStatus.reviewedResults',
    propToPropagate: 'results_reviewed',
    isSelected: false
  },
  // -eslint-disable-next-line @typescript-eslint/camelcase
  prel_asessment: {
    title: 'patientInfo.patientStatus.prelAssessment',
    propToPropagate: 'prel_asessment',
    isSelected: false
  },
  // -eslint-disable-next-line @typescript-eslint/camelcase
  asessment_rewieved: {
    title: 'patientInfo.patientStatus.assessmentReviewed',
    propToPropagate: 'asessment_rewieved',
    isSelected: false
  },
  // -eslint-disable-next-line @typescript-eslint/camelcase
  report_generated: {
    title: 'patientInfo.patientStatus.reportGenerated',
    propToPropagate: 'report_generated',
    isSelected: false
  }
};

const PatientSideInfo: React.FC<PropsFromState> = (props: PropsFromState) => {
  const [ toEditFrom, setToEditForm] = useState(false);
  const dispatch = useDispatch();
  const { code } = useSelector(getLoginCodeSelector);
  const { patientInfo, pathname, onDownloadPDF } = props;
  const requestFamilySurvey = (): void => {
    dispatch(openModalWindow('requestFamilySurvey', { patient: patientInfo }));
  };
  return (
      <div className="p-relative">
        <div className="diagnostic_card card-box shadow">
          <div className="user_personal-info">
            <div className="user-info flex flex-container align-end pb-5">
              <div className="flex flex-item item--12 pb-10 flex--wrap">
                <div className="m-0 t_size_16 t_left pb-10 pr-10">{`${patientInfo.firstName} ${patientInfo.lastName}`}
                <p className="light">{translate(patientInfo.gender)}, {`${dateToAge(patientInfo.birthDate)} ${translate('years')}` || ''} </p>
                {
                  patientInfo.personalNumber && (<p className="light">{patientInfo.personalNumber}</p>)

                }
                </div>
                <h3 className='ml-auto'>{translate('loginCode.title')}: <span className='t_bold t_uppercase ml-auto'>{code} </span></h3>
              </div>
              <div className=" flex-item item--12 pb-10 p-20 w-100">
              <button
                    className="g_btn g_btn--outlined w-100"
                    type="button"
                    onClick={(): void => setToEditForm(true)}>
                  {
                    toEditFrom
                        ? <Redirect to={{pathname: `/patient/edit/${patientInfo._id}`, state: {from: pathname}}}/>
                        : translate('editPatientDetails')
                  }
                </button>
                </div>
                <div className="twoBtnDiv w-100">
                <button
                    className="g_btn g_btn--outlined w-100"
                    type="button"
                    onClick={onDownloadPDF}>
                  {translate('pdfReport')}
                </button>
                                <button
                    className="g_btn g_btn--outlined w-100"
                    type="button"
                    onClick={requestFamilySurvey}>

                  {translate('requestFamilySurvey')}
                </button>
                </div>
            </div>
          </div>
        </div>

    </div>
  );
}

export default PatientSideInfo;
