import { PatientFilters } from "../store/Patients/types";
import { apiV2, wrapResponse } from "./repository";
import { CreateUserByAdminDto, UpdateUserByAdminDto } from "../sdk";

export const PatientRepository = {
  async find(filters: PatientFilters, page: number) {
    // TODO remove mapping
    return wrapResponse(apiV2.userAdmin.findPatientsByAdmin({
      page,
      filter: {
        ...filters,
        ageMin: +filters.ageMin,
        ageMax: +filters.ageMax,
        // @ts-ignore
        gender: filters.gender == 'all' ? undefined : filters.gender,
        // @ts-ignore
        language: filters.language == 'all' ? undefined : filters.language
      }
    }))
  },

  getArchived() {
    return wrapResponse(apiV2.userAdmin.getArchivedPatients())
  },

  async update(id: string, changedFields: UpdateUserByAdminDto) {
    const birthDate = changedFields.birthDate && new Date(changedFields.birthDate).toISOString()

    return wrapResponse(apiV2.userAdmin.updatePatient(id, {
      ...changedFields,
      birthDate
    }))
  },

  async create(fields: CreateUserByAdminDto) {
    return wrapResponse(apiV2.userAdmin.createPatient(fields))
  },

  async archive(id: string) {
    return wrapResponse(apiV2.userAdmin.archivePatient(id))
  },

  async delete(id: string) {
    return wrapResponse(apiV2.userAdmin.deleteArchivedPatient(id))
  },

  async restore(id: string) {
    return wrapResponse(apiV2.userAdmin.restoreAchievedPatient(id))
  },
}
