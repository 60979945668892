import { PatientsActionTypes, PatientsActionType, ResponsePayload, FilterChangedData, PatientFilters } from './types';
import { UserDto } from "../../sdk";

export const fetchRequest = (page: number, filter?: PatientFilters): PatientsActionType => ({
  type: PatientsActionTypes.GET_PATIENTS_REQUEST,
  page,
  filter
});
export const fetchError = (error: string): PatientsActionType => ({
  type: PatientsActionTypes.GET_PATIENTS_FAILURE,
  errorMsg: error
});
export const fetchSuccess = (data: ResponsePayload): PatientsActionType => ({type: PatientsActionTypes.GET_PATIENTS_SUCCESS, patients: data});

export const filterChanged = (data: FilterChangedData): PatientsActionType => ({type: PatientsActionTypes.FILTER_CHANGED, filterData: data});

export const removeRequest = (data: string): PatientsActionType => ({type: PatientsActionTypes.REMOVE_PATIENT_REQUEST, patientId: data});
export const removeSuccess = (data: {_id: string; status: boolean}): PatientsActionType => ({type: PatientsActionTypes.REMOVE_PATIENT_SUCCESS, data: data});
export const removeFailure = (error: string): PatientsActionType => ({type: PatientsActionTypes.REMOVE_PATIENT_FAILURE, errorMsg: error});
export const getDeletedPatients = (): PatientsActionType => ({type: PatientsActionTypes.GET_DELETED_PATIENTS_REQUEST});
export const getDeletedPatientsSuccess = (data: {patients: UserDto[]}): PatientsActionType => ({type: PatientsActionTypes.GET_DELETED_PATIENTS_SUCCESS, data});
export const getDeletedPatientsFailure = (error: string): PatientsActionType => ({type: PatientsActionTypes.GET_DELETED_PATIENTS_FAILURE, errorMsg: error});

export const restorePatient = (id: string): PatientsActionType => ({type: PatientsActionTypes.RESTORE_PATIENT_REQUEST, id});
export const restorePatientSuccess = (data: {_id: string; status: boolean}): PatientsActionType => ({type: PatientsActionTypes.RESTORE_PATIENT_SUCCESS, data});
export const restorePatientFailure = (error: string): PatientsActionType => ({type: PatientsActionTypes.RESTORE_PATIENT_FAILURE, errorMsg: error});

export const deletePatient = (id: string): PatientsActionType => ({type: PatientsActionTypes.DELETE_PATIENT_REQUEST, id});
export const deletePatientSuccess = (data: {_id: string; status: boolean}): PatientsActionType => ({type: PatientsActionTypes.DELETE_PATIENT_SUCCESS, data});
export const deletePatientFailure = (error: string): PatientsActionType => ({type: PatientsActionTypes.DELETE_PATIENT_FAILURE, errorMsg: error});

export const resetPatientsState = (): PatientsActionType => ({type: PatientsActionTypes.RESET_PATIENTS_STATE});

export const getBBPReport = (reportType: string): PatientsActionType => ({type: PatientsActionTypes.GET_PATIENTS_BBP_REPORT_REQUEST, payload: {type: reportType}});
export const getBBPReportSuccess = (data: any): PatientsActionType => ({type: PatientsActionTypes.GET_PATIENTS_BBP_REPORT_SUCCESS, response: data});
export const getBBPReportFailure = (error: string): PatientsActionType => ({type: PatientsActionTypes.GET_PATIENTS_BBP_REPORT_FAILURE, errMsg: error});

export const getViewedPatients = (): PatientsActionType => ({type: PatientsActionTypes.GET_VIEWED_PATIENTS});

export const getViewedPatientsSuccess = (payload: any): PatientsActionType => ({type: PatientsActionTypes.GET_VIEWED_PATIENTS_SUCCESS, payload});
