import { MedStaffDto } from "../../sdk";

export enum AccountActionTypes {
  GET_ACCOUNTS_REQUEST = '@@accounts/GET_ACCOUNTS_REQUEST',
  GET_ACCOUNTS_SUCCESS = '@@accounts/GET_ACCOUNTS_SUCCESS',
  GET_ACCOUNTS_FAILURE = '@@accounts/GET_ACCOUNTS_FAILURE',

  CREATE_ACCOUNT_REQUEST = '@@accounts/CREATE_ACCOUNT_REQUEST',
  CREATE_ACCOUNT_SUCCESS = '@@accounts/CREATE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_FAILURE = '@@accounts/CREATE_ACCOUNT_FAILURE',

  UPD_ACCOUNT_REQUEST = '@@accounts/UPD_ACCOUNT_REQUEST',
  UPD_ACCOUNT_SUCCESS = '@@accounts/UPD_ACCOUNT_SUCCESS',
  UPD_ACCOUNT_FAILURE = '@@accounts/UPD_ACCOUNT_FAILURE',

  DELETE_ACCOUNT_REQUEST = '@@accounts/DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS = '@@accounts/DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILURE = '@@accounts/DELETE_ACCOUNT_FAILURE',

  REMOVE_ACCOUNT_REQUEST = '@@accounts/REMOVE_ACCOUNT_REQUEST',
  REMOVE_ACCOUNT_SUCCESS = '@@accounts/REMOVE_ACCOUNT_SUCCESS',
  REMOVE_ACCOUNT_FAILURE = '@@accounts/REMOVE_ACCOUNT_FAILURE',

  RESTORE_ACCOUNT_REQUEST = '@@accounts/RESTORE_ACCOUNT_REQUEST',
  RESTORE_ACCOUNT_SUCCESS = '@@accounts/RESTORE_ACCOUNT_SUCCESS',
  RESTORE_ACCOUNT_FAILURE = '@@accounts/RESTORE_ACCOUNT_FAILURE',

  GET_ACCOUNT_REQUEST = '@@accounts/GET_ACCOUNT_REQUEST',
  GET_ACCOUNT_SUCCESS = '@@accounts/GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_FAILURE = '@@accounts/GET_ACCOUNT_FAILURE',

  GET_DELETED_ACCOUNTS_REQUEST = '@@accounts/GET_DELETED_ACCOUNTS_REQUEST',
  GET_DELETED_ACCOUNTS_SUCCESS = '@@accounts/GET_DELETED_ACCOUNTS_SUCCESS',
  GET_DELETED_ACCOUNTS_FAILURE = '@@accounts/GET_DELETED_ACCOUNTS_FAILURE',

  FILTER_CHANGED = '@@accounts/FILTER_CHANGED',

  UNSET_ACCOUNT_PROFILE = '@@acounts/UNSET_ACCOUNT_PROFILE',

  RESET_ACCOUNTS_STATE = '@@accounts/RESET_ACCOUNTS_STATE'
}

export interface AccountFilters {
  searchTerm: string;
  sort: SortChangedData;
}

export interface AccountsState {
  all: MedStaffDto[];
  accountInfo: MedStaffDto;
  filters: AccountFilters;
  errorMsg: string;
  successMsg: string;
}

export interface AccountAction {
  action: string;
  _id: string;
}

export interface FilterChangedData {
  [key: string]: string | SortChangedData;
}

export interface SortChangedData {
  field: string;
  direction: boolean;
}

interface GetAccountsRequest {
  type: typeof AccountActionTypes.GET_ACCOUNTS_REQUEST;
}
interface GetAccountsFailure {
  type: typeof AccountActionTypes.GET_ACCOUNTS_FAILURE;
  errorMsg: string;
}
interface GetAccountsSuccess {
  type: typeof AccountActionTypes.GET_ACCOUNTS_SUCCESS;
  accounts: MedStaffDto[];
}

interface FilterChanged {
  type: typeof AccountActionTypes.FILTER_CHANGED;
  filterData: FilterChangedData;
}

interface DeleteAccountRequest {
  type: typeof AccountActionTypes.DELETE_ACCOUNT_REQUEST;
  id: string;
}
interface DeleteAccountSuccess {
  type: typeof AccountActionTypes.DELETE_ACCOUNT_SUCCESS;
  data: {
    _id: string;
  };
}

interface DeleteAccountFailure {
  type: typeof AccountActionTypes.DELETE_ACCOUNT_FAILURE;
  errorMsg: string;
}

interface UpdateAccountRequest {
  type: typeof AccountActionTypes.UPD_ACCOUNT_REQUEST;
  data: MedStaffDto;
}

interface UpdateAccountSuccess {
  type: typeof AccountActionTypes.UPD_ACCOUNT_SUCCESS;
  account: MedStaffDto;
}

interface UpdateAccountFailure {
  type: typeof AccountActionTypes.UPD_ACCOUNT_FAILURE;
  errorMsg: string;
}

interface CreateAccountRequest {
  type: typeof AccountActionTypes.CREATE_ACCOUNT_REQUEST;
  data: MedStaffDto;
}

interface CreateAccountSuccess {
  type: typeof AccountActionTypes.CREATE_ACCOUNT_SUCCESS;
  data: MedStaffDto;
}

interface CreateAccountFailure {
  type: typeof AccountActionTypes.CREATE_ACCOUNT_FAILURE;
  errorMsg: string;
}

export interface GetAccountRequest {
  type: typeof AccountActionTypes.GET_ACCOUNT_REQUEST;
  id: string;
}

export interface GetAccountSuccess {
  type: typeof AccountActionTypes.GET_ACCOUNT_SUCCESS;
  response: MedStaffDto;
}

export interface GetAccountFailure {
  type: typeof AccountActionTypes.GET_ACCOUNT_FAILURE;
  errMsg: string;
}

interface UnsetAccountsProfile {
  type: typeof AccountActionTypes.UNSET_ACCOUNT_PROFILE;
}

interface RemoveAccountSucces {
  type: typeof AccountActionTypes.REMOVE_ACCOUNT_SUCCESS;
  data: {
    _id: string;
  };
}

interface RemoveAccountFailure {
  type: typeof AccountActionTypes.REMOVE_ACCOUNT_FAILURE;
  errorMsg: string;
}

export interface RemoveAccountRequest {
  type: typeof AccountActionTypes.REMOVE_ACCOUNT_REQUEST;
  id: string;
}

interface RestoreAccountSucces {
  type: typeof AccountActionTypes.RESTORE_ACCOUNT_SUCCESS;
  data: {
    _id: string;
  };
}

interface RestoreAccountFailure {
  type: typeof AccountActionTypes.RESTORE_ACCOUNT_FAILURE;
  errorMsg: string;
}

interface RestoreAccountRequest {
  type: typeof AccountActionTypes.RESTORE_ACCOUNT_REQUEST;
  id: string;
}

interface ResetAccountsState {
  type: typeof AccountActionTypes.RESET_ACCOUNTS_STATE;
}

interface GetDeletedAccountsRequest {
  type: typeof AccountActionTypes.GET_DELETED_ACCOUNTS_REQUEST;
}

interface GetDeletedAccountsSuccess {
  type: typeof AccountActionTypes.GET_DELETED_ACCOUNTS_SUCCESS;
  data: MedStaffDto[];
}

interface GetDeletedAccountsFailure {
  type: typeof AccountActionTypes.GET_DELETED_ACCOUNTS_FAILURE;
  errorMsg: string;
}

export type AccountsActionType =
  GetAccountsRequest |
  GetAccountsFailure |
  GetAccountsSuccess |
  FilterChanged |
  DeleteAccountRequest |
  DeleteAccountSuccess |
  CreateAccountRequest |
  CreateAccountSuccess |
  UpdateAccountRequest |
  UpdateAccountSuccess |
  GetAccountRequest |
  GetAccountSuccess |
  GetAccountFailure |
  CreateAccountFailure |
  UpdateAccountFailure |
  DeleteAccountFailure |
  ResetAccountsState |
  RemoveAccountFailure |
  RemoveAccountSucces |
  RemoveAccountRequest |
  RestoreAccountFailure |
  RestoreAccountSucces |
  RestoreAccountRequest |
  GetDeletedAccountsRequest |
  GetDeletedAccountsSuccess |
  GetDeletedAccountsFailure |
  UnsetAccountsProfile;
