/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ScreenType {
    SECTION_TITLE = 'section-title',
    QUESTION = 'question',
    UNIQUE = 'unique',
}
