import React from "react";
import {translate} from "../../selectors/translations";

interface OwnProps {
    roles: Array<string>;
}

const AccountRoles: React.FC<OwnProps> = (props: OwnProps) => {
    const roles = Array.isArray(props.roles) ? props.roles : [];
    return (
        <>
            { roles.map((role: string): string => translate(role)).join(', ') }
        </>
    );
};

export default AccountRoles;
