import {PatientsActionType, PatientsActionTypes, PatientsState} from './types';

const initialState: PatientsState = {
  all: [],
  totalUsersCount: 0,
  errorMsg: '',
  successMsg: '',
  filters: {
    searchTerm: '',
    displayedType: 'all',
    gender: 'all',
    language: 'all',
    sort: {
      field: 'createdAt',
      direction: false
    },
    ageMin: '0',
    ageMax: '150',
    scoreMin: '0',
    scoreMax: '59',
    institution: '',
  },
  userToDelete: '',
  selectViewedPatients: []
};

export function PatientReducer(
  state = initialState,
  action: PatientsActionType
): PatientsState {
  switch (action.type) {
    case PatientsActionTypes.GET_PATIENTS_FAILURE:
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    case PatientsActionTypes.GET_PATIENTS_SUCCESS:
      return {
        ...state,
        all: action.patients.patients,
        totalUsersCount: action.patients.totalUsersCount,
        errorMsg: ''
      }
    case PatientsActionTypes.GET_VIEWED_PATIENTS_SUCCESS:
      return {
        ...state,
        selectViewedPatients: action.payload,
      }
    case PatientsActionTypes.FILTER_CHANGED:
      return {
        ...state,
        ...{ filters: { ...state.filters, ...action.filterData } }
      }
    case PatientsActionTypes.REMOVE_PATIENT_REQUEST:
      return { ...state }
    case PatientsActionTypes.REMOVE_PATIENT_SUCCESS:
      return {
        ...state,
        all: [...state.all.filter(p => p._id !== action.data._id)],
      }
    case PatientsActionTypes.GET_DELETED_PATIENTS_SUCCESS:
      return {
        ...state,
        all: action.data.patients
      }
    case PatientsActionTypes.RESTORE_PATIENT_SUCCESS:
      return {
        ...state,
        all: state.all.filter((p) => p._id !== action.data._id),
      }
    case PatientsActionTypes.RESET_PATIENTS_STATE:
      return {
        ...state,
        all: [],
        totalUsersCount: 0,
        filters: { ...initialState.filters }
      }
    case PatientsActionTypes.DELETE_PATIENT_SUCCESS:
      return {
        ...state,
        all: state.all.filter((p) => p._id !== action.data._id),
      }
    default:
      return state;
  }
}
