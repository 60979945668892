import React, { useState } from "react";
import { ModalWindowAction } from "../../store/ModalWindowData/types";
import { ReactComponent as CheckboxIcon } from "../../assets/img/icons/check-mark.svg";
import { useSelector } from "react-redux";
import { getQuestioners } from "../../selectors/patientProfile";
import useTranslate from "../../hooks/useTranslate";
import { translate } from "../../selectors/translations";
import { QuestionerType } from "../../sdk";

type PropsFromState = {
    onConfirm: (data: ModalWindowAction<any>) => void;
}

enum AppLanguage {
    English = 'en',
    Swedish = 'sv',
}

const formatDay = (date: Date): string => {
    let day: string
    if (date.getDate() < 10) {
        day = '0' + date.getDate()
    } else {
        day = date.getDate().toString()
    }
    let month: string
    if (date.getMonth() < 10) {
        month = '0' + date.getMonth()
    } else {
        month = date.getMonth().toString()
    }
    return `${date.getFullYear()}-${month}-${day}`
}

export type ExportUserAnswersFilters = {
    startDate: string,
    endDate: string,
    questionerType: QuestionerType,
    language: AppLanguage,
    latest: boolean,
    completedOnly: boolean,
    section: string[]
}

const downloadFile = (params: ExportUserAnswersFilters) => {
    const baseUrl = process.env.REACT_APP_NEW_API_URL;
    const paramsString = new URLSearchParams(params as Record<string, any>).toString()
    const url = `${baseUrl}/questioner-answer/export?${paramsString}`

    window.open(url, '_blank');
}

export const UserAnswersExportPopup = (props: PropsFromState) => {
    const questioners = useSelector(getQuestioners);
    const translateLocalazy = useTranslate();

    const { onConfirm } = props;
    const [params, setParams] = useState({
        startDate: formatDay(new Date()),
        endDate: formatDay(new Date()),
        questionerType: QuestionerType.ANAMNESIS,
        language: AppLanguage.Swedish,
        latest: false,
        completedOnly: false,
        section: questioners.find(e => e.kind == QuestionerType.ANAMNESIS)!.sections.map(e => e.slug)
    });

    const handleSuccessClick = () => {
        downloadFile(params);
    };

    const handleCancelClick = () => {
        onConfirm({ action: 'cancel', data: null });
    };
    const toggleLatest = () => setParams({ ...params, latest: !params.latest })
    const toggleCompleted = () => setParams({ ...params, completedOnly: !params.completedOnly })
    const changeType = (questionerType: QuestionerType) => setParams({
        ...params,
        questionerType,
        section: questioners.find(e => e.kind == questionerType)!.sections.map(e => e.slug)
    })
    const changeLanguage = (language: AppLanguage) => setParams({ ...params, language })
    const changeStartDate = (startDate: string) => setParams({ ...params, startDate })
    const changeEndDate = (endDate: string) => setParams({ ...params, endDate })
    const toggleSection = (section: string) => {
        if (params.section.includes(section)) {
            setParams({ ...params, section: params.section.filter(e => e != section) })
        } else {
            setParams({ ...params, section: [...params.section, section] })
        }
    }

    return (
        <>
            <div className="ph-10 pb-20">
                <div className="flex align-center margin-me--btm" style={{ justifyContent: 'space-between' }}>
                    <div>
                        <label>Start date </label>
                        <input type="date" value={params.startDate} onChange={e => changeStartDate(e.target.value)}
                               style={{ width: 'auto' }}></input>
                    </div>
                    <div>
                        <label>End date </label>
                        <input type="date" value={params.endDate} onChange={e => changeEndDate(e.target.value)}
                               style={{ width: 'auto' }}></input>
                    </div>
                </div>
                <div className="flex align-center margin-me--btm" style={{ justifyContent: 'space-between' }}>
                    <div className="flex">
                        <div className="custom-checkbox_wrapper margin-right flex-item--shrink">
                            <input
                                checked={params.completedOnly}
                                onChange={toggleCompleted}
                                className="hidden-checkbox"
                                type="checkbox"/>
                            <div className="pseudo-checkbox_holder"></div>
                            <CheckboxIcon className="pseudo-checkbox_icon"/>
                        </div>
                        <span
                            style={{ cursor: 'pointer' }}
                            className="flex-item--shrink"
                            onClick={toggleCompleted}>
                              Completed only
                          </span>
                    </div>
                    <div className="flex">
                        <div className="custom-checkbox_wrapper margin-right flex-item--shrink">
                            <input
                                checked={params.latest}
                                onChange={toggleLatest}
                                className="hidden-checkbox"
                                type="checkbox"/>
                            <div className="pseudo-checkbox_holder"></div>
                            <CheckboxIcon className="pseudo-checkbox_icon"/>
                        </div>
                        <span
                            style={{ cursor: 'pointer' }}
                            className="flex-item--shrink"
                            onClick={toggleLatest}>
                              Latest only
                          </span>
                    </div>
                </div>
                <div className="flex align-center margin-me--btm" style={{ justifyContent: 'space-between' }}>
                    <div>
                        <label>Type </label>
                        <select onChange={e => changeType(e.target.value as QuestionerType)}
                                value={params.questionerType}
                                style={{ width: 'auto' }}
                                name="type">
                            {Object.values(QuestionerType).map((g, i) => <option key={i}
                                                                                 value={g}>{translate(g)}</option>)}
                        </select>
                    </div>
                    <div>
                        <label>Language </label>
                        <select onChange={e => changeLanguage(e.target.value as AppLanguage)}
                                value={params.language}
                                style={{ width: 'auto' }}
                                name="language">
                            {Object.values(AppLanguage).map((g, i) => <option key={i}
                                                                              value={g}>{translate(g)}</option>)}
                        </select>
                    </div>
                </div>
                <div className="margin-me--btm">
                    {questioners.find(e => e.kind == params.questionerType)!
                        .sections.map(e => e.slug)
                        .map(e => <div className="flex align-center margin-me--btm" key={e}>
                            <div className="custom-checkbox_wrapper margin-right flex-item--shrink">
                                <input
                                    checked={params.section.includes(e)}
                                    onChange={() => toggleSection(e)}
                                    className="hidden-checkbox"
                                    type="checkbox"/>
                                <div className="pseudo-checkbox_holder"></div>
                                <CheckboxIcon className="pseudo-checkbox_icon"/>
                            </div>
                            <span
                                style={{ cursor: 'pointer' }}
                                className="flex-item--shrink"
                                onClick={() => toggleSection(e)}>
                              {translateLocalazy(`questioner.${params.questionerType}.section.${e}`)}
                          </span>
                        </div>)}
                </div>

                <div className="pl-20 flex" style={{ justifyContent: 'space-between' }}>
                    <button
                        onClick={handleSuccessClick}
                        className="confirmation_btn g_btn confirmation_btn--yes"
                        type="button">{translate('downloadReportModalBtn')}</button>
                    <button
                        className="confirmation_btn g_btn confirmation_btn--cancel"
                        type="button"
                        onClick={handleCancelClick}>{translate('cancel')}</button>
                </div>
            </div>
        </>
    );
}
