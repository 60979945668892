import { QuestionerAnswersDTOOutput, QuestionerType } from "../../sdk";

export type QuestionerAnswerWithSummarization = QuestionerAnswersDTOOutput & { sumOfPoints: number, sumOfTime: number }

export function sumOfPoints(cognitive: QuestionerAnswersDTOOutput) {
  if (cognitive.questioner.kind !== QuestionerType.COGNITIVE) {
    return 0
  }

  return cognitive.answers.reduce((totalPoints, obj) => {
    if (obj.points && obj.points.length > 0) {
      let sum = 0;
      obj.points.forEach(num => {
        sum += num;
      })
      totalPoints += sum;
    }
    return totalPoints;
  }, 0)
}

export function sumOfTime(cognitive: QuestionerAnswersDTOOutput) {
  if (cognitive.questioner.kind !== QuestionerType.COGNITIVE) {
    return 0
  }

  return cognitive.answers.reduce((totalPoints, obj) => {
    if (obj.timeUsed && obj.timeUsed.length > 0) {
      let sum = 0;
      obj.timeUsed.forEach(num => {
        sum += num;
      })
      totalPoints += sum;
    }
    return totalPoints;
  }, 0)
}
