import React, { useEffect } from 'react';
import { Store } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux'
import './assets/scss/app.scss';
import { ApplicationState } from './store';
import Sidebar from './components/Sidebar';
import Routes from './routes';
import ModalWindowGuard from './hoc/ModalWindowGuard';
import NotificationComponent from './components/Notifications';
import { fetchTranslations } from "./store/Translations/actions"
import { datadogRum } from "@datadog/browser-rum"
import { datadogLogs } from "@datadog/browser-logs";
interface MainProps {
  store: Store<ApplicationState>;
}

const logoutInterval = 60 * 60 * 1000;

const logout = (): void => {
  localStorage.removeItem('token');
  window.location.reload();
}

const resetTimer = (timerObj: {t: NodeJS.Timeout}): void => {
  clearTimeout(timerObj.t);
  timerObj.t = setTimeout(logout, logoutInterval)
}
const TranslationsFetcher = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTranslations())
  }, [])
  return <></>
}

// eslint-disable-next-line react/prop-types
const App: React.FC<MainProps> = ({ store }) => {
  const timerObj = {t: setTimeout(logout, logoutInterval)}
  useEffect(() => {
    window.onload = resetTimer.bind(null, timerObj);
    window.onmousemove = resetTimer.bind(null, timerObj);
    window.onmousedown = resetTimer.bind(null, timerObj);
    window.ontouchstart = resetTimer.bind(null, timerObj);
    window.onclick = resetTimer.bind(null, timerObj);
    window.onkeypress = resetTimer.bind(null, timerObj);
    window.addEventListener('scroll', resetTimer.bind(null, timerObj), true);
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'sv');
    }
  })
  useEffect(() => {
    if (process.env.REACT_APP_ENV) {
      datadogRum.init({
        applicationId: '8ec89524-8ab1-4e55-81c9-af8d04d684b9',
        clientToken: 'pub233cf8790d5c06139217ef6b0b96a266',
        site: 'datadoghq.eu',
        service: 'hcp-react',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        env: process.env.REACT_APP_ENV
      })

      datadogLogs.init({
        clientToken: 'pub233cf8790d5c06139217ef6b0b96a266',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        site: 'datadoghq.eu',
        env: process.env.REACT_APP_ENV,
        service: 'hcp-react',
        useSecureSessionCookie: true,
      })

    }
  }, [])
  return (
    <Provider store={store}>
      <TranslationsFetcher/>
      <Router>
        <div className="wrapper">
          <Sidebar></Sidebar>
          <Routes />
          <ModalWindowGuard />
          <NotificationComponent />
        </div>
      </Router>
    </Provider >
  );
}

export default App;
