import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { languageInUse, translate } from '../../selectors/translations'
import { ReactComponent as CloseIcon } from '../../assets/img/icons/close-icon.svg'
import { openModalWindow } from '../../store/ModalWindowData/action'
import { ReactComponent as GroupIcon } from '../../assets/img/icons/group.svg'
import { ReactComponent as MessageIcon } from '../../assets/img/icons/message.svg'
import CustomRadioButton from "../CustomRadioButton"
import TooltipIconComponent from '../TooltipIconComponent'
import { userInstitutionSelector } from "../../selectors/auth"
import { getPatientState } from "../../selectors/patientProfile"
import { isMessage } from "../../pages/patients/util"
import { institutionName } from "../../selectors/institutions"
import { smsTemplatesSelector } from "../../selectors/patients"
import { AppLanguage } from "../../sdk"
import useTranslate from "../../hooks/useTranslate"
import { formatDate } from "../../utils/datetime"

type PhoneSettingsProps = {
  phoneNumber?: string;
  currentLanguage?: string;
  onSubmit: (textMessageType: string) => void;
}

type InstitutionSettingsProps = {
  sharedInstitution: string[];
  onSubmit: (data: any) => void;
  patientId: string;
}

export const InstitutionSettings: React.FC<InstitutionSettingsProps> = (props: InstitutionSettingsProps): React.ReactElement => {
  const {sharedInstitution, onSubmit, patientId} = props
  const [careTaker, setCareTaker] = useState('')
  const dispatch = useDispatch()

  const handleSubmit = (): void => {
    if (!careTaker) {
      return
    }
    dispatch(openModalWindow('institutionConsent', {
      _id: patientId,
      sharedInstitution: [...sharedInstitution, careTaker]
    }))
    setCareTaker('')
  }

  return (
    <div className="card-box">
      <div className="box-title">
        <i className="icon-holder patients-detail_icon-holder"><GroupIcon/></i>
        <h3>{translate('referrals')}</h3>
      </div>
      <div className="card_inputs-box">
        <div className="input-holder flex-item">
          <label>{translate('Add care provider for referral / consultation')}</label>
          <div className="flex flex--wrap justify-between">
            <div className="item--12 item-md-7">
              <input type="text"
                     onChange={(e: SyntheticEvent): void => {
                       e.persist()
                       setCareTaker((e.target as HTMLInputElement).value)
                     }}
                     value={careTaker}
                     placeholder={translate('Type here...')}/>
            </div>
            <div className="pt-20 pt-md-0">
              {careTaker && <button
                  onClick={handleSubmit}
                  className="t_uppercase g_btn g_btn--primary"
                  type="button">{translate('Send')}</button>}
              {!careTaker && <button
                  className="t_uppercase g_btn g_btn--disabled"
                  type="button">{translate('Send')}</button>}
            </div>
          </div>
        </div>
        <div className="input-holder flex-item">
          <div className="flex flex--wrap">
            {
              sharedInstitution.length
                ? sharedInstitution.map((id: string, i: number) =>
                  <div key={i}>
                    <div className="optional-button">
                      <span>{institutionName(id)}</span>
                      <CloseIcon
                        onClick={(): void => onSubmit({sharedInstitution: sharedInstitution.filter((c: string, idx: number) => i !== idx)})}/>
                    </div>
                  </div>)
                : <span>{translate('no-referrals-currently')}</span>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export const PhoneSettings: React.FC<PhoneSettingsProps> = (props: PhoneSettingsProps): React.ReactElement => {
  const {phoneNumber, onSubmit} = props
  const translateLocalazy = useTranslate()
  const currentLanguage = useSelector(languageInUse)
  const [selectedType, setSelectedType] = useState<string>()
  const userInstitution = useSelector(userInstitutionSelector)
  const patientProfile = useSelector(getPatientState)
  const messageHistory = patientProfile.interactionHistory.filter(isMessage)
  const textMessagesToPatient = useSelector(smsTemplatesSelector)
  const lastSentMessage = (messageHistory.length && messageHistory[messageHistory.length - 1]) || null

  const textMessagesToShow = useMemo(() => {
    if (!phoneNumber) {
      return []
    }
    return textMessagesToPatient.filter((item) => {
      return (item.visibleForInstitutions.includes(userInstitution) && !item.internal)
    })
  }, [userInstitution, phoneNumber])

  const disableSubmit = !phoneNumber || !selectedType

  const handleSubmit = useCallback(() => {
    if (disableSubmit) {
      return
    }
    onSubmit(selectedType)
  }, [onSubmit, disableSubmit, selectedType])
  const smsTemplates = useSelector(smsTemplatesSelector)
  const lastSentMessageTitle = translateLocalazy(
    smsTemplates.find(e => e._id === lastSentMessage?.metadata.messageTemplate)?.titleKey
    || lastSentMessage?.metadata.messageTemplate || ''
  )

  return (
    <div className="card-box flex flex-column">
      <div className="">
        <div className="box-title">
          <i className="icon-holder patients-detail_icon-holder"><MessageIcon/></i>
          <h3>{translate('patientPhoneNumber.title')}</h3>
        </div>
        {!phoneNumber &&
            <div className="flex-item">{translate('patientPhoneNumber.noMobileNumber')}</div>
        }
        {phoneNumber && textMessagesToShow.filter(e => !e.internal).map((item) => (
          <div className="pb-10"
               key={item._id}>
            <div className="flex align-center">
              <div className="flex-item--shrink pr-10">
                <CustomRadioButton
                  isSelected={selectedType === item._id}
                  onClick={() => setSelectedType(item._id)}
                />
              </div>
              <p className="flex-item--shrink m-0 pr-10"
                 onClick={() => setSelectedType(item._id)}>{translateLocalazy(item.titleKey)}</p>
              {item.translations &&
                  <div className="flex-item--shrink">
                      <TooltipIconComponent
                          translateX={-19}
                          translateY={18}
                          htmlMessage={item.translations[currentLanguage || AppLanguage.EN]}/>
                  </div>
              }
            </div>
          </div>
        ))
        }
      </div>
      <div className="flex-column t_right lastSentTextMessageHolder">
        {phoneNumber &&
            <div className="btns-holder pt-10">
              {!disableSubmit &&
                  <button onClick={handleSubmit}
                          className="t_uppercase g_btn g_btn--primary"
                          type="button">{translate('Send')}</button>
              }
              {disableSubmit &&
                  <button className="t_uppercase g_btn g_btn--primary g_btn--disabled"
                          type="button">{translate('Send')}</button>
              }
            </div>
        }
        {lastSentMessage &&
            <div className="pt-10">
                <p className="t_bold m-0">{translate('patientPhoneNumber.lastSentTextMessage')}</p>
                <p className="m-0">
                  {lastSentMessageTitle}
                </p>
                <p className="m-0">{formatDate(lastSentMessage.createdAt)}</p>
            </div>
        }
      </div>
    </div>

  )
}
