/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum BankIdAttemptStatus {
    PENDING = 'pending',
    COMPLETE = 'complete',
    FAILED = 'failed',
    ACCOUNT_NOT_FOUND = 'accountNotFound',
    TOO_YOUNG = 'tooYoung',
}
