import React from 'react';
import Toggleable from '../../rc-components/Toggleable';
import GeneralInfo from './GeneralInfo';
import SectionInfo from './SectionInfo';
import { DropdownCb, AnamnesisConfig } from '../../types/Common';
import { Survey, AnamnesisSectionData } from '../../store/PatientProfile/types';
import {setTestAsViewed} from "../../store/PatientProfile/action";
import {useDispatch} from "react-redux";

type SurveyCardProps = {
  survey: Survey;
  surveyConfig: {
    sections: AnamnesisSectionData[];
    questions: { [key: number]: AnamnesisConfig };
  };
  isNewAnamnesis: boolean;
}

const SurveyCard: React.FC<SurveyCardProps> = (props: SurveyCardProps) => {
  const { survey, surveyConfig, isNewAnamnesis } = props;
  const dispatch = useDispatch();
  return (
    <div className="flex-item item-sm-12">
      <div className="card-box diagnostic_test-box">
        <Toggleable defaultValue="survey">
          {
            (dropdownId: string, setDropdownId: DropdownCb): React.ReactElement => (
              <div>
                <GeneralInfo
                  { ...survey }
                  onClick={(): void => {
                    setDropdownId(dropdownId === 'survey' ? '' : 'survey');
                    dispatch(setTestAsViewed({testId: survey._id, sectionId: 0}))
                  }}
                />
                {!dropdownId && <SectionInfo survey={survey} config={surveyConfig} isNewAnamnesis={isNewAnamnesis} />}
              </div>
            )
          }
        </Toggleable>
      </div>
    </div>
  );
}

export default SurveyCard;
