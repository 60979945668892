import React from "react";
import {translate} from "../../../../selectors/translations";
import {NavLink} from "react-router-dom";
import {RouteNames} from "../../../../types/constants";
import './CreatePatientButton.scss';

const CreatePatientButton: React.FC = (): JSX.Element => {
    return (
        <NavLink
            exact
            to={RouteNames.PatientNew}>
            <button
                type='button'
                className='g_btn g_btn--primary create-patient-btn'>
                <span>{translate('startPage.button.createPatient')}</span>
            </button>
        </NavLink>
    );
};

export default CreatePatientButton;
