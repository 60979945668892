import {
  ModalWindowDataTypes,
  ModalWindowDataType
} from './types'
import {
  ModalWindowProps,
  ModalWindowType
} from "../../hoc/ModalWindowGuard/ModalWindowGuard"

export const openModalWindow = <T extends ModalWindowType>(
  type: T,
  data?: ModalWindowProps<T>,
  cb?: () => void,
  questionNumber?: string
): ModalWindowDataType => ({
  type: ModalWindowDataTypes.OPEN_MODAL_WINDOW,
  data,
  modalType: type,
  questionNumber
})
export const closeModalWindow = (): ModalWindowDataType => ({type: ModalWindowDataTypes.CLOSE_MODAL_WINDOW})
