import { all, } from 'redux-saga/effects'
import {
  DeletePatientRequest,
  PatientsActionTypes, RemovePatientRequest, RestorePatientRequest,
} from './types'
import {
  fetchError,
  removeSuccess,
  removeFailure,
  getDeletedPatientsFailure,
  getDeletedPatientsSuccess,
  restorePatientFailure,
  restorePatientSuccess,
  getViewedPatientsSuccess, deletePatientSuccess, deletePatientFailure, fetchSuccess
} from './action'
import { closeModalWindow } from '../ModalWindowData/action';
import { PatientRepository } from "../../repositories/patient.repository";
import { generator } from "../bind-handler";
import { apiV2, wrapResponse } from "../../repositories/repository";

export default function* patientsSaga(): Generator {
  yield all([
    generator(({page, filter}) => PatientRepository.find(filter, page))
      .onSuccess(fetchSuccess)
      .onError(fetchError)
      .bind(PatientsActionTypes.GET_PATIENTS_REQUEST),

    generator((data: RemovePatientRequest) => PatientRepository.archive(data.patientId))
      .onSuccess([removeSuccess, closeModalWindow])
      .onError(removeFailure)
      .bind(PatientsActionTypes.REMOVE_PATIENT_REQUEST),

    generator(PatientRepository.getArchived)
      .onSuccess(getDeletedPatientsSuccess)
      .onError(getDeletedPatientsFailure)
      .bind(PatientsActionTypes.GET_DELETED_PATIENTS_REQUEST),

    generator((data: RestorePatientRequest) => PatientRepository.restore(data.id))
      .onSuccess([restorePatientSuccess, closeModalWindow])
      .onError(restorePatientFailure)
      .bind(PatientsActionTypes.RESTORE_PATIENT_REQUEST),

    generator((data: DeletePatientRequest) => PatientRepository.delete(data.id))
      .onSuccess([deletePatientSuccess, closeModalWindow])
      .onError(deletePatientFailure)
      .bind(PatientsActionTypes.DELETE_PATIENT_REQUEST),

    generator(() => wrapResponse(apiV2.medStaff.getLastViewed()))
      .onSuccess(getViewedPatientsSuccess)
      .onError(fetchError)
      .bind(PatientsActionTypes.GET_VIEWED_PATIENTS),
  ])
}
