import React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux'

import { AccountsActionType, AccountFilters, AccountAction } from '../../store/Accounts/types';
import { fetchRequest, filterChanged } from '../../store/Accounts/action';
import { ApplicationState } from '../../store';
import { getAccounts } from '../../selectors/accounts';
import {getLanguages, translate} from '../../selectors/translations';

import { ReactComponent as SearchIcon } from '../../assets/img/icons/search-icon.svg';

import { openModalWindow } from '../../store/ModalWindowData/action';
import { ModalWindowDataType } from '../../store/ModalWindowData/types';
import { TableHeaders, AccountsTableBody } from '../../components/DataTable/Table';
import {LanguageType} from "../../store/Translations/types";

import greenHills from '../../assets/img/greenHills.svg';
import '../../assets/scss/layouts/greenHills.scss'
import { MedStaffDto } from "../../sdk"
import { ModalWindowType } from "../../hoc/ModalWindowGuard/ModalWindowGuard"

const sortingFields = ['role', 'lastName', 'firstName', 'gender', 'institution', 'country'];

type PropsFromState = {
  accounts: MedStaffDto[];
  filters: AccountFilters;
  successMsg?: string;
  errorMsg?: string;
  authUser: MedStaffDto;
  languages: { key: LanguageType; inUse: boolean }[];
}

type PropsFromDispatch = ReturnType<typeof mapDispatchToProps>

type AllProps = PropsFromDispatch & PropsFromState & RouteComponentProps;

const tableTitles = ['lastName', 'firstName', 'gender', 'institution', 'role'];

class AccountsListClass extends React.Component<AllProps> {
  actionTypes: { [key: string]: (data: string) => void } = {
    'edit': (): void => { },
    'remove': (): void => { },
    'card': (): void => void 0
  }

  constructor(props: AllProps) {
    super(props);
    this.actionTypes.edit = ((id: string): void => this.props.history.push(`/accounts/${id}`)).bind(this)
    this.actionTypes.remove = ((id: string): void => {
      const patient = this.props.accounts.find((a) => a._id === id)!;
      this.props.openModal('remove', { _id: patient._id, firstName: patient.firstName, lastName: patient.lastName, type: 'removeAccount' })
    }).bind(this);
  }

  public componentDidMount(): void {
    this.props.fetchRequest();
  }

  render(): React.ReactElement {
    const { filters, filterChanged, accounts, authUser } = this.props;
    return (
      <div>
        {/* FILTERS BLOCK */}
        <div className="search-box">
          <div className="flex-container justify-between align-start">
            <div className="flex-item item-sm-12 item-lg-7">
              <div className="search-block_wrapper flex">
                <div className="input-holder flex-item--stretch">
                  <div className="icon-holder">
                    <SearchIcon className="search-block_search-icon" />
                  </div>
                  <input
                    className="search-block_input"
                    onChange={(e): void => filterChanged({ searchTerm: e.target.value })}
                    type="text"
                    placeholder={translate('account.search.placeholder')} />
                </div>
              </div>
              <div className="search-block_results-counter hidden-lg-down">
                <span>{translate('search.results')}:&nbsp;</span>
                <span className="t_bold">{accounts.length || 0}&nbsp;</span>
                <span>{translate('accounts')}</span>
              </div>
            </div>
            <div className="search_filters-row flex-item item-sm-12 item-lg-5">
            </div>
          </div>
        </div>

        {/* TABLE BLOCK */}
        <div className="search_table-box">
        <table className="search_patients-table">
          <TableHeaders
            onSortClick={filterChanged}
            columnTitles={tableTitles}
            sortOpts={{
              sortableColumns: sortingFields,
              isAsc: filters.sort.direction,
              filterField: filters.sort.field
            }}
          />
          <AccountsTableBody
            data={accounts}
            actions={['edit', 'remove']}
            propKeys={tableTitles}
            onActionClick={(data: AccountAction): void => this.actionTypes[data.action](data._id)}
            authUser={authUser}
          />
        </table>
        </div>
        <img className="greenHills" src={greenHills}/>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  accounts: getAccounts(state),
  filters: state.accounts.filters,
  successMsg: '',
  errorMsg: '',
  authUser: state.auth.user,
  languages: getLanguages(state)
});

const mapDispatchToProps = (dispatch: (action: AccountsActionType | ModalWindowDataType) => void) => ({
  fetchRequest: (): void => dispatch(fetchRequest()),
  filterChanged: (data): void => dispatch(filterChanged(data)),
  openModal: (type: ModalWindowType, data: any): void => dispatch(openModalWindow(type, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountsListClass);

