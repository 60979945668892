/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum MedStaffRole {
    NURSE = 'nurse',
    ASSISTANT_NURSE = 'assistant_nurse',
    DOCTOR = 'doctor',
    ADMIN = 'admin',
    ADMINISTRATOR = 'administrator',
    THERAPIST = 'therapist',
    NEUROPSYCHOLOGIST = 'neuropsychologist',
    PSYCHOLOGIST = 'psychologist',
    PHYSIOTHERAPIST = 'physiotherapist',
    SPEECH_THERAPIST = 'speech_therapist',
    RADIOLOGIST = 'radiologist',
    ENDOCRINOLOGIST = 'endocrinologist',
    CLINICAL_PHARMACOLOGIST = 'clinical_pharmacologist',
}
