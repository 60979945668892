import React from "react"
import greenHills from '../assets/img/greenHills.svg'
import '../assets/scss/layouts/greenHills.scss'

const ContactsPage: React.FC = () => {
  return (
    <div className="h-80">
      <div className="h-80">
        <div>
          <div className="margin-me--top">
            <p className="m-0">
              <a href="tel:+46107500726">+46 (0)10 - 750 07 26</a>
            </p>
            <p className="m-0">
              <a href="mailto:admin@gerassolutions.com">admin@gerassolutions.com</a>
            </p>
            <h4 className="mt-10 pt-10">
              Geras Solutions AB‍<br/>
              Norrtullsgatan 6<br/>
              113 29 Stockholm<br/>
              Sweden</h4>
          </div>
        </div>
      </div>
      <img className="greenHills" src={greenHills}/>
    </div>
  )
}

export default ContactsPage
