/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserStatus {
    INFORMATION_GATHERING = 'information_gathering',
    COMPLEMENTARY_INFO = 'complementary_info',
    RESULTS_NOT_REVIEWED = 'results_not_reviewed',
    RESULTS_REVIEWED = 'results_reviewed',
    PRE_ASSESSMENT = 'pre_assessment',
    ASSESSMENT_REVIEWED = 'assessment_reviewed',
    REPORT_GENERATED = 'report_generated',
}
