import React from 'react';
import { translate } from '../../selectors/translations';
import { formatDate, formatToDate } from '../../utils/datetime';
import { PropertyChangeInteractionHistoryDto } from "../../sdk";
import useTranslate from "../../hooks/useTranslate";
import { generateInteractor } from "../../pages/patients/util";

type PropsFromState = {
  history: PropertyChangeInteractionHistoryDto[];
}

const PatientEditHistory: React.FC<PropsFromState> = (props: PropsFromState) => {
  const {history} = props;
  const translateLocalazy = useTranslate();
  const interactor = generateInteractor();

  return (
    <div className="card-box patient-history">
      <div className="box-title">
        <h3>{translateLocalazy('patient.history.edit')}</h3>
      </div>
      <div className="card_table-box">
        <table className="table--colored">
          <thead>
          <tr>
            <th>{translate('change')}</th>
            <th>{translate('transcription from')}</th>
            <th>{translate('transcription to')}</th>
            <th>{translate('date')}</th>
            <th>{translate('editor')}</th>
          </tr>
          </thead>
          <tbody>
          {
            history.length
              ? history.map((change, index) => (
                <tr key={index}>
                  <td>{translate(change.metadata.property)}</td>
                  <td>{change.metadata.property === 'birthDate' ? formatToDate(new Date(change.metadata.originalData as Date)) : change.metadata.originalData}</td>
                  <td>{change.metadata.property === 'birthDate' ? formatToDate(new Date(change.metadata.newData as Date)) : change.metadata.newData}</td>
                  <td>{formatDate(new Date(change.createdAt))}</td>
                  <td>{interactor(change.interactor)}</td>
                </tr>
              ))
              : (<tr>
                <td className="t_center" colSpan={5}>{translate('no results')}</td>
              </tr>)
          }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PatientEditHistory;
