import React from 'react';
import { translate } from '../../selectors/translations';
import { ApplicationState } from '../../store';
import { AuthState } from '../../store/Auth/types';
import { connect } from 'react-redux';

type PropsFromState = {
  auth: AuthState;
}

const NotFound: React.SFC<PropsFromState> = (props: PropsFromState) => {
  const { errorMsg } = props.auth;
  return (
    <div className="login_container justify-center">
      <div className="login_wrapper">
        <h1 className="login_title">{translate('welcome_geras_1')}<br />{translate('welcome_geras_2')}</h1>
        <div className="login_switcher-holder" style={{ justifyContent: 'center' }}>
          <p className="login-title">
            {
              errorMsg
                ? translate(errorMsg)
                : translate('Sorry, we can not recognize URL you have requested for :)')
            }
          </p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
  auth: state.auth
})

export default connect(mapStateToProps, null)(NotFound);