import React, { useEffect } from 'react'
import { translate } from '../../selectors/translations'
import { ApplicationState } from "../../store"
import { VoucherCodesActionType, VoucherStatsUsageResponse } from "../../store/VoucherCodes/types"
import { getVoucherUsageStats } from "../../store/VoucherCodes/action"
import { connect } from "react-redux"
import { formatDate } from '../../utils/datetime'

const TWO_MONTHS_DIFF = 60 * 24 * 60 * 60 * 1000

const PaymentsStatistic: React.FC<any> = (props: any) => {

  const {stats} = props

  useEffect(() => {
    props.getVoucherUsageStats()
  }, [])

  return (
    <div className="card-box patient-info">
      <div className="box-title">
        <h3>{translate('voucherCodeStats')}</h3>
      </div>
      <div className="voucher-code">
        <span className="statistic-item">User Name</span>
        <span className="statistic-item">Discount</span>
        <span className="statistic-item">Code Used</span>
        <span className="statistic-item">Date Paid</span>
      </div>
      {
        stats && stats.filter(e => +new Date() - +new Date(e.datePaid) < TWO_MONTHS_DIFF).map((payment: VoucherStatsUsageResponse, i: number) => (
          <div key={i} className="voucher-code">
            <span className="statistic-item">{payment.userName}</span>
            <span className="statistic-item">{payment.voucherDiscount}</span>
            <span className="statistic-item">{payment.voucherCodeName || ''}</span>
            <span className="statistic-item">{formatDate(payment.datePaid)}</span>
          </div>
        ))
      }
    </div>
  )
}

const mapStateToProps = (state: ApplicationState): any => ({
  stats: state.voucherCodes.stats,
  user: state.auth.user,
})

const mapDispatchToProps = (dispatch: (action: VoucherCodesActionType) => void): any => ({
  getVoucherUsageStats: (): void => dispatch(getVoucherUsageStats()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsStatistic)
