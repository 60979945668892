import { MedStaffDto, SendSsToRelativeDto, UserDto } from "../../sdk"
export enum FamilyActionTypes {
  POST_FAMILY_TOKEN_REQUEST = '@@family/POST_FAMILY_TOKEN_REQUEST',
  POST_FAMILY_TOKEN_SUCCESS = '@@family/POST_FAMILY_TOKEN_SUCCESS',
  POST_FAMILY_TOKEN_FAILURE = '@@family/POST_FAMILY_TOKEN_FAILURE',
  POST_FAMILY_MEMBER_REQUEST = '@@family/POST_FAMILY_MEMBER_REQUEST',
  POST_FAMILY_MEMBER_SUCCESS = '@@family/POST_FAMILY_MEMBER_SUCCESS',
  POST_FAMILY_MEMBER_FAILURE = '@@family/POST_FAMILY_MEMBER_FAILURE',
  UPDATE_FAMILY_MEMBER_REQUEST = '@@family/UPDATE_FAMILY_MEMBER_REQUEST',
  UPDATE_FAMILY_MEMBER_SUCCESS = '@@family/UPDATE_FAMILY_MEMBER_SUCCESS',
  UPDATE_FAMILY_MEMBER_FAILURE = '@@family/UPDATE_FAMILY_MEMBER_FAILURE',
  POST_ASK_FAMILY_SURVEY_REQUEST = '@@family/POST_ASK_FAMILY_SURVEY_REQUEST',
  POST_ASK_FAMILY_SURVEY_SUCCESS = '@@family/POST_ASK_FAMILY_SURVEY_SUCCESS',
  POST_ASK_FAMILY_SURVEY_FAILURE = '@@family/POST_ASK_FAMILY_SURVEY_FAILURE',
}

export interface RecognizeInfo {
  ip: { ip4: string; ip6: string};
  language: string;
  pixelRatio: number;
  shortSide: number;
  longSide: number;
  deviceType: string;
}

export interface FamilyState {
  email: string;
  alreadyAtFamily: boolean;
  id: string | null;
  patientId: string;
  referrer: string;
  recognizeInfo: RecognizeInfo;
  errorMsg: string;
  isExisitngMember: boolean;
}

export interface ChekFamilyTokenPayload {
  token: string;
}

export interface InvitationCheckData {
  patientId: string;
  familyMember: {
    id: string | null;
    email: string;
    alreadyAtFamily: boolean;
  };
}

export interface FamilyMemberFormData {
  firstName: string;
  password: string;
  lastName: string;
  email: string;
  isAgreed: boolean;
}

export interface FamilyMemberRequestPayload {
  patientId: string;
  userInfo: FamilyMemberFormData;
  referrer: string;
}

export interface FamilyMemberResponsePayload {
  referrer: string;
  user: MedStaffDto;
  alreadyAtFamily: true;
}

export interface UpdateFMRequestPayload {
  patientId: string;
  id: string;
  referrer: string;
  email: string;
}

export interface UpdateFMRequestReponse {
  user: UserDto;
}

export interface PostFamilyTokenRequest {
  type: typeof FamilyActionTypes.POST_FAMILY_TOKEN_REQUEST;
  data: ChekFamilyTokenPayload;
}
interface PostFamilyTokenSuccess {
  type: typeof FamilyActionTypes.POST_FAMILY_TOKEN_SUCCESS;
  inviteData: InvitationCheckData;
}
interface PostFamilyTokenFailure {
  type: typeof FamilyActionTypes.POST_FAMILY_TOKEN_FAILURE;
  errorMsg: string;
}
export interface PostFamilyMemberRequest {
  type: typeof FamilyActionTypes.POST_FAMILY_MEMBER_REQUEST;
  data: FamilyMemberRequestPayload;
}
export interface PostFamilyMemberSuccess {
  type: typeof FamilyActionTypes.POST_FAMILY_MEMBER_SUCCESS;
  response: FamilyMemberResponsePayload;
}
export interface PostFamilyMemberFailure {
  type: typeof FamilyActionTypes.POST_FAMILY_MEMBER_FAILURE;
  errorMsg: string;
}
export interface UpdateFamilyMemberRequest {
  type: typeof FamilyActionTypes.UPDATE_FAMILY_MEMBER_REQUEST;
  data: UpdateFMRequestPayload;
}
export interface UpdateFamilyMemberSuccess {
  type: typeof FamilyActionTypes.UPDATE_FAMILY_MEMBER_SUCCESS;
  response: UpdateFMRequestReponse;
}
export interface UpdateFamilyMemberFailure {
  type: typeof FamilyActionTypes.UPDATE_FAMILY_MEMBER_FAILURE;
  errorMsg: string;
}

export interface PostAskFamilySurveyRequest {
  type: typeof FamilyActionTypes.POST_ASK_FAMILY_SURVEY_REQUEST;
  data: SendSsToRelativeDto;
}
interface PostAskFamilySurveySuccess {
  type: typeof FamilyActionTypes.POST_ASK_FAMILY_SURVEY_SUCCESS;
  data: object;
}
interface PostAskFamilySurveyFailure {
  type: typeof FamilyActionTypes.POST_ASK_FAMILY_SURVEY_FAILURE;
  errorMsg: string;
}

export type FamilyActionType =
  PostFamilyTokenRequest |
  PostFamilyTokenSuccess |
  PostFamilyTokenFailure |
  PostFamilyMemberRequest |
  PostFamilyMemberSuccess |
  PostFamilyMemberFailure |
  UpdateFamilyMemberFailure |
  UpdateFamilyMemberRequest |
  UpdateFamilyMemberSuccess |
  PostAskFamilySurveyRequest |
  PostAskFamilySurveySuccess |
  PostAskFamilySurveyFailure;
