/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InteractionType {
    PROPERTY_CHANGE = 'property-change',
    REPORT_EXPORT = 'report-export',
    VIEW = 'view',
    TEXT_MESSAGE = 'text-message',
    QUESTIONER_ANSWER_CHANGE = 'questioner-answer-change',
}
