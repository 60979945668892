import React from "react";
import ExpandIcon from "../../../../components/ExpandIcon";
import {ArrowDirections} from "../../../../types/constants";

interface IProps {
    children: JSX.Element | boolean;
    isExpanded: boolean;
    handleExpandClick: () => void;
    title: string;
}
const PatientSideHistoryLayout: React.FC<IProps> = (props: IProps): JSX.Element => {

    const { isExpanded, children, handleExpandClick, title } = props;
    return (
        <div className="diagnostic_card card-box--history card-box no-borders shadow card_comment">
            <div className="card_header c-pointer"
                 onClick={handleExpandClick}>
                <div className="title">{title}</div>
                <ExpandIcon direction={isExpanded ? ArrowDirections.Top : ArrowDirections.Bottom}></ExpandIcon>
            </div>
            { children }
        </div>
    );
};

export default PatientSideHistoryLayout;
