import React from 'react';
import BrainProfilTemplate from '../../../assets/img/brain-config/00_1.png';
import BrainTopTemplate from '../../../assets/img/brain-config/00_2.png';
import Toggleable from '../../../rc-components/Toggleable';
import SelectorBox from '../../../components/SelectorBox';
import DropdownMenu from '../../DropdownMenu';
import { translate } from '../../../selectors/translations';
import { BrainConfigItem } from '../../../store/Patients/types';

type Props = {
  onProfileChange: (i: string) => void;
  selectedProfile: BrainConfigItem;
  list: BrainConfigItem[];
}
const SideBrainSwitcher: React.FC<Props> = (props: Props) => {
  const {onProfileChange, selectedProfile, list} = props;
  return (
    <div className="flex-item item-sm-5">
      <div className="disfunctions_side_box flex-column">
        <div className="disfunctions_side_head flex-item--shrink">
          <div className="side_title flex collapse align-center">
            <div className="flex-item flex-item--stretch">
              {translate('Indicative reference library')}
              <br />
              <span style={{fontSize: '8px'}}>-&nbsp;{translate('Typical authentic patient cases, not scientifically validated')}</span>
              </div>
          </div>
          <div className="side_selector">
            <br/>
            <div className="title">{translate('Select profile')}</div>
            <Toggleable>
              {
                (dropdownId: string, setDropdownId: (id: string) => void): React.ReactElement => (
                  <SelectorBox
                    items={list.map((t: BrainConfigItem, i: number) => ({ title: translate(t.title), isSelected: i.toString() === list.indexOf(selectedProfile).toString(), propToPropagate: i.toString() }))}
                    type="displayedType"
                    Elem={
                      <DropdownMenu
                        show={dropdownId === 'displayedType'}
                        onClick={(val): void => (onProfileChange(val), setDropdownId(''))}
                        items={list.map((t: any, i: number) => ({ title: translate(t.title), isSelected: i.toString() === list.indexOf(selectedProfile).toString(), propToPropagate: i.toString() }))} />
                    }
                    dropdownId={dropdownId}
                    onToggleMenu={setDropdownId}>
                  </SelectorBox>)}
            </Toggleable>
          </div>
        </div>
        <div className="disfunctions_side_body flex-item--stretch flex-column justify-between" style={{flex: '1 1 auto'}}>
          <div className="disfunctions_side_patterns">
            <div className="brain-category frontal-pt t_center">
              <div>
              <div className="test_description_title">{translate('Frontal')}</div>
                <div className="title-points">{translate('Points')}:
                  <strong>{selectedProfile.frontal.reduce((acc, val) => val >= 0 ? acc + val : acc, 0)}  / 12</strong>
                </div>
              </div>
              <div>
              <div className="test_description_title">{translate('Parietal / Temporal')}</div>
                <div className="title-points">{translate('Points')}:
                  <strong>{selectedProfile.temporal.reduce((acc, val) => val >= 0 ? acc + val : acc, 0)} / 18</strong>
                </div>
              </div>
            </div>
            <div className="brain-holder">
              <img src={BrainProfilTemplate} className="brain-img_template" />
              <img src={selectedProfile.frontImage} className="patterns_layer" />
            </div>
          </div>
          <hr className="drawing-line" style={{marginTop:'40px', marginBottom:'40px'}}/>
          <div className="disfunctions_side_patterns">
            <div className="brain-category t_center">
              <div className="test_description_title">{translate('Subcortical')}</div>
                <div className="title-points">{translate('Points')}:
                  <strong>{selectedProfile.subcortical.reduce((acc, val) => val >= 0 ? acc + val : acc, 0)} / 16</strong>
                </div>
            </div>
            <div className="brain-holder">
              <img src={BrainTopTemplate} className="brain-img_template" />
              <img src={selectedProfile.backImage} className="patterns_layer" />
            </div>
          </div>
          <hr className="drawing-line" style={{marginTop:'40px', marginBottom:'40px'}}/>
          <div className="disfunctions_side_patterns flex-column collapse align-center">
            <div className="flex-item brain-category" style={{flex: '1 1 auto'}}>
              <div className="test_description_title">{translate('Memory')}</div>
                <div className="title-points">{translate('Points')}:
                  <strong>{selectedProfile.memory.reduce((acc, val) => val >= 0 ? acc + val : acc, 0)} / 14</strong>
                </div>
            </div>
            <div className="thermometer_box">
              {
                selectedProfile.memory
                .map((v, i: number) => ({data: v, index: i + 1 }))
                .map((v, i: number) => (
                    <div key={i} className="thermometer_item">
                      <div className={`thermometer_item_cell color-${v.data + 1}`}></div>
                      <div className="thermometer_item_title arrow_box">M{v.index}</div>
                    </div>
                  ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBrainSwitcher;
