import React from 'react';
import Cube from './Cube';
import Clock from './Clock';
import Tail from './Tail';
import './SvgComponent.css';
import { FormattedAnswer } from '../../store/PatientProfile/types';
import { translate } from '../../selectors/translations';
import { isIE } from 'react-device-detect';
type SvgTypes = {
  [key: number]: (paths: string[], setup: any, times: number[], key: number) => React.ReactElement;
}

type SvgResult = {
  paths: string[];
  times: number[];
  oldTimes: number[][];
  oldPaths: string[][];
}

type SvgObj = {
  paths: string[];
  times: number[];
}

export type Element = {
  type: number;
  cx: number;
  cy: number;
  strokeWidth: number;
  r: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  text: string;
}

export type SvgSetup = {
  width: number;
  height: number;
  elements: Element[];
}

const svgPathsTypes: SvgTypes = {
  1: (paths: string[], setup: SvgSetup, times: number[], key: number): React.ReactElement => <Tail isIE={isIE} key={key} times={times} setup={setup} paths={paths} /> as React.ReactElement,
  2: (paths: string[], setup: SvgSetup, times: number[], key: number): React.ReactElement => <Cube isIE={isIE} key={key} setup={setup} times={times} paths={paths} /> as React.ReactElement,
  3: (paths: string[], setup: SvgSetup, times: number[], key: number): React.ReactElement => <Clock isIE={isIE} key={key} times={times} setup={setup} paths={paths} /> as React.ReactElement,
};

const drawSvg = (paths: string[], setup: any, times: number[], key: number) => (type: number): React.ReactElement => svgPathsTypes[type](paths, setup, times, key);

const SvgComponent: React.FC<FormattedAnswer> = (props: FormattedAnswer) => {
  const { result, setup, type } = props;
  const { paths, times, oldTimes, oldPaths } = result as SvgResult;
  return (
    <div className="svg-group">
      {
        [...(oldPaths || []).map((path, index: number) => ({paths: path, times: oldTimes[index]})), {paths, times}].map((r: SvgObj, i: number) => (
          <div key={i}>
            <span style={{fontSize: '14px'}}>{translate('try')}: <strong>{i + 1}</strong></span>
            {drawSvg(r.paths, (setup as SvgSetup), r.times, i)(type)}
          </div>
        ))
      }
    </div>
  )
}

export default SvgComponent;
