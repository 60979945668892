import { AppLanguage, Gender, UserRole, UserStatus } from "../../sdk";

export class PatientProfile {
    _id = '';
    id = '';
    email = '';
    firstName = '';
    lastName = '';
    birthDate = '';
    gender = Gender.MALE;
    status = UserStatus.INFORMATION_GATHERING;
    language = AppLanguage.EN;
    roles = [] as UserRole[];
    personalNumber = '';
    questionerAnswers = [];
    progress = 0;
    anamnesisSectionSet = [];
    username = '';
    phoneNumber = '';
    height = 0;
    institution = '';
    sharedInstitution = [];
    country = 'sv';
    pdfFileLink = '';
    downloads = [];
    weight = 0;
    historyChanges = [];
    testsHistoryChanges = [];
    age = '';
    measurements = [];
    cognitiveTests = [];
    recordings = [];
    bookings = [];
    createdAt = '';
    password = '';
    updatedAt = '';
    viewingAttempts = [];
    anamnesisQuestions = {};
    anamnesisSections = [];
    anamnesisQuestionsNew = {};
    anamnesisSectionsNew = [];
    newPatient = false;
    redirectAfterCreation = false;
    pdfReportType = 'doctor';
    loginCode = null;
    messageHistory = [];
    availableQuestioners = { anamnesis: null, cognitive: null, symptom_survey: null};
    refreshToken = ''
    doctorNotes = []
    interactionHistory = []
    sentSsAt = null
}
