import React from 'react';

type PropsFromState = {
  embeddedHtml: string;
}

const IFrame: React.FC<PropsFromState> = (props: PropsFromState) => {
  const { embeddedHtml } = props;
  return embeddedHtml 
    ? (
      <div style={{ marginTop: '18px' }} className="flex justify-center">
        <div dangerouslySetInnerHTML={{ __html: embeddedHtml}}
             className="document-iframe-holder w-100"></div>
      </div>
    )
    : null;
}

export default IFrame;
