import {
  SmsTemplatesState,
  SmsTemplatesActionTypes,
  SmsTemplatesActionType
} from './types'

const initialState: SmsTemplatesState = {
  all: [],
  errorMsg: ''
}

export function SmsTemplatesReducer(
  state = initialState,
  action: SmsTemplatesActionType
): SmsTemplatesState {
  switch (action.type) {
    case SmsTemplatesActionTypes.GET_SMS_TEMPLATE_REQUEST:
      return {...state}
    case SmsTemplatesActionTypes.GET_SMS_TEMPLATE_FAILURE:
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    case SmsTemplatesActionTypes.GET_SMS_TEMPLATE_SUCCESS:
      return {
        ...state,
        all: [...action.response],
        errorMsg: ''
      }
    case SmsTemplatesActionTypes.POST_SMS_TEMPLATE_FAILURE:
      alert(action.errorMsg);
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    case SmsTemplatesActionTypes.POST_SMS_TEMPLATE_SUCCESS:
      return {
        ...state,
        errorMsg: ''
      }
    default:
      return state
  }
}
