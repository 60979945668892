/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PaymentStatus {
    CREATED = 'created',
    COMPLETED = 'completed',
    FAILED = 'failed',
}
