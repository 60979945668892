import { QuestionScreenDTO } from "./models/QuestionScreenDTO";
import { SectionTitleScreenDTO } from "./models/SectionTitleScreenDTO";
import { UniqueScreenDTO } from "./models/UniqueScreenDTO";
import { ScreenType } from "./models/ScreenType";
import { SelectorInputDTO } from "./models/SelectorInputDTO";
import { RadioGroupInputDTO } from "./models/RadioGroupInputDTO";
import { CheckboxGroupInputDTO } from "./models/CheckboxGroupInputDTO";
import { TwoChairsInputDTO } from "./models/TwoChairsInputDTO";
import { FreeTextInputDTO } from "./models/FreeTextInputDTO";
import { InputType } from "./models/InputType";

export function isQuestionScreen(screen: QuestionScreenDTO | SectionTitleScreenDTO | UniqueScreenDTO): screen is QuestionScreenDTO {
  return screen.kind == ScreenType.QUESTION
}


export function isMultiChoiceInput(
  input: TwoChairsInputDTO | SelectorInputDTO | RadioGroupInputDTO | CheckboxGroupInputDTO | FreeTextInputDTO
): input is SelectorInputDTO | RadioGroupInputDTO | CheckboxGroupInputDTO {
  return [InputType.CHECKBOX_GROUP, InputType.SELECTOR, InputType.RADIO_GROUP].includes(input.kind)
}

export function inputHasPoints(input: TwoChairsInputDTO | SelectorInputDTO | RadioGroupInputDTO | CheckboxGroupInputDTO | FreeTextInputDTO): input is SelectorInputDTO | RadioGroupInputDTO {
  return [InputType.SELECTOR, InputType.RADIO_GROUP].includes(input.kind) && (input as RadioGroupInputDTO).metadata.values.some(el => el.points)
}
