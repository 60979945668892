import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { languageInUse, translate } from "../../../selectors/translations";
import { getPatientState } from "../../../selectors/patientProfile";
import {
  getAnamnesisConfig,
  getPatient,
  getViewingAttempts,
  sendTextMessageRequest,
  unsetPatientProfile,
  updatePatientRequest
} from "../../../store/PatientProfile/action";
import PatientCommonForm from "../components/PatientCommonForm";
import PatientAnamnesisForm from "../components/PatientAnamnesisForm";
import { InstitutionSettings, PhoneSettings } from "../../../components/PatientSettings/PatientSettings";
import SymptomSurveyForm from "./SymptomSurveyForm/SymptomSurveyForm";
import LoginCodeForm from "./loginCodeForm/LoginCodeForm";
import { ReactComponent as BackIcon } from '../../../assets/img/icons/arrow_left_icon.svg';
import { PatientFormMode } from "../constants/constants";
import './PatientAdministrate.scss';
import PatientSideViewHistory from "../components/PatientSideViewHistory";
import PatientSideEditHistory from "../components/PatientSideEditHistory";
import PatientSidePDFSection from "../components/PatientSidePDFSection";
import PatientSideMessagesHistory from "../components/PatientSideMessagesHistory";
import { RouteNames } from "../../../types/constants";
import { isGerasInstitution } from "../../../selectors/institutions"
import { AvailableQuestionersDto, InteractionType, UserDto } from "../../../sdk";
import { isAnswerChange, isMessage, isPropertyChange, isReportExport } from "../util";
import PatientEditHistory from "../../../components/PatientEditHistory";

const PatientAdministratePage: React.FC = (): React.JSX.Element => {
  const [commonFormState, setCommonFormState] = useState<any>({});
  const history = useHistory();
  // @ts-ignore
  const {id: patientId = ''} = useParams();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(languageInUse) as string;
  const patientProfile = useSelector(getPatientState);
  const [anamnesisFormState, setAnamnesisFormState] = useState<AvailableQuestionersDto>();

  const profileChanges = patientProfile.interactionHistory.filter(isPropertyChange)
  const viewingAttempts = patientProfile.interactionHistory.filter(e => e.kind == InteractionType.VIEW)
  const downloads = patientProfile.interactionHistory.filter(isReportExport)
  const messageHistory = patientProfile.interactionHistory.filter(isMessage)
  const testsHistoryChanges = patientProfile.interactionHistory.filter(isAnswerChange)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const paymentLink = () => {
    window.open(`https://buy.stripe.com/4gw17fa4r8eG8ScfZ3`)
  }

  const supportToRelative = () => {
    window.open(`https://www.demensforbundet.se/att-vara-anhorig/rad-och-stod/#:~:text=Ring%20v%C3%A5r%20telefonr%C3%A5dgivning%20f%C3%B6r%20r%C3%A5d%20och%20st%C3%B6d%2C`)
  }

  const minnesmottagningenSE = () => {
    window.open(`https://www.minnesmottagningen.se/`)
  }

  const elevenseventyseven = () => {
    window.open(`https://www.1177.se/hitta-vard/kontaktkort/Minnesmottagningense/`)
  }

  const priveraDoctorFile = () => {
    window.open(`https://loopia1993867.sharepoint.com/:x:/s/Geras/EfJAmWTVabJPsqnPfC8sE0MBkfpssTUqLywlymt-eratMg?e=sH8YJC`)
  }

  const priveraIntroFile = () => {
    window.open(`https://loopia1993867.sharepoint.com/:x:/r/sites/Geras/_layouts/15/Doc.aspx?sourcedoc=%7BFE6C541E-3168-47F5-A67D-455E50B211FA%7D&file=NYTT%20privera%20Introm%C3%B6te.xlsx&action=default&mobileredirect=true`)
  }


  const bookDoctorAppointment = () => {
    window.open(`https://minnesmottagningen.as.me/schedule/66635e0d/appointment/37419517/calendar/any?firstName=User_ID&lastName=${patientId}&phone=+${patientProfile.phoneNumber}&email=privatbokade%40gerassolutions.com`)
  }

  const bookRevisitAppointment = () => {
    window.open(`https://minnesmottagningen.as.me/schedule/66635e0d/appointment/37177771/calendar/any?firstName=User_ID&lastName=${patientId}&phone=+${patientProfile.phoneNumber}&email=privatbokade%40gerassolutions.com`)
  }

  const clinicalAssessment = () => {
    window.open(`https://loopia1993867.sharepoint.com/:f:/s/Geras/EqWj2JY783xPtlcXr3XF2PIBdJOTRzmTXB9qfBmE-A_Mmg?e=dczoXv`)
  }

  const personalNumberLook = () => {
    window.open(`https://mrkoll.se/resultat?n=${patientProfile.personalNumber}&c=&min=16&max=120&sex=a&c_stat=all&company=`)
  }

  const confrereShortcut = () => {
    window.open(`https://minnesmottagningen.confrere.com/dashboard`)
  }

  const acuityShortcut = () => {
    window.open(`https://keyboard-spinach-k9a9.squarespace.com/config/scheduling/appointments.php`)
  }

  const elksShortcut = () => {
    window.open(`https://46elks.se/dashboard/send-sms`)
  }

  const handleCancel = useCallback(() => {
    history.push(`${RouteNames.PatientCard}/${patientId}`);
  }, []);

  const disableButton = useMemo(() => {
    const {isFormValid, isFormChanged} = commonFormState;
    return !isFormChanged || !isFormValid;
  }, [commonFormState]);

  const handleSubmitCommonForm = useCallback(() => {
    const {inputs, isFormValid} = commonFormState;
    const dto = {
      ...inputs
    };
    if (!isFormValid) {
      return;
    }
    if (dto.personalNumber) {
      const reG = /-/gi;
      dto.personalNumber = dto.personalNumber.replace(reG, '');
    }
    if (dto.birthDate) {
      dto.birthDate = dto.birthDate.toString();
    }
    if (patientId) {
      dispatch(updatePatientRequest({...dto, _id: patientId}));
    }
  }, [commonFormState]);

  const handleSubmitAnamnesis = useCallback(() => {
    if (patientId) {
      dispatch(updatePatientRequest({
        _id: patientId,
        availableQuestioners: anamnesisFormState,
        resetTestStatuses: true
      }));
    }
  }, [anamnesisFormState]);

  const handleSubmitInstitution = (data: any) => {
    if (patientId) {
      dispatch(updatePatientRequest({...data, _id: patientId}));
    }
  };

  const handleSendTextMessage = (textMessageType: string): void => {
    dispatch(sendTextMessageRequest({textMessageType, patientId: patientProfile._id}))
  }

  const CopyPatientIdButton = () => {
    const patientIdCopy = patientProfile._id;
    navigator.clipboard.writeText(patientIdCopy)
  };

  const CopyPaymentLink = () => {
    const paymentLinkCopy = "https://buy.stripe.com/4gw17fa4r8eG8ScfZ3";
    navigator.clipboard.writeText(paymentLinkCopy)
  };


  useEffect(() => {
    dispatch(getAnamnesisConfig('', currentLanguage));
  }, [currentLanguage]);

  useEffect(() => {
    if (patientId) {
      dispatch(getPatient(patientId));
      dispatch(getViewingAttempts(patientId))
    }
    return () => {
      dispatch(unsetPatientProfile());
    };
  }, []);

  return (
    <div>
      <div className="administrative-page__heading flex align-center">
        <BackIcon onClick={handleCancel}/>
        <p onClick={handleCancel}>{translate('back')}</p>
      </div>
      <div className="flex flex--wrap">
        <div className="item--12 item-xl-8">
          <PatientCommonForm
            data={{
              _id: patientProfile._id,
              birthDate: patientProfile.birthDate,
              firstName: patientProfile.firstName,
              phoneNumber: patientProfile.phoneNumber,
              institution: patientProfile.institution,
              sharedInstitution: patientProfile.sharedInstitution,
              language: patientProfile.language,
              lastName: patientProfile.lastName,
              personalNumber: patientProfile.personalNumber || '',
            }}
            onFieldChange={setCommonFormState}
            mode={PatientFormMode.EditPatient}>
            <div className="btns-holder">
              {disableButton &&
                  <button className="g_btn t_uppercase g_btn--disabled"
                          type="button">{translate('save')}</button>
              }
              {!disableButton &&
                  <button onClick={handleSubmitCommonForm}
                          className="g_btn t_uppercase g_btn--primary"
                          type="button">{translate('save')}</button>
              }
            </div>
          </PatientCommonForm>
          <PatientAnamnesisForm
            onFormChange={setAnamnesisFormState}
            homeScreenMap={(patientProfile as never as UserDto).availableQuestioners}>
            <div className="btns-holder">
              <button onClick={handleSubmitAnamnesis}
                      className="g_btn t_uppercase g_btn--primary"
                      type="button">{translate('activateTestsInApp')}</button>
            </div>
          </PatientAnamnesisForm>
          <PhoneSettings
            currentLanguage={currentLanguage}
            phoneNumber={patientProfile.phoneNumber}
            onSubmit={(textMessageType) => handleSendTextMessage(textMessageType)}
          />
        </div>
        <div className="item--12 item-xl-4 administrative-page__aside">

          {isGerasInstitution(patientProfile.institution) &&
              <div className="card-box">
                  <div className="box-title">
                      <i className="icon-holder patients-detail_icon-holder"></i>
                      <h3>Verktyg: Minnesmottagningen.se</h3>
                  </div>
                  <div className="input-holder btns-holder item--12 t-align sub-item"
                       style={{display: 'flex', flexDirection: 'row'}}>

                      <div>
                          <div style={{padding: '20px'}}>
                              <h1 className="t_center">Videomöte</h1>
                              <button onClick={confrereShortcut} className="g_btn--section toolButton"
                                      type="button">Starta videomöte
                              </button>
                              <button onClick={priveraDoctorFile} className="g_btn--section toolButton"
                                      type="button">Läkarsträngar
                              </button>
                              <button onClick={priveraIntroFile} className="g_btn--section toolButton"
                                      type="button">Introsträngar
                              </button>
                              <button onClick={clinicalAssessment} className="g_btn--section toolButton"
                                      type="button">Onedrive C7
                              </button>
                          </div>
                          <div style={{padding: '20px'}}>
                              <h1 className="t_center">Boka videomöte</h1>
                              <button onClick={bookDoctorAppointment} className="g_btn--section toolButton"
                                      type="button">Uppföljningsmöte
                              </button>
                              <button onClick={bookRevisitAppointment} className="g_btn--section toolButton"
                                      type="button">Åter/intromöte
                              </button>
                              <button onClick={CopyPaymentLink} className="g_btn--section toolButton"
                                      type="button">Kopiera betalning
                              </button>
                          </div>
                      </div>
                      <div>
                          <div style={{padding: '20px'}}>
                              <h1 className="t_center">Övrigt</h1>
                              <button onClick={acuityShortcut} className="g_btn--section toolButton"
                                      type="button">Möteskalender
                              </button>
                              <button onClick={elksShortcut} className="g_btn--section toolButton"
                                      type="button">Manuella SMS
                              </button>
                              <button onClick={personalNumberLook} className="g_btn--section toolButton"
                                      type="button">Kolla adress
                              </button>
                              <button onClick={CopyPatientIdButton} className="g_btn--section toolButton"
                                      type="button">Kopiera patient ID
                              </button>
                          </div>


                          <div style={{padding: '20px'}}>
                              <h1 className="t_center">Hemsidor</h1>
                              <button onClick={minnesmottagningenSE} className="g_btn--section toolButton"
                                      type="button">MM.se
                              </button>
                              <button onClick={elevenseventyseven} className="g_btn--section toolButton"
                                      type="button">1177
                              </button>
                              <button onClick={supportToRelative} className="g_btn--section toolButton"
                                      type="button">Anhörigstöd
                              </button>
                          </div>

                      </div>


                  </div>
              </div>
          }

          <LoginCodeForm patientId={patientId}/>
          <SymptomSurveyForm patientId={patientId}/>
          {isGerasInstitution(patientProfile.institution) &&
              <InstitutionSettings
                  patientId={patientProfile._id}
                  sharedInstitution={patientProfile.sharedInstitution}
                  onSubmit={handleSubmitInstitution}/>
          }
        </div>
      </div>
      <PatientEditHistory history={profileChanges}/>
      <div className="flex-container pb-20">
        <div className="flex-item item-md-6">
          <PatientSideViewHistory viewingAttempts={viewingAttempts}/>
        </div>
        <div className="flex-item item-md-6">
          <PatientSideEditHistory history={testsHistoryChanges}/>
        </div>
        <div className="flex-item item-md-6 pt-10">
          <PatientSidePDFSection downloads={downloads}/>
        </div>
        <div className="flex-item item-md-6 pt-10">
          <PatientSideMessagesHistory messageHistory={messageHistory}/>
        </div>
      </div>
    </div>
  );
};

export default PatientAdministratePage;
