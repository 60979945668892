import {
  FamilyActionTypes,
  FamilyActionType,
  ChekFamilyTokenPayload,
  InvitationCheckData,
  FamilyMemberRequestPayload,
  FamilyMemberResponsePayload,
  UpdateFMRequestPayload,
  UpdateFMRequestReponse
} from './types';
import { SendSsToRelativeDto } from "../../sdk"

export const postFamilyToken = (data: ChekFamilyTokenPayload): FamilyActionType => ({type:FamilyActionTypes.POST_FAMILY_TOKEN_REQUEST, data});
export const postFamilyTokenSuccess = (inviteData: InvitationCheckData): FamilyActionType => ({type:FamilyActionTypes.POST_FAMILY_TOKEN_SUCCESS, inviteData});
export const postFamilyTokenError = (error: string): FamilyActionType => ({type:FamilyActionTypes.POST_FAMILY_TOKEN_FAILURE, errorMsg: error});

export const postFamilyMember = (data: FamilyMemberRequestPayload): FamilyActionType => ({type: FamilyActionTypes.POST_FAMILY_MEMBER_REQUEST, data});
export const postFamilyMemberSuccess = (data: FamilyMemberResponsePayload): FamilyActionType => ({type: FamilyActionTypes.POST_FAMILY_MEMBER_SUCCESS, response: data})
export const postFamilyMemberFailure = (error: string): FamilyActionType => ({type: FamilyActionTypes.POST_FAMILY_MEMBER_FAILURE, errorMsg: error});

export const updateFamilyMember = (data: UpdateFMRequestPayload): FamilyActionType => ({type: FamilyActionTypes.UPDATE_FAMILY_MEMBER_REQUEST, data});
export const updateFamilyMemberSuccess = (data: UpdateFMRequestReponse): FamilyActionType => ({type: FamilyActionTypes.UPDATE_FAMILY_MEMBER_SUCCESS, response: data})
export const updateFamilyMemberFailure = (error: string): FamilyActionType => ({type: FamilyActionTypes.POST_FAMILY_MEMBER_FAILURE, errorMsg: error});

export const postAskFamilySurvey = (data: SendSsToRelativeDto): any => ({type: FamilyActionTypes.POST_ASK_FAMILY_SURVEY_REQUEST, data});
export const postAskFamilySurveySuccess = (data: any): any => ({type: FamilyActionTypes.POST_ASK_FAMILY_SURVEY_SUCCESS, response: data});
export const postAskFamilySurveyError = (error: string) => ({type: FamilyActionTypes.POST_ASK_FAMILY_SURVEY_FAILURE, errorMsg: error});
