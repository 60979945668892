import React from 'react';
import { NavLink } from 'react-router-dom';
import HomeIcon from '../../../assets/img/icons/home-icon.svg';
import UserIcon from '../../../assets/img/icons/user-icon.svg';
import CalendarIcon from '../../../assets/img/icons/calendar-icon.svg';
import PhoneIcon from '../../../assets/img/icons/phone-icon.svg';
import AccountIcon from '../../../assets/img/icons/account-icon.svg';
import LogoutIcon from '../../../assets/img/icons/logout.svg';
import DocumentIcon from '../../../assets/img/icons/document.svg';
import ContactIcon from '../../../assets/img/icons/contacts-icon.svg';
import AdminIcon from '../../../assets/img/icons/admin.svg';
import { SidebarSection } from '../Sidebar';
import { translate } from '../../../selectors/translations';
import {useLocation} from "react-router";

type SectionProps = {
  section: SidebarSection;
  type: string;
  isActive: boolean;
  logout: () => void;
}

interface IconObj {
  [key: string]: string;
}

const ICONS: IconObj = {
  home: HomeIcon,
  user: UserIcon,
  calendar: CalendarIcon,
  phone: PhoneIcon,
  account: AccountIcon,
  contacts: ContactIcon,
  logout: LogoutIcon,
  document: DocumentIcon,
  admin: AdminIcon
};

const Section: React.FC<SectionProps> = (props: SectionProps) => {
  const { isActive, section} = props;
  const location = useLocation();
  return (
    <li>
      <div className={`nav-item ${isActive ? 'active' : ''}`}>
        <div className="nav_icon-holder">
          <img src={ICONS[section.icon]} className="sidebar_nav-icon" />
        </div>
        {
          section.link && section.link !== '/login'
            ? (<NavLink className="nav_title" exact to={section.link}>
                  {translate(section.titleKey)}
                </NavLink>)
            : (<NavLink onClick={(e): void => (e.stopPropagation(), props.logout())} exact to={'/login'} className="nav_title">
                {translate('logout')}
              </NavLink>)
        }
      </div>
      {
        isActive && section.subSections.length
        && section.subSections.length
          ? (<ul className="inner-nav">
              {
                section.subSections.map((subSection, index) => {
                  const className = subSection.link === location.pathname ? 'active' : '';
                  return (
                      <li key={index} className={className}>
                        <NavLink exact to={subSection.link}>
                          {translate(subSection.titleKey)}
                        </NavLink>
                      </li>);
                })
              }
            </ul>)
          : ''
        }

    </li>
  )
};





export default Section;
