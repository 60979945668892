import React, { useCallback, useState } from 'react'
import { translate } from '../../../../selectors/translations'
import { formatDate } from '../../../../utils/datetime'
import PatientSideHistoryLayout from "../PatientSideHistoryLayout"
import { ReportExportHistoryDto, ReportExportMetadataDto } from "../../../../sdk"
import { generateInteractor } from "../../util"

type PropsFromState = {
  downloads: ReportExportHistoryDto[];
}

const PatientSidePDFSection: React.FC<PropsFromState> = (props: PropsFromState): React.ReactElement => {
  const {downloads} = props
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const interactor = generateInteractor()

  const handleExpandClick = useCallback((): void => {
    setIsExpanded((prevState) => !prevState)
  }, [])

  const getTranslatedAction = (actionData: ReportExportMetadataDto) => {
    if (actionData && actionData.reportForPatient) return translate('sentToPatient')
    return translate('downloaded')
  }

  return (
    <PatientSideHistoryLayout
      isExpanded={isExpanded}
      handleExpandClick={handleExpandClick}
      title={translate('reportExport')}
    >
      {isExpanded &&
          <div className="card_body">
            {downloads.length
              ? downloads
                .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
                .map((downloadInfo, i) => (
                  <article key={i} className="card_comment-box" style={{padding: '5px'}}>
                    <div className="comment_header">
                      <div className="comment_header_title flex collapse justify-between align-center">
                        <div className="flex-item flex-item--shrink">
                                        <span
                                          className="download-pdf-title">{interactor(downloadInfo.interactor)} {getTranslatedAction(downloadInfo.metadata)},</span>
                          <br/>
                          <span
                            className="download-pdf-date">{formatDate(downloadInfo.createdAt)}</span>
                        </div>
                      </div>
                    </div>
                  </article>))
              : (
                <article className="card_comment-box">
                  <div className="comment_header">
                    <div className="comment_header_title">{translate('no results')}</div>
                  </div>
                </article>
              )
            }
          </div>
      }
    </PatientSideHistoryLayout>
  )
}

export default PatientSidePDFSection
