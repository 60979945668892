import React, {useEffect, useState} from 'react';
import Toggleable from '../../rc-components/Toggleable';
import {DropdownCb} from '../../types/Common';
import { translate } from '../../selectors/translations';
import {NormalizedNote} from '../../store/PatientProfile/types';
import EditNoteForm from "../EditNoteForm";
import NoteItem from "../NoteItem";
import {patientPostNoteRequest } from "../../store/PatientProfile/action";
import {useDispatch} from "react-redux";
import HttpPendingStub from "../HttpPendingStub";
import { DoctorNoteType } from "../../sdk";

type PropsFromState = {
    notes: NormalizedNote[];
    patientId: string;
    title: string;
    noteType: DoctorNoteType;
};

const PatientAsideNotes: React.FC<PropsFromState> = (props: PropsFromState) => {
    const { notes, patientId, title, noteType } = props;
    const [ isAddMode, setIsAddMode ] = useState(false);
    const [ showAll, setShowAll ] = useState(false);
    const [ isHTTPPending, setIsHTTPPending ] = useState(false);
    const [ notesToRender, setNotesToRender ] = useState(notes.slice(0, 3));
    useEffect(() => {
        if (showAll) {
            setNotesToRender(notes);
        }
    }, [showAll]);
    useEffect(() => {
        if (showAll) {
            setNotesToRender(notes);
        } else {
            setNotesToRender(notes.slice(0, 3));
        }
    }, [notes]);
    const dispatch = useDispatch();
    const addNewNoteHandler = (): void => {
        setIsAddMode(true);
    };
    const cancelAddNewNoteHandler = (): void => {
        setIsAddMode(false);
    };
    const handleSaveAddResponse = (result: boolean): void => {
        setIsHTTPPending(false);
        if (result) {
            cancelAddNewNoteHandler();
        }
    };
    const submitAddFormHandler = (comment: string): void => {
        if (typeof comment !== "string" || !comment.length) {
            return;
        }
        const payload = {
            userId: patientId,
            comment,
            noteType,
            cb: handleSaveAddResponse
        };
        dispatch(patientPostNoteRequest(payload));
    };

    const showAllHandler = (): void => {
        if (!showAll) {
            setShowAll(true);
        }
    };
    const isShowAllButtonShouldBeShown = !showAll && (notesToRender.length < notes.length);
    return (<div className="">
        <div className="card-box diagnostic_test-box">
            <Toggleable
                defaultValue={'notes'}>
                {
                    (dropdownId: string, setDropdownId: DropdownCb): React.ReactElement => (
                        <div>
                            <div className="diagnostic_test_summary">
                                <div className="flex-container flex--wrap">
                                    <div className="flex-item item--12"
                                         onClick={(): void => setDropdownId(dropdownId === 'notes' ? '' : 'notes')}>
                                        <div className="test-title">{translate(title)}</div>
                                    </div>
                                    <div className="flex-item item--12 flex justify-end">
                                        <div className="flex-item item--6 p-0 pl-5">
                                            <button
                                                className="g_btn g_btn--outlined w-100"
                                                type="button"
                                                onClick={addNewNoteHandler}>
                                                <span>
                                                  {translate('add_sign_note_button')}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                { isAddMode &&
                                    <div className="pt-10 p-relative">
                                        { isHTTPPending &&
                                            <div className="p-absolute w-100 h-100">
                                                <HttpPendingStub title={'Saving data...'}></HttpPendingStub>
                                            </div>
                                        }
                                        <EditNoteForm
                                            onCancelCb={cancelAddNewNoteHandler}
                                            onSubmitCb={submitAddFormHandler}></EditNoteForm>
                                    </div>
                                }
                                { dropdownId && !!notes.length && (notesToRender.length
                                        ? notesToRender.map((note, index) => (
                                            <div className="pt-10"
                                                 key={index}>
                                                <NoteItem
                                                    noteType={noteType}
                                                    patientId={patientId}
                                                    note={note}></NoteItem>
                                            </div>
                                        ))
                                        : <div className="flex">{translate('no results')}</div>
                                    )
                                }
                                { dropdownId && isShowAllButtonShouldBeShown &&
                                    <div className="t_right card_comment pt-10">
                                        <button className="t_uppercase add-comment-btn"
                                                onClick={showAllHandler}>{translate('show_all_records')}</button>
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }

            </Toggleable>
        </div>
    </div>);
}

export default PatientAsideNotes;
