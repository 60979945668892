import React, {useEffect} from 'react';
import  {DefaultFormState} from '../../hooks/formStateHandler';
import {ReactComponent as UserIcon} from '../../assets/img/icons/user-icon.svg';
import {translate} from '../../selectors/translations';
import {getLanguages} from '../../selectors/translations';
import {LanguageType} from '../../store/Translations/types';
import {ApplicationState} from '../../store';
import {connect} from 'react-redux';
import './AccountForm.css';
import {AccountFormMode} from "../../pages/accounts/constants";
import useFormStateHandlerG2, {FormValidators} from "../../hooks/formStateHandlerG2";
import greenHills from '../../assets/img/greenHills.svg';
import '../../assets/scss/layouts/greenHills.scss'
import { MedStaffDto, MedStaffRole } from "../../sdk"

type AccountFormState = MedStaffDto;

type PropsFromState = {
    languages: { key: LanguageType; inUse: boolean }[];
    authUser: MedStaffDto;
}

export type FormState = AccountFormState & DefaultFormState;

type allProps = FormState & PropsFromState & {
    mode: AccountFormMode;
};

const VALIDATORS: FormValidators = {
    email: [
        (value) => {
            if (!value) {
                return null;
            }
            return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.toString()) ? null : 'emailFormat';
        },
        (value) => {
            if (!value) {
                return 'email';
            }
            return null;
        }
    ],
    firstName: [
        (value) => value ? null : 'firstName'
    ],
    lastName: [
        (value) => value ? null : 'lastName'
    ],
    institution: [
        (value) => value ? null : 'institution'
    ],
    medPersonalNumber: [
        (value) => {
            if (!value) {
                return null;
            }
            const normalizedPNR = value ? value.toString().replace(/-/g, '').trim() : '';
            if (!/^[0-9]+$/.test(normalizedPNR)) {
                return 'invalidPNRFormat';
            }
            if (!normalizedPNR || normalizedPNR.length !== 12) {
                return 'invalidPNRLength';
            }
            return null;
        }
    ]
}

const genderArr = ['male', 'female'];

const AccountForm: React.FC<allProps> = (props: allProps) => {
    const {onSubmit, data, languages, authUser, mode} = props;
    const {
        inputs,
        errors,
        handleSubmit,
        handleInputChange,
        handleForceUpdate,
        handleInputFocus
    } = useFormStateHandlerG2(onSubmit, data, VALIDATORS);

    useEffect(() => {
        const _data = Object.keys(data)
            .map((key: string) => data[key as keyof AccountFormState] ? {[key]: data[key as keyof AccountFormState]} : {})
            .reduce((acc, obj) => ({...acc, ...obj}), {})
        handleForceUpdate(_data);
    }, [data]);

    const saveButtonLabel = mode === AccountFormMode.CreateNewAccount ? translate('createNewAccount') : translate('save');

    return (
        <div className="card-box patient-info">
            <div className="box-title">
                <i className="icon-holder patients-title_icon-holder"><UserIcon/></i>
                <h3>{translate('createCareGiveAccount')}</h3>
            </div>

            <div className="card_inputs-box">
                <div className="input-holder flex-item item-sm-3">
                    <label>{translate('firstName')}*</label>
                    <input value={inputs.firstName}
                           name="firstName"
                           type="text"
                           onChange={handleInputChange}
                           onFocus={handleInputFocus}
                           placeholder={translate('Type here...')}/>
                    {errors.includes('firstName') &&
                        <span className="login_form-error">{translate('Field must not be empty')}</span>}
                </div>

                <div className="input-holder flex-item item-sm-3">
                    <label>{translate('lastName')}*</label>
                    <input value={inputs.lastName}
                           name="lastName"
                           type="text"
                           onChange={handleInputChange}
                           onFocus={handleInputFocus}
                           placeholder={translate('Type here...')}/>
                    {errors.includes('lastName') &&
                        <span className="login_form-error">{translate('Field must not be empty')}</span>}
                </div>

                <div className="input-holder flex-item item-sm-3">
                    <label>{translate('email')}*</label>
                    <input value={inputs.email}
                           name="email"
                           type="text"
                           onChange={handleInputChange}
                           onFocus={handleInputFocus}
                           placeholder={translate('Type here...')}/>
                    {errors.includes('email') &&
                        <span className="login_form-error">{translate('Field must not be empty')}</span>}
                    {errors.includes('emailFormat') &&
                        <span className="login_form-error">{translate('patientForm.errors.emailIsNotValid')}</span>}
                </div>

                <div className="input-holder flex-item item-sm-3">
                    <label>{translate('personalNumber')}</label>
                    <input value={inputs.medPersonalNumber}
                           name="medPersonalNumber"
                           type="text"
                           onChange={handleInputChange}
                           onFocus={handleInputFocus}
                           placeholder={translate('pnumberFormat')}/>
                    {errors.includes('invalidPNRLength') &&
                        <span className="login_form-error">{translate('The field must be 12 characters long')}</span>}
                    {errors.includes('invalidPNRFormat') &&
                        <span className="login_form-error">{translate('errors.loginForm.invalidPNRFormat')}</span>}
                </div>

                <div className="input-holder flex-item item-sm-3"><label>{translate('gender')}</label>
                    <select onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            value={inputs.gender}
                            name="gender">
                        {genderArr.map((g: string, i: number) => <option key={i} value={g}>{translate(g)}</option>)}
                    </select></div>

                <div className="input-holder flex-item item-sm-3">
                    <label>{translate('institution')}</label>
                    <input
                        disabled={!authUser.roles.includes(MedStaffRole.ADMIN)}
                        value={inputs.institution}
                        name="institution"
                        type="text"
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        placeholder={translate('Type here...')}/>
                    {errors.includes('institution') &&
                        <span className="login_form-error">{translate('Field must not be empty')}</span>}
                </div>

                <div className="input-holder flex-item item-sm-4"><label>{translate('country')}</label>
                    <select onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            value={inputs.country}
                            name="country">
                        <option value="sv">{translate('country-sv')}</option>
                        <option value="zh">{translate('country-zh')}</option>
                        <option value="us">{translate('country-us')}</option>
                    </select>
                </div>

                <div className="input-holder flex-item item-sm-4"><label>{translate('role')}</label>
                    <select onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            value={inputs.roles}
                            name="roles">
                        <option value="doctor">{translate('doctor')}</option>
                        <option value="nurse">{translate('nurse')}</option>
                        <option value="therapist">{translate('therapist')}</option>
                        <option value="neuropsychologist">{translate('neuropsychologist')}</option>
                        <option value="psychologist">{translate('psychologist')}</option>
                        <option value="physiotherapist">{translate('physiotherapist')}</option>
                        <option value="speech_therapist">{translate('speech_therapist')}</option>
                        <option value="new_physiotherapist">{translate('new_physiotherapist')}</option>
                        <option value="assistant_nurse">{translate('assistant_nurse')}</option>
                        <option value="administrator">{translate('administrator')}</option>
                        <option value="radiologist">{translate('radiologist')}</option>
                        <option value="endocrinologist">{translate('endocrinologist')}</option>
                        <option value="clinical_pharmacologist">{translate('clinical_pharmacologist')}</option>
                        <option value="other">{translate('other')}</option>
                    </select>
                </div>

                <div className="input-holder flex-item item-sm-3"><label>{translate('selectLanguage')}</label>
                    <select onChange={handleInputChange}
                            value={inputs.language}
                            onFocus={handleInputFocus}
                            name="language">
                        {languages.map((l: { key: string }, i: number) => (
                            <option key={i} value={l.key}>{translate(l.key)}</option>))}
                    </select></div>
            </div>

            <div className="btns-holder">
                <button
                    onClick={handleSubmit}
                    className="t_uppercase g_btn g_btn--primary"
                    type="button">{saveButtonLabel}</button>
            </div>
            <img className="greenHills" src={greenHills}/>
        </div>
    )
};

const mapStateToProps = (state: ApplicationState): PropsFromState => ({
    languages: getLanguages(state),
    authUser: state.auth.user
});


export default connect(mapStateToProps)(AccountForm);
