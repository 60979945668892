export const initialQuestionnaireEn = {
  1: {
    type: 1,
    labels: [
      'Have you experienced increased memory difficulties?',
      ''
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  2: {
    type: 1,
    labels: [
      'Have you found it more difficult to read, count or difficult to express yourself?',
      ''
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  3: {
    type: 1,
    labels: [
      'Have you found it harder to concentrate?',
      ''
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  4: {
    type: 1,
    labels: [
      'Have you found it harder to find your way indoors?',
      ''
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  5: {
    type: 1,
    labels: [
      'Have you had more difficulty coping with practical chores at home, such as cooking, washing, making coffee, or keeping track of your medications?',
      ''
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  6: {
    type: 1,
    labels: [
      'Have you been falling more often than before?',
      ''
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  7: {
    type: 1,
    labels: [
      'Do you feel that your personality has changed, for example becoming more angry or finding difficulties in taking new initiatives?',
      ''
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
};

export const initialQuestionnaireSv = {
  1: {
    type: 1,
    labels: [
      'Har du upplevt att du fått tilltagande minnessvårigheter?',
      ''
    ],
    optionLabels: [
      ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  2: {
    type: 1,
    labels: [
      'Har du fått svårare att läsa, räkna eller svårt för att uttrycka dig?',
      ''
    ],
    optionLabels: [
      ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  3: {
    type: 1,
    labels: [
      'Har du fått det svårare att koncentrera dig?',
      ''
    ],
    optionLabels: [
      ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  4: {
    type: 1,
    labels: [
      'Har du fått svårare att hitta i din omgivning eller inomhus?',
      ''
    ],
    optionLabels: [
      ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  5: {
    type: 1,
    labels: [
      'Har du fått svårare att klara av praktiska sysslor i hemmet, såsom att laga mat, tvätta, koka kaffe eller hålla reda på dina mediciner?',
      ''
    ],
    optionLabels: [
      ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  6: {
    type: 1,
    labels: [
      'Har du fallit oftare än tidigare?',
      ''
    ],
    optionLabels: [
      ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  7: {
    type: 1,
    labels: [
      'Upplever du att din personlighet har förändrats, tex blir oftare arg eller svårare att ta nya initiativ?',
      ''
    ],
    optionLabels: [
      ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  }
};
