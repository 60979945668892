import {CognitiveConfig} from './../types/Common';
import CirclePatterns from '../utils/circlePatterns';

export const cognitiveEn: CognitiveConfig[] = [
  {
    titleKey: 'MEMORY',
    type: 'stm',
    testLabels: [
      {
        label: 'Delayed words recall', maxPoints: 5, type: 9, answerVariants: []
      },
      {
        label: 'Delayed pattern recall', maxPoints: 5, type: 15, answerVariants: [
          CirclePatterns,
          CirclePatterns,
          CirclePatterns,
          CirclePatterns,
          CirclePatterns
        ]
      }
    ]
  },
  {
    titleKey: 'VISUOSPATIAL ABILITIES',
    type: 'va',
    testLabels: [
      {
        label: 'Draw cube', maxPoints: 1, type: 2, answerVariants: [
        ]
      },
      {
        label: 'Draw clock', maxPoints: 5, type: 3, answerVariants: []
      },
      {
        label: 'Determine test time', maxPoints: 5, type: 16, answerVariants: []
      }
    ]
  },
  {
    titleKey: 'EXECUTIVE FUNCTIONING',
    type: 'stm',
    testLabels: [
      {label: 'Trail test', maxPoints: 3, type: 1, answerVariants: []},
      {
        label: 'Coding test', maxPoints: 6, type: 13, answerVariants: [
          ['+20 / 80']
        ]
      },
    ]
  },
  {
    titleKey: 'ATTENTION, CONCENTRATION, WORKING MEMORY',
    type: 'stm',
    testLabels: [
      {label: 'Immediate word recall', maxPoints: 1, type: 5, answerVariants: []},
      {
        label: 'Immediate pattern recall', maxPoints: 5, type: 14, answerVariants: [
          CirclePatterns,
          CirclePatterns,
          CirclePatterns,
          CirclePatterns,
          CirclePatterns
        ]
      },
      {label: 'Digit span forward test', maxPoints: 4, type: 6, answerVariants: []},
      {label: 'Digit span backward test', maxPoints: 4, type: 7, answerVariants: []},
      {label: 'Subtraction', maxPoints: 5, type: 8, answerVariants: []},
    ]
  },
  {
    titleKey: 'LANGUAGE',
    type: 'stm',
    testLabels: [
      {label: 'Naming animals', maxPoints: 1, type: 4, answerVariants: []},
      {
        label: 'Abstraction test', maxPoints: 2, type: 10, answerVariants: [
          ['They are animals',
            'They are vegetables',
            'They are vehicles',
            'They are fruits',
            'They are instruments',],
          ['They are animals',
            'They are vegetables',
            'They are vehicles',
            'They are fruits',
            'They are instruments',]
        ]
      },
      {label: 'Letter fluency test A + F', maxPoints: 2, type: 12, answerVariants: []},
    ]
  },
  {
    titleKey: 'ORIENTATION',
    type: 'stm',
    testLabels: [
      {
        label: 'Orientation', maxPoints: 5, type: 11, answerVariants: [
          [],
          ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          [],
          ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
          ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        ]
      }
    ]
  },
];

export const cognitiveSv: CognitiveConfig[] = [
  {
    titleKey: 'MINNE',
    type: 'stm',
    testLabels: [
      {
        label: 'Minne av ord, fördröjd', maxPoints: 5, type: 9, answerVariants: []
      },
      {
        label: 'Minne av mönster, fördröjd', maxPoints: 5, type: 15, answerVariants: [
          CirclePatterns,
          CirclePatterns,
          CirclePatterns,
          CirclePatterns,
          CirclePatterns
        ]
      }
    ]
  },
  {
    titleKey: 'VISUOSPATIAL FÖRMÅGA',
    type: 'va',
    testLabels: [
      {
        label: 'Rita kub', maxPoints: 1, type: 2, answerVariants: []
      },
      {
        label: 'Rita klocka', maxPoints: 5, type: 3, answerVariants: []
      },
      {
        label: 'Avgör tiden', maxPoints: 5, type: 16, answerVariants: []
      }
    ]
  },
  {
    titleKey: 'EXEKUTIVA FUNKTIONER',
    type: 'stm',
    testLabels: [
      {label: 'Strecktest', maxPoints: 3, type: 1, answerVariants: []},
      {
        label: 'Kodtest', maxPoints: 6, type: 13, answerVariants: [
          ['+20 / 80']
        ]
      },
    ]
  },
  {
    titleKey: 'UPPMÄRKSAMHET, KONCENTRATION, ARBETSMINNE',
    type: 'stm',
    testLabels: [
      {label: 'Minne av ord, omedelbar', maxPoints: 1, type: 5, answerVariants: []},
      {
        label: 'Minne av mönster, omedelbar', maxPoints: 5, type: 14, answerVariants: [
          CirclePatterns,
          CirclePatterns,
          CirclePatterns,
          CirclePatterns,
          CirclePatterns
        ]
      },
      {label: 'Siffertest, framlänges', maxPoints: 4, type: 6, answerVariants: []},
      {label: 'Siffertest, baklänges', maxPoints: 4, type: 7, answerVariants: []},
      {label: 'Siffertest, subtraktion', maxPoints: 5, type: 8, answerVariants: []},
    ]
  },
  {
    titleKey: 'SPRÅK',
    type: 'stm',
    testLabels: [
      {label: 'Namnge djur', maxPoints: 1, type: 4, answerVariants: []},
      {
        label: 'Abstraktionstest', maxPoints: 2, type: 10, answerVariants: [
          ['De är någon typ av djur',
            'De är någon typ av grönsak',
            'De är någon typ av fordon',
            'De är någon typ av frukt',
            'De är någon typ av instrument',],
          ['De är någon typ av djur',
            'De är någon typ av grönsak',
            'De är någon typ av fordon',
            'De är någon typ av frukt',
            'De är någon typ av instrument',]
        ]
      },
      {label: 'Bokstavstest A + F', maxPoints: 2, type: 12, answerVariants: []},
    ]
  },
  {
    titleKey: 'ORIENTERING',
    type: 'stm',
    testLabels: [
      {
        label: 'Orientering', maxPoints: 5, type: 11, answerVariants: [
          [],
          ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
          [],
          ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'],
          ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'],
        ]
      }
    ]
  },
];
