import React from 'react'
import Toggleable from '../../../rc-components/Toggleable'
import { DropdownCb } from '../../../types/Common'
import { translate } from '../../../selectors/translations'
import useTranslate from "../../../hooks/useTranslate"

type PropsFromState = {
  questions: Record<string, string>;
  answers: Record<string, string>;
  patientName: string
}

const QuestionCard = (props: PropsFromState) => {
  const {questions, answers, patientName} = props
  const translateLocalazy = useTranslate()

  return (
    <section className="spoiler_box">
      <Toggleable>
        {
          (dropdownId: string, setDropdownId: DropdownCb): React.ReactElement => (
            <div>
              <div className="spoiler_header diagnostic_question_header"
                   onClick={(): void => setDropdownId(dropdownId === 'question' ? '' : 'question')}>
                <div className="flex justify-between">
                  <div style={{width: '100%'}} className="flex-item--shrink">
                    <h3>{translate('Questions')}</h3>
                  </div>
                </div>
              </div>
              {
                dropdownId && (
                  <div className="spoiler_body">
                    {
                      Object.entries(questions).map(([slug, question], index) => {
                        const title = translateLocalazy(question).replaceAll('<strong>', '').replaceAll('</strong>', '')
                        const needsPatientName = title.trim().charAt(0).toUpperCase() !== title.trim().charAt(0)

                        return (
                          <div key={slug} style={{marginBottom: '10px'}} className="diagnostic_question_body p-10">
                            <div className="flex">
                              <div className="inner-wrapper inner-wrapper--answers flex-item--stretch">
                                <div className="test_description_title"
                                     dangerouslySetInnerHTML={{__html: `${index + 1}. ${needsPatientName ? patientName : ''} ${title}`}}></div>
                                <article className="diagnostic_question_details flex flex--wrap">
                                  <dl className="answer-item">
                                    <dt>{translate('answer')}</dt>
                                    <dd className="word-wrap-break">{translateLocalazy(answers[slug])}</dd>
                                  </dl>
                                </article>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }
            </div>
          )
        }
      </Toggleable>
    </section>
  )
}

export default QuestionCard
