import React, { useCallback, useState } from 'react'
import { translate } from '../../../../selectors/translations'
import { formatDate } from '../../../../utils/datetime'
import PatientSideHistoryLayout from "../PatientSideHistoryLayout"
import {
  InputType,
  QuestionerAnswerChangeHistoryDto, QuestionerAnswerChangeMetadataType,
  QuestionScreenDTO, RememberWordsAnswerDTO,
  ScreenType, SelectorInputMetadata, TwoChairsInputDTO, UniqueScreenMetadataShortDTO,
  UniqueScreenShortDTO, UniqueScreenType
} from "../../../../sdk"
import { generateInteractor } from "../../util"
import useTranslate from "../../../../hooks/useTranslate"

type PropsFromState = {
  history: QuestionerAnswerChangeHistoryDto[];
}

const PatientSideEditHistory: React.FC<PropsFromState> = (props: PropsFromState) => {
  const {history} = props
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const interactor = generateInteractor()
  const translateLocalazy = useTranslate()


  const handleExpandClick = useCallback((): void => {
    setIsExpanded((prevState) => !prevState)
  }, [])

  const screenName = (screen: QuestionScreenDTO | UniqueScreenShortDTO): string => {
    if (screen.kind === ScreenType.QUESTION) {
      return translateLocalazy((screen as QuestionScreenDTO).metadata.title) + ' (' + (screen as QuestionScreenDTO).metadata.input.kind + ')'
    } else {
      return translateLocalazy((screen as UniqueScreenShortDTO).metadata.title)
    }
  }

  const screenValue = (change: QuestionerAnswerChangeHistoryDto, value: any): string | null => {
    if (change.metadata.kind === QuestionerAnswerChangeMetadataType.POINTS) {
      return value.join(', ')
    }

    if (change.metadata.screen.kind === ScreenType.UNIQUE) {
      switch ((change.metadata.screen.metadata as UniqueScreenMetadataShortDTO).config.kind) {
        case UniqueScreenType.REMEMBER_WORDS:
        case UniqueScreenType.LETTER:
        case UniqueScreenType.NAME_REMEMBERED_WORDS: {
          return (value as RememberWordsAnswerDTO).words?.join(', ') || null
        }
        default:
          return null
      }
    }

    const inputScreen = (change.metadata.screen as QuestionScreenDTO)
    switch (inputScreen.metadata.input.kind) {
      case InputType.CHECKBOX_GROUP:
        const possibleCheckboxAnswers = (inputScreen.metadata.input.metadata as SelectorInputMetadata).values
        const checkboxValueTitle = possibleCheckboxAnswers.filter((a) => value.includes(a.value))
        return checkboxValueTitle.map(t => translateLocalazy(t.label)).join(', ')
      case InputType.RADIO_GROUP:
      case InputType.SELECTOR:
        const possibleAnswers = (inputScreen.metadata.input.metadata as SelectorInputMetadata).values
        const valueTitle = possibleAnswers.find((a) => a.value == value)
        return translateLocalazy(valueTitle?.label || '')
      case InputType.FREE_TEXT:
        return value
      case InputType.TWO_CHOICE:
        return value == '1' ? translateLocalazy((inputScreen.metadata.input as TwoChairsInputDTO).metadata.firstLabel) :
          translateLocalazy((inputScreen.metadata.input as TwoChairsInputDTO).metadata.secondLabel)
    }
  }

  return (
    <PatientSideHistoryLayout
      isExpanded={isExpanded}
      handleExpandClick={handleExpandClick}
      title={translateLocalazy('patient.history.edit-answers')}
    >
      {isExpanded &&
          <div className="card_body">
            {
              history.length
                ? history
                  .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt))
                  .map((h, index) => (
                    <article key={index} className="card_comment-box">
                      <div className="comment_header">
                        <div className="comment_header_title">
                          {translateLocalazy('patient.history.changed-title').replace('{metadata.kind}', h.metadata.kind)}
                          <b> &quot;{screenName(h.metadata.screen)}&quot;</b>
                        </div>
                        <div className="comment_header_author">
                          {interactor(h.interactor)} - {formatDate(h.createdAt)}
                        </div>
                      </div>
                      <div className="comment_content">
                        <p>
                          {translateLocalazy("history.old-value")}: &nbsp;
                          {screenValue(h, h.metadata.originalData)}
                          <br/>
                          {translateLocalazy("history.new-value")}:&nbsp;
                          {screenValue(h, h.metadata.newData)}
                        </p>
                      </div>
                    </article>
                  ))
                : <article className="card_comment-box">
                  <div className="comment_header">
                    <div className="comment_header_title">{translate('no results')}</div>
                  </div>
                </article>
            }
          </div>
      }
    </PatientSideHistoryLayout>
  )
}

export default PatientSideEditHistory
