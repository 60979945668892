import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import callApi from '../../utils/apiCaller';
import { AppointmentsEnum } from './types';
import { fetchMeetingsSuccess, fetchError } from './action'
import { ApiDashboardPath, ApiMethods} from '../../types/Api';

function* handleMeetingsFetch(params: any): Generator<any> {
  const path = `${ApiDashboardPath.GET}/meetings?startDate=${params.datesRange[0]}&endDate=${params.datesRange[1]}`;
  try {
    const res: any = yield call(callApi, ApiMethods.GET, path)

    if (res.error) {
      yield put(fetchError(res.error))
    } else {
      // yield put(fetchMeetingsSuccess(res))
      return
    }
  } catch (err) {
    if (err instanceof Error && err.stack) {
      yield put(fetchError(err.stack))
    } else {
      yield put(fetchError('An unknown error occured.'))
    }
  }
}

function* watchMeetingsFetchRequest(): Generator {
  yield takeEvery(AppointmentsEnum.GET_MEETINGS_REQUEST, handleMeetingsFetch)
}


// GET APPOINTMENTS LIST
function* handleMockyFetch(): Generator<any> {
  const mokyData = [
    {
      date: new Date(new Date().setDate(new Date().getDate() + 3)).getTime(),
      appointments: [
        {
          type: 'past',
          _id: '1',
          meetingDateStart: 1579417200000,
          meetingDateEnd: 1579420800000,
          patientName: 'John Sv...',
          meetingId: '1',
        },
      ]
    },

    {
      date: new Date(new Date().setDate(new Date().getDate() + 4)).getTime(),
      appointments: [
        {
          type: 'empty',
          meetingDateStart: 1579503600000,
          meetingDateEnd: 1579507200000,
          _id: '',
          patientName: '',
          meetingId: ''
        },
      ]
    },

    {
      date: new Date(new Date().setDate(new Date().getDate() + 5)).getTime(),
      appointments: [
        {
          type: 'future',
          _id: '9',
          meetingDateStart: 1579590000000,
          meetingDateEnd: 1579593600000,
          patientName: 'John Sv...',
          meetingId: '9',
        },
      ]
    },

  ]
  yield put(fetchMeetingsSuccess(mokyData))
}

function* watchMockyFetchRequest(): Generator {
  yield takeEvery(AppointmentsEnum.GET_MOCKY_MEETINGS_REQUEST, handleMockyFetch)
}

function* appointmentsSaga(): Generator {
  yield all([
    fork(watchMockyFetchRequest),
    fork(watchMeetingsFetchRequest)
  ])
}

export default appointmentsSaga;
