import { AnamnesisConfig } from '../../types/Common';
import {
    DoctorNoteDto,
    DoctorNoteType,
    LoginCodeDto, ObjectId,
    PatientProfileDTO,
    QuestionerAnswersDTOOutput,
    UserDto
} from "../../sdk"

export interface Answer {
    data: string | number | (string | number | [])[];
    key: string;
    type: number;
    result: object | string | number;
    expect: object | string | number | [];
    updatedAt: string;
    createdAt: string;
    _id: string;
    timeFromTouch: number | undefined;
    grade: number;
    externalId: string;
    corrections: [];
    timeUsed: number;
    isRead: boolean;
    setup: any;
}

export interface FormattedAnswer {
    data: string | number | (string | number | [])[];
    key: string;
    type: number;
    record?: any;
    externalId: string;
    result: object | string | number;
    expect: object | string | number | [];
    expectData: any[];
    resultData: any[];
    updatedAt: string;
    createdAt: string;
    _id: string;
    timeFromTouch: number | undefined;
    grade: number;
    corrections: [];
    timeUsed: number;
    isRead: boolean;
    setup: any;
}

export interface TestCategoryData {
    type: string;
    spentTime: number;
    preliminaryResult: number;
    answers: Answer[][];
}

export interface TestDeviceInfo {
    memory: string[];
    manufacturer: string[];
    brand: string[];
    model: string[];
    osVersion: string[];
}

export interface ViewingAttempt {
    accountId: string;
    date: number;
}

export interface Survey {
    _id: string;
    updatedAt: string;
    createdAt: string;
    type: string;
    userId: string;
    gender?: string;
    sectionSet?: number[];
    answers: Answer[];
    testData: TestCategoryData[];
    totalPoints: number;
    totalTestTime: number;
    earlyExit: boolean;
    completed: boolean;
    deviceInfo: TestDeviceInfo;
    testsHistoryChanges: AnswerHistoryChanges;
    viewingAttempts: { [key: string]: ViewingAttempt };
}

export interface AnamnesisSurveyConfig {
    sections: AnamnesisSectionData[];
    questions: { [key: number]: AnamnesisConfig };
}

export interface Downloads {
    actionData: object | null;
    actionInitiator: string;
    actionInitiatorRole: string;
    datetime: number;
}

export interface AnswerHistoryChanges {
    datetime: number;
    answerChangerRole: string;
    answerType: number;
    answerId: string;
    answerAttempt: number;
    answerValueFrom: string | number;
    answerValueTo: string | number;
    answerChanger: string;
}


export interface Recordings {
    transcription: string;
    name: string;
    path: string;
    userId: string;
    _id: string;
    url: string;
}

// @ts-ignore
export interface PatientProfileState extends PatientProfileDTO {
  loginCode: LoginCodeDto | null
  recordings: Recordings[];
  bookings: any[];
  downloads: Downloads[];
  pdfFileLink: string;
  viewingAttempts: ViewingAttemptsResponse[];
  pdfReportType: string;
  redirectAfterCreation: boolean;
  questionerAnswers: QuestionerAnswersDTOOutput[] | []
  measurements: Survey[];
  cognitiveTests: any[];
  // TODO remove usage
  anamnesisSectionSet: number[]
  anamnesisQuestions: { [key: number]: AnamnesisQuestionData };
  anamnesisSections: AnamnesisSectionData[];
  anamnesisQuestionsNew: { [key: number]: AnamnesisQuestionData };
  anamnesisSectionsNew: AnamnesisSectionData[];
  age: string;
}

export interface AdjustedLoginCode {
    expirationDate: string;
    code: string | null;
}

export interface NormalizedNote {
    _id: ObjectId
    kind: DoctorNoteType
    text: string
    updatedAt: string
    normalizedComment: string
    isNurse: boolean
    doctorName: string
    doctorRole: string
}

export interface NoteRequestPayload {
    userId: string;
    comment: string;
}

export interface PatientNoteRequestPayload {
    userId: string;
    comment: string;
    noteType: DoctorNoteType;
    cb: (result: boolean) => void;
}

export interface DownloadFileResponse {
    downloads: Downloads[];
    fileLink: string;
    status: boolean;
}

export interface ViewingAttemptsResponse {
    accountName: string;
    accountRole: string;
    accountInstitution: string;
    datetime: number;
}

export interface AnamnesisRiskIntervals {
    range: string;
    titleKey: string;
}

export interface AnamnesisRiskInformation {
    note: {
        descriptionKey: string;
        intervals: { [key: string]: AnamnesisRiskIntervals[] };
    };
    maxScore: number;
}

export interface AnamnesisSectionData {
    title: string;
    id: number;
    questionsSet: number[];
    riskInformation: null | AnamnesisRiskInformation;
}

export interface AnamnesisQuestionData {
    type: number;
    labels: string[];
    optionLabels: [[string]];
    disabled: boolean;
    sort: number;
    showIdk: boolean;
    skipIfNo: number | null;
    expandOn: number | null;
}

export interface HomeScreenMapData {
    cognitiveTests: number[];
    anamnesisFlex: number[];
    familySurvey: number[];
}

export interface CreatePatientPayload {
    _id?: string;
    birthDate: string;
    email: string;
    firstName: string;
    gender: string;
    username: string | null;
    anamnesisSectionSet: number[];
    phoneNumber: string | null;
    homeScreenMap: HomeScreenMapData;
    country: string;
    institution: string;
    sharedInstitution: string[];
    language: string;
    lastName: string;
    personalNumber: string | null;
    resetTestStatuses?: boolean | null;
}

export enum PatientProfileActionTypes {
    GET_PATIENT_REQUEST = '@@patientProfile/GET_PATIENT_REQUEST',
    GET_PATIENT_SUCCESS = '@@patientProfile/GET_PATIENT_SUCCESS',
    GET_PATIENT_FAILURE = '@@patientProfile/GET_PATIENT_FAILURE',
    UNSET_PATIENT_PROFILE = '@@patientProfile/UNSET_PATIENT_PROFILE',

    POST_NOTE_REQUEST = '@@patientProfile/POST_NOTES_REQUEST',
    POST_NOTE_SUCCESS = '@@patientProfile/POST_NOTES_SUCCESS',
    POST_NOTE_FAILURE = '@@patientProfile/POST_NOTE_FAILURE',

    PUT_NOTE_REQUEST = '@@patientProfile/PUT_NOTES_REQUEST',
    PUT_NOTE_SUCCESS = '@@patient/PUT_NOTE_SUCCESS',

    UPLOAD_PROFILE_IMAGES_SUCCESS = '@@patientProfile/UPLOAD_PROFILE_IMAGES_SUCCESS',
    UPLOAD_PROFILE_IMAGES_FAILURE = '@@patientProfile/UPLOAD_PROFILE_IMAGES_FAILURE',

    GET_VIEWING_ATTEMPTS_REQUEST = '@@patientProfile/GET_VIEWING_ATTEMPTS_REQUEST',
    GET_VIEWING_ATTEMPTS_SUCCESS = '@@patientProfile/GET_VIEWING_ATTEMPTS_SUCCESS',
    GET_VIEWING_ATTEMPTS_FAILURE = '@@patientProfile/GET_VIEWING_ATTEMPTS_FAILURE',

    GET_ANAMNESIS_CONFIG_REQUEST = '@@patientProfile/GET_ANAMNESIS_CONFIG_REQUEST',
    GET_ANAMNESIS_CONFIG_SUCCESS = '@@patientProfile/GET_ANAMNESIS_CONFIG_SUCCESS',
    GET_ANAMNESIS_CONFIG_FAILURE = '@@patientProfile/GET_ANAMNESIS_CONFIG_FAILURE',

    UPDATE_SURVEY_ANSWER_REQUEST = '@@patientProfile/UPDATE_SURVEY_ANSWER_REQUEST',
    UPDATE_SURVEY_ANSWER_SUCCESS = '@@patientProfile/UPDATE_SURVEY_ANSWER_SUCCESS',
    UPDATE_SURVEY_ANSWER_FAILURE = '@@patientProfile/UPDATE_SURVEY_ANSWER_FAILURE',

    ADD_SURVEY_ANSWER_REQUEST = '@@patientProfile/ADD_SURVEY_ANSWER_REQUEST',
    ADD_SURVEY_ANSWER_SUCCESS = '@@patientProfile/ADD_SURVEY_ANSWER_SUCCESS',
    ADD_SURVEY_ANSWER_FAILURE = '@@patientProfile/ADD_SURVEY_ANSWER_FAILURE',

    UPDATE_PATIENTS_REQUEST = '@@patientProfile/UPDATE_PATINTS_REQUEST',
    UPDATE_PATIENTS_SUCCESS = '@@patientProfile/UPDATE_PATIENTS_SUCCESS',
    UPDATE_PATIENTS_FAILURE = '@@patientProfile/UPDATE_PATIENTS_FAILURE',

    CREATE_PATIENTS_REQUEST = '@@patientProfile/CREATE_PATIENTS_REQUEST',
    CREATE_PATIENTS_SUCCESS = '@@patientProfile/CREATE_PATIENTS_SUCCESS',
    CREATE_PATIENTS_FAILURE = '@@patientProfile/CREATE_PATIENTS_FAILURE',
    CREATE_PATIENTS_RESET = '@@patientProfile/CREATE_PATIENTS_RESET',

    SEND_WELCOMING_EMAIL_REQUEST = '@@patientProfile/SEND_WELCOMING_EMAIL_REQUEST',
    SEND_WELCOMING_EMAIL_SUCCESS = '@@patientProfile/SEND_WELCOMING_EMAIL_SUCCESS',
    SEND_WELCOMING_EMAIL_FAILURE = '@@patientProfile/SEND_WELCOMING_EMAIL_FAILURE',

    SEND_TEXT_MESSAGE_REQUEST = '@@patientProfile/SEND_TEXT_MESSAGE_REQUEST',
    SEND_WELCOMING_TEXT_MESSAGE_REQUEST = '@@patientProfile/SEND_WELCOMING_TEXT_MESSAGE_REQUEST',
    SEND_TEXT_MESSAGE_SUCCESS = '@@patientProfile/SEND_TEXT_MESSAGE_SUCCESS',
    SEND_TEXT_MESSAGE_FAILURE = '@@patientProfile/SEND_TEXT_MESSAGE_FAILURE',

    SET_TEST_AS_VIEWED_REQUEST = '@@patientProfile/SET_TEST_AS_VIEWED_REQUEST',
    SET_TEST_AS_VIEWED_SUCCESS = '@@patientProfile/SET_TEST_AS_VIEWED_SUCCESS',
    SET_TEST_AS_VIEWED_FAILURE = '@@patientProfile/SET_TEST_AS_VIEWED_FAILURE',

    SET_PDF_REPORT_TYPE = '@@patientProfile/SET_PDF_REPORT_TYPE',
    REFRESH_LOGIN_CODE_REQUEST = '@@patientProfile/REFRESH_LOGIN_CODE_REQUEST',
    REFRESH_LOGIN_CODE_SUCCESS = '@@patientProfile/REFRESH_LOGIN_CODE_SUCCESS',

    GET_NEW_API_QUESTIONER_ANSWERS_REQUEST = '@@patientProfile/GET_NEW_API_QUESTIONER_ANSWERS_REQUEST',
    GET_NEW_API_QUESTIONER_ANSWERS_SUCCESS = '@@patientProfile/GET_NEW_API_QUESTIONER_ANSWERS_SUCCESS',
    GET_NEW_API_QUESTIONER_ANSWERS_FAILURE = '@@patientProfile/GET_NEW_API_QUESTIONER_ANSWERS_FAILURE',

}

export interface GetNewApiQuestionerAnswersRequest {
    type: typeof PatientProfileActionTypes.GET_NEW_API_QUESTIONER_ANSWERS_REQUEST;
    patientId: string;
}

interface GetNewApiQuestionerAnswersFailure {
    type: typeof PatientProfileActionTypes.GET_NEW_API_QUESTIONER_ANSWERS_FAILURE;
    errorMsg: string;
}

interface GetNewApiQuestionerAnswersSuccess {
    type: typeof PatientProfileActionTypes.GET_NEW_API_QUESTIONER_ANSWERS_SUCCESS;
    payload: QuestionerAnswersDTOOutput[];
}

interface GetPatientRequest {
    type: typeof PatientProfileActionTypes.GET_PATIENT_REQUEST;
    patientId: string;
}

interface GetPatientFailure {
    type: typeof PatientProfileActionTypes.GET_PATIENT_FAILURE;
    errorMsg: string;
}

interface GetPatientSuccess {
    type: typeof PatientProfileActionTypes.GET_PATIENT_SUCCESS;
    patient: PatientProfileState;
}

export interface PostNoteRequest {
    type: PatientProfileActionTypes.POST_NOTE_REQUEST;
    data: NoteRequestPayload;
}

export interface PatientPostNoteRequest {
    type: PatientProfileActionTypes.POST_NOTE_REQUEST;
    data: PatientNoteRequestPayload;
}

interface PostNoteSuccess {
    type: PatientProfileActionTypes.POST_NOTE_SUCCESS;
    response: DoctorNoteDto;
}

interface PostNoteFailure {
    type: PatientProfileActionTypes.POST_NOTE_FAILURE;
    errorMsg: string;
}

export interface PutNoteRequest {
    type: PatientProfileActionTypes.PUT_NOTE_REQUEST;
    data: NoteRequestPayload;
    noteId: string;
}

export interface PatientPutNoteRequest {
    type: PatientProfileActionTypes.PUT_NOTE_REQUEST;
    data: PatientNoteRequestPayload;
    noteId: string;
}

interface PutNoteSuccess {
    type: PatientProfileActionTypes.PUT_NOTE_SUCCESS;
    response: DoctorNoteDto;
}

interface UnsetPatientProfile {
    type: typeof PatientProfileActionTypes.UNSET_PATIENT_PROFILE;
}

interface UploadProfileImagesSuccess {
    type: typeof PatientProfileActionTypes.UPLOAD_PROFILE_IMAGES_SUCCESS;
    data: DownloadFileResponse;
}

interface UploadProfileImagesFailure {
    type: typeof PatientProfileActionTypes.UPLOAD_PROFILE_IMAGES_FAILURE;
    errorMsg: string;
}

export interface ViewingAttemptsRequest {
    type: typeof PatientProfileActionTypes.GET_VIEWING_ATTEMPTS_REQUEST;
    userId: string;
}

export interface ViewingAttemptsSuccess {
    type: typeof PatientProfileActionTypes.GET_VIEWING_ATTEMPTS_SUCCESS;
    response: { attempts: ViewingAttemptsResponse[] };
}

export interface ViewingAttemptsFailure {
    type: typeof PatientProfileActionTypes.GET_VIEWING_ATTEMPTS_FAILURE;
    errorMsg: string;
}

export interface GetAnamnesisConfigRequest {
    type: typeof PatientProfileActionTypes.GET_ANAMNESIS_CONFIG_REQUEST;
    data: { userId: string; language?: string };
}

export interface GetAnamnesisConfigSuccess {
    type: typeof PatientProfileActionTypes.GET_ANAMNESIS_CONFIG_SUCCESS;
    response: {
        sections: AnamnesisSectionData[];
        questions: { [key: number]: AnamnesisQuestionData };
        sectionsNew: AnamnesisSectionData[];
        questionsNew: { [key: number]: AnamnesisQuestionData };
    };
}

export interface GetAnamnesisConfigFailure {
    type: typeof PatientProfileActionTypes.GET_ANAMNESIS_CONFIG_FAILURE;
    errorMsg: string;
}

export interface UpdatePatientsRequest {
    type: typeof PatientProfileActionTypes.UPDATE_PATIENTS_REQUEST;
    data: CreatePatientPayload;
}

export interface UpdatePatientsSuccess {
    type: typeof PatientProfileActionTypes.UPDATE_PATIENTS_SUCCESS;
    data: UserDto;
}

export interface UpdatePatientsFailure {
    type: typeof PatientProfileActionTypes.UPDATE_PATIENTS_FAILURE;
    errorMsg: string;
}

export interface CreatePatientsRequest {
    type: typeof PatientProfileActionTypes.CREATE_PATIENTS_REQUEST;
    data: CreatePatientPayload;
}

export interface CreatePatientsSuccess {
    type: typeof PatientProfileActionTypes.CREATE_PATIENTS_SUCCESS;
    data: UserDto;
}

export interface CreatePatientsFailure {
    type: typeof PatientProfileActionTypes.CREATE_PATIENTS_FAILURE;
    errorMsg: string;
}

export interface CreatePatientsReset {
    type: typeof PatientProfileActionTypes.CREATE_PATIENTS_RESET;
}

export interface SendEmailRequest {
    type: typeof PatientProfileActionTypes.SEND_WELCOMING_EMAIL_REQUEST;
    data: {
        withInstruction: boolean;
        patientId: string;
    };
}

export interface SendEmailSuccess {
    type: typeof PatientProfileActionTypes.SEND_WELCOMING_EMAIL_SUCCESS;
}

export interface SendTextMessageRequest {
    type: PatientProfileActionTypes.SEND_TEXT_MESSAGE_REQUEST;
    data: {
        textMessageType: string;
        patientId: string;
    };
}

export interface SendLoginCodeTextMessageRequest {
    type: PatientProfileActionTypes.SEND_WELCOMING_TEXT_MESSAGE_REQUEST;
    data: {
        phoneNumber: string;
        patientId: string;
    };
}

export interface SendTextMessageSuccess {
    type: PatientProfileActionTypes.SEND_TEXT_MESSAGE_SUCCESS;
}

export interface SetTestAsViewedRequest {
    type: typeof PatientProfileActionTypes.SET_TEST_AS_VIEWED_REQUEST;
    data: {
        testId: string;
        sectionId: number;
    };
}

export interface SetTestAsViewedSuccess {
    type: typeof PatientProfileActionTypes.SET_TEST_AS_VIEWED_SUCCESS;
    response: {
        type: string;
        testId: string;
        viewingAttempts: { [key: number]: ViewingAttempt };
    };
}

export interface SetTestAsViewedFailure {
    type: typeof PatientProfileActionTypes.SET_TEST_AS_VIEWED_FAILURE;
    errorMsg: string;
}

export interface RefreshLoginCodeRequest {
    type: typeof PatientProfileActionTypes.REFRESH_LOGIN_CODE_REQUEST;
    payload: {
        patientId: string;
    };
}

export interface RefreshLoginCodeSuccess {
    type: typeof PatientProfileActionTypes.REFRESH_LOGIN_CODE_SUCCESS;
    payload: LoginCodeDto
}

export interface SetPdfReportType {
    type: typeof PatientProfileActionTypes.SET_PDF_REPORT_TYPE;
    payload: 'patient' | 'doctor';
}

export type PatientProfileActionType =
    GetPatientRequest |
    GetPatientSuccess |
    GetPatientFailure |
    PostNoteRequest |
    PatientPostNoteRequest |
    PostNoteSuccess |
    PostNoteFailure |
    PatientPutNoteRequest |
    PutNoteRequest |
    PutNoteSuccess |
    UnsetPatientProfile |
    UploadProfileImagesSuccess |
    UploadProfileImagesFailure |
    ViewingAttemptsRequest |
    ViewingAttemptsSuccess |
    ViewingAttemptsFailure |
    GetAnamnesisConfigRequest |
    GetAnamnesisConfigSuccess |
    GetAnamnesisConfigFailure |
    UpdatePatientsRequest |
    UpdatePatientsSuccess |
    UpdatePatientsFailure |
    CreatePatientsFailure |
    CreatePatientsRequest |
    CreatePatientsSuccess |
    CreatePatientsReset |
    SendEmailRequest |
    SendEmailSuccess |
    SendTextMessageRequest |
    SendLoginCodeTextMessageRequest |
    SendTextMessageSuccess |
    SetTestAsViewedRequest |
    SetTestAsViewedSuccess |
    SetTestAsViewedFailure |
    SetPdfReportType |
    RefreshLoginCodeRequest |
    RefreshLoginCodeSuccess |
    GetNewApiQuestionerAnswersRequest |
    GetNewApiQuestionerAnswersSuccess |
    GetNewApiQuestionerAnswersFailure;
