import {ApplicationState} from "../store";
import {AppState} from "../store/App/types";
import {createSelector} from "reselect";

export const selectAppState = (state: ApplicationState): AppState => state.appState;

export const isSideMenuExpanded = createSelector(
    selectAppState,
    (state: AppState): boolean => {
        return state.isSideMenuExpanded;
    }
);

export const selectHttpAction = createSelector(
    selectAppState,
    (state: AppState): any => {
        return state.httpPendingAction;
    }
);
