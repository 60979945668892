import {
  PatientProfileActionType,
  PatientProfileActionTypes,
  NoteRequestPayload,
  ViewingAttemptsResponse,
  AnamnesisQuestionData,
  AnamnesisSectionData,
  CreatePatientPayload,
  ViewingAttempt, PatientNoteRequestPayload, PatientProfileState
} from './types';
import { DoctorNoteDto, LoginCodeDto, QuestionerAnswersDTOOutput, UserDto } from "../../sdk"

export const getPatient = (patientId: string): PatientProfileActionType => ({type: PatientProfileActionTypes.GET_PATIENT_REQUEST, patientId});
export const getPatientError = (error: string): PatientProfileActionType => ({type: PatientProfileActionTypes.GET_PATIENT_FAILURE, errorMsg: error});
export const getPatientSuccess = (data: PatientProfileState): PatientProfileActionType => ({type: PatientProfileActionTypes.GET_PATIENT_SUCCESS, patient: data});
export const unsetPatientProfile = (): PatientProfileActionType => ({type: PatientProfileActionTypes.UNSET_PATIENT_PROFILE}) ;

export const postNoteRequest = (data: NoteRequestPayload): PatientProfileActionType => ({type: PatientProfileActionTypes.POST_NOTE_REQUEST, data});
export const postNoteSuccess = (response: DoctorNoteDto): PatientProfileActionType => ({type: PatientProfileActionTypes.POST_NOTE_SUCCESS, response});
export const postNoteFailure = (errorMsg: string): PatientProfileActionType => ({type: PatientProfileActionTypes.POST_NOTE_FAILURE, errorMsg});
export const patientPostNoteRequest = (data: PatientNoteRequestPayload): PatientProfileActionType => ({type: PatientProfileActionTypes.POST_NOTE_REQUEST, data});

export const updatePatientRequest = (data: any): PatientProfileActionType => ({type: PatientProfileActionTypes.UPDATE_PATIENTS_REQUEST, data});
export const updatePatientSuccess = (data: UserDto): PatientProfileActionType => ({type: PatientProfileActionTypes.UPDATE_PATIENTS_SUCCESS, data});
export const updatePatientFailure = (error: string): PatientProfileActionType => ({type: PatientProfileActionTypes.UPDATE_PATIENTS_FAILURE, errorMsg: error});

export const createPatientRequest = (data: CreatePatientPayload): PatientProfileActionType => ({type: PatientProfileActionTypes.CREATE_PATIENTS_REQUEST, data});
export const createPatientSuccess = (data: UserDto): PatientProfileActionType => ({type: PatientProfileActionTypes.CREATE_PATIENTS_SUCCESS, data});
export const createPatientFailure = (error: string): PatientProfileActionType => ({type: PatientProfileActionTypes.CREATE_PATIENTS_FAILURE, errorMsg: error});
export const createPatientReset = (): PatientProfileActionType => ({type: PatientProfileActionTypes.CREATE_PATIENTS_RESET});

export const putNoteRequest = (data: NoteRequestPayload, noteId: string): PatientProfileActionType => ({type: PatientProfileActionTypes.PUT_NOTE_REQUEST, data, noteId});
export const putNoteSuccess = (response: DoctorNoteDto): PatientProfileActionType => ({type: PatientProfileActionTypes.PUT_NOTE_SUCCESS, response});
export const patientPutNoteRequest = (data: PatientNoteRequestPayload, noteId: string): PatientProfileActionType => ({type: PatientProfileActionTypes.PUT_NOTE_REQUEST, data, noteId});

export const getViewingAttempts = (userId: string): PatientProfileActionType => ({type: PatientProfileActionTypes.GET_VIEWING_ATTEMPTS_REQUEST, userId});
export const getViewingAttemptsSuccess = (response: {attempts: ViewingAttemptsResponse[]}): PatientProfileActionType => ({type: PatientProfileActionTypes.GET_VIEWING_ATTEMPTS_SUCCESS, response});
export const getViewingAttemptsFailure = (err: string): PatientProfileActionType => ({type: PatientProfileActionTypes.GET_VIEWING_ATTEMPTS_FAILURE, errorMsg: err});

export const getAnamnesisConfig = (userId: string, language?: string): PatientProfileActionType => ({type: PatientProfileActionTypes.GET_ANAMNESIS_CONFIG_REQUEST, data:{ userId, language }});
export const getAnamnesisConfigSuccess = (response: { sections: AnamnesisSectionData[]; questions: {[key: number]: AnamnesisQuestionData }; sectionsNew: AnamnesisSectionData[]; questionsNew: {[key: number]: AnamnesisQuestionData }}): PatientProfileActionType => ({type: PatientProfileActionTypes.GET_ANAMNESIS_CONFIG_SUCCESS, response});
export const getAnamnesisConfigFailure = (errorMsg: string): PatientProfileActionType => ({type: PatientProfileActionTypes.GET_ANAMNESIS_CONFIG_FAILURE, errorMsg});

export const sendTextMessageRequest = (data: {textMessageType: string; patientId: string;}): PatientProfileActionType => ({type: PatientProfileActionTypes.SEND_TEXT_MESSAGE_REQUEST, data})
export const sendLoginCodeTextMessageRequest = (data: {phoneNumber: string; patientId: string}): PatientProfileActionType => ({type: PatientProfileActionTypes.SEND_WELCOMING_TEXT_MESSAGE_REQUEST, data})
export const sendTextMessageSuccess = (): PatientProfileActionType => ({type: PatientProfileActionTypes.SEND_TEXT_MESSAGE_SUCCESS});

export const sendEmailRequest = (data: {withInstruction: boolean; patientId: string}): PatientProfileActionType => ({type: PatientProfileActionTypes.SEND_WELCOMING_EMAIL_REQUEST, data})
export const sendEmailSuccess = (): PatientProfileActionType => ({type: PatientProfileActionTypes.SEND_WELCOMING_EMAIL_SUCCESS});

// TODO implement on new backend
export const setTestAsViewed = (data: {testId: string; sectionId: number;}): PatientProfileActionType => ({type: PatientProfileActionTypes.SET_TEST_AS_VIEWED_REQUEST, data})
export const setTestAsViewedSuccess = (response: {type: string; testId: string; viewingAttempts: { [key: number]: ViewingAttempt }}): PatientProfileActionType => ({type: PatientProfileActionTypes.SET_TEST_AS_VIEWED_SUCCESS, response});
export const setTestAsViewedFailure = (errorMsg: string): PatientProfileActionType => ({type: PatientProfileActionTypes.SET_TEST_AS_VIEWED_FAILURE, errorMsg});

export const setPdfReportType = (payload: 'doctor' | 'patient'): PatientProfileActionType => ({type: PatientProfileActionTypes.SET_PDF_REPORT_TYPE, payload});

export const refreshLoginCodeRequest = (payload: { patientId: string }): PatientProfileActionType => ({type: PatientProfileActionTypes.REFRESH_LOGIN_CODE_REQUEST, payload});
export const refreshLoginCodeSuccess = (payload: LoginCodeDto): PatientProfileActionType => ({type: PatientProfileActionTypes.REFRESH_LOGIN_CODE_SUCCESS, payload});

export const getNewApiQuestionerAnswersFailure = (error: string): PatientProfileActionType => ({type: PatientProfileActionTypes.GET_NEW_API_QUESTIONER_ANSWERS_FAILURE, errorMsg: error});
export const getNewApiQuestionerAnswersSuccess = (payload: QuestionerAnswersDTOOutput[]): PatientProfileActionType => ({type: PatientProfileActionTypes.GET_NEW_API_QUESTIONER_ANSWERS_SUCCESS, payload});
