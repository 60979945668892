import { all, put } from 'redux-saga/effects'
import { InstitutionEnum } from "./types";
import { fetchInstitutionsSuccess } from "./action";
import { bindHandler } from "../bind-handler";
import { apiV2, wrapResponse } from "../../repositories/repository"

function* handleGetInstitutions(): Generator<any> {
  const res: any = yield wrapResponse(apiV2.institution.getAllInstitutions())
  if (res.error) {
    // yield put(fetchError(res.error))
  } else {
    yield put(fetchInstitutionsSuccess(res.data))
  }
}


export default function* institutionsSaga(): Generator {
  yield all([
    bindHandler(InstitutionEnum.GET_INSTITUTIONS_REQUEST, handleGetInstitutions),
  ])
}

