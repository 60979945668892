import {
  SmsTemplatesActionTypes,
  SmsTemplatesActionType,
} from './types'
import { ManageSmsTemplateDto, SmsTemplateDto, SmsTemplateWithTranslationsDto } from "../../sdk"

export const getSmsTemplates = (): SmsTemplatesActionType => ({type: SmsTemplatesActionTypes.GET_SMS_TEMPLATE_REQUEST})
export const getSmsTemplatesSuccess = (data: Array<SmsTemplateWithTranslationsDto>): SmsTemplatesActionType => ({
  type: SmsTemplatesActionTypes.GET_SMS_TEMPLATE_SUCCESS,
  response: data
})
export const getSmsTemplatesError = (error: string): SmsTemplatesActionType => ({
  type: SmsTemplatesActionTypes.GET_SMS_TEMPLATE_FAILURE,
  errorMsg: error
})

export const postSmsTemplate = (data: ManageSmsTemplateDto): SmsTemplatesActionType => ({
  type: SmsTemplatesActionTypes.POST_SMS_TEMPLATE_REQUEST,
  data
})
export const postSmsTemplateSuccess = (data: SmsTemplateDto): SmsTemplatesActionType => ({
  type: SmsTemplatesActionTypes.POST_SMS_TEMPLATE_SUCCESS,
  response: data
})
export const postSmsTemplateError = (error: string): SmsTemplatesActionType => ({
  type: SmsTemplatesActionTypes.POST_SMS_TEMPLATE_FAILURE,
  errorMsg: error
})

export const updateSmsTemplate = (data: ManageSmsTemplateDto): SmsTemplatesActionType => ({
  type: SmsTemplatesActionTypes.UPDATE_SMS_TEMPLATE_REQUEST,
  data
})
export const updateSmsTemplateSuccess = (data: SmsTemplateDto): SmsTemplatesActionType => ({
  type: SmsTemplatesActionTypes.UPDATE_SMS_TEMPLATE_SUCCESS,
  response: data
})
export const updateSmsTemplateError = (error: string): SmsTemplatesActionType => ({
  type: SmsTemplatesActionTypes.UPDATE_SMS_TEMPLATE_FAILURE,
  errorMsg: error
})

export const deleteSmsTemplate = (_id: string): SmsTemplatesActionType => ({
  type: SmsTemplatesActionTypes.DELETE_SMS_TEMPLATE_REQUEST,
  _id
})
export const deleteSmsTemplateSuccess = (): SmsTemplatesActionType => ({type: SmsTemplatesActionTypes.DELETE_SMS_TEMPLATE_SUCCESS})

export const deleteSmsTemplateError = (error: string): SmsTemplatesActionType => ({
  type: SmsTemplatesActionTypes.DELETE_SMS_TEMPLATE_FAILURE,
  errorMsg: error
})
