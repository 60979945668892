import { createSelector } from 'reselect';
import { TranlsationLanguage } from './../store/Translations/types';
import { ApplicationState } from './../store/index';
import { store } from '../configureStore';

export const selectTranslations = (state: ApplicationState): TranlsationLanguage[] => state.translations.languages;

export const getTranslation = createSelector(
  selectTranslations,
  (languages: TranlsationLanguage[]) => (key: string): string => {
    const langKey = localStorage.getItem('lang');
    let language = languages.find(lang => lang.key === langKey);
    if (!language) {
        language = languages.find(lang => lang.key === 'sv');
    }
      return (language && language.translations[key]) ? language.translations[key] : key;
  }
);

const getAvailableLanguages = createSelector(
  selectTranslations,
  (languages: TranlsationLanguage[]) => languages.map((l: TranlsationLanguage) => ({key: l.key, inUse: l.key === localStorage.getItem('lang')}))
);

export const languageInUse = createSelector(
    selectTranslations,
    (languages: TranlsationLanguage[]) => {
        const currentLanguage = languages.find(l => l.inUse);
        return currentLanguage ? currentLanguage.key : null;
    }
);

export const getLanguages = getAvailableLanguages;
export const translate = getTranslation(store.getState());
