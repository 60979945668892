import { QuestionerDTO } from "../../sdk";


export interface QuestionersState {
    all: QuestionerDTO[];
}

export enum QuestionersActionTypes {
    GET_ALL_QUESTIONERS = '@@questioners/GET_ALL_QUESTIONERS',
    GET_ALL_QUESTIONERS_SUCCESS = '@@questioners/GET_ALL_QUESTIONERS_SUCCESS',
    GET_ALL_QUESTIONERS_FAILURE = '@@questioners/GET_ALL_QUESTIONERS_FAILURE',
}

interface GetAllQuestionersAction {
    type: typeof QuestionersActionTypes.GET_ALL_QUESTIONERS;
}

interface GetAllQuestionersSuccessAction {
    type: typeof QuestionersActionTypes.GET_ALL_QUESTIONERS_SUCCESS;
    payload: QuestionerDTO[]
}

interface GetAllQuestionersFailureAction {
    type: typeof QuestionersActionTypes.GET_ALL_QUESTIONERS_FAILURE;
    payload: any
}

export type QuestionersActionType =
    GetAllQuestionersAction |
    GetAllQuestionersSuccessAction |
    GetAllQuestionersFailureAction;
