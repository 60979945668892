import { VoucherCodesState, VoucherCodesActionType, VoucherCodesActionTypes } from './types';

const initialState: VoucherCodesState = {
  all: [],
  stats: [],
  errorMsg: ''
};

export function VoucherCodeReducer(
    state = initialState,
    action: VoucherCodesActionType
): VoucherCodesState {
  switch (action.type) {
    case VoucherCodesActionTypes.GET_VOUCHER_CODE_REQUEST:
      return { ...state };
    case VoucherCodesActionTypes.GET_VOUCHER_CODE_FAILURE:
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    case VoucherCodesActionTypes.GET_VOUCHER_CODE_SUCCESS:
      return {
        ...state,
        all: [...action.response],
        errorMsg: ''
      }
    case VoucherCodesActionTypes.POST_VOUCHER_CODE_REQUEST:
      return { ...state };
    case VoucherCodesActionTypes.POST_VOUCHER_CODE_FAILURE:
      alert(action.errorMsg);
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    case VoucherCodesActionTypes.POST_VOUCHER_CODE_SUCCESS:
      return {
        ...state,
        errorMsg: ''
      }
    case VoucherCodesActionTypes.UPDATE_VOUCHER_CODE_REQUEST:
      return { ...state };
    case VoucherCodesActionTypes.UPDATE_VOUCHER_CODE_FAILURE:
      alert(action.errorMsg);
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    case VoucherCodesActionTypes.UPDATE_VOUCHER_CODE_SUCCESS:
      return {
        ...state,
        errorMsg: ''
      }
    case VoucherCodesActionTypes.REFRESH_VOUCHER_CODE_REQUEST:
      return { ...state };
    case VoucherCodesActionTypes.REFRESH_VOUCHER_CODE_FAILURE:
      alert(action.errorMsg);
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    case VoucherCodesActionTypes.REFRESH_VOUCHER_CODE_SUCCESS:
      return {
        ...state,
        errorMsg: ''
      }
    case VoucherCodesActionTypes.DELETE_VOUCHER_CODE_REQUEST:
      return { ...state };
    case VoucherCodesActionTypes.DELETE_VOUCHER_CODE_FAILURE:
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    case VoucherCodesActionTypes.DELETE_VOUCHER_CODE_SUCCESS:
      return {
        ...state,
        all: state.all.filter(el => el._id !== action.response._id),
        errorMsg: ''
      }
    case VoucherCodesActionTypes.GET_VOUCHER_USAGE_STATS_REQUEST:
      return { ...state };
    case VoucherCodesActionTypes.GET_VOUCHER_USAGE_STATS_FAILURE:
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    case VoucherCodesActionTypes.GET_VOUCHER_USAGE_STATS_SUCCESS:
      return {
        ...state,
        stats: [...action.response],
        errorMsg: ''
      }
    default:
      return state;
  }
}
