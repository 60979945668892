import { createSelector } from 'reselect';
import { PatientFieldsToSort, PatientFilters, PatientsState } from '../store/Patients/types';
import { ApplicationState } from '../store';
import { UserDto } from "../sdk";

const selectSmsTemplates = (state: ApplicationState) => state.smsTemplates
export const smsTemplatesSelector = createSelector(
  selectSmsTemplates,
  (templates) => templates.all
)
const selectPatients = (state: ApplicationState): PatientsState => state.patients
const selectFilters = (state: ApplicationState): PatientFilters => state.patients.filters
export const getPatients = createSelector(
  [selectPatients, selectFilters],
  (patientsState: PatientsState): UserDto[] => {
    return patientsState.all
  }
);
export const selectViewedPatients = createSelector(
  selectPatients,
  (patientsState: PatientsState): UserDto[] => {
    return patientsState.selectViewedPatients
  }
);

export const getDeletedPatients = createSelector(
  [selectPatients, selectFilters],
  (patientsState: PatientsState, filters: PatientFilters): Array<UserDto> => {
    const patients = patientsState.all;
    const sortingField = filters.sort.field as keyof PatientFieldsToSort;
    const direction = filters.sort.direction;
    return patients
      .filter(p => filters.searchTerm ? `${p.firstName}_${p.lastName}_${p.email}`.toLowerCase().includes(filters.searchTerm.toLocaleLowerCase()) : true)
      .filter(p => filters.gender === 'all' ? true : p.gender === filters.gender)
      .filter(p => filters.language === 'all' ? true : p.language === filters.language)
      .sort((a, b) => (a[sortingField] > b[sortingField] ? (direction ? 1 : -1) : a[sortingField] < b[sortingField] ? (direction ? -1 : 1) : 0))
  }
);
