import { FamilyActionType, FamilyActionTypes, FamilyState} from './types';
import { AuthActionTypes, AuthActionType } from '../Auth/types';
const initialState: FamilyState = {
  email: '',
  alreadyAtFamily: false,
  id: null,
  referrer: '',
  patientId: '',
  errorMsg: '',
  isExisitngMember: false,
  recognizeInfo: {
    ip: {ip4: '', ip6: ''},
    language: '',
    pixelRatio: 0,
    shortSide: 0,
    longSide: 0,
    deviceType: '',
  }
}

export function FamilyReducer(
  state = initialState,
  action: FamilyActionType | AuthActionType
): FamilyState {
  switch (action.type) {
    case FamilyActionTypes.POST_FAMILY_TOKEN_SUCCESS:
      return {
        ...state,
        patientId: action.inviteData.patientId,
        ...action.inviteData.familyMember,
        isExisitngMember: Boolean(action.inviteData.familyMember.id)
      }
    case FamilyActionTypes.POST_FAMILY_TOKEN_FAILURE:
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    case FamilyActionTypes.POST_FAMILY_MEMBER_SUCCESS:
        return {
          ...state,
          id: action.response.user._id,
          alreadyAtFamily: action.response.alreadyAtFamily
        }
    case AuthActionTypes.POST_SESSION_INFO_SUCCESS:
      return {
        ...state,
        referrer: action.response.referrer
      }
    case FamilyActionTypes.POST_ASK_FAMILY_SURVEY_SUCCESS:
      return {
        ...state,
      }
    case FamilyActionTypes.POST_ASK_FAMILY_SURVEY_FAILURE:
      return {
        ...state,
        errorMsg: action.errorMsg
      }
    default:
      return {
        ...state
      }
  }
}
