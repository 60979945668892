export const questionaryEn = {
  0: {
    type: 1,
    labels: [
      'Do you experience memory difficulties that disturb your daily life?',
      '',
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  1: {
    type: 1,
    labels: [
      'Do you have trouble planning and problem solving?',
      '',
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  2: {
    type: 1,
    labels: [
      'Do you often experience difficulties in carrying out tasks that used to be familiar to you?',
      '',
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  3: {
    type: 1,
    labels: [
      'Do you often get confused as to where you are?',
      '',
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  4: {
    type: 1,
    labels: [
      'Do you often get confused as to what time it is?',
      '',
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  5: {
    type: 1,
    labels: [
      'Do you have difficulties remembering people\'s names over long term?',
      '',
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  6: {
    type: 1,
    labels: [
      'Do you misplace things and cannot retrace your steps to find them?',
      '',
    ],
    optionLabels: [
      ['No', 'Yes']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  }
};

export const questionarySv = {
  0: {
    type: 1,
    labels: [
      'Upplever du minnesproblematik som påverkar din vardag?',
      '',
    ],
    optionLabels: [
      ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  1: {
    type: 1,
    labels: [
      'Har du bekymmer med planering och problemlösning?',
      '',
    ],
    optionLabels: [
       ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  2: {
    type: 1,
    labels: [
      'Upplever du ofta svårigheter med att göra saker som förr brukade vara lättare?',
      '',
    ],
    optionLabels: [
       ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  3: {
    type: 1,
    labels: [
      'Glömmer du ofta bort var du befinner dig?',
      '',
    ],
    optionLabels: [
       ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  4: {
    type: 1,
    labels: [
      'Blir du ofta osäker på över vad klockan är?',
      '',
    ],
    optionLabels: [
       ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  5: {
    type: 1,
    labels: [
      'Har du svårt att långsiktigt komma ihåg namn på folk?',
      '',
    ],
    optionLabels: [
       ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  },
  6: {
    type: 1,
    labels: [
      'Händer det ofta att du glömmer bort var du lägger dina saker?',
      '',
    ],
    optionLabels: [
       ['Nej', 'Ja']
    ],
    disabled: false,
    sort: 1,
    showIdk: false,
    skipIfNo: null,
    expandOn: null
  }
};