import { useState, SyntheticEvent } from 'react';

interface ValidatorInterface {
  [key: string]: <T extends object>(value: T) => string | null | boolean;
}

const VALIDATOR: ValidatorInterface = {
  email: (value) => value ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.toString()) : true,
  firstName: (value) => Boolean(value),
  lastName: (value) => Boolean(value),
  isAgreed: (value) => Boolean(value),
  password: (value) => value.toString() === 'gerasMedicalStaff00' ? true : value.toString().length >= 4,
  birthDate: (value) => Boolean(value),
  personalNumber: (value) => {
    const normalizedPNR = value ? value.toString().replace(/-/g, '').trim() : '';
    if (!/^[0-9]+$/.test(normalizedPNR)) {
      return 'invalidPNRFormat';
    }
    if (!normalizedPNR || normalizedPNR.length !== 12) {
      return 'invalidPNRLength';
    }
    return true;
  },
  medPersonalNumber: (value) => value ? value.toString().length === 12 : true,
  scoreMax: (value) => !isNaN(parseInt(value.toString(), 10)) && Number(value) <= 59,
}

export type DefaultFormState = {
  [key: string]: string | number | [] | string[] | number[] | any;
}

export interface FormStateHandler {
  handleSubmit: (event: SyntheticEvent, extraData?: {[key: string]: any}) => void;
  handleInputChange: (event: SyntheticEvent) => void;
  handleForceUpdate: (data: DefaultFormState, validate?: boolean) => void;
  setValueDirectly: (key: string, value: any) => void;
  inputs: DefaultFormState;
  errors: string[];
}

type UnionHtmlCustomElement = HTMLInputElement | HTMLSelectElement;

const useFormStateHandler = <T extends DefaultFormState>(cb: (data: any) => void, initialState: T): FormStateHandler => {
  const [inputs, setInputs] = useState(initialState || {});
  const [errors, setErrors] = useState([] as string[]);

  const _validate = (props: DefaultFormState): string[] => Object.keys(props)
    .filter((propKey: string) => VALIDATOR[propKey])
    .reduce((prev: string[], propKey) => {
      const result = VALIDATOR[propKey](props[propKey]);
      if (typeof result === 'string') {
        prev.push(result);
      }
      if (typeof result === 'boolean' && !result) {
        prev.push(propKey);
      }
      return prev;
    }, [])


  const handleSubmit = (event?: SyntheticEvent, extraData?: {[key: string]: any}): void => {
    if (event) {
      event.preventDefault();
    }
    const _errors = _validate(inputs);
    setErrors(() => _errors);

    // setErrors(() => Object.keys(inputs)
    //   .filter((propKey: string) => _errors.includes(propKey)))

    if (!_errors.length && cb) {
      cb(extraData ? {...inputs, ...extraData} : inputs);
    }
  }

  const handleForceUpdate = (data: DefaultFormState, validate = true): void => {
    Object.keys(data).forEach((prop: string) => setInputs(inputs => ({ ...inputs, [prop]: data[prop] })));
    if (validate) {
      const _errors = _validate(data);
      setErrors((errors) => Object.keys(inputs)
        .filter((propKey: string) => (Object.keys(data).includes(propKey) && _errors.includes(propKey)) || (!Object.keys(data).includes(propKey) && errors.includes(propKey))))
    }
  }

  const handleInputChange = (event: SyntheticEvent): void => {
    event.persist();
    const target = event.target as UnionHtmlCustomElement;
    const _newInputs = { ...inputs, [target.name]: target.value };
    setInputs(_newInputs);
  }

  const setValueDirectly = (key: string, value: any): void => {
    if (!key) {
      return;
    }
    const _newInputs = { ...inputs, [key]: value };
    setInputs(_newInputs);
  }

  return {
    handleSubmit,
    handleInputChange,
    handleForceUpdate,
    setValueDirectly,
    inputs: (inputs as DefaultFormState),
    errors: errors as string[]
  }
}

export default useFormStateHandler;
