import React, {useRef, useLayoutEffect} from 'react';
import { translate } from '../../selectors/translations';
interface SelectorButtonProps {
  icon?: React.ReactElement;
  Elem: React.ReactElement;
  dropdownId: string;
  type: string;
  classPrefix: string;
  title?: string;
  onToggleMenu: (id: string) => void;
}

const SelectorButton: React.FC<SelectorButtonProps> = (props: SelectorButtonProps) => {
  const {dropdownId, title, type, onToggleMenu, Elem, icon, classPrefix} = props;
  const node: any = useRef<HTMLDivElement>(null);

  const handleClickOutside = (e: Event) => {        
    if (!node.current.contains(e.target)) {
      // outside click
      onToggleMenu('')
      return;
    }
  }

  useLayoutEffect(() => {
    if (dropdownId && (dropdownId === type)) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return (): void => {
      document.removeEventListener("mousedown", handleClickOutside);        
    };
  }, [dropdownId]);
  return (<div ref={node}>
    <button
      onClick={(): void => onToggleMenu(type === dropdownId ? '' : type)}
      className={`search_btn search_btn${classPrefix}`}
      type="button">
      {icon && <div className="icon-holder">{icon}</div>}
      {title && translate(title) }
    </button>
    { (type === dropdownId) && Elem }
  </div>);
}

export default SelectorButton;
