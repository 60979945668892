/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DoctorNoteType {
    JOURNAL_NOTE = 'journal-note',
    DIAGNOSIS = 'diagnosis',
    SUMMARY_RESULTS_AND_ASSESSMENT = 'summary-results-and-assessment',
    RECOMMENDATION = 'recommendation',
}
