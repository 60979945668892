import { ActionPattern, fork, put, takeEvery } from "redux-saga/effects";


export function generator<
  Endpoint extends (args?: any) => any,
  SuccessHandler extends (arg: any) => any,
  ErrorHandler extends (arg: string) => any
>(endpoint: Endpoint) {
  return {
    onSuccess: (onSuccessHandler: SuccessHandler[] | SuccessHandler) => {
      return {
        onError: (onErrorHandler: ErrorHandler[] | ErrorHandler) => {
          return {
            bind: (actionType: ActionPattern) => {
              const fn = function* (data: any) {
                const res: any = yield endpoint(data)

                if (res.success) {
                  if (Array.isArray(onSuccessHandler)) {
                    for (const successEffect of (onSuccessHandler as SuccessHandler[])) {
                      yield put(successEffect(res.data))
                    }
                  } else {
                    yield put(onSuccessHandler(res.data))
                  }
                } else {
                  if (Array.isArray(onErrorHandler)) {
                    for (const successEffect of onErrorHandler) {
                      yield put(successEffect(res.error))
                    }
                  } else {
                    yield put(onErrorHandler(res.error))
                  }
                }
              }

              return bindHandler(actionType, fn)
            }
          }
        }
      }
    }
  }
}

export function bindHandler<P extends ActionPattern>(actionType: P, fn: (data: any) => any) {
  return fork(function* () {
    yield takeEvery(actionType, fn)
  })
}
